import { useSelector } from 'src/hooks/useSelector';
import UserLabel from 'src/utils/constants/userLabels';

interface WorkflowState {
    isResponded: boolean;
    isInvited: boolean;
    isDiscarded: boolean;
    showWorkflowStatus: boolean;
}

export const useWorkflowState = (vacancyId: number): WorkflowState => {
    const userLabels = useSelector((state) => state.userLabelsForVacancies)[vacancyId];

    const isResponded = userLabels?.includes(UserLabel.Response);
    const isInvited = userLabels?.includes(UserLabel.Invite);
    const isDiscarded = userLabels?.includes(UserLabel.Discard);
    const showWorkflowStatus = isDiscarded || isInvited || isResponded;

    return { isResponded, isInvited, isDiscarded, showWorkflowStatus };
};
