import { forwardRef, ForwardRefRenderFunction, useContext } from 'react';
import classnames from 'classnames';

import { Badge } from '@hh.ru/magritte-ui';

import isRedesignActiveContext from 'src/components/SupernovaMainMenu/isRedesignActiveContext';

import styles from './styles.less';

interface SupernovaBadgeProps {
    children: number | string;
    onLightBg: boolean;
    'data-qa'?: string;
}

const SupernovaBadge: ForwardRefRenderFunction<HTMLDivElement, SupernovaBadgeProps> = (
    { children, onLightBg, ...props },
    forwardedRef
) => {
    let content: string = children.toString();
    const isRedesignActive = useContext(isRedesignActiveContext);

    if (typeof children === 'number') {
        if (children > 1000) {
            content = `${Math.floor(children / 1000)}K`;
        } else if (children > 99) {
            content = '99+';
        }
    }

    return (
        <span className={classnames({ [styles.empty]: !content })}>
            <Badge
                style={isRedesignActive && !onLightBg ? 'contrast' : 'accent'}
                size="small"
                {...props}
                ref={forwardedRef}
            >
                {content}
            </Badge>
        </span>
    );
};

export default forwardRef(SupernovaBadge);
