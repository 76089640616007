import { onTTFB, onCLS, onLCP, onFCP, onINP } from 'web-vitals';

import Analytics from '@hh.ru/analytics-js';

import statsSender from 'HHC/Performance/StatsSender';

const onReport = ({ name, value }) => {
    const metricName = name.toLowerCase();

    statsSender.sendMetrics({
        metricName,
        valueInMs: value,
    });

    if (name !== 'CLS') {
        Analytics.sendHHEvent('xhh_performance_metrics', {
            metricName,
            value,
        });
    }
};

onINP(onReport);
onTTFB((metric) => {
    onReport(metric);

    const { value } = metric;
    const performanceNavigation = window.performance?.getEntriesByType('navigation')[0];
    const serverTiming = performanceNavigation?.serverTiming;

    if (!serverTiming) {
        return;
    }

    serverTiming.forEach(({ name, duration }) => {
        if (name === 'frontik') {
            try {
                const durationMs = duration * 1000;
                const networkTimeMs = value - durationMs;

                statsSender.sendMetrics([
                    {
                        metricName: 'network-time',
                        valueInMs: networkTimeMs,
                    },
                    {
                        metricName: 'bff-time',
                        valueInMs: durationMs,
                    },
                ]);

                Analytics.sendHHEvent('xhh_performance_metrics', {
                    metricName: 'bff_xhh_time',
                    value: durationMs,
                });
                Analytics.sendHHEvent('xhh_performance_metrics', {
                    metricName: 'network_xhh_time',
                    value: networkTimeMs,
                });
            } catch (_) {} // eslint-disable-line no-empty
        }
    });
});
onCLS(onReport);
onLCP(onReport);
onFCP(onReport);
