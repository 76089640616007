import { Dispatch } from 'redux';

import { makeSetStoreField } from '@hh.ru/redux-create-reducer';

import SearchType from 'src/components/Search/Common/Filters/types';
import { EmployerVacancySearchParams } from 'src/components/Search/Common/Filters/utils/getSearchParams/employerVacancy';
import { ResumeSearchParams } from 'src/components/Search/Common/Filters/utils/getSearchParams/resume';
import { VacancySearchParams } from 'src/components/Search/Common/Filters/utils/getSearchParams/vacancy';
import { VacancyMapSearchParams } from 'src/components/Search/Common/Filters/utils/getSearchParams/vacancyMap';
import { NovaFilterKey } from 'src/models/novaFilters';

const updateSavedAreaIds = makeSetStoreField('savedAreaIds');

// We force changing saved (detected) area when user changes area filter manually
export const adjustSavedArea = (
    lastUpdatedSearchCluster: NovaFilterKey | null,
    searchType: SearchType,
    isAdjustDetectedRegionResumeExpEnabled: boolean,
    searchParams: VacancySearchParams | ResumeSearchParams | VacancyMapSearchParams | EmployerVacancySearchParams,
    dispatch: Dispatch
): void => {
    // if user changes any filter other than area, we don't touch detected area
    if (lastUpdatedSearchCluster !== NovaFilterKey.Area) {
        return;
    }

    const isResumeSearch = searchType === SearchType.Resume;
    // force writing detected area into link
    // and update redux state (experiment adjust_detected_region_resume and always for vacancy search)
    if (isAdjustDetectedRegionResumeExpEnabled || !isResumeSearch) {
        searchParams.L_save_area = true;
        dispatch(updateSavedAreaIds(searchParams.area?.map(String) ?? []));
    }
};
