import { useEffect, useRef, useState } from 'react';
import classnames from 'classnames';

import cookiesBannerBrButtonClickAccept from '@hh.ru/analytics-js-events/build/xhh/common/notifications/cookies_banner_br_button_click_accept';
import cookiesBannerBrButtonClickDecline from '@hh.ru/analytics-js-events/build/xhh/common/notifications/cookies_banner_br_button_click_decline';
import cookiesBannerBrElementShown from '@hh.ru/analytics-js-events/build/xhh/common/notifications/cookies_banner_br_element_shown';
import {
    Text,
    Button as ButtonMagritte,
    Link as LinkMagritte,
    HSpacing,
    VSpacing,
    useBreakpoint,
} from '@hh.ru/magritte-ui';
import { Link } from '@hh.ru/redux-spa-middleware';
import Button, { ButtonKind } from 'bloko/blocks/button';
import Column, { ColumnsWrapper } from 'bloko/blocks/column';
import BlokoLink from 'bloko/blocks/link';
import Cookies from 'bloko/common/Cookies';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import LocalStorageWrapper from 'bloko/common/storage/LocalStorageWrapper';

import ElementShownAnchor from 'src/components/ElementShownAnchor';
import NoIndex from 'src/components/NoIndex';
import { COOKIE_SUBSCRIPTION_INFORMER_NAME } from 'src/components/SubscriptionInformer';
import translation from 'src/components/translation';
import { useIsBelarus } from 'src/hooks/useCountries';
import { useIsIosEmployerWebViewSpecificVersion, useIsIosJTBApplicantWebView } from 'src/hooks/useIsWebView';
import { useIsZarplataPlatform } from 'src/hooks/usePlatform';
import { useSelector } from 'src/hooks/useSelector';

import {
    COOKIE_AGREEMENT_NAME,
    RESELECT_PARAMETER,
    COOKIE_EXPIRE_TIME,
    LOCALSTORAGE_KEY,
    clearCookies,
    checkUrlAndReload,
    setTopLevelCookie,
} from 'src/components/CookiesPolicy/utils';

import styles from './banner.less';

const TrlKeys = {
    accept: 'cookiePolicy.banner.accept',
    decline: 'cookiePolicy.banner.decline',
    text: 'cookiePolicy.banner.text',
    more: 'cookiePolicy.banner.more',
    textMagritte: 'cookiePolicy.informer.text.magritte',
    linkMagritte: 'cookiePolicy.informer.link.magritte',
};

const Features = {
    cookiesPolicyBanner: 'cookies_policy_banner',
    criticalCookiesRegexpList: 'critical_cookies_regexp_list',
    cookiesPolicyExcludedAppVersion: 'cookies_policy_excluded_app_version',
};

const CookiesPolicy: TranslatedComponent = ({ trls }) => {
    const [isVisible, setIsVisible] = useState(false);
    const isBelarus = useIsBelarus();
    const topLevelDomain = useSelector((state) => state.topLevelDomain);
    const isCookiesPolicyBannerEnabled = useSelector((state) => state.features[Features.cookiesPolicyBanner]);
    const excludedAppVersion = useSelector(
        (state) => state.features[Features.cookiesPolicyExcludedAppVersion]
    ) as string;
    const criticalCookiesRegexpList = useSelector(
        (state) => (state.features[Features.criticalCookiesRegexpList] as string) || ''
    );
    const criticalCookiesRegexpString = useRef<string>(criticalCookiesRegexpList.split(/\r\n|\n|\r/).join('|'));
    const reselectCookiePolicy = useSelector((state) => state.router.location.query?.[RESELECT_PARAMETER] === 'true');
    const isIosJTBApplicantWebView = useIsIosJTBApplicantWebView();
    const isExcludedAppVersion = useIsIosEmployerWebViewSpecificVersion(excludedAppVersion);
    const isZp = useIsZarplataPlatform();
    const shouldShow = isCookiesPolicyBannerEnabled && isBelarus && !isIosJTBApplicantWebView && !isExcludedAppVersion;
    const { isXS } = useBreakpoint();
    const isAnonymousMagritteExp = useSelector((state) => state.isAnonymousMagritteExp);
    const hhtmSource = useSelector((state) => state.analyticsParams.hhtmSource);

    useEffect(() => {
        if (!shouldShow) {
            return;
        }

        const cookieAgreementValue = Cookies.get(COOKIE_AGREEMENT_NAME);
        const isAlreadyAnswered = cookieAgreementValue !== null;
        const needToClear = !isAlreadyAnswered || LocalStorageWrapper.getItem(LOCALSTORAGE_KEY);

        setIsVisible(!isAlreadyAnswered || reselectCookiePolicy);

        if (needToClear) {
            clearCookies(topLevelDomain!, criticalCookiesRegexpString.current);
            LocalStorageWrapper.removeItem(LOCALSTORAGE_KEY);
        }
    }, [shouldShow, reselectCookiePolicy, topLevelDomain]);

    if (!isVisible || !shouldShow) {
        return null;
    }

    const setAgreed = () => {
        cookiesBannerBrButtonClickAccept();
        setTopLevelCookie(COOKIE_AGREEMENT_NAME, 'true', COOKIE_EXPIRE_TIME);
        setTopLevelCookie(COOKIE_SUBSCRIPTION_INFORMER_NAME, 'true', 1);
        setIsVisible(false);
        checkUrlAndReload();
    };

    const setDeclined = () => {
        cookiesBannerBrButtonClickDecline();
        setTopLevelCookie(COOKIE_AGREEMENT_NAME, 'false', COOKIE_EXPIRE_TIME);
        clearCookies(topLevelDomain!, criticalCookiesRegexpString.current);
        LocalStorageWrapper.setItem(LOCALSTORAGE_KEY, 'true');
        setIsVisible(false);
        checkUrlAndReload();
    };

    if (isAnonymousMagritteExp) {
        return (
            <NoIndex>
                <ElementShownAnchor fn={cookiesBannerBrElementShown}>
                    <div className={classnames(styles.wrapper, styles.wrapperMagritte, { [styles.wrapperTop]: isXS })}>
                        <Text
                            data-qa="cookiePolicyBannerText"
                            style="contrast"
                            typography="paragraph-2-regular"
                            Element="span"
                        >
                            {trls[TrlKeys.textMagritte]}{' '}
                            <LinkMagritte
                                inline
                                underlined
                                style="contrast"
                                href={`/article/cookie_policy?hhtmFrom=${hhtmSource}`}
                            >
                                {trls[TrlKeys.linkMagritte]}
                            </LinkMagritte>
                        </Text>
                        {isXS ? <VSpacing default={16} /> : <HSpacing default={24} />}
                        <div>
                            <ButtonMagritte
                                size="small"
                                style="contrast"
                                mode="primary"
                                data-qa="cookies-policy-informer-accept"
                                onClick={setAgreed}
                            >
                                {trls[TrlKeys.accept]}
                            </ButtonMagritte>
                            <HSpacing default={12} />
                            <ButtonMagritte
                                size="small"
                                style="contrast"
                                mode="primary"
                                onClick={setDeclined}
                                data-qa="cookies-policy-informer-decline"
                            >
                                {trls[TrlKeys.decline]}
                            </ButtonMagritte>
                        </div>
                    </div>
                </ElementShownAnchor>
            </NoIndex>
        );
    }

    return (
        <NoIndex>
            <div className={styles.wrapper}>
                <ElementShownAnchor fn={cookiesBannerBrElementShown}>
                    <ColumnsWrapper>
                        <Column xs="4" s="8" m="12" l="16">
                            <div className={styles.content}>
                                <div data-qa="cookiePolicyBannerText" className={styles.text}>
                                    {trls[TrlKeys.text]}{' '}
                                    {isZp ? (
                                        <BlokoLink href="https://hhcdn.ru/file/17961880.doc" target="_blank">
                                            {trls[TrlKeys.more]}
                                        </BlokoLink>
                                    ) : (
                                        <Link to="/article/cookie_policy" target="_blank">
                                            {trls[TrlKeys.more]}
                                        </Link>
                                    )}
                                </div>
                                <div className={styles.buttons}>
                                    <Button
                                        data-qa="cookiePolicyBannerAccept"
                                        kind={ButtonKind.Primary}
                                        onClick={setAgreed}
                                        stretched
                                    >
                                        {trls[TrlKeys.accept]}
                                    </Button>
                                    <Button onClick={setDeclined} kind={ButtonKind.Primary} stretched>
                                        {trls[TrlKeys.decline]}
                                    </Button>
                                </div>
                            </div>
                        </Column>
                    </ColumnsWrapper>
                </ElementShownAnchor>
            </div>
        </NoIndex>
    );
};

export default translation(CookiesPolicy);
