import { useEffect, useState } from 'react';

import Analytics from '@hh.ru/analytics-js';
import debounce from 'bloko/common/debounce';
import LocalStorageWrapper from 'bloko/common/storage/LocalStorageWrapper';

import { ResponseStep } from 'src/components/VacancyResponsePopup/BottomSheet/responseSteps';
import useGetResponseQuestion from 'src/components/VacancyView/hooks/useResponseQuestion';
import { useSelector } from 'src/hooks/useSelector';
import { ResponseQuestion } from 'src/models/vacancyResponseQuestions';

interface LetterProps {
    vacancyId: number;
    responseStep?: ResponseStep;
    defaultQuestionTrl: string;
    isQuestionResponse?: boolean;
}

interface LetterState {
    isLetterExpanded: boolean;
    expandLetter: () => void;
    letterValue: string;
    setLetterValue: (value: string) => void;
    isLetterValueEmpty: boolean;
    isLetterRequired: boolean;
    letterMaxLength: number;
}

const saveLetterToLocalstorage = debounce((localstorageKey: string, letterText: string) => {
    LocalStorageWrapper.setItem(localstorageKey, letterText);
}, 100);

export default function useLetter({
    vacancyId,
    responseStep,
    defaultQuestionTrl,
    isQuestionResponse,
}: LetterProps): LetterState {
    const [isExpandedByClick, setIsExpandedByClick] = useState(false);
    const responseStatus = useSelector((state) => state.applicantVacancyResponseStatuses[vacancyId]);
    const lastQuestionResponse = useSelector((state) => state.lastQuestionResponse);

    const isExpandedByStep = responseStep === ResponseStep.Letter;
    const vacancy = responseStatus.shortVacancy;
    const isLetterRequired = vacancy['@responseLetterRequired'];

    const getVacancyResponseQuestion = useGetResponseQuestion();
    const responseQuestion = getVacancyResponseQuestion(vacancyId);
    const isExpandedByOtherQuestion = responseQuestion?.id === ResponseQuestion.Other;

    const localstorageLetterKey = `vacancy_response_letter_${vacancyId}`;
    const localstorageQuestionKey = `vacancy_response_question_${vacancyId}`;
    const [letterText, setLetterText] = useState(
        LocalStorageWrapper.getItem(localstorageLetterKey) || responseQuestion?.value || ''
    );
    const [questionText, setQuestionText] = useState(
        lastQuestionResponse || LocalStorageWrapper.getItem(localstorageQuestionKey) || defaultQuestionTrl
    );

    const expandLetter = () => {
        setIsExpandedByClick(true);
        Analytics.sendHHEventButtonClick('vacancy_response_letter_toggle');
    };

    useEffect(() => {
        saveLetterToLocalstorage(
            isQuestionResponse ? localstorageQuestionKey : localstorageLetterKey,
            isQuestionResponse ? questionText : letterText
        );
    }, [letterText, questionText, localstorageQuestionKey, localstorageLetterKey, isQuestionResponse]);

    useEffect(() => {
        if (!isQuestionResponse && responseQuestion) {
            setLetterText(responseQuestion.value);
        }
    }, [isQuestionResponse, responseQuestion]);

    useEffect(() => {
        if (isQuestionResponse && lastQuestionResponse) {
            setQuestionText(lastQuestionResponse);
        }
    }, [isQuestionResponse, lastQuestionResponse]);

    const letterMaxLength = responseStatus.letterMaxLength;
    const letterValue = isQuestionResponse ? questionText : letterText;
    const setLetterValue = isQuestionResponse ? setQuestionText : setLetterText;
    const isLetterValueEmpty = isQuestionResponse ? !questionText.length : !letterText.length;
    const isExpandedByAnyCondition =
        isLetterRequired ||
        isQuestionResponse ||
        isExpandedByClick ||
        isExpandedByStep ||
        isExpandedByOtherQuestion ||
        Boolean(letterValue);

    return {
        expandLetter,
        isLetterExpanded: isExpandedByAnyCondition,
        letterValue,
        setLetterValue,
        isLetterValueEmpty,
        isLetterRequired,
        letterMaxLength,
    };
}
