import { FC } from 'react';

import { Text } from '@hh.ru/magritte-ui';

interface ItemTextProps {
    text: string;
    date?: string;
}

const ItemText: FC<ItemTextProps> = ({ date, text }) => {
    return (
        <div>
            <div className="updates-text">
                <Text typography="label-2-regular">{text}</Text>
            </div>

            {date && (
                <Text typography="label-3-regular" style="secondary">
                    {date}
                </Text>
            )}
        </div>
    );
};

export default ItemText;
