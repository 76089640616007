import { Breakpoint, getBreakpoint } from 'bloko/common/media';

const isXsFriendly = (): boolean => {
    return document.body.className.includes('xs-friendly');
};

/**
 * Утилита для проверки текущего media breakpoint.
 *
 * @type {Object}
 * @exports Utils/Media
 */
const Media = {
    /**
     * Работает ли в текущий момент xs-breakpoint.
     *
     * @returns {Boolean}
     *
     * @member
     * @method
     */
    isXs(): boolean {
        return isXsFriendly() && getBreakpoint() === Breakpoint.XS;
    },

    isXsFriendly,

    isS(): boolean {
        return document.body.className.includes('s-friendly') && getBreakpoint() === Breakpoint.S;
    },
};
export default Media;
