export const TAB_IDS = {
    NEW_TAB_ID: 'new',
    COMING_SOON_TAB_ID: 'coming_soon',
} as const;

export type TabValue = (typeof TAB_IDS)[keyof typeof TAB_IDS];

export enum UpdateType {
    New = 'new',
    Soon = 'soon',
}
