import { useCallback, useContext } from 'react';

import { AbortApplyFiltersContext } from 'src/components/Search/Common/Filters/AbortApplyFiltersContext/utils';

type AbortAndGetUpdatedSignalFunction = () => AbortSignal;

export const useUpdateAbortController = (): AbortAndGetUpdatedSignalFunction => {
    const abortControllerRef = useContext(AbortApplyFiltersContext);

    return useCallback(() => {
        abortControllerRef.current?.abort();

        const abortController = new AbortController();
        abortControllerRef.current = abortController;

        return abortController.signal;
    }, [abortControllerRef]);
};
