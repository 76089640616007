import { useCallback } from 'react';

import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import FilterItemWithCheckbox from 'src/components/Search/Common/Filters/FilterItemWithCheckbox';
import FilterList from 'src/components/Search/Common/Filters/FilterList';
import FilterWrapper from 'src/components/Search/Common/Filters/FilterWrapper';
import MobileViewControl from 'src/components/Search/Common/Filters/MobileViewControl';
import { useApplyFilter } from 'src/components/Search/Common/Filters/hooks/useApplyFilter';
import { useFilterGroups } from 'src/components/Search/Common/Filters/hooks/useFilterGroups';
import { toggleValueInArray } from 'src/components/Search/Common/Filters/utils/toggleValueInArray';
import MobileFilter from 'src/components/Search/Vacancy/Filters/MobileFilter';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { NovaFilterGroup, NovaFilterKey } from 'src/models/novaFilters';
import { PartTimeOption } from 'src/models/search/searchCriteria.types';

const EMPTY_SELECTED_VALUES = [] as Array<PartTimeOption>;
const EMPTY_ORDERS = [] as Array<string>;

const TrlKeys = {
    title: 'cluster.partTime',
};

const PartTime: TranslatedComponent = ({ trls }) => {
    const applyFilter = useApplyFilter(NovaFilterKey.PartTime);

    const groups = useSelector((state) => state.searchClusters[NovaFilterKey.PartTime].groups);
    const selectedValues =
        useSelector((state) => state.searchClusters[NovaFilterKey.PartTime].selectedValues) || EMPTY_SELECTED_VALUES;
    const orders = useSelector((state) => state.searchClustersOrder[NovaFilterKey.PartTime]) || EMPTY_ORDERS;
    const { items } = useFilterGroups(groups, selectedValues.length, orders, false);

    const onChange = useCallback(
        ({ id }: NovaFilterGroup<PartTimeOption>) => applyFilter(toggleValueInArray(id, selectedValues)),
        [applyFilter, selectedValues]
    );

    return (
        <MobileViewControl mobileView={<MobileFilter title={trls[TrlKeys.title]} name={NovaFilterKey.PartTime} />}>
            <FilterWrapper filterKey={NovaFilterKey.PartTime} title={trls[TrlKeys.title]}>
                <FilterList>
                    {items.map((item) => (
                        <FilterItemWithCheckbox
                            key={item.id}
                            item={item}
                            name={NovaFilterKey.PartTime}
                            onChange={() => onChange(item)}
                            checked={selectedValues.includes(item.id)}
                        />
                    ))}
                </FilterList>
            </FilterWrapper>
        </MobileViewControl>
    );
};

export default translation(PartTime);
