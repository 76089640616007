import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

export interface ProfessionalRoleCategory {
    id: string;
    name: string;
    url: string;
}

export interface ProfessionalRoleCategoryCounted extends ProfessionalRoleCategory {
    count: number;
}

interface ProfessionalRoleCategoryCollection {
    title: string;
    titleNom: string;
    collection: ProfessionalRoleCategory[];
    collectionCounted: ProfessionalRoleCategoryCounted[];
}

export default autoGeneratedReducer<ProfessionalRoleCategoryCollection>(null);
