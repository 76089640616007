import { type FC, type ComponentType } from 'react';

import notificationCloseButtonClick from '@hh.ru/analytics-js-events/build/xhh/common/notifications/notification_close_button_click';
import Notification from 'bloko/blocks/notificationManager/Notification';

import NotificationAnalytics from 'src/components/Notifications/NotificationAnalytics';

import type {
    StoredNotification,
    BlokoNotificationComponent,
    RemoveNotification,
} from 'src/components/Notifications/Provider/types';

const BlokoNotifications: FC<{
    notifications: StoredNotification[];
    removeNotificationByIdAction: RemoveNotification;
}> = ({ notifications, removeNotificationByIdAction }) => {
    return (
        <>
            {notifications.map(({ component, id, props }) => {
                const { Element, onClose, notificationName, ...notificationProps } =
                    component as BlokoNotificationComponent;
                const removeNotification = removeNotificationByIdAction.bind(null, id);
                const bindedOnClose = onClose?.bind(null, removeNotification, props);
                const onCloseCallback = () => {
                    bindedOnClose?.();
                    removeNotification();
                    notificationName && notificationCloseButtonClick({ notificationName });
                };
                const elProps = {
                    ...props,
                    removeNotification,
                };

                const ElementComponent = Element as ComponentType<{ removeNotification: RemoveNotification }>;
                const children = <ElementComponent {...elProps} />;

                return (
                    <Notification {...notificationProps} key={`notify-${id}`} onClose={onCloseCallback}>
                        {notificationName ? (
                            <NotificationAnalytics notificationName={notificationName}>
                                {children}
                            </NotificationAnalytics>
                        ) : (
                            children
                        )}
                    </Notification>
                );
            })}
        </>
    );
};

export default BlokoNotifications;
