import { FC, useRef } from 'react';

import { TooltipHover } from '@hh.ru/magritte-ui';
import { HrBrandOutlinedCircledSize16, HrBrandOutlinedSize24, HrBrandOutlinedSize16 } from '@hh.ru/magritte-ui/product';
import { Link } from '@hh.ru/redux-spa-middleware';
import HoverTip, { TipPlacement, TipLayer } from 'bloko/blocks/drop/Tip/HoverTip';

import BadgeContainer from 'src/components/Employer/BadgeContainer';
import { useSelector } from 'src/hooks/useSelector';
import { HrBrand } from 'src/models/employer/employerInfo';

import HrBrandContent from 'src/components/HrBrandIcon/HrBrandContent';

const IconSizeMap = {
    small: HrBrandOutlinedSize16,
    medium: HrBrandOutlinedSize24,
} as const;

export type HrBrandIconSize = keyof typeof IconSizeMap;

interface Props {
    hrBrand: HrBrand;
    size?: HrBrandIconSize;
    dataQa: string;
    isRedesignedIcon?: boolean;
    isVacancyPage?: boolean;
    isMagritte?: boolean;
}

const HrBrandIcon: FC<Props> = ({ hrBrand, size = 'medium', dataQa, isRedesignedIcon, isVacancyPage, isMagritte }) => {
    const activatorRef = useRef<HTMLSpanElement>(null);
    const userType = useSelector((state) => state.userType);
    if (!hrBrand.nominees && !hrBrand.winners) {
        return null;
    }
    const utmCampaign = isVacancyPage ? 'badge' : 'icon';
    const urlParams = `?utm_source=hh.ru&utm_medium=referral&utm_campaign=${utmCampaign}&utm_term=${userType}`;

    const dataQaSpecific = dataQa
        ? Object.keys(hrBrand)
              .map((hrBrandType) => {
                  return `${dataQa}_${hrBrandType}`;
              })
              .join(' ')
        : '';

    const url = `${hrBrand?.nominees?.['@url'] || hrBrand?.winners?.['@url'] || ''}${urlParams}`;

    if (isMagritte) {
        return (
            <Link to={url} target="_blank" rel="nofollow noindex" data-qa={dataQa ? `${dataQa} ${dataQaSpecific}` : ''}>
                <span ref={activatorRef}>
                    <HrBrandOutlinedCircledSize16 initial="warning-secondary" />
                </span>
                <TooltipHover placement="top-center" activatorRef={activatorRef}>
                    <HrBrandContent hrBrand={hrBrand} />
                </TooltipHover>
            </Link>
        );
    }

    const IconComponent = IconSizeMap[size];

    return (
        <Link to={url} target="_blank" rel="nofollow noindex" data-qa={dataQa ? `${dataQa} ${dataQaSpecific}` : ''}>
            <HoverTip
                render={() => <HrBrandContent hrBrand={hrBrand} />}
                placement={TipPlacement.Top}
                layer={TipLayer.Overlay}
                host={!process.env.SSR ? document.body : null}
                activatorRef={activatorRef}
            >
                {isRedesignedIcon ? (
                    <span className={'vacancy-serp-bage-hr-brand'} ref={activatorRef} />
                ) : (
                    <span ref={activatorRef}>
                        <BadgeContainer size={size} icon={<IconComponent initial="accent" />} />
                    </span>
                )}
            </HoverTip>
        </Link>
    );
};
export default HrBrandIcon;
