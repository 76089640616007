import { useCallback, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import Analytics from '@hh.ru/analytics-js';
import {
    Avatar,
    Button,
    Card,
    Cell,
    CellText,
    Divider,
    LoadingContextProvider,
    Skeleton,
    Text,
    VSpacing,
} from '@hh.ru/magritte-ui';
import { ChevronRightOutlinedSize16 } from '@hh.ru/magritte-ui/icon';
import { makeSetStoreField } from '@hh.ru/redux-create-reducer';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import urlParser from 'bloko/common/urlParser';

import paths from 'src/app/routePaths';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import fetcher from 'src/utils/fetcher';

import Resume, { VacancySuitableResume } from 'src/components/VacancySuitableResumesBanner/Resume';

const SUITABLE_RESUMES_URL = '/shards/employer/vacancy/suitable_resumes';

declare global {
    interface FetcherGetApi {
        [SUITABLE_RESUMES_URL]: {
            queryParams: {
                vacancyId: number;
            };
            response: VacancySuitableResumes;
        };
    }
}

interface VacancySuitableResumesBannerProps {
    vacancyId: number;
}

const TrlKeys = {
    title: 'employer.vacancy.suitableResumesBanner.title',
    description: 'employer.vacancy.suitableResumesBanner.description',
    button: 'employer.vacancy.suitableResumesBanner.button',
};

export interface VacancySuitableResumes {
    total: number;
    resumes: VacancySuitableResume[];
    vacancyId: number;
}

const MAX_RESUMES = 99;

const renderSkeleton = () => (
    <Cell
        right={<ChevronRightOutlinedSize16 initial="tertiary" />}
        left={<Avatar.Skeleton aria-label="avatar" mode="placeholder" size={48} />}
        vertPadding
        align="top"
    >
        <CellText>
            <Skeleton width={91} height={18} borderRadius={4} />
            <VSpacing default={12} />
            <Skeleton width={140} height={18} borderRadius={4} />
        </CellText>
    </Cell>
);

export enum BannerState {
    Skeleton = 'skeleton',
    List = 'list',
    Error = 'error',
    Empty = 'empty',
}

const setVacancySuitableResumes = makeSetStoreField('vacancySuitableResumes');
const setVacancySuitableResumesBannerState = makeSetStoreField('vacancySuitableResumesBannerState');

const getResumesTotal = (total: number) => (total > MAX_RESUMES ? `${MAX_RESUMES}+` : total);

const VacancySuitableResumesBanner: TranslatedComponent<VacancySuitableResumesBannerProps> = ({ vacancyId, trls }) => {
    const vacancySuitableResumes = useSelector(({ vacancySuitableResumes }) => vacancySuitableResumes);
    const vacancySuitableResumesBannerState = useSelector(
        ({ vacancySuitableResumesBannerState }) => vacancySuitableResumesBannerState
    );

    const dispatch = useDispatch();
    const skeletonRef = useRef<HTMLDivElement>(null);
    const listRef = useRef<HTMLDivElement>(null);

    const suitableResumesUrl = `${paths.resumeSearch}?${urlParser.stringify({
        vacancy_id: vacancyId,

        order_by: 'relevance',

        search_period: 365,
        from: 'vacancy_view_suitable_resumes_banner',
        hhtmFromLabel: 'vacancy_view_suitable_resumes_banner',
    })}`;

    const fetchData = useCallback(async () => {
        try {
            const data = await fetcher.get(SUITABLE_RESUMES_URL, {
                params: { vacancyId },
            });
            dispatch(setVacancySuitableResumes(data));
            dispatch(
                setVacancySuitableResumesBannerState(data.resumes.length === 0 ? BannerState.Empty : BannerState.List)
            );
        } catch (_) {
            dispatch(setVacancySuitableResumesBannerState(BannerState.Error));
        }
    }, [dispatch, vacancyId]);

    useEffect(() => {
        if (skeletonRef.current) {
            Analytics.sendHHEventElementShown(skeletonRef.current, {
                name: 'vacancy_suitable_resumes_banner_skeleton',
                vacancyId,
            });
        }
        if (listRef.current) {
            Analytics.sendHHEventElementShown(listRef.current, {
                name: 'vacancy_suitable_resumes_banner',
                vacancyId,
                suitableResumesCount: vacancySuitableResumes?.total,
            });
        }

        if (
            vacancySuitableResumesBannerState === BannerState.Skeleton &&
            (!vacancySuitableResumes || vacancySuitableResumes?.vacancyId !== vacancyId)
        ) {
            setVacancySuitableResumesBannerState(BannerState.Skeleton);
            void fetchData();
        }
    }, [fetchData, vacancyId, vacancySuitableResumes, vacancySuitableResumesBannerState]);

    if ([BannerState.Error, BannerState.Empty].includes(vacancySuitableResumesBannerState)) {
        return null;
    }

    return (
        <Card borderRadius={24} padding={12} showBorder stretched>
            <Card padding={12} stretched>
                <Text Element="strong" style="primary" typography="subtitle-2-semibold">
                    {trls[TrlKeys.title]}
                </Text>
                {[BannerState.Skeleton, BannerState.List].includes(vacancySuitableResumesBannerState) && (
                    <>
                        <VSpacing default={10} />
                        <Text typography="label-3-regular" style="secondary">
                            {trls[TrlKeys.description]}
                        </Text>
                        <VSpacing default={10} />
                    </>
                )}
                <LoadingContextProvider loading={vacancySuitableResumesBannerState === BannerState.Skeleton}>
                    {vacancySuitableResumesBannerState === BannerState.Skeleton && (
                        <div ref={skeletonRef}>
                            {renderSkeleton()}
                            <Divider />
                            {renderSkeleton()}
                            <Divider />
                            {renderSkeleton()}
                        </div>
                    )}
                </LoadingContextProvider>

                {vacancySuitableResumesBannerState === BannerState.List && (
                    <div ref={listRef}>
                        {vacancySuitableResumes?.resumes.map((resume, i) => (
                            <Resume key={resume.resumeHash} resume={resume} vacancyId={vacancyId} index={i} />
                        ))}
                    </div>
                )}
            </Card>

            <LoadingContextProvider loading={vacancySuitableResumesBannerState === BannerState.Skeleton}>
                {vacancySuitableResumesBannerState === BannerState.Skeleton && (
                    <Card.Skeleton stretched borderRadius={12} height={48} />
                )}
            </LoadingContextProvider>

            {vacancySuitableResumesBannerState === BannerState.List && (
                <Button
                    Element={Link}
                    to={suitableResumesUrl}
                    target="_blank"
                    stretched
                    mode="secondary"
                    postfix={vacancySuitableResumes ? getResumesTotal(vacancySuitableResumes.total) : undefined}
                    onClick={() => {
                        Analytics.sendHHEventButtonClick('new_employer_vacancy_promo_banner_suitable_resumes_link', {
                            vacancyId,
                        });
                    }}
                >
                    {trls[TrlKeys.button]}
                </Button>
            )}
        </Card>
    );
};

export default translation(VacancySuitableResumesBanner);
