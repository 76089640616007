import ConversionNumber from 'bloko/blocks/conversion';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { NON_BREAKING_SPACE } from 'src/utils/constants/symbols';

const TrlKeys = {
    vacancies: {
        one: 'professions.vacancies.one',
        some: 'professions.vacancies.some',
        many: 'professions.vacancies.many',
    },
};

interface VacanciesCounterProps {
    value: number;
}

const VacanciesCounter: TranslatedComponent<VacanciesCounterProps> = ({ trls, value }) => {
    return (
        <ConversionNumber
            one={trls[TrlKeys.vacancies.one]}
            some={trls[TrlKeys.vacancies.some]}
            many={trls[TrlKeys.vacancies.many]}
            zero={NON_BREAKING_SPACE}
            hasValue={true}
            value={value}
        />
    );
};

export default translation(VacanciesCounter);
