import { Snackbar } from '@hh.ru/magritte-ui';
import { MinusCircleFilledSize24 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';

const TrlKeys = {
    errorText: 'webcall.resumeData.error.text',
};

interface ComponentProps {
    onClose: VoidFunction;
}

const WebcallUserDataError: TranslatedComponent<ComponentProps> = ({ trls, onClose }) => {
    return (
        <Snackbar
            showClose
            autohideTime={3000}
            data-qa="webcall-user-data-error"
            addon={<MinusCircleFilledSize24 initial="negative" />}
            onClose={onClose}
        >
            {trls[TrlKeys.errorText]}
        </Snackbar>
    );
};

export default translation(WebcallUserDataError);
