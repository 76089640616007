import { FC, PropsWithChildren } from 'react';
import classnames from 'classnames';

import styles from './styles.less';

interface NotificationTextProps {
    multiline?: boolean;
}

const NotificationText: FC<NotificationTextProps & PropsWithChildren> = ({ children, multiline }) => {
    return (
        <div className={classnames(styles.text, { [styles.multiline]: multiline })} data-qa="notification-content">
            {children}
        </div>
    );
};

export default NotificationText;
