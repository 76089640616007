import Analytics from '@hh.ru/analytics-js';
import { AnalyticsParams } from '@hh.ru/analytics-js/lib/hhAnalytics';
import { TranslatedComponent } from '@hh.ru/front-static-app';
import { Button, Card, Text, VSpacing } from '@hh.ru/magritte-ui';
import { Link as SpaLink } from '@hh.ru/redux-spa-middleware';

import ElementShownAnchor from 'src/components/ElementShownAnchor';
import translation from 'src/components/translation';

import styles from './styles.less';

const TrlKeys = {
    title: 'indexPage.employer.newYearStats.banner.title',
    description: 'indexPage.employer.newYearStats.banner.description',
    action: 'indexPage.employer.newYearStats.banner.action',
};

const NewYearStatsBanner: TranslatedComponent = ({ trls }) => {
    const handleShowBanner = (element: HTMLElement, analyticsParams: AnalyticsParams) => {
        return Analytics.sendHHEventElementShown(element, {
            element_name: 'employer_new_year_stats_banner',
            ...analyticsParams,
        });
    };

    return (
        <Card verticalStretched stretched showBorder borderRadius={24} padding={12}>
            <ElementShownAnchor className={styles.content} fn={handleShowBanner}>
                <div className={styles.banner}>
                    <Text style="contrast" typography="subtitle-1-semibold">
                        {trls[TrlKeys.title]}
                    </Text>
                    <VSpacing default={16} />
                    <Text style="contrast" typography="label-3-regular">
                        {trls[TrlKeys.description]}
                    </Text>
                    <VSpacing default={16} />
                </div>
                <Button
                    Element={SpaLink}
                    to="/article/stats2024"
                    onClick={() => {
                        Analytics.sendHHEventButtonClick('employer_new_year_stats_banner_link');
                    }}
                    size="small"
                    stretched
                    mode="secondary"
                    data-qa="employer-new-year-stats-banner-action"
                    style="neutral"
                    additionalQueryParams={{ hhtmFrom: 'banner_main' }}
                    target="_blank"
                >
                    {trls[TrlKeys.action]}
                </Button>
            </ElementShownAnchor>
        </Card>
    );
};

export default translation(NewYearStatsBanner);
