import { useMemo, useCallback } from 'react';

import { Select, FormLabel, VSpacing, Link, Button } from '@hh.ru/magritte-ui';
import { PlusOutlinedSize16 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { useApplyFilter } from 'src/components/Search/Common/Filters/hooks/useApplyFilter';
import { toggleValueInArray } from 'src/components/Search/Common/Filters/utils/toggleValueInArray';
import ChipsList from 'src/components/Search/Vacancy/Filters/FilterChips';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { NovaFilterKey } from 'src/models/novaFilters';
import { EMPTY_CLUSTER } from 'src/models/searchClusters';

const TrlKeys = {
    add: 'vacancySearch.neighboursArea.add',
    search: 'vacancySearch.neighboursArea.search',
    title: 'vacancySearch.neighboursArea.title',
    save: 'novaModal.save',
};

const NeighboursAreaMobile: TranslatedComponent = ({ trls }) => {
    const applyFilter = useApplyFilter(NovaFilterKey.Neighbours);

    const { groups, selectedValues } =
        useSelector((state) => state.searchClusters[NovaFilterKey.Neighbours]) || EMPTY_CLUSTER;

    const selectOptions = useMemo(
        () =>
            Object.values(groups).map((item) => ({
                label: item.title,
                value: item.id,
            })),
        [groups]
    );

    const selectChips = useMemo(
        () => Object.values(groups).filter(({ id }) => selectedValues.includes(id)),
        [groups, selectedValues]
    );

    const onChangeNeighbours = useCallback(
        (area: string) => applyFilter(toggleValueInArray(area, selectedValues)),
        [applyFilter, selectedValues]
    );

    return (
        <>
            <FormLabel>{trls[TrlKeys.title]}</FormLabel>
            <VSpacing default={12} />
            <ChipsList name={NovaFilterKey.Neighbours} options={selectChips} onClear={(id) => onChangeNeighbours(id)} />
            <VSpacing default={12} />
            <Select
                searchable
                multiple
                type="checkbox"
                triggerProps={{
                    size: 'medium',
                    label: trls[TrlKeys.title],
                    elevateLabel: true,
                    stretched: true,
                }}
                value={selectedValues}
                name={NovaFilterKey.Neighbours}
                options={selectOptions}
                renderTrigger={({ onChange, expanded }) => (
                    <Link
                        iconLeft={PlusOutlinedSize16}
                        Element="button"
                        data-qa="novafilters-mobile-add-neighbours-areas"
                        onClick={() => {
                            onChange?.(!expanded);
                        }}
                    >
                        {trls[TrlKeys.add]}
                    </Link>
                )}
                onChange={applyFilter}
                applyChangesButton={
                    <Button mode="primary" style="accent">
                        {trls[TrlKeys.save]}
                    </Button>
                }
            />
            <VSpacing default={24} />
        </>
    );
};

export default translation(NeighboursAreaMobile);
