import { FC } from 'react';

import { CustomChip, ChipsContainer } from '@hh.ru/magritte-ui';

import { ChipsOption } from 'src/components/Search/Vacancy/Filters/FilterChips/types';

interface ChipsSelectProps {
    name: string;
    options: ChipsOption<string>[];
    onClear: (id: string) => void;
}

const ChipsList: FC<ChipsSelectProps> = ({ name, options, onClear }) => {
    if (options.length === 0) {
        return null;
    }

    return (
        <ChipsContainer scrollable noWrap>
            {options.map(({ id, title, addon }) => (
                <CustomChip
                    key={`${name}_${id}`}
                    id={id}
                    value={id}
                    data-qa={`serp__novafilter-xs-${name}-${id}`}
                    name={name}
                    onDelete={() => onClear(id)}
                    addon={addon}
                >
                    {title}
                </CustomChip>
            ))}
        </ChipsContainer>
    );
};

export default ChipsList;
