import React from 'react';
import classnames from 'classnames';

import styles from './styles.less';

interface SupernovaDropdownSectionProps {
    mainContent?: boolean;
    services?: boolean;
    support?: boolean;
    supportUnavailable?: boolean;
    notification?: boolean;
    flex?: boolean;
    isMagritte?: boolean;
}

const SupernovaDropdownSection: React.FC<SupernovaDropdownSectionProps & React.PropsWithChildren> = ({
    children,
    mainContent,
    services,
    support,
    supportUnavailable,
    notification,
    flex,
    isMagritte,
    ...props
}) => {
    return (
        <div
            className={classnames('supernova-dropdown-section', {
                'supernova-dropdown-section_main-content': mainContent,
                'supernova-dropdown-section_redesign': isMagritte,
                'supernova-dropdown-section_services': services,
                'supernova-dropdown-section_support': support,
                'supernova-dropdown-section_support-unavailable': supportUnavailable,
                [styles.notification]: notification,
                'supernova-dropdown-section_flex': flex,
            })}
            {...props}
        >
            {children}
        </div>
    );
};

export default SupernovaDropdownSection;
