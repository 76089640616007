import { Link, VSpacing, Divider } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import SearchType from 'src/components/Search/Common/Filters/types';
import translation from 'src/components/translation';
import { usePlatform } from 'src/hooks/usePlatform';
import { useSelector } from 'src/hooks/useSelector';
import { Platform } from 'src/models/locale.types';

const ARTICLE_LINKS = {
    [SearchType.Vacancy]: { [Platform.HeadHunter]: '/article/1175', [Platform.Zarplata]: '/article/1175' },
    [SearchType.VacancyMap]: { [Platform.HeadHunter]: '/article/1175', [Platform.Zarplata]: '/article/1175' },
    [SearchType.Resume]: {
        [Platform.HeadHunter]: '/article/23786',
        [Platform.Zarplata]: '/article/25295?hhtmFrom=resume_search_form',
    },
};

const TrlKeys = {
    link: 'novafilters.searchLanguageLink',
};

const SearchLanguageLink: TranslatedComponent = ({ trls }) => {
    const searchType = useSelector((state) => state.searchClustersSettings.type);
    const platform = usePlatform();

    if (searchType === SearchType.EmployerVacancy) {
        return null;
    }

    return (
        <>
            {searchType !== SearchType.Resume && <Divider marginBottom={24} />}
            <Link href={ARTICLE_LINKS[searchType][platform]} enableVisited>
                {trls[TrlKeys.link]}
            </Link>
            <VSpacing default={24} />
        </>
    );
};

export default translation(SearchLanguageLink);
