import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import FilterContent from 'src/components/Search/Common/Filters/FilterContent';
import FilterWrapper from 'src/components/Search/Common/Filters/FilterWrapper';
import MobileViewControl from 'src/components/Search/Common/Filters/MobileViewControl';
import { useApplyFilter } from 'src/components/Search/Common/Filters/hooks/useApplyFilter';
import { hasClusterGroups } from 'src/components/Search/Common/Filters/utils/hasClusterGroups';
import ChipsItem from 'src/components/Search/Vacancy/Filters/FilterChips/Item';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { NovaFilterVacancyId, NovaFilterKey } from 'src/models/novaFilters';

const getVacancyName = (cluster: NovaFilterVacancyId): string => {
    const vacancyId = cluster.selectedValues?.[0] || '';
    if (!vacancyId) {
        return '';
    }
    return cluster.groups?.[vacancyId].name;
};

const TrlKeys = {
    title: 'vacancySearch.clusters.suitable.vacancy',
};

const VacancyId: TranslatedComponent = ({ trls }) => {
    const applyFilter = useApplyFilter(NovaFilterKey.VacancyId);

    const cluster = useSelector((state) => state.searchClusters[NovaFilterKey.VacancyId]);

    if (!hasClusterGroups(cluster)) {
        return null;
    }

    return (
        <MobileViewControl
            mobileView={
                <ChipsItem
                    title={trls[TrlKeys.title]}
                    value={getVacancyName(cluster)}
                    onDelete={() => applyFilter([])}
                />
            }
        >
            <FilterWrapper filterKey={NovaFilterKey.VacancyId} title={trls[TrlKeys.title]}>
                <FilterContent name={NovaFilterKey.VacancyId} />
            </FilterWrapper>
        </MobileViewControl>
    );
};

export default translation(VacancyId);
