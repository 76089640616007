import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

export interface EmployerNewYearStats {
    showModal: boolean;
    showBanner: boolean;
}

export default autoGeneratedReducer<EmployerNewYearStats>({
    showBanner: false,
    showModal: false,
});
