import { ComponentType, useLayoutEffect, useRef, FC, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import classnames from 'classnames';

import { GridColumn, GridLayout } from '@hh.ru/magritte-ui';
import Column, { ColumnsWrapper } from 'bloko/blocks/column';
import Metrics from 'bloko/common/metrics';

import IsDashboardContext from 'src/components/SupernovaMainMenu/IsDashboardContext';
import useExperiment from 'src/hooks/useExperiment';
import { useSelector } from 'src/hooks/useSelector';
import { NavItem } from 'src/models/supernovaNaviMenu';
import { UserType } from 'src/models/userType';

import AreaSwitcherMenuItem from 'src/components/SupernovaOverlayMenu/AreaSwitcher/MenuItem';
import DefaultMenuItem from 'src/components/SupernovaOverlayMenu/DefaultMenuItem';
import MainContent from 'src/components/SupernovaOverlayMenu/MainContent';
import { MenuItemRendererProps } from 'src/components/SupernovaOverlayMenu/MenuItemRendererProps';
import { OverlayTypes, OverlayTypeSetterFunc } from 'src/components/SupernovaOverlayMenu/Overlay';
import OverlayContent from 'src/components/SupernovaOverlayMenu/OverlayContent';
import ServicesMenuItem from 'src/components/SupernovaOverlayMenu/ServicesMenuItem';
import Switchers from 'src/components/SupernovaOverlayMenu/Switchers';

const renderOverlayMenuItem = (item: NavItem, showOverlay: OverlayTypeSetterFunc) => {
    const menuItemTypeToComponentMapping: Record<string, ComponentType<MenuItemRendererProps>> = {
        mainContent: MainContent,
        switchers: Switchers,

        fill: () => <div className="supernova-navi-fill" />,
        employerProjects: ServicesMenuItem,
        applicantServices: ServicesMenuItem,
        areaSwitcher: AreaSwitcherMenuItem,
        default: DefaultMenuItem,
    };

    const Component =
        item.name in menuItemTypeToComponentMapping
            ? menuItemTypeToComponentMapping[item.name]
            : menuItemTypeToComponentMapping.default;

    return <Component menuItem={item} renderer={renderOverlayMenuItem} key={item.name} showOverlay={showOverlay} />;
};

interface SupernovaOverlayMenuProps {
    overlay: OverlayTypes;
    showOverlay: OverlayTypeSetterFunc;
}

const SupernovaOverlayMenu: FC<SupernovaOverlayMenuProps> = ({ overlay, showOverlay }) => {
    const isDashboard = useContext(IsDashboardContext);
    const userType = useSelector(({ userType }) => userType);
    const isEmployer = userType === UserType.Employer;
    const isApplicant = userType === UserType.Applicant;
    const headerMenu = useSelector(({ headerMenu }) => headerMenu);
    const isRedesignAnonymousMenuExpB = useExperiment('redesign_anonymous_menu_v2_b', true, false);
    const isRedesignAnonymousMenuExpC = useExperiment('redesign_anonymous_menu_v2_c', true, false);
    const isRedesignAnonymousMenuExpD = useExperiment('redesign_anonymous_menu_v2_d', true, false);

    const isAnonymousMagritteExp = useSelector(({ isAnonymousMagritteExp }) => isAnonymousMagritteExp);
    const { pathname } = useLocation();

    const isRedesignActive =
        userType === UserType.Anonymous &&
        (isDashboard || pathname === '/employer') &&
        (isRedesignAnonymousMenuExpB || isRedesignAnonymousMenuExpC || isRedesignAnonymousMenuExpD);

    const { bgColor, subItems } = { ...headerMenu.find((menuItem) => menuItem.name === 'menuLvl1') };

    const overlayRef = useRef<HTMLDivElement>(null);

    useLayoutEffect(() => {
        if (overlayRef.current === null || typeof ResizeObserver === 'undefined') {
            return () => undefined;
        }

        const resizeObserver = new ResizeObserver(() => {
            if (overlayRef.current !== null) {
                if (overlay !== OverlayTypes.None) {
                    overlayRef.current.style.paddingRight =
                        overlayRef.current.clientHeight === overlayRef.current.scrollHeight
                            ? `${Metrics.getScrollbarWidth()}px`
                            : '';
                } else {
                    overlayRef.current.style.paddingRight = '';
                }
            }
        });

        resizeObserver.observe(overlayRef.current);

        return () => {
            resizeObserver.disconnect();
        };
    }, [overlay]);

    const shouldHideMenu = isEmployer || isApplicant || isRedesignActive;

    if (subItems === undefined) {
        return null;
    }

    return (
        <div
            className={classnames('supernova-overlay', {
                'supernova-overlay_visible': overlay !== OverlayTypes.None,
            })}
            ref={overlayRef}
        >
            {!shouldHideMenu && (
                <div
                    className={classnames('supernova-overlay__navi', {
                        [`supernova-overlay__navi_${bgColor!}`]: bgColor,
                    })}
                >
                    {isAnonymousMagritteExp ? (
                        <GridLayout>
                            <GridColumn xs={4} s={8} m={12}>
                                <div className="supernova-navi supernova-navi_lvl-1">
                                    {subItems.map((menuItem) => renderOverlayMenuItem(menuItem, showOverlay))}
                                </div>
                            </GridColumn>
                        </GridLayout>
                    ) : (
                        <ColumnsWrapper>
                            <Column xs="4" s="8" m="12" l="16">
                                <div className="supernova-navi supernova-navi_lvl-1">
                                    {subItems.map((menuItem) => renderOverlayMenuItem(menuItem, showOverlay))}
                                </div>
                            </Column>
                        </ColumnsWrapper>
                    )}
                </div>
            )}
            <OverlayContent overlayType={overlay} setOverlayType={showOverlay} />
        </div>
    );
};

export default SupernovaOverlayMenu;
