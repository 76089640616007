import { ComponentType } from 'react';

import { Text, VSpacing } from '@hh.ru/magritte-ui';

import ResumeList from 'src/components/ImportResumeModal/ResumeList';
import { useZpImportResumes } from 'src/components/ImportResumeModal/hooks';
import { Step } from 'src/components/ImportResumeModal/types';
import translation from 'src/components/translation';

const TrlKeys = {
    title: 'zp.importResume.copy.title',
    cant: 'zp.importResume.v2.copy.cant',
};

interface Props {
    value: string;
    error: boolean;
    onSetStep: (step: Step) => void;
    onSetValue: (value: string) => void;
}

const CopyStep: ComponentType<Props> = translation(({ trls, error, value, onSetValue, onSetStep }) => {
    const resumes = useZpImportResumes(onSetStep);

    return error ? (
        <Text typography="subtitle-1-semibold">{trls[TrlKeys.cant]}</Text>
    ) : (
        <>
            <Text typography="label-2-regular" style="secondary">
                {trls[TrlKeys.title]}
            </Text>
            <VSpacing default={12} />
            <ResumeList resumes={resumes} value={value} onChange={onSetValue} />
        </>
    );
});

export default CopyStep;
