import { useMemo } from 'react';

import createRemoteDataProvider from 'bloko/blocks/suggest/createRemoteDataProvider';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import FilterWithAdditionalList from 'src/components/Search/Vacancy/Filters/FilterWithAdditionalList';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { NovaFilterKey } from 'src/models/novaFilters';

const EMPTY_ARRAY = [] as const;

const WILDCARD = '%QUERY%';
const REMOTE = `/autosuggest/multiprefix/v2?q=${WILDCARD}&d=districts_{area}`;

const TrlKeys = {
    title: 'clusters.district',
    placeholder: 'novafilters.district.search',
    add: 'vacancySearch.district.add',
};

const District: TranslatedComponent = ({ trls }) => {
    const selectedArea =
        useSelector((state) => state.searchClusters?.[NovaFilterKey.Area]?.selectedValues) || EMPTY_ARRAY;
    const currentArea = selectedArea.length === 1 ? selectedArea[0].toString() : '';
    const dataProvider = useMemo(
        () => createRemoteDataProvider(REMOTE.replace('{area}', currentArea), WILDCARD),
        [currentArea]
    );

    return (
        <FilterWithAdditionalList
            filterName={NovaFilterKey.District}
            dataProvider={dataProvider}
            title={trls[TrlKeys.title]}
            placeholder={trls[TrlKeys.placeholder]}
            placeholderMobile={trls[TrlKeys.title]}
            add={trls[TrlKeys.add]}
        />
    );
};

export default translation(District);
