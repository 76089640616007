import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';

export type AccountType = 'APPLICANT' | 'EMPLOYER';

const SET_ACCOUNT_TYPE = 'SET_ACCOUNT_TYPE';

interface AccountLoginState {
    accountType: AccountType | null;
}

const initialState: AccountLoginState = {
    accountType: null,
};

interface PayloadTypes {
    [SET_ACCOUNT_TYPE]: AccountType;
}

const ActionCreator = ActionCreatorHelper<PayloadTypes>();

export const setAccountType = ActionCreator(SET_ACCOUNT_TYPE);

export default createReducer<AccountLoginState, PayloadTypes>(initialState, {
    [SET_ACCOUNT_TYPE]: (state, { payload }) => ({
        ...state,
        accountType: payload,
    }),
});
