import vacancyClick, {
    Type as VacancyType,
} from '@hh.ru/analytics-js-events/build/xhh/employer/vacancies_widget/vacancies_widget_vacancy_button_click';
import { HSpacing, Text, VSpacing } from '@hh.ru/magritte-ui';
import { Link as SPALink } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { format } from 'bloko/common/trl';

import formatDate from 'Modules/formatDate';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { VacanciesWidgetVacancy } from 'src/models/employer/vacanciesWidget';

import VacancyButton from 'src/components/EmployerVacanciesWidget/components/VacancyButton';

import styles from './vacancy.less';

const TrlKeys = {
    expires: 'employer.indexPage.widgets.myVacancies.vacancy.expires',
    draft: 'employer.indexPage.widgets.myVacancies.vacancy.draft',
    archive: 'employer.indexPage.widgets.myVacancies.vacancy.archive',
};

interface VacancyProps {
    vacancy: VacanciesWidgetVacancy;
}

const Vacancy: TranslatedComponent<VacancyProps> = ({ trls, vacancy }) => {
    const { activeVacanciesAmount } = useSelector((state) => state.vacanciesWidget);

    const isDraft = vacancy.type === 'draft';
    const isArchive = vacancy.type === 'archive';
    const draftUrl = `/employer/vacancy/create?draftId=${vacancy.id}`;
    const vacancyUrl = `/vacancy/${vacancy.id}`;

    const getVacancyAnalyticsType = (): VacancyType => {
        if (vacancy.unread?.responses) {
            return 'responses';
        }

        if (isDraft) {
            return 'draft';
        }

        if (isArchive) {
            return 'archive';
        }

        return 'show';
    };

    const handleVacancyClick = () => {
        vacancyClick({
            activeVacanciesAmount,
            type: getVacancyAnalyticsType(),
            buttonName: 'vacancies_widget_vacancy_title',
        });
    };

    const handleVacancyButtonClick = () => {
        vacancyClick({
            activeVacanciesAmount,
            type: getVacancyAnalyticsType(),
            buttonName: 'vacancies_widget_vacancy',
        });
    };

    return (
        <li className={styles.vacancy}>
            <SPALink
                data-qa={`my-vacancies_vacancy-link_${vacancy.id}`}
                to={isDraft ? draftUrl : vacancyUrl}
                className={styles.vacancyContent}
                onClick={handleVacancyClick}
            >
                <Text typography="label-2-regular">{vacancy.title}</Text>

                <VSpacing default={4} />

                <div className={styles.vacancyDescription}>
                    {vacancy.area?.name && (
                        <span className={styles.vacancyDescriptionItem}>
                            <Text
                                typography="label-3-regular"
                                style="secondary"
                                data-qa={`my-vacancies_vacancy-area_${vacancy.id}`}
                            >
                                {vacancy.area.name}
                            </Text>
                        </span>
                    )}

                    {vacancy.expireTime && (
                        <span className={styles.vacancyDescriptionItem}>
                            <Text
                                typography="label-3-regular"
                                style={vacancy.isProlongateHighlight ? 'negative' : 'secondary'}
                                data-qa={`my-vacancies_vacancy-expire_${vacancy.id}`}
                                data-qa-timestamp={vacancy.expireTime}
                            >
                                {format(trls[TrlKeys.expires], {
                                    '{0}': formatDate(vacancy.expireTime, 'd MMMM'),
                                })}
                            </Text>
                        </span>
                    )}

                    {isDraft && (
                        <span className={styles.vacancyDescriptionItem}>
                            <Text typography="label-3-regular" style="warning">
                                {trls[TrlKeys.draft]}
                            </Text>
                        </span>
                    )}

                    {isArchive && (
                        <span className={styles.vacancyDescriptionItem}>
                            <Text typography="label-3-regular" style="negative">
                                {trls[TrlKeys.archive]}
                            </Text>
                        </span>
                    )}
                </div>
            </SPALink>

            <VSpacing default={6} gteM={0} />
            <HSpacing default={0} gteM={24} />

            <div className={styles.vacancyButtonWrapper}>
                <VacancyButton
                    vacancy={vacancy}
                    isArchive={isArchive}
                    isDraft={isDraft}
                    draftUrl={draftUrl}
                    onClick={handleVacancyButtonClick}
                />
            </div>
        </li>
    );
};

export default translation(Vacancy);
