import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

enum Extension {
    Doc = 'doc',
    Rtf = 'rtf',
    Pdf = 'pdf',
    Txt = 'txt',
}

export interface ResumeExportTypeDto {
    name: Extension;
    link: string;
    description: string;
    postprocessing: boolean;
}

export const resumeExportTypesReducer = autoGeneratedReducer<ResumeExportTypeDto[]>([]);
