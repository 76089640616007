import { VrSurveyType } from 'src/models/vrSignupLogin';
import fetcher from 'src/utils/fetcher';

interface DraftResumeParams {
    vrResponseHash: string;
    profRolesFromSurvey?: number[];
    surveyType: VrSurveyType;
}

interface DraftResumeResult {
    resumeHash: string;
    redirectUrl?: string;
}

declare global {
    interface FetcherPostApi {
        ['/shards/resume/draft_resume']: {
            queryParams: void;
            body: DraftResumeParams;
            response: DraftResumeResult;
        };
    }
}

interface CreateVrDraftResumeArgs extends DraftResumeParams {
    onFail: (error: unknown) => void;
}

async function createVrDraftResume({
    vrResponseHash,
    profRolesFromSurvey,
    surveyType,
    onFail,
}: CreateVrDraftResumeArgs): Promise<void> {
    try {
        const {
            data: { resumeHash, redirectUrl },
        } = await fetcher.post('/shards/resume/draft_resume', {
            vrResponseHash,
            profRolesFromSurvey,
            surveyType,
        });

        window.location.assign(redirectUrl || `/applicant/resumes/short?resume=${resumeHash}&hhtmFrom=vr_signup_login`);
    } catch (error) {
        onFail(error);
    }
}

export default createVrDraftResume;
