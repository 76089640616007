import { FC } from 'react';

import Filter from 'src/components/Search/Common/Filters/Filter';
import { FilterContentProps } from 'src/components/Search/Common/Filters/FilterContent';
import MobileViewControl from 'src/components/Search/Common/Filters/MobileViewControl';
import { useHasClusterGroups } from 'src/components/Search/Common/Filters/hooks/useHasClusterGroups';
import MobileFilter from 'src/components/Search/Vacancy/Filters/MobileFilter';

type AdaptiveViewFilterProps = FilterContentProps & {
    title: string;
};

const AdaptiveViewFilter: FC<AdaptiveViewFilterProps> = ({ title, name, ...props }) => {
    const hasCluster = useHasClusterGroups(name);
    if (!hasCluster) {
        return null;
    }

    return (
        <MobileViewControl mobileView={<MobileFilter {...props} name={name} title={title} />}>
            <Filter name={name} title={title} {...props} />
        </MobileViewControl>
    );
};

export default AdaptiveViewFilter;
