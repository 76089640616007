import { useState, useEffect, useMemo, useCallback } from 'react';

import { Checkbox } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import CurrencySelector from 'src/components/Search/Common/Filters/CurrencySelector';
import FilterItem from 'src/components/Search/Common/Filters/FilterItem';
import FilterWrapper from 'src/components/Search/Common/Filters/FilterWrapper';
import MobileViewControl from 'src/components/Search/Common/Filters/MobileViewControl';
import { useApplyFilter } from 'src/components/Search/Common/Filters/hooks/useApplyFilter';
import { convertToString } from 'src/components/Search/Common/Filters/utils/convertToString';
import { hasClusterGroups } from 'src/components/Search/Common/Filters/utils/hasClusterGroups';
import SwitchItem from 'src/components/Search/Vacancy/Filters/FilterSwitchItem';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { NovaFilterCompensation, NovaFilterGroupMap, NovaFilterKey } from 'src/models/novaFilters';
import { CriteriaKey } from 'src/models/search/searchCriteria.types';
import { EMPTY_CLUSTER } from 'src/models/searchClusters';

import CustomCompensation from 'src/components/Search/Vacancy/Filters/Compensation/Custom';
import CompensationList from 'src/components/Search/Vacancy/Filters/Compensation/List';
import CompensationMobile from 'src/components/Search/Vacancy/Filters/Compensation/Mobile';

const checkCustomCompensation = (selectedValueFrom: string, groups: NovaFilterGroupMap<string>) =>
    selectedValueFrom !== '' && !Object.keys(groups).includes(selectedValueFrom);

const TrlKeys = {
    title: 'searchvacancy.clusters.income',
    withSalaryTitle: 'searchvacancy.clusters.notWithoutIncome.active',
};

/**
 * Блок фильтра по доходу на поиске вакансий.
 */
const Compensation: TranslatedComponent = ({ trls }) => {
    const applyFilter = useApplyFilter(NovaFilterKey.Compensation);

    const cluster = useSelector((state) => state.searchClusters[NovaFilterKey.Compensation]) || EMPTY_CLUSTER;
    const { groups, selectedValues } = cluster;
    const { selectedValueFrom, onlyWithSalary } = useMemo(
        () => ({
            selectedValueFrom: convertToString(selectedValues?.salary),
            onlyWithSalary: selectedValues?.onlyWithSalary || false,
        }),
        [selectedValues]
    );

    const [isCustomCompensation, setIsCustomCompensation] = useState(
        checkCustomCompensation(selectedValueFrom, groups)
    );
    useEffect(
        () => setIsCustomCompensation(checkCustomCompensation(selectedValueFrom, groups)),
        [selectedValueFrom, onlyWithSalary, setIsCustomCompensation, groups]
    );

    const onChangeListValue = useCallback(
        (newValue: NovaFilterCompensation) => {
            setIsCustomCompensation(false);
            applyFilter(newValue);
        },
        [applyFilter]
    );

    const onChangeCompensationMobile = useCallback(
        (newSalary: string) => {
            if (!newSalary) {
                onChangeListValue({ salary: '', onlyWithSalary: false });
                return;
            }
            setIsCustomCompensation(true);
            applyFilter({ salary: newSalary, onlyWithSalary });
        },
        [applyFilter, onChangeListValue, onlyWithSalary]
    );

    const onChangeWithSalary = useCallback(
        () => applyFilter({ salary: selectedValueFrom, onlyWithSalary: !onlyWithSalary }),
        [selectedValueFrom, applyFilter, onlyWithSalary]
    );

    if (!hasClusterGroups(cluster)) {
        return null;
    }

    return (
        <MobileViewControl
            mobileView={
                <>
                    <CompensationMobile
                        amount={selectedValueFrom}
                        title={trls[TrlKeys.title]}
                        onChange={onChangeCompensationMobile}
                    />
                    <SwitchItem
                        title={trls[TrlKeys.withSalaryTitle]}
                        checked={onlyWithSalary}
                        onClick={onChangeWithSalary}
                        dataQa="serp__novafilter-only_with_salary"
                    />
                </>
            }
        >
            <FilterWrapper
                filterKey={NovaFilterKey.Salary}
                title={trls[TrlKeys.title]}
                titleSideElement={
                    <CurrencySelector
                        onCurrencySelectorChange={() => onChangeListValue({ salary: '', onlyWithSalary })}
                    />
                }
            >
                <CompensationList
                    name={NovaFilterKey.Salary}
                    groups={groups}
                    selectedValueFrom={selectedValueFrom}
                    isCustomCompensation={isCustomCompensation}
                    onChangeListValue={onChangeListValue}
                />
                <CustomCompensation
                    isInputEnabled={isCustomCompensation}
                    setInputEnabled={setIsCustomCompensation}
                    selectedValueFrom={selectedValueFrom}
                    onlyWithSalary={onlyWithSalary}
                    applyFilter={applyFilter}
                />
                <FilterItem
                    left={
                        <Checkbox
                            name={CriteriaKey.OnlyWithSalary}
                            onChange={onChangeWithSalary}
                            checked={onlyWithSalary}
                            dataQaCheckbox="serp__novafilter-only_with_salary"
                        />
                    }
                    title={trls[TrlKeys.withSalaryTitle]}
                    count={groups?.only_with_salary?.count}
                />
            </FilterWrapper>
        </MobileViewControl>
    );
};

export default translation(Compensation);
