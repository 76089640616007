import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

import { NavItem } from 'src/models/supernovaNaviMenu';

export interface AllServices {
    items: NavItem[];
}

export enum ConnectedServiceType {
    ResumeTargetEmployer = 'RESUME_TARGET_EMPLOYER',
    ResumeMark = 'RESUME_MARK',
    ResumeRenewal = 'RESUME_RENEWAL',
    CareerGuidance = 'CAREER_GUIDANCE',
    CompleteResumeBase = 'COMPLETE_RESUME_BASE',
    CompleteResumeOptimal = 'COMPLETE_RESUME_OPTIMAL',
    CompleteResumeMaximal = 'COMPLETE_RESUME_MAXIMAL',
    CompleteResumeConsultOnly = 'COMPLETE_RESUME_CONSULT_ONLY',
    CompleteResumeInterviewPractice = 'COMPLETE_RESUME_INTERVIEW_PRACTICE',
    ResumeAudit = 'RESUME_AUDIT',
    ResumeFastVacancies = 'RESUME_FAST_VACANCIES',
    VacancyResponsesSummary = 'VACANCY_RESPONSES_SUMMARY',
    HhProSubscription = 'HH_PRO_SUBSCRIPTION',
}

export interface ConnectedService {
    orderCode?: string;
    activationDate: string;
    extra?: object;
    serviceType: ConnectedServiceType;
}

export const MAP_CONNECTED_SERVICES = [
    ConnectedServiceType.ResumeTargetEmployer,
    ConnectedServiceType.ResumeMark,
    ConnectedServiceType.ResumeRenewal,
    ConnectedServiceType.CareerGuidance,
    ConnectedServiceType.CompleteResumeBase,
    ConnectedServiceType.CompleteResumeOptimal,
    ConnectedServiceType.CompleteResumeMaximal,
    ConnectedServiceType.CompleteResumeConsultOnly,
    ConnectedServiceType.CompleteResumeInterviewPractice,
    ConnectedServiceType.ResumeAudit,
    ConnectedServiceType.ResumeFastVacancies,
    ConnectedServiceType.VacancyResponsesSummary,
    ConnectedServiceType.HhProSubscription,
];

export interface ConnectedServiceMap {
    [ConnectedServiceType.ResumeTargetEmployer]?: ConnectedService[];
    [ConnectedServiceType.ResumeMark]?: ConnectedService[];
    [ConnectedServiceType.ResumeRenewal]?: ConnectedService[];
    [ConnectedServiceType.CareerGuidance]?: ConnectedService[];
    [ConnectedServiceType.CompleteResumeBase]?: ConnectedService[];
    [ConnectedServiceType.CompleteResumeOptimal]?: ConnectedService[];
    [ConnectedServiceType.CompleteResumeMaximal]?: ConnectedService[];
    [ConnectedServiceType.CompleteResumeConsultOnly]?: ConnectedService[];
    [ConnectedServiceType.CompleteResumeInterviewPractice]?: ConnectedService[];
    [ConnectedServiceType.ResumeAudit]?: ConnectedService[];
    [ConnectedServiceType.ResumeFastVacancies]?: ConnectedService[];
    [ConnectedServiceType.VacancyResponsesSummary]?: ConnectedService[];
    [ConnectedServiceType.HhProSubscription]?: ConnectedService[];
}

export default autoGeneratedReducer<AllServices>({ items: [] });
