import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import Analytics from '@hh.ru/analytics-js';
import { Link } from '@hh.ru/magritte-ui';
import { CrossOutlinedSize16, IconDynamic } from '@hh.ru/magritte-ui/icon';
import { IconLink } from 'bloko/blocks/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import defaultRequestErrorHandler from 'src/api/notifications/defaultRequestErrorHandler';
import paths from 'src/app/routePaths';
import BlokoIconReplaceContainer from 'src/components/BlokoIconReplaceContainer';
import { useNotification } from 'src/components/Notifications/Provider';
import translation from 'src/components/translation';
import { removeSavedSearch, SavedSearch } from 'src/models/employer/employerLastSearches';
import fetcher from 'src/utils/fetcher';

import SearchDetails from 'src/components/IndexPageEmployer/components/LastSearches/SearchDetails';

const TrlKeys = {
    search: 'index.employerLastSearches.search',
};

const REMOVE_URL = '/shards/employer/last_search/remove';

declare global {
    interface FetcherPostApi {
        [REMOVE_URL]: {
            queryParams: void;
            body: {
                'search-id': string;
            };
            response: unknown;
        };
    }
}

const SavedSearchItem: TranslatedComponent<SavedSearch> = ({
    trls,
    id,
    searchQuery,
    text,
    searchCriteria,
    position,
}) => {
    const dispatch = useDispatch();
    const { addNotification } = useNotification();

    const handleSavedSearchClick = useCallback(() => {
        Analytics.sendHHEventButtonClick('last_searches_item', { type: 'search' });
    }, []);

    const handleRemove = async () => {
        Analytics.sendHHEventButtonClick('last_searches_item', { type: 'remove' });
        dispatch(removeSavedSearch({ id }));
        try {
            await fetcher.postFormData('/shards/employer/last_search/remove', { 'search-id': id });
        } catch (error) {
            defaultRequestErrorHandler(error, addNotification);
        }
    };

    return (
        <li className="last-searches-item">
            <div className="last-searches-item__content">
                <span className="last-searches-item__name">
                    <Link
                        href={`${paths.resumeSearch}?${searchQuery}&mark=main_page_last_search_${position}`}
                        data-qa="last-searches-search-link"
                        style="neutral"
                        typography="paragraph-2-regular"
                        onClick={handleSavedSearchClick}
                    >
                        {text || trls[TrlKeys.search]}
                    </Link>
                </span>
                <span className="last-searches-item__remove" data-qa="last-searches-search-remove">
                    <IconDynamic>
                        <IconLink data-search-id={id} onClick={handleRemove}>
                            <BlokoIconReplaceContainer>
                                <CrossOutlinedSize16 initial="secondary" />
                            </BlokoIconReplaceContainer>
                        </IconLink>
                    </IconDynamic>
                </span>
            </div>
            <SearchDetails searchCriteria={searchCriteria} />
        </li>
    );
};

export default translation(SavedSearchItem);
