import { useCallback, useMemo } from 'react';
import { FieldInputProps } from 'react-final-form';

import { Select as MagritteSelect, useBreakpoint } from '@hh.ru/magritte-ui';
import ConversionNumber from 'bloko/blocks/conversion';
import Select, { Option } from 'bloko/blocks/select';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { formatToReactComponent } from 'bloko/common/trl';

import translation from 'src/components/translation';
import { useMagritte } from 'src/hooks/useMagritte';
import { useSelector } from 'src/hooks/useSelector';

const DELETE_INACTIVE_TIME_YEARS = [3, 4, 5];

export const DELETE_INACTIVE_TIME_DEFAULT = 'YEARS_5';

const TrlKeys = {
    title: 'accountDeleteInactiveTime.title',
    in: 'accountDeleteInactiveTime.in',
    description: 'accountDeleteInactiveTime.description',
    years: {
        one: 'abstract.years.one',
        some: 'abstract.years.some',
        many: 'abstract.years.many',
    },
};

const AccountDeleteInactiveSelect: TranslatedComponent<FieldInputProps<string | undefined>> = ({
    trls,
    value,
    ...input
}) => {
    const isMagritte = useMagritte();
    const isAnonymousMagritteExp = useSelector(({ isAnonymousMagritteExp }) => isAnonymousMagritteExp);
    const { isGtM, isGtS } = useBreakpoint();

    const optionLabel = useCallback(
        (value: number) =>
            formatToReactComponent(trls[TrlKeys.in], {
                '{0}': (
                    <ConversionNumber
                        many={trls[TrlKeys.years.many]}
                        some={trls[TrlKeys.years.some]}
                        one={trls[TrlKeys.years.one]}
                        value={value}
                    />
                ),
            }),
        [trls]
    );

    const MAGRITTE_DELETE_INACTIVE_TIME_YEARS = useMemo(
        () => [
            {
                value: 'YEARS_3',
                label: <>{optionLabel(3)}</>,
            },
            {
                value: 'YEARS_4',
                label: <>{optionLabel(4)}</>,
            },
            {
                value: 'YEARS_5',
                label: <>{optionLabel(5)}</>,
            },
        ],
        [optionLabel]
    );

    if (isMagritte) {
        return (
            <MagritteSelect
                {...input}
                type="radio"
                multiple={false}
                triggerProps={{
                    size: 'medium',
                    label: trls[TrlKeys.title],
                    ...(isAnonymousMagritteExp && { stretched: true }),
                    ...(isAnonymousMagritteExp && isGtS && { maxWidth: 358 }),
                    ...(isAnonymousMagritteExp && isGtM && { maxWidth: 364 }),
                }}
                options={MAGRITTE_DELETE_INACTIVE_TIME_YEARS}
                bottomSheetHeight="content"
                value={String(value)}
            />
        );
    }

    return (
        <Select {...input} value={value}>
            {DELETE_INACTIVE_TIME_YEARS.map((value) => {
                return (
                    <Option value={`YEARS_${value}`} key={value}>
                        {optionLabel(value)}
                    </Option>
                );
            })}
        </Select>
    );
};

export default translation(AccountDeleteInactiveSelect);
