export const COUNTER_SPLITTER = ' ・ ';
export const COUNTER_SPLIT_REGEXP = /\s・\s?(.*)/;
export const COUNTER_MATCH_REGEXP = /^\d+\+?\s・\s?(.*)/;
export const MAX_UNREAD_CHATS_COUNTER = 99;
export const PLUS_SIGN = '+';

const truncateCounter = (count: number): string =>
    count > MAX_UNREAD_CHATS_COUNTER ? `${MAX_UNREAD_CHATS_COUNTER}${PLUS_SIGN}` : count.toString();

export const getDocumentTitleWithCounter = (documentTitle: string, counter: number): string => {
    const truncatedCounterStr = truncateCounter(counter);
    const counterWithSplitter = `${truncatedCounterStr}${COUNTER_SPLITTER}`;

    if (!COUNTER_MATCH_REGEXP.exec(documentTitle)) {
        return counter ? `${counterWithSplitter}${documentTitle}` : documentTitle;
    }

    // Регулярка на тот случай, если в заголовке встречаются несколько COUNTER_SPLITTER
    const [titleCounter, title] = documentTitle.split(COUNTER_SPLIT_REGEXP);

    if (truncatedCounterStr === titleCounter) {
        return documentTitle;
    }

    if (counter) {
        return title ? `${counterWithSplitter}${title}` : counterWithSplitter;
    }

    return title;
};

export const updateDocumentTitleCounter = (counter: number): void => {
    const updatedDocumentTitle = getDocumentTitleWithCounter(document.title, counter);

    if (document.title !== updatedDocumentTitle) {
        document.title = updatedDocumentTitle;
    }
};
