import { useMemo, useState, useRef, ReactNode } from 'react';
import classnames from 'classnames';

import Analytics from '@hh.ru/analytics-js';
import {
    Action,
    ChipsContainer,
    CustomChip,
    BottomSheet,
    NavigationBar,
    VSpacingContainer,
    VSpacing as VSpacingMagritte,
    Title,
    useBreakpoint as useBreakpointMagritte,
    Breakpoint as BreakpointMagritte,
} from '@hh.ru/magritte-ui';
import { CrossOutlinedSize24, BoltOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { Link } from '@hh.ru/redux-spa-middleware';
import { CompensationMode } from '@hh.ru/types-hh-microcore';
import Column, { ColumnsWrapper } from 'bloko/blocks/column';
import Conversion from 'bloko/blocks/conversion';
import Gap from 'bloko/blocks/gap';
import { H2 } from 'bloko/blocks/header';
import BlokoLink, { LinkAppearance } from 'bloko/blocks/link';
import VSpacing from 'bloko/blocks/vSpacing';
import useBreakpoint, { Breakpoint } from 'bloko/common/hooks/useBreakpoint';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import Strings from 'Utils/Strings';
import paths from 'src/app/routePaths';
import IndexCollapsibleBlock from 'src/components/IndexCollapsibleBlock';
import { MIN_VACANCIES_NUM_TO_SHOW } from 'src/components/VacanciesOfTheDay/constants';
import translation from 'src/components/translation';
import { useMagritte } from 'src/hooks/useMagritte';
import { useSelector } from 'src/hooks/useSelector';
import { CurrencyType } from 'src/models/currencies.types';
import { ProfessionsListProfession } from 'src/models/professionsList';
import { MIN_ANONYMOUS_SUITABLE_VACANCIES_COUNT } from 'src/models/suitableVacancies';
import { UserType } from 'src/models/userType';
import { NON_BREAKING_SPACE } from 'src/utils/constants/symbols';

import BottomSheetProfessions from 'src/components/RainbowCatalog/BottomSheet';
import BottomSheetItem from 'src/components/RainbowCatalog/BottomSheetItem';
import DropContentCompaniesOfTheDay from 'src/components/RainbowCatalog/Drop/CompaniesOfTheDay';
import DropContentProfession from 'src/components/RainbowCatalog/Drop/Profession';
import DropContentVacanciesOfTheDay from 'src/components/RainbowCatalog/Drop/VacanciesOfTheDay';
import Modal from 'src/components/RainbowCatalog/Modal';
import ModalContentProfession from 'src/components/RainbowCatalog/Modal/Profession';
import Tile, { RainbowTileProps } from 'src/components/RainbowCatalog/Tile';
import TileVacancy from 'src/components/RainbowCatalog/Tile/Vacancy';
import useGetDropPosition from 'src/components/RainbowCatalog/useGetDropPosition';
import { PART_TIME_CATALOG_ID } from 'src/components/RainbowCatalog/utils';

import styles from './styles.less';

interface RainbowCatalogProps {
    spacingBottom?: ReactNode;
}

const Features = {
    seoLinksForBots: 'rainbow_seo_links_for_search_bots',
};

const TrlKeys = {
    promoVacancies: 'professions.promo.vacancies',
    promoEmployers: 'professions.promo.employers',
    promoRemote: 'professions.promo.remote',
    xsTitle: 'index.professions.mainTitle',
    magritteTtile: 'index.professions.title',
    titlePopular: 'index.anonnymous.popular.title',
    more: {
        title: 'professions.more',
        one: 'professions.one',
        some: 'professions.some',
        many: 'professions.many',
        suitable: 'anonymousSuitableVacancies.showMore',
    },
    vacancies: {
        one: 'professions.vacancies.one',
        some: 'professions.vacancies.some',
        many: 'professions.vacancies.many',
    },
};

const INITIAL_TILES_COUNT = 12;

const TILES_IN_A_ROW_DEFAULT = 4;
const tilesInARowMap = {
    [BreakpointMagritte.S]: 2,
    [BreakpointMagritte.M]: 3,
    [BreakpointMagritte.L]: 4,
};

const RainbowCatalog: TranslatedComponent<RainbowCatalogProps> = ({ trls, spacingBottom }) => {
    const userType = useSelector((state) => state.userType);
    const professionsList = useSelector((state) => state.professionsList);
    const suitableVacancies = useSelector((state) => state.suitableVacancies);
    const applicantInfo = useSelector((state) => state.applicantInfo);
    const remoteWorkCounter = useSelector((state) => state.remoteWorkCounter);
    const companiesOfTheDay = useSelector((state) => state.companiesOfTheDay);
    const vacanciesOfTheDay = useSelector((state) => state.vacanciesOfTheDay);

    const showSeoLinksForBots = useSelector(
        (state) => state.isBot && !!professionsList?.professions && !!state.features[Features.seoLinksForBots]
    );
    const breakpoint = useBreakpoint();
    const isXs = breakpoint === Breakpoint.XS;
    const [showAll, setShowAll] = useState(showSeoLinksForBots);
    const [activeTileContent, setActiveTileContent] = useState<ReactNode>(null);
    const [activeMobileTileContent, setActiveMobileTileContent] = useState<{
        title: string;
        body: ReactNode;
        vacanciesCount: number | undefined;
    } | null>(null);
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [activeTileIndex, setActiveTileIndex] = useState(-1);
    const [activeDropIndex, setActiveDropIndex] = useState(-1);
    const tileIndexRef = useRef<number>(0);
    const catalogElementRef = useRef<HTMLDivElement>(null);
    const previousActiveTileIndex = useRef<number>(-1);
    const isMagritte = useMagritte();

    const { isXS, isMobile } = useBreakpointMagritte();

    useGetDropPosition({ activeTileIndex, setActiveDropIndex, catalogElementRef });
    // tempexp_PORTFOLIO-30605_start
    // Мемоизируем подоходящие вакансии, чтобы при фильтрации выдачи блок с ними в радужном каталоге не менялся
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const suitableVacanciesContent = useMemo(() => suitableVacancies?.vacancies || [], []);
    // tempexp_PORTFOLIO-30605_end
    const isAnonymousSuitableVacancies =
        userType === UserType.Anonymous && suitableVacanciesContent?.length > MIN_ANONYMOUS_SUITABLE_VACANCIES_COUNT;
    const isXsOnly = userType === UserType.Applicant && applicantInfo.finished > 0;
    const companiesOfTheDayVacanciesCount = companiesOfTheDay.reduce(
        (acc, employer) => acc + employer.vacanciesNumber,
        0
    );

    const [isBottomSheetVisible, setIsBottomSheetVisible] = useState(false);
    const [professionToShow, setProfessionToShow] = useState<ProfessionsListProfession>();
    const isAnonymousMagritteExp = useSelector((state) => state.isAnonymousMagritteExp);

    const [isProfessionsBottomsheetVisible, setProfessionsBottomsheetVisible] = useState(false);

    if (isMagritte && !isXsOnly) {
        return null;
    }

    const onTileClick = (index: number, content: ReactNode) => {
        if (!showSeoLinksForBots) {
            previousActiveTileIndex.current = activeTileIndex;
            const newActiveTileIndex = activeTileIndex === index ? -1 : index;
            setActiveTileIndex(newActiveTileIndex);
            setActiveTileContent(content);
        }
    };

    const onMobileTileClick = (title: string, content: ReactNode, vacanciesCount?: number | undefined) => {
        if (!showSeoLinksForBots) {
            setIsModalVisible(true);
            setProfessionsBottomsheetVisible(true);
            setActiveMobileTileContent({ title, body: content, vacanciesCount });
        }
    };

    const commonPromoTilesProps = {
        isSuitableVacanciesMode: isAnonymousSuitableVacancies,
        isXsOnly,
        promo: true,
        onClick: onTileClick,
        activeDropIndex,
        activeTileContent,
        activeTileIndex,
        previousActiveTileIndex: previousActiveTileIndex.current,
    };
    const promoTiles: Omit<RainbowTileProps, 'tileIndex'>[] = [];
    const promoTilesMobile: Omit<RainbowTileProps, 'tileIndex'>[] = [];

    if (
        vacanciesOfTheDay.success &&
        (vacanciesOfTheDay.isLoading || vacanciesOfTheDay.vacancies.length > MIN_VACANCIES_NUM_TO_SHOW)
    ) {
        promoTiles.push({
            title: trls[TrlKeys.promoVacancies],
            vacanciesCount: vacanciesOfTheDay.vacancies.length,
            compensation: vacanciesOfTheDay.compensation,
            isLoading: vacanciesOfTheDay.isLoading,
            hideMobile: true,
            pageAnalyticsValue: 'vacancies',
            children: <DropContentVacanciesOfTheDay vacancies={vacanciesOfTheDay.vacancies} />,
            dataQa: { tile: 'vacancy-item-desktop' },
            ...commonPromoTilesProps,
        });
    }
    if (companiesOfTheDay.length) {
        promoTiles.push({
            title: trls[TrlKeys.promoEmployers],
            vacanciesCount: companiesOfTheDayVacanciesCount,
            hideMobile: true,
            pageAnalyticsValue: 'companies',
            children: <DropContentCompaniesOfTheDay companiesOfTheDay={companiesOfTheDay} />,
            dataQa: { tile: 'company-item-desktop' },
            ...commonPromoTilesProps,
        });
    }
    if (remoteWorkCounter.count && remoteWorkCounter.areaId) {
        promoTiles.push({
            title: trls[TrlKeys.promoRemote],
            vacanciesCount: remoteWorkCounter.count,
            link: `${paths.vacancySearch}?schedule=remote&L_profession_id=0&area=${remoteWorkCounter.areaId}`,
            dataQa: { tile: 'remote-item-desktop' },
            ...commonPromoTilesProps,
        });

        promoTilesMobile.push({
            title: trls[TrlKeys.promoRemote],
            vacanciesCount: remoteWorkCounter.count,
            link: `${paths.vacancySearch}?schedule=remote&L_profession_id=0&area=${remoteWorkCounter.areaId}`,
            dataQa: { tile: 'remote-item-desktop' },
            ...commonPromoTilesProps,
        });
    }

    let totalTilesCount = promoTiles.length;
    if (isAnonymousSuitableVacancies) {
        totalTilesCount += suitableVacanciesContent.length;
    } else if (professionsList?.professions) {
        totalTilesCount += professionsList.professions.length;
    }

    const displayShowMoreLink = isAnonymousSuitableVacancies ? true : !showAll && totalTilesCount > INITIAL_TILES_COUNT;

    const onShowMoreLinkClick = () => {
        setShowAll(true);
        Analytics.sendEvent(userType, 'profession_block', 'expand_all');
    };

    tileIndexRef.current = 0;

    // Считаем по каким индексам обрезать массивы, чтобы отобразить нужное количество на превью, а остальное спрятать в разворачивалку
    const promoTilesLength = promoTiles.length;
    const suitableVacanciesContentLength = !isAnonymousSuitableVacancies ? 0 : suitableVacanciesContent.length;
    const professionsContentLength = professionsList?.professions ? professionsList?.professions.length : 0;

    const tilesInARow = tilesInARowMap[breakpoint as keyof typeof tilesInARowMap] || TILES_IN_A_ROW_DEFAULT;
    const tilesInAColumn = 3;
    const tilesToShow = tilesInARow * tilesInAColumn;

    const emptyTilesAfterPromoLength = tilesToShow - promoTilesLength;
    const sliceSuitable =
        suitableVacanciesContentLength - emptyTilesAfterPromoLength > 0 ? emptyTilesAfterPromoLength : 0;
    const sliceProfessions = sliceSuitable > 0 ? 0 : emptyTilesAfterPromoLength - suitableVacanciesContentLength;
    const isShowMoreVisible = isXS
        ? professionsContentLength > 3
        : promoTilesLength + suitableVacanciesContentLength + professionsContentLength > tilesToShow;

    const renderTileProfession = (
        index: number,
        currentIndex: number,
        profession: ProfessionsListProfession,
        currency: CurrencyType
    ) => (
        <Tile
            professionIndex={index}
            title={profession.name}
            compensation={{
                from: profession.compensationFrom,
                to: profession.compensationTo,
                currencyCode: currency,
            }}
            vacanciesCount={profession.count}
            isSuitableVacanciesMode={isAnonymousSuitableVacancies}
            isXsOnly={false}
            onClick={onTileClick}
            onClickMobile={onMobileTileClick}
            mobileContent={
                <BottomSheetProfessions
                    professions={profession.professions}
                    parentProfession={profession}
                    currency={currency}
                />
            }
            tileIndex={currentIndex}
            activeTileIndex={activeTileIndex}
            activeDropIndex={activeDropIndex}
            activeTileContent={activeTileContent}
            previousActiveTileIndex={previousActiveTileIndex.current}
            dataQa={{ tile: 'professions-item-desktop' }}
            key={profession.id}
            showSeoLinksForBots={showSeoLinksForBots}
        >
            <DropContentProfession
                professions={profession.professions}
                parentProfession={profession}
                currency={currency}
                showSeoLinksForBots={showSeoLinksForBots}
            />
        </Tile>
    );

    const renderMagrittePreview = () => (
        <div className={styles.container}>
            {promoTiles.map((props) => {
                tileIndexRef.current += 1;
                return <Tile {...props} key={props.title} tileIndex={tileIndexRef.current - 1} />;
            })}

            {isAnonymousSuitableVacancies &&
                suitableVacanciesContent.slice(0, sliceSuitable).map((vacancy) => {
                    tileIndexRef.current += 1;
                    return (
                        <TileVacancy
                            vacancy={vacancy}
                            tileIndex={tileIndexRef.current - 1}
                            activeDropIndex={activeDropIndex}
                            activeTileContent={activeTileContent}
                            previousActiveTileIndex={previousActiveTileIndex.current}
                            key={vacancy.vacancyId}
                        />
                    );
                })}

            {professionsList?.professions?.slice(0, sliceProfessions).map((profession, index) => {
                const currentIndex = tileIndexRef.current;
                tileIndexRef.current += 1;
                return renderTileProfession(index, currentIndex, profession, professionsList.currency);
            })}
        </div>
    );

    const renderMagritteRest = () => (
        <div className={styles.container}>
            {sliceSuitable < 0 && (
                <>
                    {isAnonymousSuitableVacancies &&
                        suitableVacanciesContent.slice(sliceSuitable).map((vacancy) => {
                            tileIndexRef.current += 1;
                            return (
                                <TileVacancy
                                    vacancy={vacancy}
                                    tileIndex={tileIndexRef.current - 1}
                                    activeDropIndex={activeDropIndex}
                                    activeTileContent={activeTileContent}
                                    previousActiveTileIndex={previousActiveTileIndex.current}
                                    key={vacancy.vacancyId}
                                />
                            );
                        })}
                </>
            )}
            {professionsList?.professions?.slice(sliceProfessions).map((profession, index) => {
                const currentIndex = tileIndexRef.current;
                tileIndexRef.current += 1;
                return renderTileProfession(index, currentIndex, profession, professionsList.currency);
            })}
        </div>
    );

    const renderMagritteMobile = () => (
        <div className={styles.containerMobile}>
            {promoTilesMobile.map((props) => {
                tileIndexRef.current += 1;
                return <Tile {...props} key={props.title} tileIndex={tileIndexRef.current - 1} />;
            })}
            {professionsList?.professions?.slice(0, 3).map((profession, index) => {
                const currentIndex = tileIndexRef.current;
                tileIndexRef.current += 1;
                return renderTileProfession(index, currentIndex, profession, professionsList.currency);
            })}
        </div>
    );

    // В мобильном отображении подходящие вакансии в отдельном блоке, поэтому в БШ их нет
    const renderMagritteBottomSheet = () => (
        <div className={styles.containerMobile}>
            {promoTilesMobile.map((props) => {
                tileIndexRef.current += 1;
                return <Tile {...props} key={props.title} tileIndex={tileIndexRef.current - 1} />;
            })}
            {professionsList?.professions?.map((profession, index) => {
                const currentIndex = tileIndexRef.current;
                tileIndexRef.current += 1;
                return renderTileProfession(index, currentIndex, profession, professionsList.currency);
            })}
        </div>
    );

    if (isMagritte) {
        const onChipClick = (profession: ProfessionsListProfession) => {
            if (profession.professions.length) {
                setProfessionToShow(profession);
                setIsBottomSheetVisible(true);
            }
        };

        const getProfessionVisibility = (profession: ProfessionsListProfession) =>
            !showSeoLinksForBots || profession.catalogPath || professionToShow?.id === PART_TIME_CATALOG_ID;

        if (!professionsList || !isXs) {
            return null;
        }
        return (
            <>
                <Title Element="h3" size="medium">
                    {trls[TrlKeys.magritteTtile]}
                </Title>
                <VSpacingMagritte default={16} />
                <ChipsContainer scrollable>
                    {professionsList.professions?.map((profession, index) => {
                        return (
                            <CustomChip
                                Element="button"
                                onClick={() => onChipClick(profession)}
                                key={profession.name}
                                data-page-analytics-position={index}
                                data-page-analytics-value={profession.name}
                                data-page-analytics-event={`vacancy_rainbow_catalog_item.${
                                    isXs ? 'mobile' : 'desktop'
                                }`}
                            >
                                {profession.name}
                            </CustomChip>
                        );
                    })}
                </ChipsContainer>
                <BottomSheet
                    visible={isBottomSheetVisible}
                    onClose={() => setIsBottomSheetVisible(false)}
                    header={
                        <NavigationBar
                            showDivider="always"
                            title={professionToShow?.name}
                            right={<Action icon={CrossOutlinedSize24} onClick={() => setIsBottomSheetVisible(false)} />}
                        />
                    }
                >
                    {!!professionToShow && (
                        <VSpacingContainer default={16}>
                            {[professionToShow, ...professionToShow.professions].map((item, index) => {
                                if (!getProfessionVisibility(item)) {
                                    return null;
                                }
                                return (
                                    <BottomSheetItem
                                        item={item}
                                        key={item.catalogPath}
                                        parentItem={professionToShow}
                                        index={index}
                                    />
                                );
                            })}
                        </VSpacingContainer>
                    )}
                </BottomSheet>
                {spacingBottom}
            </>
        );
    }

    if (isAnonymousMagritteExp) {
        return (
            <>
                <div ref={catalogElementRef}>
                    <Title Element="h3" size={isMobile ? 'small' : 'medium'} style="primary">
                        <div className={styles.title}>
                            <BoltOutlinedSize24 initial="special" />
                            {trls[TrlKeys.titlePopular]}
                        </div>
                    </Title>
                    <VSpacingMagritte default={16} gteS={20} gteM={24} />
                    <IndexCollapsibleBlock
                        renderPreview={() => (isXS ? renderMagritteMobile() : renderMagrittePreview())}
                        renderRest={() => renderMagritteRest()}
                        restSpacing={<VSpacingMagritte default={24} />}
                        needToExpand={isShowMoreVisible}
                        renderBottomSheetContent={() => {
                            return (
                                <div className={styles.wrapperVertical}>
                                    {activeMobileTileContent
                                        ? activeMobileTileContent.body
                                        : renderMagritteBottomSheet()}
                                </div>
                            );
                        }}
                        bottomSheetTitle={
                            activeMobileTileContent ? activeMobileTileContent.title : trls[TrlKeys.titlePopular]
                        }
                        bottomSheetSubtitle={
                            activeMobileTileContent?.vacanciesCount
                                ? Strings.numConversion(activeMobileTileContent.vacanciesCount, [
                                      trls[TrlKeys.vacancies.one],
                                      trls[TrlKeys.vacancies.some],
                                      trls[TrlKeys.vacancies.many],
                                  ])
                                : undefined
                        }
                        onBottomSheetClose={() => {
                            setProfessionsBottomsheetVisible(false);
                            // Меняем содержимое только после закрытия, иначе боттомшит моргнет списком вакансий
                            setTimeout(() => setActiveMobileTileContent(null), 300);
                        }}
                        onBottomSheetClickBack={
                            activeMobileTileContent?.body ? () => setActiveMobileTileContent(null) : undefined
                        }
                        forceBottomSheetVisible={isProfessionsBottomsheetVisible}
                        // Пришлось добавить, т.к. фиксированная maxHeight после анимации мешает раскрывать дропы со списками
                        // Минус: при закрытии не будет анимации, но лучше ничего не придумал
                        collapseCallback={(ref, expanded) =>
                            setTimeout(() => {
                                if (!isXS && ref.current && expanded) {
                                    ref.current.style.maxHeight = `none`;
                                }
                            }, 500)
                        }
                    />
                </div>
            </>
        );
    }

    return (
        <Gap top>
            <Column xs="4" s="0" m="0" l="0">
                <H2>{trls[TrlKeys.xsTitle]}</H2>
                <VSpacing base={4} />
            </Column>
            <div
                className={classnames('dashboard-tiles-wrapper', {
                    'dashboard-tiles-wrapper_xs-only': isXsOnly,
                })}
                ref={catalogElementRef}
            >
                <div
                    className={classnames('dashboard-tiles-content', {
                        'dashboard-tiles-content_scroll': !showSeoLinksForBots,
                    })}
                >
                    <ColumnsWrapper>
                        {promoTiles.map((props) => {
                            tileIndexRef.current += 1;
                            return <Tile {...props} key={props.title} tileIndex={tileIndexRef.current - 1} />;
                        })}
                        {isAnonymousSuitableVacancies &&
                            suitableVacanciesContent.map((vacancy) => {
                                tileIndexRef.current += 1;
                                return (
                                    <TileVacancy
                                        vacancy={vacancy}
                                        tileIndex={tileIndexRef.current - 1}
                                        activeDropIndex={activeDropIndex}
                                        activeTileContent={activeTileContent}
                                        previousActiveTileIndex={previousActiveTileIndex.current}
                                        key={vacancy.vacancyId}
                                    />
                                );
                            })}
                        {professionsList?.professions?.map((profession, index) => {
                            const currentIndex = tileIndexRef.current;
                            const stripOnDesktop = !showAll && currentIndex >= INITIAL_TILES_COUNT;
                            tileIndexRef.current += 1;
                            return (
                                <Tile
                                    professionIndex={index}
                                    title={profession.name}
                                    compensation={{
                                        // TODO_HH-242151: Привести компенсацию к новому виду (mode, perModeFrom, perModeTo)
                                        perModeFrom: profession.compensationFrom,
                                        perModeTo: profession.compensationTo,
                                        currencyCode: professionsList.currency,
                                        mode: CompensationMode.Month,
                                    }}
                                    vacanciesCount={profession.count}
                                    isSuitableVacanciesMode={isAnonymousSuitableVacancies}
                                    isXsOnly={isAnonymousSuitableVacancies || isXsOnly || stripOnDesktop}
                                    onClick={onTileClick}
                                    onClickMobile={onMobileTileClick}
                                    mobileContent={
                                        <ModalContentProfession
                                            professions={profession.professions}
                                            parentProfession={profession}
                                        />
                                    }
                                    tileIndex={currentIndex}
                                    activeTileIndex={activeTileIndex}
                                    activeDropIndex={activeDropIndex}
                                    activeTileContent={activeTileContent}
                                    previousActiveTileIndex={previousActiveTileIndex.current}
                                    dataQa={{ tile: 'professions-item-desktop' }}
                                    key={profession.id}
                                    showSeoLinksForBots={showSeoLinksForBots}
                                >
                                    <DropContentProfession
                                        professions={profession.professions}
                                        parentProfession={profession}
                                        currency={professionsList.currency}
                                        showSeoLinksForBots={showSeoLinksForBots}
                                    />
                                </Tile>
                            );
                        })}
                        {displayShowMoreLink && (
                            <Column xs="0" s="8" m="12" l="16">
                                {!isAnonymousSuitableVacancies && (
                                    <BlokoLink
                                        appearance={LinkAppearance.Pseudo}
                                        onClick={onShowMoreLinkClick}
                                        data-page-analytics-event="vacancy_rainbow_catalog_list.professions"
                                    >
                                        {trls[TrlKeys.more.title]}
                                        {NON_BREAKING_SPACE}
                                        <Conversion
                                            value={totalTilesCount - INITIAL_TILES_COUNT}
                                            one={trls[TrlKeys.more.one]}
                                            some={trls[TrlKeys.more.some]}
                                            many={trls[TrlKeys.more.many]}
                                        />
                                    </BlokoLink>
                                )}
                                {suitableVacancies &&
                                    isAnonymousSuitableVacancies &&
                                    suitableVacancies.resultsFound > suitableVacancies.itemsOnPage && (
                                        <BlokoLink
                                            disableVisited
                                            Element={Link}
                                            data-qa="index-recommended-link"
                                            data-page-analytics-event="vacancy_rainbow_catalog_list.suitable"
                                            to={`${
                                                paths.vacancySearch
                                            }?recommended_by_uid=true${suitableVacancies.areaIds
                                                .map((areaId) => `&area=${areaId}`)
                                                .join('')}`}
                                        >
                                            {trls[TrlKeys.more.suitable]}
                                        </BlokoLink>
                                    )}
                            </Column>
                        )}
                    </ColumnsWrapper>
                </div>
            </div>
            <Modal
                isVisible={isXs && isModalVisible}
                onClose={() => setIsModalVisible(false)}
                content={activeMobileTileContent}
            />
        </Gap>
    );
};

export default translation(RainbowCatalog);
