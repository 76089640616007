import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';
import { AdditionalDefault, ModelData } from 'bloko/common/tree/types';

import { CurrencyType } from 'src/models/currencies.types';
import { PublicationProductType } from 'src/models/price/product.types';
import { ServiceItemCode } from 'src/models/price/productCodes';

export type PriceProfRolesPublicationsCodesType =
    | ServiceItemCode.Standart
    | ServiceItemCode.StandartPlus
    | ServiceItemCode.Premium
    | ServiceItemCode.Anonymous;

export interface PurchaseInfo {
    products: PublicationProductType[];
    price: number;
    currency: CurrencyType;
    discount: {
        fullPrice: number;
        profitPercent: number;
    };
}

export enum Variant {
    Single = 'single',
    Package = 'package',
}

export interface CountInfo {
    info?: string;
    value: number;
    // temp_exp_33230_start
    period?: number;
}

export interface Scope {
    profRoleGroupId: string | null;
    priceRegionId: string;
    count: number;
}

interface VariantInfo {
    defaultCounts: CountInfo[];
    defaultScope: Scope;
    scopes: Scope[];
}

export interface ProfRoleGroupAdditional extends AdditionalDefault {
    includedProfRoleGroupsTrl: string;
    rolesQuantity: number;
}

export interface PriceProfRolesPublicationsType {
    amountNoticeCount: number;
    counts?: Record<string, CountInfo[]>;
    loading: boolean;
    profRoleGroupNotFoundSubstituteIds: string[];
    profRoleGroupTree: ModelData<ProfRoleGroupAdditional>[];
    profRoleGroupTrls: string[];
    purchasesByCode: Record<PriceProfRolesPublicationsCodesType, PurchaseInfo> | null;
    regionalThreshold: number;
    regions: ModelData[];
    regionPreTrls: string[];
    regionTrls: string[];
    variant: Variant;
    variants: Record<Variant, VariantInfo>;
    variantsOrder: Variant[];
    zpCrossPostAvailable: boolean;
    zpCrossPostChecked: boolean | null;
    // temp_exp_33230_next_line
    useImprovedPublicationTabUi: boolean;
    // tempexp_33230_next_line
    showRegionalsUpgradeTip: boolean;
    taxonomyOnboardingShown: boolean;
    // tempexp_37037_next-line
    isNewEmployerWithoutServices?: boolean;
}

const UPDATE_TAB_DATA = 'UPDATE_TAB_DATA';
const ADD_SCOPE = 'ADD_SCOPE';
const DELETE_SCOPE = 'DELETE_SCOPE';
const UPDATE_SCOPE = 'UPDATE_SCOPE';
const UPDATE_COUNTS = 'UPDATE_COUNTS';
const HIDE_TAXONOMY_ONBOARDING_TOOLTIP = 'HIDE_TAXONOMY_ONBOARDING_TOOLTIP';

interface PayloadPriceProfRolesPublicationsType {
    [UPDATE_TAB_DATA]: Partial<PriceProfRolesPublicationsType>;
    [ADD_SCOPE]: void;
    [DELETE_SCOPE]: number;
    [UPDATE_SCOPE]: {
        index: number;
        updatedScope: Scope;
        variant: Variant;
    };
    [UPDATE_COUNTS]: Record<string, CountInfo[]>;
    [HIDE_TAXONOMY_ONBOARDING_TOOLTIP]: void;
}

const actionCreator = ActionCreatorHelper<PayloadPriceProfRolesPublicationsType>();
export const updateTabData = actionCreator(UPDATE_TAB_DATA);
export const addScope = actionCreator(ADD_SCOPE);
export const deleteScope = actionCreator(DELETE_SCOPE);
export const updateScope = actionCreator(UPDATE_SCOPE);
export const updateCounts = actionCreator(UPDATE_COUNTS);
export const hideTaxonomyOnboardingTooltip = actionCreator(HIDE_TAXONOMY_ONBOARDING_TOOLTIP);

export default createReducer<PriceProfRolesPublicationsType, PayloadPriceProfRolesPublicationsType>(null, {
    [UPDATE_TAB_DATA]: (state, { payload }) => {
        return { ...state, ...payload };
    },
    [ADD_SCOPE]: (state) => {
        const variantInfo = state.variants[Variant.Single];
        const lastAddedScope = variantInfo.scopes[variantInfo.scopes.length - 1];
        const scopes = [
            ...variantInfo.scopes,
            { ...variantInfo.defaultScope, priceRegionId: lastAddedScope.priceRegionId },
        ];
        return { ...state, variants: { ...state.variants, [Variant.Single]: { ...variantInfo, scopes } } };
    },
    [DELETE_SCOPE]: (state, { payload: index }) => {
        const variantInfo = state.variants[Variant.Single];
        variantInfo.scopes.splice(index, 1);
        return {
            ...state,
            variants: { ...state.variants, [Variant.Single]: { ...variantInfo, scopes: [...variantInfo.scopes] } },
        };
    },
    [UPDATE_SCOPE]: (state, { payload: { index, updatedScope, variant } }) => {
        const variantInfo = state.variants[variant];
        variantInfo.scopes[index] = updatedScope;
        return {
            ...state,
            variants: { ...state.variants, [variant]: { ...variantInfo, scopes: [...variantInfo.scopes] } },
        };
    },
    [UPDATE_COUNTS]: (state, { payload }) => {
        return {
            ...state,
            counts: { ...state.counts, ...payload },
        };
    },
    [HIDE_TAXONOMY_ONBOARDING_TOOLTIP]: (state) => {
        return {
            ...state,
            taxonomyOnboardingShown: true,
        };
    },
});
