import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';

const SET_VACANCY_SEARCH_SEEN_ANALYTICS = 'SET_VACANCY_SEARCH_SEEN_ANALYTICS';
const MARK_VACANCY_SEARCH_SEEN_ANALYTICS_AS_SENT = 'MARK_VACANCY_SEARCH_SEEN_ANALYTICS_AS_SENT';
const DELETE_VACANCY_SEARCH_SEEN_ANALYTICS_OLD_DATA = 'DELETE_VACANCY_SEARCH_SEEN_ANALYTICS_OLD_DATA';

const INITIAL_STATE = {};

export interface VacancySearchSeenAnalytics {
    [key: string]: {
        lastSeenPosition: number;
        lastSeenId: number;
        globalAnalyticsParams: GlobalAnalyticsParams;
        wasSent?: true;
    };
}

interface PayloadTypes {
    [SET_VACANCY_SEARCH_SEEN_ANALYTICS]: {
        searchRid: string;
        itemId: number;
    };
    [MARK_VACANCY_SEARCH_SEEN_ANALYTICS_AS_SENT]: void;
    [DELETE_VACANCY_SEARCH_SEEN_ANALYTICS_OLD_DATA]: void;
}

const actionCreator = ActionCreatorHelper<PayloadTypes>();
export const vacancySearchSeenAnalyticsAction = actionCreator(SET_VACANCY_SEARCH_SEEN_ANALYTICS);
export const vacancySearchSeenAnalyticsMarkAsSentAction = actionCreator(MARK_VACANCY_SEARCH_SEEN_ANALYTICS_AS_SENT);
export const vacancySearchSeenAnalyticsDeleteOldDataAction = actionCreator(
    DELETE_VACANCY_SEARCH_SEEN_ANALYTICS_OLD_DATA
);

export default createReducer<VacancySearchSeenAnalytics, PayloadTypes>(INITIAL_STATE, {
    [SET_VACANCY_SEARCH_SEEN_ANALYTICS]: (oldState, action) => {
        const { searchRid, itemId } = action.payload;
        const globalAnalyticsParams = {
            ...window?.globalVars?.analyticsParams,
            originalRequestId: window?.globalVars?.requestId,
        };

        return {
            ...oldState,
            [searchRid]: {
                globalAnalyticsParams,
                lastSeenPosition: oldState[searchRid] ? oldState[searchRid].lastSeenPosition + 1 : 1,
                lastSeenId: itemId,
            },
        };
    },
    [MARK_VACANCY_SEARCH_SEEN_ANALYTICS_AS_SENT]: (oldState) => {
        const newState: VacancySearchSeenAnalytics = {};
        Object.entries(oldState).forEach(([searchRid, value]) => {
            newState[searchRid] = {
                ...value,
                wasSent: true,
            };
        });
        return newState;
    },
    [DELETE_VACANCY_SEARCH_SEEN_ANALYTICS_OLD_DATA]: () => {
        return {};
    },
});
