import { useContext } from 'react';

import { ChatikContext } from '@hh.ru/chatik-integration';
import { Card, Cell, CellText, Link as MagritteLink } from '@hh.ru/magritte-ui';
import { InfoCircleOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { Link } from '@hh.ru/redux-spa-middleware';
import Button from 'bloko/blocks/button';
import BlokoLink from 'bloko/blocks/link';
import { type TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import buildChatikUrl from 'src/components/ChatikIntegration/utils/buildChatikUrl';
import translation from 'src/components/translation';
import useMagritteResponseExp from 'src/hooks/useMagritteResponseExp';
import { useSelector } from 'src/hooks/useSelector';
import { ResponseStatusResume } from 'src/models/applicantVacancyResponseStatuses';
import NegotiationTopic from 'src/models/negotiationTopic.types';

interface AlreadyRespondedPlateProps {
    visible: boolean;
    resumes: Record<string, ResponseStatusResume>;
    topics: NegotiationTopic[];
    isFromPopup?: boolean;
}

const TrlKeys = {
    bloko: {
        applied: {
            text: 'vacancy.response.resumes.applied',
            link: 'vacancy.response.resumes.applied.link',
        },
        invited: {
            text: 'vacancy.response.resumes.invitation',
            link: 'vacancy.response.resumes.invitation.link',
        },
    },
    magritte: {
        applied: {
            text: 'vacancy.response.alreadyResponded.applied.short',
            link: 'vacancy.response.alreadyResponded.applied.link.short',
        },
        invited: {
            text: 'vacancy.response.alreadyResponded.invitation.short',
            link: 'vacancy.response.alreadyResponded.invitation.link.short',
        },
    },
    openChat: 'vacancy.view.response.openChat',
    chat: 'vacancy.view.response.chat',
};

const AlreadyRespondedPlate: TranslatedComponent<AlreadyRespondedPlateProps> = ({
    visible,
    topics,
    resumes,
    trls,
    isFromPopup,
}) => {
    const openChatik = useContext(ChatikContext)?.openChatik;
    const { isMagritteResponseExpAny } = useMagritteResponseExp();
    const topLevelDomain = useSelector(({ topLevelDomain }) => topLevelDomain || '');
    const chatikOrigin = useSelector(({ chatik }) => chatik?.chatikOrigin || '');

    const filteredTopics = topics.filter((topic) => !!resumes[topic.resumeId]);

    if (!visible || !filteredTopics.length) {
        return null;
    }

    const trlKeysAtDS = isMagritteResponseExpAny ? TrlKeys.magritte : TrlKeys.bloko;

    const invitedTopic = filteredTopics.find((topic) => !!topic.invited);
    const respondedTopic = invitedTopic || filteredTopics[0];
    const message = trls[invitedTopic ? trlKeysAtDS.invited.text : trlKeysAtDS.applied.text];
    const linkText = trls[invitedTopic ? trlKeysAtDS.invited.link : trlKeysAtDS.applied.link];
    const href = buildChatikUrl({
        chatId: respondedTopic.chatId,
        hhtmFromLabel: 'vacancy_response_page',
        chatikOrigin: chatikOrigin || `https://chatik.${topLevelDomain}`,
    });

    const onClickOpenChatik = () => {
        if (!(typeof openChatik === 'function')) {
            return;
        }

        openChatik({ chatId: respondedTopic.chatId, hhtmFromLabel: 'vacancy_response_page' });
    };

    const renderLink = () => {
        const isChatik = filteredTopics.length === 1 && respondedTopic && !!openChatik;

        if (isMagritteResponseExpAny) {
            return isChatik ? (
                <MagritteLink
                    data-qa="open-vacancy-chat"
                    {...(isFromPopup
                        ? { Element: 'a', href, target: '_blank' }
                        : { Element: 'button', onClick: onClickOpenChatik })}
                >
                    {trls[TrlKeys.chat]}
                </MagritteLink>
            ) : (
                <MagritteLink Element={Link} to="/applicant/negotiations">
                    {linkText}
                </MagritteLink>
            );
        }

        const chatikLink = isFromPopup ? (
            <BlokoLink data-qa="open-vacancy-chat" href={href} target="_blank">
                {trls[TrlKeys.openChat]}
            </BlokoLink>
        ) : (
            <BlokoLink data-qa="open-vacancy-chat" Element={Button} onClick={onClickOpenChatik}>
                {trls[TrlKeys.openChat]}
            </BlokoLink>
        );

        return isChatik ? (
            chatikLink
        ) : (
            <BlokoLink disableVisited Element={Link} to="/applicant/negotiations">
                {linkText}
            </BlokoLink>
        );
    };

    if (isMagritteResponseExpAny) {
        return (
            <Card style="neutral" padding={16} borderRadius={16} stretched>
                <Cell left={<InfoCircleOutlinedSize24 />} right={renderLink()}>
                    <CellText>{message}</CellText>
                </Cell>
            </Card>
        );
    }

    return (
        <div className="vacancy-response">
            {message} {renderLink()}
        </div>
    );
};

export default translation(AlreadyRespondedPlate);
