import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

import { Statuses } from 'src/models/resume/resumeCommon.types';

export enum ValidationSchema {
    Full = 'full_validation',
    Incomplete = 'incomplete',
    NoValidation = 'no_validation',
}

interface ResumeFormAttributes {
    countryPhoneCode?: string;
    fromapp?: string | null;
    status: Statuses | null | '';
    hasNotFinishedResumes?: boolean;
    id?: string | null;
    showHeader?: boolean;
    showImportButton?: boolean;
    source?: string;
    userId?: string;

    validation_schema?: ValidationSchema;
    vacancyResponseSucceeded?: boolean;
}

export default autoGeneratedReducer<ResumeFormAttributes>({ status: '' });
