import { ReactElement } from 'react';
import classnames from 'classnames';

import { NextFunction } from 'src/components/Markup/makrupTypes';

const HIGHLIGHT_TAG_REGEXP = /<\/?highlighttext>/g;

const HighlightProcessor =
    (next: NextFunction, short?: boolean) =>
    (string: string): ReactElement | null => {
        if (!string) {
            return null;
        }
        return (
            <>
                {string.split(HIGHLIGHT_TAG_REGEXP).map((item, index) => {
                    const key = `${string}_${index}`;
                    return index % 2 ? (
                        <span key={key} className={classnames('highlighted', { highlighted_short: short })}>
                            {next(item)}
                        </span>
                    ) : (
                        // `span` нужен даже если не подсвечиваем, из-за косяка
                        // с Google-переводчиком. Он оборачивает текстовые ноды
                        // в `<font>`, Реакт при анмаунте не может найти их и падает.
                        <span key={key}>{next(item)}</span>
                    );
                })}
            </>
        );
    };

export default HighlightProcessor;
