import { FC } from 'react';
import classnames from 'classnames';

import { Button } from '@hh.ru/magritte-ui';
import { Link } from '@hh.ru/redux-spa-middleware';

import styles from './mobile-app-button.less';

interface MobileAppButtonProps {
    name: string;
    appLink: string;
    onClickLink?: () => void;
}

const MobileAppButton: FC<MobileAppButtonProps> = ({ name, appLink, onClickLink }) => {
    const icon = (
        <div
            className={classnames(styles.mobileAppButton, {
                [styles.mobileAppButtonAndroid]: name === 'android',
                [styles.mobileAppButtonIos]: name === 'ios',
                [styles.mobileAppButtonHuawei]: name === 'huawei',
            })}
        />
    );

    return (
        <Button
            Element={Link}
            to={appLink}
            target="_blank"
            onClick={onClickLink}
            mode="primary"
            icon={icon}
            hideLabel
            aria-label={name}
            data-qa={`mobile-app-link_${name}`}
            stretched
        >
            {name}
        </Button>
    );
};

export default MobileAppButton;
