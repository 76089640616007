import SearchType from 'src/components/Search/Common/Filters/types';
import { NovaFilterSetting } from 'src/components/Search/Common/Filters/utils';
import { NovaFilters, NovaFilterKey } from 'src/models/novaFilters';
import { LabelOption } from 'src/models/resumeSearchCriteria';
import { CriteriaKey, OrderByOption } from 'src/models/search/searchCriteria.types';
import { SearchPeriodOption } from 'src/models/vacancySearchCriteria';

import getSharedSearchParams, {
    SharedSearchParams,
} from 'src/components/Search/Common/Filters/utils/getSearchParams/shared';
import { GetSearchParamsArgs } from 'src/components/Search/Common/Filters/utils/getSearchParams/types';

export type VacancySearchParams = SharedSearchParams & {
    [NovaFilterSetting.EnableSnippets]: [boolean];
    [CriteriaKey.EmployerId]: NovaFilters[typeof NovaFilterKey.Company]['selectedValues'];
    [NovaFilterSetting.ForceFiltersSaving]?: 'true';
    [CriteriaKey.Salary]?: string;
    [LabelOption.OnlyWithSalary]?: 'true';
};

const getVacancySearchParams = (args: GetSearchParamsArgs): VacancySearchParams => {
    const searchParams = getSharedSearchParams(args) as VacancySearchParams;
    const { enableVacancySnippets, novaSorts, searchType, searchClusters, vacancySearchResult } = args;

    searchParams[NovaFilterSetting.EnableSnippets] = [enableVacancySnippets];

    if (searchClusters[NovaFilterKey.Company]) {
        searchParams[CriteriaKey.EmployerId] = searchClusters[NovaFilterKey.Company].selectedValues;
    }

    if (searchType !== SearchType.EmployerVacancy && vacancySearchResult?.enableSimilarSavedSearch) {
        searchParams[NovaFilterSetting.ForceFiltersSaving] = 'true';
    }

    if (searchClusters[NovaFilterKey.Compensation]) {
        const { salary, onlyWithSalary } = searchClusters[NovaFilterKey.Compensation].selectedValues;
        if (salary) {
            searchParams[CriteriaKey.Salary] = salary;
        }
        if (onlyWithSalary) {
            searchParams[LabelOption.OnlyWithSalary] = 'true';
        }
    }

    if (searchParams[CriteriaKey.Area] && searchClusters[NovaFilterKey.Neighbours]) {
        searchParams.area = [
            ...(searchParams[CriteriaKey.Area] ?? []),
            ...searchClusters[NovaFilterKey.Neighbours].selectedValues.map(Number),
        ];
    }

    // if we have metro and district, but don't have one region (after add Neighbours)
    // need delete metro and district
    if (searchParams[CriteriaKey.Area]?.length !== 1) {
        searchParams[CriteriaKey.Metro] = [];
        searchParams[CriteriaKey.District] = [];
    }

    // sorts overrides
    searchParams[CriteriaKey.OrderBy] = novaSorts.orderBy !== OrderByOption.Relevance ? novaSorts.orderBy : undefined;
    searchParams[CriteriaKey.SearchPeriod] =
        novaSorts.searchPeriod !== SearchPeriodOption.AllTime
            ? (novaSorts.searchPeriod as SearchPeriodOption)
            : undefined;

    return searchParams;
};

export default getVacancySearchParams;
