import { useState, FC, useMemo } from 'react';

import FilterItemWithCheckbox from 'src/components/Search/Common/Filters/FilterItemWithCheckbox';
import FilterList from 'src/components/Search/Common/Filters/FilterList';
import LinkMore from 'src/components/Search/Common/Filters/LinkMore';
import { useApplyFilter } from 'src/components/Search/Common/Filters/hooks/useApplyFilter';
import { useFilterGroups } from 'src/components/Search/Common/Filters/hooks/useFilterGroups';
import { toggleValueInArray } from 'src/components/Search/Common/Filters/utils/toggleValueInArray';
import { useSelector } from 'src/hooks/useSelector';
import { NovaFilterGroup, NovaFilterKey, NovaListFilters } from 'src/models/novaFilters';
import { LabelOption } from 'src/models/vacancySearchCriteria';

export interface FilterContentProps {
    name: NovaFilterKey;
    sortFunc?: (a: NovaFilterGroup<string>, b: NovaFilterGroup<string>) => number;
}

const FilterContent: FC<FilterContentProps> = ({ name, sortFunc }) => {
    const applyFilter = useApplyFilter(name);

    const orders = useSelector((state) => state.searchClustersOrder?.[name]) || ([] as const);
    const groups =
        useSelector((state) => state.searchClusters?.[name as keyof NovaListFilters]?.groups) || ({} as const);
    const selectedValuesRaw = useSelector(
        (state) => state.searchClusters?.[name as keyof NovaListFilters]?.selectedValues
    );
    const selectedValues = useMemo(() => selectedValuesRaw?.map(String) || [], [selectedValuesRaw]);

    const [expanded, setExpanded] = useState(false);
    const { items, breakpoint } = useFilterGroups(groups, selectedValues.length, orders, expanded, sortFunc);

    const itemsToRender = useMemo(() => {
        let itemsLocal = items;
        if (name === NovaFilterKey.Label) {
            itemsLocal = itemsLocal.filter((item) => item.id !== LabelOption.Internship);
        }
        if (expanded) {
            return itemsLocal;
        }
        return itemsLocal.slice(0, breakpoint);
    }, [breakpoint, expanded, items, name]);

    if (!items.length) {
        return null;
    }

    return (
        <>
            <FilterList expanded={expanded} len={items.length}>
                {itemsToRender.map((item) => (
                    <FilterItemWithCheckbox
                        key={item.id}
                        item={item}
                        name={name}
                        onChange={() => applyFilter(toggleValueInArray(item.id, selectedValues))}
                        checked={selectedValues.includes(item.id)}
                    />
                ))}
            </FilterList>
            {items.length > breakpoint && (
                <LinkMore
                    length={items.length - breakpoint}
                    expanded={expanded}
                    onClick={() => setExpanded(!expanded)}
                />
            )}
        </>
    );
};

export default FilterContent;
