import { useRef } from 'react';

import { Avatar, Card, Cell, Text, TooltipHover, VSpacing, Link as MagritteLink } from '@hh.ru/magritte-ui';
import { Link } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import Compensation from 'src/components/Compensation';
import { getVacancyLink } from 'src/components/VacanciesOfTheDay/Utils';
import { sendAnalytics } from 'src/components/VacanciesOfTheDay/analytics';
import VacancyCompanyLink from 'src/components/VacancyCompanyLink';
import translation from 'src/components/translation';
import useIsTextClamped from 'src/hooks/useIsTextClamped';
import { VacancyOfTheDay as IVacancyOfTheDay } from 'src/models/vacanciesOfTheDay';
import { VacancyOfTheDayLocation } from 'src/utils/sendAdvSpyAnalytics';

import styles from './styles.less';

interface VacancyOfTheDayProps {
    vacancy: IVacancyOfTheDay;
    hhtmSource?: string;
    hhtmFromLabel?: string;
}

const VacancyOfTheDay: TranslatedComponent<VacancyOfTheDayProps> = ({ vacancy, hhtmSource, hhtmFromLabel }) => {
    const vacancyNameRef = useRef(null);
    const isVacancyNameClamped = useIsTextClamped(vacancyNameRef);
    const companyNameRef = useRef(null);
    const isCompanyNameClamped = useIsTextClamped(companyNameRef);

    return (
        <Card showBorder stretched padding={24} borderRadius={24} actionCard verticalStretched>
            <div className={styles.container}>
                <div>
                    <MagritteLink
                        style="neutral"
                        Element={Link}
                        to={getVacancyLink(vacancy, VacancyOfTheDayLocation.MainPageBottom)}
                        target="_blank"
                        additionalQueryParams={{
                            source: 'vacancies_of_the_day',
                            hhtmFromLabel,
                            from: hhtmSource,
                        }}
                        onClick={() => sendAnalytics(vacancy.vacancyId, 'vacancy_of_the_day')}
                    >
                        <Text typography="subtitle-1-semibold" maxLines={2}>
                            <div ref={vacancyNameRef}>{vacancy.name}</div>
                        </Text>
                        {isVacancyNameClamped && (
                            <TooltipHover placement="top-center" activatorRef={vacancyNameRef}>
                                {vacancy.name}
                            </TooltipHover>
                        )}
                    </MagritteLink>
                    <Text typography="paragraph-2-regular">
                        <Compensation
                            {...vacancy.compensation}
                            analyticsContext="VacancyOfTheDay 1"
                            showNoSalaryMessage
                        />
                    </Text>
                </div>

                <VSpacing default={24} />
                <Cell
                    align="center"
                    avatar={
                        <Avatar
                            shapeCircle
                            size={40}
                            mode="image"
                            fallbackMode="placeholder"
                            image={vacancy.company.logoUrl || ''}
                            placeholder="city"
                            aria-label="company-logo"
                        />
                    }
                >
                    <VacancyCompanyLink vacancy={vacancy}>
                        <Text typography="paragraph-2-regular" style="primary" maxLines={1}>
                            <div ref={companyNameRef}>{vacancy.company.visibleName}</div>
                        </Text>
                        {isCompanyNameClamped && (
                            <TooltipHover placement="top-center" activatorRef={companyNameRef}>
                                {vacancy.company.visibleName}
                            </TooltipHover>
                        )}
                    </VacancyCompanyLink>
                </Cell>
            </div>
        </Card>
    );
};

export default translation(VacancyOfTheDay);
