import { createContext, useContext, FC, PropsWithChildren, useMemo } from 'react';

interface SignupAnalyticsContextType {
    hhtmSource: string;
}

const SignupAnalyticsContext = createContext<SignupAnalyticsContextType>({ hhtmSource: '' });

export const useSignupAnalyticsContext = (): SignupAnalyticsContextType => useContext(SignupAnalyticsContext);

export const SignupAnalyticsProvider: FC<PropsWithChildren<SignupAnalyticsContextType>> = ({
    children,
    hhtmSource,
}) => {
    const value = useMemo(() => ({ hhtmSource }), [hhtmSource]);
    return <SignupAnalyticsContext.Provider value={value}>{children}</SignupAnalyticsContext.Provider>;
};
