import { FC, MouseEventHandler, useContext, useEffect, useRef, useState, useCallback, ReactNode } from 'react';
import { useLocation } from 'react-router-dom';
import classnames from 'classnames';

import { useChatik } from '@hh.ru/chatik-integration';
import { GridColumn, GridLayout } from '@hh.ru/magritte-ui';
import Column, { ColumnsWrapper } from 'bloko/blocks/column';
import useBreakpoint, { Breakpoint } from 'bloko/common/hooks/useBreakpoint';

import { OverlayTypes, OverlayTypeSetterFunc } from 'src/components/SupernovaOverlayMenu/Overlay';
import useExperiment from 'src/hooks/useExperiment';
import { useIsZarplataPlatform } from 'src/hooks/usePlatform';
import { useSelector } from 'src/hooks/useSelector';
import { SupernovaUserType } from 'src/models/supernovaUserType';
import { UserType } from 'src/models/userType';

import ChatikActivator from 'src/components/SupernovaMainMenu/ChatikActivator';
import CreateResume from 'src/components/SupernovaMainMenu/CreateResume';
import DropdownMenu from 'src/components/SupernovaMainMenu/DropdownMenu';
import EmployerProfile from 'src/components/SupernovaMainMenu/EmployerProfile';
import EmployerSignup from 'src/components/SupernovaMainMenu/EmployerSignup';
import FavResumes from 'src/components/SupernovaMainMenu/FavResumes';
import FavVacancies from 'src/components/SupernovaMainMenu/FavVacancies';
import Feedback from 'src/components/SupernovaMainMenu/Feedback';
import GenericNavItem from 'src/components/SupernovaMainMenu/GenericNavItem';
import GeoSwitcher from 'src/components/SupernovaMainMenu/GeoSwitcher';
import Help from 'src/components/SupernovaMainMenu/Help';
import IsDashboardContext from 'src/components/SupernovaMainMenu/IsDashboardContext';
import Login from 'src/components/SupernovaMainMenu/Login';
import Logo from 'src/components/SupernovaMainMenu/Logo';
import Mobile from 'src/components/SupernovaMainMenu/Mobile';
import MoreItems from 'src/components/SupernovaMainMenu/MoreItems';
import MyResumes from 'src/components/SupernovaMainMenu/MyResumes';
import NavItemWithDropdown from 'src/components/SupernovaMainMenu/NavItemWithDropdown';
import NaviSearch from 'src/components/SupernovaMainMenu/NaviSearch';
import Search from 'src/components/SupernovaMainMenu/Search';
import Signup from 'src/components/SupernovaMainMenu/Signup';
import Support from 'src/components/SupernovaMainMenu/Support';
import SupportChatProvider from 'src/components/SupernovaMainMenu/SupportChatProvider';
import UserNotifications from 'src/components/SupernovaMainMenu/UserNotifications';
import UserProfile from 'src/components/SupernovaMainMenu/UserProfile';
import UserTypeDrop from 'src/components/SupernovaMainMenu/UserTypeDrop';
import UserTypeSegmented from 'src/components/SupernovaMainMenu/UserTypeSegmented';
import UserTypeSwitcher from 'src/components/SupernovaMainMenu/UserTypeSwitcher';
import VacancyCreate from 'src/components/SupernovaMainMenu/VacancyCreate';
import VacancyResponses from 'src/components/SupernovaMainMenu/VacancyResponses';
import useIsAuthorizedRedesign from 'src/components/SupernovaMainMenu/hooks/useIsAuthorizedRedesign';
import isMenuLight from 'src/components/SupernovaMainMenu/isMenuLight';
import isRedesignActiveContext from 'src/components/SupernovaMainMenu/isRedesignActiveContext';
import isRedesignAnonymouContext from 'src/components/SupernovaMainMenu/isRedesignAnonymouContext';

interface SupernovaMainMenuProps {
    overlay: OverlayTypes;
    showOverlay: OverlayTypeSetterFunc;
    afterMenuContent?: ReactNode;
}

const SupernovaMainMenu: FC<SupernovaMainMenuProps> = ({ showOverlay, afterMenuContent }) => {
    const headerWrapperRef = useRef<HTMLDivElement>(null);
    const headerContentRef = useRef<HTMLDivElement>(null);
    const [isStickyState, setIsStickyState] = useState(false);
    const isDashboard = useContext(IsDashboardContext);
    const supernovaSearchExpanded = useSelector(({ supernovaSearchView }) => supernovaSearchView.expanded);
    const supernovaSearchName = useSelector(({ supernovaSearchName }) => supernovaSearchName);
    const supernovaUserType = useSelector(({ supernovaUserType }) => supernovaUserType);
    const userType = useSelector(({ userType }) => userType);
    const [searchExpanded, setSearchExpanded] = useState(false);
    const [mobileMenuExpanded, setMobileMenuExpanded] = useState(false);
    const isEmployerSearch = userType === UserType.Employer || supernovaUserType === SupernovaUserType.Employer;
    const isRedesignAnonymousMenuExpB = useExperiment('redesign_anonymous_menu_v2_b', true, false);
    const isRedesignAnonymousMenuExpC = useExperiment('redesign_anonymous_menu_v2_c', true, false);
    const isRedesignAnonymousMenuExpD = useExperiment('redesign_anonymous_menu_v2_d', true, false);
    const isAnonymousMagritteExp = useSelector(({ isAnonymousMagritteExp }) => isAnonymousMagritteExp);

    const { pathname } = useLocation();
    const isRedesignAnonymousActive =
        userType === UserType.Anonymous &&
        (isDashboard || pathname === '/employer') &&
        (isRedesignAnonymousMenuExpB || isRedesignAnonymousMenuExpC || isRedesignAnonymousMenuExpD);

    const isRedesignAuthorizedActive = useIsAuthorizedRedesign();

    const isRedesignActive = isRedesignAnonymousActive || isRedesignAuthorizedActive;

    const shouldUseDashboardStyles = isDashboard && !isRedesignActive;

    const { closeChatik } = useChatik();
    const headerMenu = useSelector(({ headerMenu }) => headerMenu);
    const { bgColor, subItems } = { ...headerMenu.find((menuItem) => menuItem.name === 'menuLvl2') };
    const isZp = useIsZarplataPlatform();

    const breakpoint = useBreakpoint();
    const isXS = breakpoint === Breakpoint.XS;

    const closeChatOnClickInAnotherMenuItem: MouseEventHandler<HTMLDivElement> = useCallback(
        (event): void => {
            // закрываем чатик только на XS
            if (!isXS) {
                return;
            }

            const target = event.target as HTMLElement;

            if (!target.classList.contains('supernova-icon')) {
                return;
            }

            if (!target.classList.contains('supernova-icon_chatik')) {
                closeChatik();
            }
        },
        [closeChatik, isXS]
    );

    const handlePageScrolled = useCallback(() => {
        setIsStickyState(window.scrollY > 0);
    }, []);

    useEffect(() => {
        if (isRedesignAnonymousActive) {
            window.addEventListener('scroll', handlePageScrolled);
        }

        return () => window.removeEventListener('scroll', handlePageScrolled);
    }, [handlePageScrolled, isRedesignActive, isRedesignAnonymousActive]);

    if (subItems === undefined) {
        return null;
    }

    const content = (
        <div
            ref={headerContentRef}
            className={classnames(
                'supernova-navi',
                'supernova-navi_lvl-2',
                // На этот класс завязано положение нотификаций
                'HH-Supernova-NotificationManager-Container',
                {
                    'supernova-navi_expanded': mobileMenuExpanded,
                    [`supernova-navi_${bgColor!}`]: bgColor && !shouldUseDashboardStyles,
                    'supernova-navi_dashboard':
                        shouldUseDashboardStyles || (pathname === '/employer' && !isRedesignActive),
                    'supernova-navi_dashboard-redesign': isRedesignActive,
                    'supernova-navi_anonymous-redesign': isRedesignAnonymousActive,
                    'supernova-navi_anonymous-redesign-segmented':
                        isRedesignAnonymousActive && isRedesignAnonymousMenuExpB,
                }
            )}
            data-qa={isDashboard || pathname === '/employer' ? 'supernova-navi-dashboard' : ''}
            onClick={userType === UserType.Anonymous ? undefined : closeChatOnClickInAnotherMenuItem}
        >
            <SupportChatProvider>
                {subItems?.map((item) => {
                    switch (item.name) {
                        case 'applicantProfile':
                        case 'userProfile':
                            return <UserProfile key={item.name} {...item} />;
                        case 'chatikActivator':
                            return <ChatikActivator key={item.name} {...item} />;
                        case 'createResume':
                            return <CreateResume key={item.name} {...item} />;
                        case 'copiny':
                        case 'feedback':
                            return <Feedback key={item.name} {...item} />;
                        case 'employerMenu':
                        case 'applicantMenu':
                            return <DropdownMenu key={item.name} {...item} />;
                        case 'employerProfile':
                            return <EmployerProfile headerContentRef={headerContentRef} key={item.name} {...item} />;
                        case 'employerSignup':
                            return <EmployerSignup key={item.name} {...item} />;
                        case 'favVacancies':
                            return <FavVacancies key={item.name} {...item} />;
                        case 'favResumes':
                            return <FavResumes key={item.name} {...item} />;
                        case 'fill':
                            return <div key={item.name} className="supernova-navi-fill" />;
                        case 'help':
                        case 'anonSupport':
                            return <Help key={item.name} {...item} />;
                        case 'login':
                            return <Login key={item.name} {...item} />;
                        case 'logo':
                            return (
                                <Logo
                                    trackClicks={item.trackClicks}
                                    isRedesign={isRedesignActive}
                                    key={item.name}
                                    onLightBg={bgColor === 'light'}
                                    isTinyOnXs={isRedesignActive && !isRedesignAnonymousMenuExpB}
                                />
                            );
                        case 'mobile':
                            return (
                                <Mobile
                                    key={item.name}
                                    {...item}
                                    isExpanded={mobileMenuExpanded}
                                    setExpanded={setMobileMenuExpanded}
                                />
                            );
                        case 'myResumes':
                            return <MyResumes key={item.name} {...item} />;
                        case 'promo':
                        case 'signup':
                            return <Signup key={item.name} {...item} />;
                        case 'searchVacancy':
                        case 'resumeSearch':
                            return <Search key={item.name} setExpanded={setSearchExpanded} {...item} />;
                        case 'support':
                            return <Support key={item.name} {...item} />;
                        case 'userNotifications':
                            return <UserNotifications key={item.name} activatorRef={headerContentRef} {...item} />;
                        case 'userTypeSegmented':
                            return <UserTypeSegmented key={item.name} {...item} />;
                        case 'userTypeDrop':
                            return <UserTypeDrop key={item.name} {...item} />;
                        case 'userTypeSwitcher':
                            return <UserTypeSwitcher visibleOnDesktop={isRedesignActive} key={item.name} {...item} />;
                        case 'vacancyResponses':
                            return <VacancyResponses key={item.name} {...item} />;
                        case 'geoSwitcher':
                            return (
                                <GeoSwitcher
                                    headerContentRef={headerContentRef}
                                    key={item.name}
                                    showOverlay={showOverlay}
                                    {...item}
                                />
                            );
                        case 'moreItems':
                            return <MoreItems key={item.name} {...item} />;
                        case 'vacancyCreate':
                            return <VacancyCreate key={item.name} {...item} />;
                    }

                    if (item.dropdown) {
                        return <NavItemWithDropdown key={item.name} {...item} />;
                    }

                    return <GenericNavItem key={item.name} arrowed={isZp && !isRedesignActive} {...item} />;
                })}
            </SupportChatProvider>
        </div>
    );

    const contentWithWrapper = () => {
        if (isRedesignActive) {
            return <>{content}</>;
        }
        if (isAnonymousMagritteExp) {
            return (
                <GridLayout>
                    <GridColumn xs={4} s={8} m={12}>
                        {content}
                    </GridColumn>
                </GridLayout>
            );
        }
        return (
            <ColumnsWrapper>
                <Column xs="4" s="8" m="12" l="16">
                    {content}
                </Column>
            </ColumnsWrapper>
        );
    };

    return (
        <isRedesignActiveContext.Provider value={isRedesignActive}>
            <isRedesignAnonymouContext.Provider value={isRedesignAnonymousActive}>
                <isMenuLight.Provider value={bgColor === 'light'}>
                    <div
                        ref={headerWrapperRef}
                        className={classnames('supernova-navi-search-wrapper', {
                            'supernova-navi-search-wrapper_expanded':
                                supernovaSearchExpanded || supernovaSearchName || searchExpanded,
                            'supernova-navi-search-wrapper_search-page': supernovaSearchName,
                            'supernova-navi-search-wrapper_employer': isEmployerSearch,
                            'supernova-navi-search-wrapper_dashboard': shouldUseDashboardStyles,
                            'supernova-navi-search-wrapper_sticky': isRedesignAnonymousActive,
                            'supernova-navi-search-wrapper_sticky-active': isStickyState,
                            'supernova-navi-search-wrapper_mobile-expanded': mobileMenuExpanded,
                        })}
                    >
                        <div
                            className={classnames('supernova-navi-wrapper', {
                                'supernova-navi-wrapper_transparent': shouldUseDashboardStyles,
                                [`supernova-navi-wrapper_${bgColor!}`]: bgColor && !shouldUseDashboardStyles,
                            })}
                        >
                            {contentWithWrapper()}
                        </div>
                        {!isDashboard && (
                            <NaviSearch afterMenuContent={afterMenuContent} searchExpanded={searchExpanded} />
                        )}
                    </div>
                </isMenuLight.Provider>
            </isRedesignAnonymouContext.Provider>
        </isRedesignActiveContext.Provider>
    );
};

export default SupernovaMainMenu;
