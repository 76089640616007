import { ValueOf } from 'type-fest';

import { EmploymentLower } from 'src/utils/constants/employment';
import { ScheduleCamelCase } from 'src/utils/constants/schedule';

import { JobSearchStatus } from 'src/models/applicantUserStatuses';
import DriverLicenseOption from 'src/models/driverLicense.types';
import { EducationLevel, Gender } from 'src/models/resume/resumeCommon.types';
import { LabelOption as LabelOptionResume, RelocationOption } from 'src/models/resumeSearchCriteria';
import {
    CommonKeys,
    CriteriaKey,
    ExperienceOption,
    PartTimeOption,
    FilterExpPeriodOption,
} from 'src/models/search/searchCriteria.types';
import { LabelOption as LabelOptionVacancy, EducationOption } from 'src/models/vacancySearchCriteria';

export const OTHER_ROLES_ID = '40';

/**
 * Названия кластеров,
 * По сути тут только ключи, которые приходят в ored_clusters
 * объединенные для вакасий и для резюме
 */
export const NovaFilterKey = {
    ...CommonKeys,
    Age: 'age',
    Company: 'company',
    Compensation: 'compensation',
    Etc: 'etc',
    SubIndustry: 'subIndustry',
    Neighbours: 'neighbours',
    PartTime: 'part_time',
    ShowClusters: 'showClusters',
    VacancyId: 'vacancy_id',
} as const;

export type NovaFilterKey = ValueOf<typeof NovaFilterKey>;

export enum MetroType {
    Station = 'station',
    Line = 'line',
}

export enum Range {
    From = 'from',
    To = 'to',
}

export interface NovaFilterGroup<T extends string | number> {
    count: number;
    id: T;
    order?: number;
    title: string;
    disabled?: boolean;
    [Range.From]?: number;
    [Range.To]?: number;
}

export type NovaFilterGroupMap<T extends string> = Record<T, NovaFilterGroup<T>>;

interface NovaFilter<SV, G extends string = string> {
    selectedValues: SV;
    groups: NovaFilterGroupMap<G>;
}

interface NovaFilterWithNameGroup extends NovaFilterGroup<string> {
    name: string;
}

type NovaFilterWithNameGroupMap = Record<number, NovaFilterWithNameGroup>;

export interface NovaFilterCompany {
    selectedValues: number[];
    groups: NovaFilterWithNameGroupMap;
}

export interface NovaFilterVacancyId {
    selectedValues: number[];
    groups: NovaFilterWithNameGroupMap;
}

export interface NovaFilterIndustryGroup extends NovaFilterGroup<string> {
    industryId: string;
}

export type NovaFilterIndustryGroupMap = Record<string, NovaFilterIndustryGroup>;

interface NovaFilterIndustry {
    selectedValues: string[];
    groups: NovaFilterIndustryGroupMap;
}

export interface NovaFilterMetroGroup extends NovaFilterGroup<string> {
    color: string;
    type: MetroType;
    cityId?: string;
    lat?: string;
    lineId?: string;
    lng?: string;
    stationId?: string;
    stationOrder?: string;
    translit?: string;
}

export type NovaFilterMetroGroupMap = Record<string, NovaFilterMetroGroup>;

interface NovaFilterMetro {
    selectedValues: string[];
    groups: NovaFilterMetroGroupMap;
}

export interface NovaFilterRange {
    [Range.From]?: string | number | null;
    [Range.To]?: string | number | null;
    onlyWith?: boolean;
}

export interface NovaFilterRangeConvertedToStrings {
    [Range.From]: string;
    [Range.To]: string;
    onlyWith?: boolean;
}

export interface NovaFilterGender {
    value: Gender;
    onlyWith: boolean;
}

export interface NovaFilterCompensation {
    salary: string | null;
    onlyWithSalary: boolean;
}

export interface NovaListFilters {
    [NovaFilterKey.District]: NovaFilter<number[]>;
    [NovaFilterKey.Employment]: NovaFilter<EmploymentLower[], EmploymentLower>;
    [NovaFilterKey.Company]: NovaFilterCompany;
    [NovaFilterKey.EducationLevel]: NovaFilter<string[], EducationLevel>;
    [NovaFilterKey.Experience]: NovaFilter<string[], ExperienceOption>;
    [NovaFilterKey.JobSearchStatus]: NovaFilter<JobSearchStatus[], JobSearchStatus>;
    [NovaFilterKey.Label]: NovaFilter<
        (LabelOptionVacancy | LabelOptionResume)[],
        LabelOptionVacancy | LabelOptionResume
    >;
    [NovaFilterKey.Neighbours]: NovaFilter<string[]>;
    [NovaFilterKey.Resume]: NovaFilter<string[]>;
    [NovaFilterKey.Schedule]: NovaFilter<ScheduleCamelCase[], ScheduleCamelCase>;
    [NovaFilterKey.VacancyId]: NovaFilterVacancyId;
    [NovaFilterKey.VerifiedSkills]: NovaFilter<number[]>;
    [NovaFilterKey.VerifiedLanguages]: NovaFilter<string[]>;
    [NovaFilterKey.PartTime]: NovaFilter<PartTimeOption[] | null, PartTimeOption>;
    [NovaFilterKey.DriverLicenseTypes]: NovaFilter<DriverLicenseOption[]>;
}

export interface NovaFilters extends NovaListFilters {
    [NovaFilterKey.AcceptTemporary]: NovaFilter<boolean>;
    [NovaFilterKey.Age]: NovaFilter<NovaFilterRange>;
    [NovaFilterKey.Area]: NovaFilter<number[]>;
    [NovaFilterKey.Compensation]: NovaFilter<NovaFilterCompensation>;
    [NovaFilterKey.University]: NovaFilter<number[]>;
    [NovaFilterKey.Education]: NovaFilter<EducationOption[], EducationOption>;
    [NovaFilterKey.ExcludedText]: NovaFilter<string[]>;
    [NovaFilterKey.FilterExpIndustry]: NovaFilter<string[]>;
    [NovaFilterKey.FilterExpPeriodForIndustry]: NovaFilter<FilterExpPeriodOption, FilterExpPeriodOption>;
    [NovaFilterKey.Gender]: NovaFilter<NovaFilterGender, Gender>;
    [NovaFilterKey.Industry]: NovaFilterIndustry;
    [NovaFilterKey.Metro]: NovaFilterMetro;
    [NovaFilterKey.Relocation]: NovaFilter<RelocationOption, RelocationOption>;
    [NovaFilterKey.Salary]: NovaFilter<NovaFilterRange>;
    [NovaFilterKey.SubIndustry]: NovaFilterIndustry;
    [NovaFilterKey.ProfessionalRole]: NovaFilter<string[]>;
    [NovaFilterKey.Citizenship]: NovaFilter<number[]>;
    [NovaFilterKey.WorkTicket]: NovaFilter<number[]>;
    [NovaFilterKey.Etc]: NovaFilter<{ [CriteriaKey.ExpCompanySize]?: string[] }>;
    [NovaFilterKey.Language]: NovaFilter<string[]>;

    [NovaFilterKey.SearchDebug]: NovaFilter<object>;
    [NovaFilterKey.SearchField]: NovaFilter<object>;
    [NovaFilterKey.ShowClusters]: NovaFilter<object>;
    [NovaFilterKey.Skill]: NovaFilter<object>;
}
