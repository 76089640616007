import { FC, PropsWithChildren, RefObject, useCallback, useRef } from 'react';

import FilterTip from 'src/components/Search/Common/Filters/FilterTip';
import TipContextProvider from 'src/components/Search/Common/Filters/TipContext';

import styles from './styles.less';

const FiltersForm: FC<PropsWithChildren> = ({ children }) => {
    const formRef = useRef(null);
    const targetRef = useRef<EventTarget | null>(null);
    const setTargetRef = useCallback((element: EventTarget) => (targetRef.current = element), [targetRef]);

    return (
        <aside
            ref={formRef}
            className={styles.form}
            data-qa="serp__criterias"
            onClick={(event) => setTargetRef(event.target)}
        >
            <TipContextProvider>
                <FilterTip
                    formRef={formRef}
                    eventElRef={targetRef as RefObject<HTMLDivElement>}
                    setEventRef={setTargetRef}
                />
                {children}
            </TipContextProvider>
        </aside>
    );
};

export default FiltersForm;
