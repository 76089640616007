import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';

import {
    FailProlongation,
    ProlongateInfo,
    ProlongateUnavailableReason,
    PublicationsVacancy,
    Publication,
    Purchase,
} from 'src/models/prolongateInfo';
import { PlatformProperties, PublicationVacancyProperties } from 'src/models/vacancyProperties';

export enum AutoUpdateUnavailableReason {
    UnableToPurchase = 'UNABLE_TO_PURCHASE',
    FreeRegularProlongationNotAvailable = 'FREE_REGULAR_PROLONGATION_NOT_AVAILABLE',
    StandardPlusRegularProlongationNotAvailable = 'STANDARD_PLUS_REGULAR_PROLONGATION_NOT_AVAILABLE',
    UnknownAutoUpdate = 'UNKNOWN_AUTO_UPDATE',
    Unknown = 'UNKNOWN',
}

interface AvailableVacancy {
    serviceName: string;
    vacancies: Record<string, PublicationsVacancy>;
}

export type RiseProlongateInfo = Omit<ProlongateInfo, 'unavailable'> & {
    unavailable: {
        reasons?: Record<number, ProlongateUnavailableReason & AutoUpdateUnavailableReason>;
        vacancyIds: number[];
        failVacancies?: (Omit<FailProlongation, 'reason'> & {
            reason: ProlongateUnavailableReason & AutoUpdateUnavailableReason;
        })[];
    };
    availableVacancies: AvailableVacancy[];
    enabled: boolean;
};

export type RiseRegularInfo = Omit<RiseProlongateInfo, 'availableToFreeProlongate'>;

export interface RegularInfo extends RiseRegularInfo {
    closestPossibleProlongation: string;
    firstPossibleDate: string;
    startDateValue: string;
    endDateValue: string;
    timeValue: string;
    maxLastUpdateDateTime: string;
    scheduleTypeValue: string;
    scheduleTypes: string[];
    enabled: boolean;
}

export enum VacancyPropertiesId {
    HhFree = 'HH_FREE',
    HhStandard = 'HH_STANDARD',
    HhStandardHhAdvertising = 'HH_STANDARD-HH_ADVERTISING',
    HHStandardHhAnonymous = 'HH_STANDARD-HH_ANONYMOUS',
    HhStandardPlus = 'HH_STANDARD_PLUS',
    HhPremium = 'HH_PREMIUM',
    HhPremiumHhAdvertising = 'HH_PREMIUM-HH_ADVERTISING',
    HhPremiumHhAnonymous = 'HH_PREMIUM-HH_ANONYMOUS',
}

export interface AvailableClassificationChanges {
    canBuy: boolean;
    canChange: boolean;
    vacancyProperties: PublicationVacancyProperties;
    purchase: Purchase | null;
    publication: Omit<Publication, 'vacancyIds'> | null;
    translations: {
        title: string;
        description: string;
    };
}

export interface ClassificationChangesInfo {
    availableClassificationChanges: Partial<Record<VacancyPropertiesId, AvailableClassificationChanges>>;
    availableVacancies: AvailableVacancy[];
    highPriorityPropertyIds: VacancyPropertiesId[];
    lowPriorityPropertyIds: VacancyPropertiesId[];
    vacancyCalculatedStates: Partial<PlatformProperties>;
    checkedTariff: AvailableClassificationChanges | null;
    enabled: boolean;
}

export enum MessageStyle {
    Accent = 'accent',
    Warning = 'warning',
    Secondary = 'secondary',
}

export interface Message {
    text: string;
    style: MessageStyle;
}

export interface EmployerVacanciesRiseProps {
    error?: string;
    success?: string;
    isSnackMessageForRisePage?: boolean;
    prolongateInfo: RiseProlongateInfo;
    regularInfo: RegularInfo;
    classificationChangesInfo: ClassificationChangesInfo;
    riseDisabledMessage?: Message;
    classificationChangesDisabledMessage?: Message;
    vacancyIds: number[];
    pageError?: string;
}

export const INITIAL_STATE: EmployerVacanciesRiseProps = {
    prolongateInfo: {
        availableVacancies: [],
        availableToFreeProlongate: { vacancyIds: [] },
        availableToPurchase: { vacancyIds: [] },
        availableToSpend: { vacancyIds: [] },
        enabled: false,
        total: 0,
        unavailable: { vacancyIds: [], failVacancies: [] },
    },
    regularInfo: {
        availableVacancies: [],
        availableToPurchase: { vacancyIds: [] },
        availableToSpend: { vacancyIds: [] },
        enabled: false,
        total: 0,
        unavailable: { vacancyIds: [], failVacancies: [] },
        closestPossibleProlongation: '',
        firstPossibleDate: '',
        startDateValue: '',
        endDateValue: '',
        timeValue: '',
        maxLastUpdateDateTime: '',
        scheduleTypeValue: '',
        scheduleTypes: [],
    },
    classificationChangesInfo: {
        vacancyCalculatedStates: {},
        availableClassificationChanges: {},
        availableVacancies: [],
        highPriorityPropertyIds: [],
        lowPriorityPropertyIds: [],
        checkedTariff: null,
        enabled: false,
    },
    vacancyIds: [],
};

const SET_RISE_SUCCESS_STATUS = 'SET_RISE_SUCCESS_STATUS';
const SET_RISE_ERROR_STATUS = 'SET_RISE_ERROR_STATUS';
const RESET_RISE_VALUES = 'RESET_RISE_VALUES';
const RESET_RISE_STATUS_VALUE = 'RESET_RISE_STATUS_VALUE';
const SET_UPDATE_INFO_FOR_SCHEDULE = 'SET_UPDATE_INFO_FOR_SCHEDULE';
const SET_START_DATE_FOR_SCHEDULE = 'SET_START_DATE_FOR_SCHEDULE';
const SET_END_DATE_FOR_SCHEDULE = 'SET_END_DATE_FOR_SCHEDULE';
const SET_TIME_FOR_SCHEDULE = 'SET_TIME_FOR_SCHEDULE';
const SET_SCHEDULE_TYPE_FOR_SCHEDULE = 'SET_SCHEDULE_TYPE_FOR_SCHEDULE';
const SET_CLASSIFICATION_CHANGE_CHECKED_TARIFF = 'SET_CLASSIFICATION_CHANGE_CHECKED_TARIFF';

interface PayloadRiseTypes {
    [SET_RISE_SUCCESS_STATUS]: { message: string; isSnackMessageForRisePage?: boolean };
    [SET_RISE_ERROR_STATUS]: { message: string; isSnackMessageForRisePage?: boolean };
    [RESET_RISE_VALUES]: void;
    [RESET_RISE_STATUS_VALUE]: void;
    [SET_UPDATE_INFO_FOR_SCHEDULE]: RiseRegularInfo;
    [SET_START_DATE_FOR_SCHEDULE]: string;
    [SET_END_DATE_FOR_SCHEDULE]: string;
    [SET_TIME_FOR_SCHEDULE]: string;
    [SET_SCHEDULE_TYPE_FOR_SCHEDULE]: string;
    [SET_CLASSIFICATION_CHANGE_CHECKED_TARIFF]: AvailableClassificationChanges;
}
const actionCreator = ActionCreatorHelper<PayloadRiseTypes>();
export const setRiseSuccessStatus = actionCreator(SET_RISE_SUCCESS_STATUS);
export const setRiseErrorStatus = actionCreator(SET_RISE_ERROR_STATUS);
export const resetRiseValues = actionCreator(RESET_RISE_VALUES);
export const resetRiseStatusValue = actionCreator(RESET_RISE_STATUS_VALUE);
export const setUpdateInfoForSchedule = actionCreator(SET_UPDATE_INFO_FOR_SCHEDULE);
export const setStartDateForSchedule = actionCreator(SET_START_DATE_FOR_SCHEDULE);
export const setEndDateForSchedule = actionCreator(SET_END_DATE_FOR_SCHEDULE);
export const setTimeForSchedule = actionCreator(SET_TIME_FOR_SCHEDULE);
export const setScheduleTypeForSchedule = actionCreator(SET_SCHEDULE_TYPE_FOR_SCHEDULE);
export const setClassificationChangeCheckedTariff = actionCreator(SET_CLASSIFICATION_CHANGE_CHECKED_TARIFF);

export default createReducer<EmployerVacanciesRiseProps, PayloadRiseTypes>(INITIAL_STATE, {
    [SET_RISE_SUCCESS_STATUS]: (state, { payload }) => {
        return { ...state, success: payload.message, isSnackMessageForRisePage: payload.isSnackMessageForRisePage };
    },
    [SET_RISE_ERROR_STATUS]: (state, { payload }) => {
        return { ...state, error: payload.message, isSnackMessageForRisePage: payload.isSnackMessageForRisePage };
    },
    [RESET_RISE_VALUES]: () => INITIAL_STATE,
    [RESET_RISE_STATUS_VALUE]: (state) => {
        return { ...state, success: undefined, error: undefined, isSnackMessageForRisePage: undefined };
    },
    [SET_UPDATE_INFO_FOR_SCHEDULE]: (state, { payload }) => {
        return { ...state, regularInfo: { ...state.regularInfo, ...payload } };
    },
    [SET_START_DATE_FOR_SCHEDULE]: (state, { payload }) => {
        return { ...state, regularInfo: { ...state.regularInfo, startDateValue: payload } };
    },
    [SET_END_DATE_FOR_SCHEDULE]: (state, { payload }) => {
        return { ...state, regularInfo: { ...state.regularInfo, endDateValue: payload } };
    },
    [SET_TIME_FOR_SCHEDULE]: (state, { payload }) => {
        return { ...state, regularInfo: { ...state.regularInfo, timeValue: payload } };
    },
    [SET_SCHEDULE_TYPE_FOR_SCHEDULE]: (state, { payload }) => {
        return { ...state, regularInfo: { ...state.regularInfo, scheduleTypeValue: payload } };
    },
    [SET_CLASSIFICATION_CHANGE_CHECKED_TARIFF]: (state, { payload }) => {
        return { ...state, classificationChangesInfo: { ...state.classificationChangesInfo, checkedTariff: payload } };
    },
});
