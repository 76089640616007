import classnames from 'classnames';
import { domToReact } from 'html-react-parser';

import { Text as TextMagriite } from '@hh.ru/magritte-ui-typography/Text';
import Column from 'bloko/blocks/column';
import Text from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';

import { TagMapperComponent, TagMapperProps } from 'src/hooks/useParseHtml';
import { useSelector } from 'src/hooks/useSelector';

import styles from 'src/components/IndexPageAnonymous/WorkInCity/styles.less';

interface BodyMapperProps extends TagMapperProps {
    expanded: boolean;
}

const Body: TagMapperComponent<BodyMapperProps> = ({ options, originalElement: { children }, expanded }) => {
    const isAnonymousMagritteExp = useSelector((state) => state.isAnonymousMagritteExp);
    if (!children) {
        return null;
    }
    if (isAnonymousMagritteExp) {
        return (
            <div className={classnames(styles.workInCity, { [styles.workInCityExpanded]: expanded })}>
                <TextMagriite typography="label-2-regular" style="secondary">
                    {domToReact(children, options)}
                </TextMagriite>
            </div>
        );
    }
    return (
        <div className={styles.workInCityWrapper}>
            <Column xs="0" s="8" m="12" l="16">
                <VSpacing base={6} />
                <div
                    className={classnames(styles.workInCity, {
                        [styles.workInCityExpanded]: expanded,
                    })}
                >
                    <Text>{domToReact(children, options)}</Text>
                </div>
            </Column>
        </div>
    );
};

export default Body;
