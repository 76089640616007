import { FC } from 'react';

import SupportContact from 'src/components/Support/SupportContacts/SupportContact';
import useSupportContacts from 'src/components/Support/SupportContacts/useSupportContacts';

import styles from './styles.less';

const SupportContacts: FC = () => {
    const contactPhones = useSupportContacts();
    const isStretched = contactPhones.length < 3;
    const isLargeContactNumber = contactPhones.length === 1;
    return (
        <div className={styles.supportContactsList}>
            {contactPhones.map((contactPhone, index) => (
                <SupportContact
                    stretched={isStretched}
                    isLargeContactNumber={isLargeContactNumber}
                    key={index}
                    {...contactPhone}
                />
            ))}
        </div>
    );
};

export default SupportContacts;
