import { useEffect } from 'react';

import Analytics from '@hh.ru/analytics-js';
import { TranslatedComponent } from '@hh.ru/front-static-app';
import { ActionBar, BottomSheet, Button, Modal, Title, VSpacing } from '@hh.ru/magritte-ui';
import Link from '@hh.ru/redux-spa-middleware/lib/Link';

import Notices from 'Modules/Notices';
import translation from 'src/components/translation';
import { useIsRussia } from 'src/hooks/useCountries';
import useOnOffState from 'src/hooks/useOnOffState';
import { useSelector } from 'src/hooks/useSelector';

interface AnnouncementModalProps {
    isShow: boolean;
}

const TrlKeys = {
    titleShort: 'indexPage.vacancyBuilder.newFields.announcement.titleShort',
    descriptionShort: 'indexPage.vacancyBuilder.newFields.announcement.descriptionShort',
    titleFull: 'indexPage.vacancyBuilder.newFields.announcement.titleFull',
    descriptionFull: 'indexPage.vacancyBuilder.newFields.announcement.descriptionFull',
    primaryAction: 'indexPage.vacancyBuilder.newFields.announcement.primaryAction',
    secondaryAction: 'indexPage.vacancyBuilder.newFields.announcement.secondaryAction',
};

const HEADER_IMAGE_PATH_DESKTOP_RU = '/images/hh/employer/vacancies/work-schedule-announcement.svg?v041224';
const HEADER_IMAGE_PATH_MOBILE_RU = '/images/hh/employer/vacancies/work-schedule-announcement-mobile.svg?v231124';
const HEADER_IMAGE_PATH_DESKTOP_COMMON =
    '/images/hh/employer/vacancies/work-schedule-announcement-no-salary.svg?v041224';
const HEADER_IMAGE_PATH_MOBILE_COMMON =
    '/images/hh/employer/vacancies/work-schedule-announcement-no-salary-mobile.svg?v041224';
const EMPLOYER_VACANCY_CREATE_NEW_FIELDS_MAIN_ANNOUNCEMENT = 'employer_vacancy_create_new_fields_main_announcement';

const Announcement: TranslatedComponent<AnnouncementModalProps> = ({ trls, isShow }) => {
    const staticHost = useSelector(({ config }) => config.staticHost);
    const [visible, _, hide] = useOnOffState(isShow);
    const isRU = useIsRussia();

    useEffect(() => {
        if (visible) {
            Analytics.sendHHEvent('screen_shown', {
                screenName: 'new_work_schedules_modal',
            });
            Notices.markAsViewed(EMPLOYER_VACANCY_CREATE_NEW_FIELDS_MAIN_ANNOUNCEMENT);
        }
    }, [visible]);

    const actions = (
        <ActionBar
            primaryActions={
                <Button
                    mode="primary"
                    style="accent"
                    Element={Link}
                    to={'/employer/vacancies'}
                    onClick={() => {
                        Analytics.sendHHEventButtonClick('new_work_schedules_modal_to_my_vacancies');
                    }}
                >
                    {trls[TrlKeys.primaryAction]}
                </Button>
            }
            secondaryActions={
                <Button
                    mode="tertiary"
                    style="accent"
                    onClick={() => {
                        hide();
                        Analytics.sendHHEventButtonClick('new_work_schedules_modal_close');
                    }}
                >
                    {trls[TrlKeys.secondaryAction]}
                </Button>
            }
        />
    );

    return (
        <>
            <Modal
                size="large"
                visible={visible}
                headerImage={`${staticHost}${isRU ? HEADER_IMAGE_PATH_DESKTOP_RU : HEADER_IMAGE_PATH_DESKTOP_COMMON}`}
                headerHeight={267}
                footer={actions}
            >
                <Title size="large" description={trls[TrlKeys.descriptionShort]} Element="h3">
                    {trls[TrlKeys.titleShort]}
                </Title>
            </Modal>
            <BottomSheet
                visible={visible}
                onClose={() => {
                    Analytics.sendHHEventButtonClick('new_work_schedules_modal_close');
                    hide();
                }}
                footer={actions}
            >
                <img
                    src={`${staticHost}${isRU ? HEADER_IMAGE_PATH_MOBILE_RU : HEADER_IMAGE_PATH_MOBILE_COMMON}`}
                    alt={trls[TrlKeys.titleFull]}
                />
                <VSpacing default={16} />
                <Title size="large" description={trls[TrlKeys.descriptionFull]} Element="h3">
                    {trls[TrlKeys.titleFull]}
                </Title>
            </BottomSheet>
        </>
    );
};

export default translation(Announcement);
