import { FC, Fragment, ReactNode } from 'react';

interface SearchCriterionProps {
    delimiter?: string;
    showDelimiter?: boolean;
    children?: ReactNode;
}

const SearchCriterion: FC<SearchCriterionProps> = ({ children, delimiter = ',', showDelimiter = true }) => {
    return (
        <Fragment>
            {children}
            {showDelimiter && <span className="last-searches-details__delimiter">{`${delimiter} `}</span>}
        </Fragment>
    );
};

export default SearchCriterion;
