import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';

import { EmployerStateType } from 'src/models/negotiationTopic.types';

const UPDATE_TEMPLATE = 'UPDATE_TEMPLATE';
const CREATE_TEMPLATE = 'CREATE_TEMPLATE';
const DELETE_TEMPLATE = 'DELETE_TEMPLATE';

export enum AnonymityType {
    COMMON = 'COMMON',
    ANONYMOUS = 'ANONYMOUS',
    NONE = 'NONE',
}

export enum FormatType {
    MAIL = 'MAIL',
    SMS = 'SMS',
}

export enum TemplateType {
    InviteMail = 'INVITE_MAIL',
    AnonymousInviteMail = 'ANONYMOUS_INVITE_MAIL',
    ResponseInviteMail = 'RESPONSE_INVITE_MAIL',
    AnonymousResponseInviteMail = 'ANONYMOUS_RESPONSE_INVITE_MAIL',
    AnotherVacancyResponseInviteMail = 'ANOTHER_VACANCY_RESPONSE_INVITE_MAIL',
    AnonymousVacancyResponseInviteMail = 'ANONYMOUS_ANOTHER_VACANCY_RESPONSE_INVITE_MAIL',
    InviteSms = 'INVITE_SMS',
    AnonymousInviteSms = 'ANONYMOUS_INVITE_SMS',
    ConsiderMail = 'CONSIDER_MAIL',
    ConsiderSms = 'CONSIDER_SMS',
    AnonymousConsiderMail = 'ANONYMOUS_CONSIDER_MAIL',
    AnonymousConsiderSms = 'ANONYMOUS_CONSIDER_SMS',
    InterviewMail = 'INTERVIEW_MAIL',
    InterviewSms = 'INTERVIEW_SMS',
    AnonymousInterviewMail = 'ANONYMOUS_INTERVIEW_MAIL',
    AnonymousInterviewSms = 'ANONYMOUS_INTERVIEW_SMS',
    AssessmentMail = 'ASSESSMENT_MAIL',
    AssessmentSms = 'ASSESSMENT_SMS',
    AnonymousAssessmentMail = 'ANONYMOUS_ASSESSMENT_MAIL',
    AnonymousAssessmentSms = 'ANONYMOUS_ASSESSMENT_SMS',
    AssessmentOwnerToolMail = 'ASSESSMENT_OWNERTOOL_MAIL',
    AssessmentOwnerToolSms = 'ASSESSMENT_OWNERTOOL_SMS',
    AnonymousAssessmentOwnerToolMail = 'ANONYMOUS_ASSESSMENT_OWNERTOOL_MAIL',
    AnonymousAssessmentOwnerToolSms = 'ANONYMOUS_ASSESSMENT_OWNERTOOL_SMS',
    OfferMail = 'OFFER_MAIL',
    OfferSms = 'OFFER_SMS',
    AnonymousOfferMail = 'ANONYMOUS_OFFER_MAIL',
    AnonymousOfferSms = 'ANONYMOUS_OFFER_SMS',
    HiredMail = 'HIRED_MAIL',
    HiredSms = 'HIRED_SMS',
    AnonymousHiredMail = 'ANONYMOUS_HIRED_MAIL',
    AnonymousHiredSms = 'ANONYMOUS_HIRED_SMS',
    ResponseDiscardMail = 'RESPONSE_DISCARD_MAIL',
    AnonymousResponseDiscardMail = 'ANONYMOUS_RESPONSE_DISCARD_MAIL',
    InterviewDiscardMail = 'INTERVIEW_DISCARD_MAIL',
    AnonymousInterviewDiscardMail = 'ANONYMOUS_INTERVIEW_DISCARD_MAIL',
    DiscardByApplicantMail = 'DISCARD_BY_APPLICANT_MAIL',
    AnonymousDiscardByApplicantMail = 'ANONYMOUS_DISCARD_BY_APPLICANT_MAIL',
    DiscardNoInteractionMail = 'DISCARD_NO_INTERACTION_MAIL',
    AnonymousDiscardNoInteractionMail = 'ANONYMOUS_DISCARD_NO_INTERACTION_MAIL',
    DiscardToOtherVacancyMail = 'DISCARD_TO_OTHER_VACANCY_MAIL',
    AnonymousDiscardToOtherVacancyMail = 'ANONYMOUS_DISCARD_TO_OTHER_VACANCY_MAIL',
    DiscardVacancyClosedMail = 'DISCARD_VACANCY_CLOSED_MAIL',
    AnonymousDiscardVacancyClosedMail = 'ANONYMOUS_DISCARD_VACANCY_CLOSED_MAIL',
    VacancyResponseReply = 'VACANCY_RESPONSE_REPLY',
    MailToApplicant = 'MAIL_TO_APPLICANT',
}

export const STATE_TO_TEMPLATE_MAIL = {
    [EmployerStateType.PhoneInterview]: TemplateType.ResponseInviteMail,
    [EmployerStateType.Consider]: TemplateType.ConsiderMail,
    [EmployerStateType.Interview]: TemplateType.InterviewMail,
    [EmployerStateType.Offer]: TemplateType.OfferMail,
    [EmployerStateType.Hired]: TemplateType.HiredMail,
    [EmployerStateType.Assessment]: TemplateType.AssessmentOwnerToolMail,
    [EmployerStateType.DiscardByEmployer]: TemplateType.ResponseDiscardMail,
    [EmployerStateType.DiscardByApplicant]: TemplateType.DiscardByApplicantMail,
    [EmployerStateType.DiscardNoInteraction]: TemplateType.DiscardNoInteractionMail,
    [EmployerStateType.DiscardVacancyClosed]: TemplateType.DiscardVacancyClosedMail,
    [EmployerStateType.DiscardToOtherVacancy]: TemplateType.DiscardToOtherVacancyMail,
};

export const STATE_TO_TEMPLATE_SMS = {
    [EmployerStateType.PhoneInterview]: TemplateType.InviteSms,
    [EmployerStateType.Consider]: TemplateType.ConsiderSms,
    [EmployerStateType.Interview]: TemplateType.InterviewSms,
    [EmployerStateType.Offer]: TemplateType.OfferSms,
    [EmployerStateType.Hired]: TemplateType.HiredSms,
    [EmployerStateType.Assessment]: TemplateType.AssessmentOwnerToolSms,
};

export interface Template {
    anonymityType: AnonymityType;
    employerManagerId: number;
    employerState: EmployerStateType | null;
    formatType: FormatType;
    id: number | null;
    primaryTemplate: boolean;
    subject: string;
    template: string;
    templateModified: boolean;
    templateName: string;
    type: TemplateType;
    typeName: string;
}

export interface ProcessedManagerTemplate extends Template {
    isSelected: boolean;
}

interface TemplatesByType {
    title: string;
    isSms: boolean;
    templates: Template[];
}

type SortedTemplatesByType = Record<TemplateType, TemplatesByType>;

export type VariableVisibilityType = 'COMMON' | 'ANONYMOUS' | 'BOTH';

export interface VariableType {
    name: string;
    translation: string;
    visibility: {
        sms: boolean;
        vacancy: boolean;
        visibility: VariableVisibilityType;
    };
}

export enum VisibilityGroups {
    Common = 'common',
    Anonymous = 'anonymous',
}

interface PayloadTypes {
    [UPDATE_TEMPLATE]: { name: string; type: TemplateType; template: string; visibility: VisibilityGroups };
    [CREATE_TEMPLATE]: { mailTemplate: Template };
    [DELETE_TEMPLATE]: { id: number | null; type: TemplateType; visibility: VisibilityGroups };
}

const ActionCreator = ActionCreatorHelper<PayloadTypes>();

export const updateTemplate = ActionCreator(UPDATE_TEMPLATE);
export const createTemplate = ActionCreator(CREATE_TEMPLATE);
export const deleteTemplate = ActionCreator(DELETE_TEMPLATE);

interface EmployerMailTemplatesType {
    transliteration: {
        mapping: Record<string, string>;
        common: Record<string, string>;
        anonymous: Record<string, string>;
    };
    variables: VariableType[];
    mailTemplates: {
        [VisibilityGroups.Anonymous]: SortedTemplatesByType;
        [VisibilityGroups.Common]: SortedTemplatesByType;
    };
}

export default createReducer<EmployerMailTemplatesType, PayloadTypes>(
    {
        mailTemplates: {
            [VisibilityGroups.Anonymous]: {} as SortedTemplatesByType,
            [VisibilityGroups.Common]: {} as SortedTemplatesByType,
        },
        variables: [],
        transliteration: { mapping: {}, common: {}, anonymous: {} },
    },
    {
        [UPDATE_TEMPLATE]: (state, action) => {
            const { name, type, template, visibility } = action.payload;
            const groupByType = state.mailTemplates[visibility][type];

            const templatesUpdated = groupByType.templates.map((t) =>
                t.templateName === name ? { ...t, template } : t
            );

            return {
                ...state,
                mailTemplates: {
                    ...state.mailTemplates,
                    [visibility]: {
                        ...state.mailTemplates[visibility],
                        [type]: {
                            ...groupByType,
                            templates: templatesUpdated,
                        },
                    },
                },
            };
        },
        [CREATE_TEMPLATE]: (state, action) => {
            const { mailTemplate } = action.payload;
            const visibility = mailTemplate.anonymityType.toLowerCase() as VisibilityGroups;
            const type = mailTemplate.type;
            const groupByType = state.mailTemplates[visibility][type];
            groupByType.templates.push(mailTemplate);

            return {
                ...state,
                mailTemplates: {
                    ...state.mailTemplates,
                    [visibility]: {
                        ...state.mailTemplates[visibility],
                        [type]: {
                            ...groupByType,
                            templates: [...groupByType.templates],
                        },
                    },
                },
            };
        },
        [DELETE_TEMPLATE]: (state, action) => {
            const { id, type, visibility } = action.payload;
            const groupByType = state.mailTemplates[visibility][type];

            return {
                ...state,
                mailTemplates: {
                    ...state.mailTemplates,
                    [visibility]: {
                        ...state.mailTemplates[visibility],
                        [type]: {
                            ...groupByType,
                            templates: [...groupByType.templates.filter((t) => t.id !== id)],
                        },
                    },
                },
            };
        },
    }
);
