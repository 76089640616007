import { FC } from 'react';

import Analytics from '@hh.ru/analytics-js';
import { ChatWritePossibility } from '@hh.ru/types-hh-microcore';

import VacancyContactsMagritte from 'src/components/VacancyView/VacancyContactsMagritte';
import { useIsZarplataPlatform } from 'src/hooks/usePlatform';
import { VacancyChatInfoType } from 'src/models/vacancyChatInfo';
import { VacancyAddress, ContactInfo } from 'src/models/vacancyView.types';

interface VacancySearchItemContactsProps {
    callTrackingEnabled?: boolean;
    contactInfo?: ContactInfo;
    onClose: () => void;
    vacancyId: number;
    address?: VacancyAddress;
    chatButtonInfo: VacancyChatInfoType | null;
    employerId: number;
    chatWritePossibility: ChatWritePossibility;
    isQuestionExp?: boolean;
}

const VacancySearchItemContacts: FC<VacancySearchItemContactsProps> = ({
    contactInfo,
    vacancyId,
    callTrackingEnabled,
    chatButtonInfo,
    employerId,
    onClose,
    chatWritePossibility,
    isQuestionExp,
}) => {
    const isZarplata = useIsZarplataPlatform();

    const onClickPhone = () => {
        Analytics.sendHHEvent('applicantCallIntent', {
            from: 'serp',
            vacancy_id: vacancyId,
        });
        Analytics.sendEvent('applicant', 'call-from-vacancy');
        if (isZarplata) {
            Analytics.addEventPixels('ZP_CALL');
        }
    };

    const onClickEmail = () => {
        Analytics.sendHHEvent('applicantEmailIntent', {
            from: 'serp',
            vacancy_id: vacancyId,
        });
    };

    return (
        <VacancyContactsMagritte
            vacancyId={vacancyId}
            contactInfo={contactInfo}
            onPhoneClick={onClickPhone}
            onEmailClick={onClickEmail}
            close={onClose}
            isCallTrackingEnabled={!!callTrackingEnabled}
            chatButtonInfo={chatButtonInfo}
            employerId={employerId}
            isChatEnabled={chatWritePossibility !== ChatWritePossibility.Disabled}
            isQuestionExp={isQuestionExp}
        />
    );
};

export default VacancySearchItemContacts;
