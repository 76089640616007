import { Text, useBreakpoint } from '@hh.ru/magritte-ui';
import BlokoText, { TextSize } from 'bloko/blocks/text';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { format } from 'bloko/common/trl';

import translation from 'src/components/translation';
import { useMagritte } from 'src/hooks/useMagritte';
import { useSelector } from 'src/hooks/useSelector';
import { TopLevelSite } from 'src/models/topLevelSite';

const TrlKeys = {
    copyright: {
        [TopLevelSite.BY]: 'footer.tutby.copyrights',
        general: 'index.footer.menuitem.copyright',
    },
};

interface FooterProps {
    isContrast?: boolean;
}

const LightPageLayoutFooter: TranslatedComponent<FooterProps> = ({ trls, isContrast }) => {
    const isMagritte = useMagritte();
    const topLevelSite = useSelector((store) => store.topLevelSite);
    const { isXS } = useBreakpoint();

    const content = (
        <span suppressHydrationWarning>
            {format(trls[TrlKeys.copyright[topLevelSite === TopLevelSite.BY ? TopLevelSite.BY : 'general']], {
                '{0}': new Date().getFullYear().toString(),
            })}
        </span>
    );

    if (isMagritte) {
        return (
            <div className="light-page-footer">
                <div className="light-page-footer__copyright">
                    <Text
                        style={isContrast && !isXS ? 'contrast' : 'primary'}
                        typography="label-2-regular"
                        data-qa="copyright"
                    >
                        {content}
                    </Text>
                </div>
            </div>
        );
    }

    return (
        <div className="light-page-footer">
            <div className="light-page-footer__copyright">
                <BlokoText size={TextSize.Small} data-qa="copyright">
                    {content}
                </BlokoText>
            </div>
        </div>
    );
};

export default translation(LightPageLayoutFooter);
