import { ComponentType, ReactElement } from 'react';

import { CheckableCard, Cell, CellText, Radio, Checkbox, useBreakpoint } from '@hh.ru/magritte-ui';

interface Props {
    checked: boolean;
    title: string;
    subtitle?: string;
    type?: 'radio' | 'checkbox';
    value?: string;
    avatar?: ReactElement;
    onChange: (value: string) => void;
}

const OptionCard: ComponentType<Props> = ({
    checked,
    onChange,
    avatar,
    title,
    subtitle,
    value = '',
    type = 'radio',
}) => {
    const { isMobile } = useBreakpoint();

    const inputElement =
        type === 'radio' ? <Radio checked={checked} readOnly /> : <Checkbox checked={checked} readOnly />;

    const cellProps = isMobile ? { right: inputElement } : { left: inputElement };

    return (
        <CheckableCard
            checked={checked}
            padding={16}
            borderRadius={12}
            type={type}
            flexible
            value={value}
            labelDataQa={`post-change-modal-option-${value}`}
            onChange={() => {
                onChange(value);
            }}
        >
            <Cell {...cellProps} avatar={avatar}>
                <CellText>{title}</CellText>
                {subtitle && <CellText type="subtitle">{subtitle}</CellText>}
            </Cell>
        </CheckableCard>
    );
};

export default OptionCard;
