import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import classnames from 'classnames';

import cookiesBannerAcceptButtonClick from '@hh.ru/analytics-js-events/build/xhh/common/notifications/cookies_banner_accept_button_click';
import cookiesBannerElementShown from '@hh.ru/analytics-js-events/build/xhh/common/notifications/cookies_banner_element_shown';
import { useBreakpoint, Link as LinkMagritte, Text, HSpacing, Button as ButtonMagritte } from '@hh.ru/magritte-ui';
import { makeSetStoreField } from '@hh.ru/redux-create-reducer';
import { Link } from '@hh.ru/redux-spa-middleware';
import Button, { ButtonKind } from 'bloko/blocks/button';
import Column, { ColumnsWrapper } from 'bloko/blocks/column';
import BlokoLink, { LinkAppearance, LinkKind } from 'bloko/blocks/link';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import NoIndex from 'src/components/NoIndex';
import translation from 'src/components/translation';
import { useIsZarplataPlatform } from 'src/hooks/usePlatform';
import { useSelector } from 'src/hooks/useSelector';

import {
    COOKIE_AGREEMENT_NAME,
    COOKIE_EXPIRE_TIME,
    BOTTOM_COOKIES_POLICY_INFORMER_ID,
    setTopLevelCookie,
} from 'src/components/CookiesPolicy/utils';

import styles from './informer.less';

const TrlKeys = {
    accept: 'cookiePolicy.informer.accept',
    text: 'cookiePolicy.informer.text',
    textShort: 'cookiePolicy.informer.textShort',
    linkText: 'cookiePolicy.informer.more',
    linkTextShort: 'cookiePolicy.informer.moreShort',
    textMagritte: 'cookiePolicy.informer.text.magritte',
    linkMagritte: 'cookiePolicy.informer.link.magritte',
};

const COOKIE_ZP_POLICY_LINK = 'https://hhcdn.ru/file/17961880.doc';
const COOKIE_POLICY_ARTICLE_LINK = '/article/cookie_policy';
const COOKIE_POLICY_BANNER_ENABLED_CLASS = 'cookie-policy-banner-enabled';

const isCookiesPolicyInformerVisibleAction = makeSetStoreField('isCookiesPolicyInformerVisible');

const CookiesPolicyInformer: TranslatedComponent = ({ trls }) => {
    const isCookiesPolicyInformerVisible = useSelector((state) => state.isCookiesPolicyInformerVisible);
    const { isGtXS } = useBreakpoint();
    const isZp = useIsZarplataPlatform();

    const dispatch = useDispatch();
    const wrapperRef = useRef<HTMLDivElement>(null);
    const isAnonymousMagritteExp = useSelector((state) => state.isAnonymousMagritteExp);
    const hhtmSource = useSelector((state) => state.analyticsParams.hhtmSource);

    useEffect(() => {
        if (isCookiesPolicyInformerVisible && wrapperRef.current) {
            cookiesBannerElementShown(wrapperRef.current);
            document.body.classList.add(COOKIE_POLICY_BANNER_ENABLED_CLASS);
        }

        if (!isCookiesPolicyInformerVisible && document.body.classList.contains(COOKIE_POLICY_BANNER_ENABLED_CLASS)) {
            document.body.classList.remove(COOKIE_POLICY_BANNER_ENABLED_CLASS);
        }
    }, [isCookiesPolicyInformerVisible]);

    if (!isCookiesPolicyInformerVisible) {
        return null;
    }

    const setAgreed = () => {
        cookiesBannerAcceptButtonClick();
        setTopLevelCookie(COOKIE_AGREEMENT_NAME, 'true', COOKIE_EXPIRE_TIME);
        dispatch(isCookiesPolicyInformerVisibleAction(false));
    };

    if (isAnonymousMagritteExp) {
        return (
            <NoIndex>
                <div
                    ref={wrapperRef}
                    className={classnames(styles.wrapper, styles.wrapperMagritte, { [styles.wrapperFixed]: isGtXS })}
                    id={isGtXS ? BOTTOM_COOKIES_POLICY_INFORMER_ID : undefined}
                    data-qa="cookies-policy-informer"
                >
                    <Text style="contrast" typography="paragraph-2-regular" Element="span">
                        {trls[TrlKeys.textMagritte]}{' '}
                        <LinkMagritte
                            inline
                            underlined
                            style="contrast"
                            href={`${isZp ? COOKIE_ZP_POLICY_LINK : COOKIE_POLICY_ARTICLE_LINK}?hhtmFrom=${hhtmSource}`}
                        >
                            {trls[TrlKeys.linkMagritte]}
                        </LinkMagritte>
                    </Text>
                    <HSpacing default={24} />
                    <ButtonMagritte
                        size="small"
                        style="contrast"
                        mode="primary"
                        data-qa="cookies-policy-informer-accept"
                        onClick={setAgreed}
                    >
                        {trls[TrlKeys.accept]}
                    </ButtonMagritte>
                </div>
            </NoIndex>
        );
    }

    return (
        <NoIndex>
            <div
                ref={wrapperRef}
                className={classnames(styles.wrapper, { [styles.wrapperFixed]: isGtXS })}
                id={isGtXS ? BOTTOM_COOKIES_POLICY_INFORMER_ID : undefined}
                data-qa="cookies-policy-informer"
            >
                <ColumnsWrapper>
                    <Column xs="4" s="8" m="12" l="16">
                        <div className={styles.content}>
                            <div className={classnames(styles.text, styles.textDesktop)}>
                                {trls[TrlKeys.text]}
                                <br />
                                <Link
                                    to={isZp ? COOKIE_ZP_POLICY_LINK : COOKIE_POLICY_ARTICLE_LINK}
                                    target="_blank"
                                    data-qa="cookies-policy-informer-link-desktop"
                                >
                                    {trls[TrlKeys.linkText]}
                                </Link>
                            </div>
                            <div className={classnames(styles.text, styles.textMobile)}>
                                {trls[TrlKeys.textShort]}{' '}
                                <BlokoLink
                                    to={isZp ? COOKIE_ZP_POLICY_LINK : COOKIE_POLICY_ARTICLE_LINK}
                                    Element={Link}
                                    kind={LinkKind.Tertiary}
                                    appearance={LinkAppearance.Underlined}
                                    target="_blank"
                                    data-qa="cookies-policy-informer-link-mobile"
                                >
                                    {trls[TrlKeys.linkTextShort]}
                                </BlokoLink>
                            </div>
                            <div className={styles.buttons}>
                                <Button
                                    onClick={setAgreed}
                                    kind={ButtonKind.Primary}
                                    data-qa="cookies-policy-informer-accept"
                                >
                                    {trls[TrlKeys.accept]}
                                </Button>
                            </div>
                        </div>
                    </Column>
                </ColumnsWrapper>
            </div>
        </NoIndex>
    );
};

export default translation(CookiesPolicyInformer);
