import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';
import { CompensationMode } from '@hh.ru/types-hh-microcore';

import { CurrencyType } from 'src/models/currencies.types';

export interface UnpremoderatedPremoderator {
    id: number;
    name: string;
    vacancyCount: number;
}

export interface UnpremoderatedVacancy {
    id: number;
    name: string;
    area: string;
    publicationTime: string;
    moneyRange: {
        currencyCode: CurrencyType;
        perModeFrom: number | null;
        perModeTo: number | null;
        mode: CompensationMode;
    };
    manager: {
        id: number;
        name: string;
    };
}

export interface UnpremoderatedVacancies {
    list: UnpremoderatedVacancy[];
    count: number;
    page: number;
    loadMore: boolean;
}

export interface UnpremoderatedVacancyList {
    premoderators: UnpremoderatedPremoderator[];
    vacancies: {
        [moderatorId: number]: UnpremoderatedVacancies;
    };
}

const LOADED_MORE_UNPREMODERATED_VACANCIES = 'LOADED_MORE_UNPREMODERATED_VACANCIES';
const REMOVE_UNPREMODERATED_VACANCY = 'REMOVE_UNPREMODERATED_VACANCY';

interface PayloadTypes {
    [LOADED_MORE_UNPREMODERATED_VACANCIES]: { moderatorId: number; vacancies: UnpremoderatedVacancies };
    [REMOVE_UNPREMODERATED_VACANCY]: { moderatorId: number; vacancyId: number };
}

const actionCreator = ActionCreatorHelper<PayloadTypes>();

export const loadedMoreUnpremoderatedVacanciesAction = actionCreator(LOADED_MORE_UNPREMODERATED_VACANCIES);
export const removeUnpremoderatedVacancyAction = actionCreator(REMOVE_UNPREMODERATED_VACANCY);

export default createReducer<UnpremoderatedVacancyList, PayloadTypes>(null, {
    [LOADED_MORE_UNPREMODERATED_VACANCIES]: (state, { payload }) => ({
        ...state,
        vacancies: {
            ...state.vacancies,
            [payload.moderatorId]: {
                ...payload.vacancies,
                list: [...(state.vacancies[payload.moderatorId]?.list || []), ...payload.vacancies.list],
            },
        },
    }),
    [REMOVE_UNPREMODERATED_VACANCY]: (state, { payload }) => ({
        ...state,
        vacancies: {
            ...state.vacancies,
            [payload.moderatorId]: {
                ...state.vacancies[payload.moderatorId],
                list: state.vacancies[payload.moderatorId].list.filter((vacancy) => vacancy.id !== payload.vacancyId),
                count: state.vacancies[payload.moderatorId].count - 1,
            },
        },
    }),
});
