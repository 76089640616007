import { ReactNode, ReactElement } from 'react';

import { Link } from '@hh.ru/magritte-ui';
import { Link as SpaLink } from '@hh.ru/redux-spa-middleware';

import { NextFunction } from 'src/components/Markup/makrupTypes';
import wrapByRegExp from 'src/components/Markup/wrapByRegExp';
import { useSelector } from 'src/hooks/useSelector';

const Features = {
    whiteListDomains: 'linkify_white_list_domains',
};

const styles: React.CSSProperties = {
    wordBreak: 'break-word',
    textAlign: 'left',
    display: 'inline-block',
};

const getRegExp = (domains: string) => {
    if (!domains) {
        return null;
    }
    // Заменяем запятые на '|', экранируем точки
    const domainPattern = domains.split(',').join('|').replace(/\./g, '\\.');
    const protocol = '(https?:\\/\\/?)';
    const path = `(\\/[^\\s]*[~&$'*+=/#\\w])`;

    return new RegExp(`\\b${protocol}?(www\\.)?(${domainPattern})${path}?`, 'gi');
};

export function findDomNodesMatchingPattern(root: Node, pattern: RegExp): Node[] {
    if (!pattern) {
        return [];
    }
    const result: Node[] = [];
    function traverseDOM(node: Node) {
        if (node.nodeName === 'SCRIPT' || node.nodeName === 'STYLE') {
            return;
        }
        if (node.nodeType === Node.TEXT_NODE && node.textContent?.trim()) {
            pattern.lastIndex = 0;
            if (pattern.test(node.textContent)) {
                result.push(node);
            }
        }
        node.childNodes.forEach((child) => traverseDOM(child));
    }
    traverseDOM(root);
    return result;
}

export function useWhiteListDomainsRegexp() {
    const domains = useSelector((state) => state.features[Features.whiteListDomains]) as string;
    return getRegExp(domains);
}

export default function useLinkifyWhiteList() {
    const regexp = useWhiteListDomainsRegexp();
    if (!regexp) {
        return null;
    }
    const wrap = (found: RegExpExecArray): ReactElement => {
        const protocol = found[1] || '';
        const www = found[2] || '';
        const domain = found[3] || '';
        const path = found[4] || '';

        return (
            <Link
                key={found.index}
                Element={SpaLink}
                to={`${protocol || 'https://'}${domain}${path}`}
                target="_blank"
                inline
                rel="noopener noreferrer"
            >
                <span style={styles}>{`${protocol}${www}${domain}${path}`}</span>
            </Link>
        );
    };

    return (next: NextFunction) =>
        (string: string): ReactNode => {
            return wrapByRegExp(string, regexp, wrap, next);
        };
}
