import SearchType from 'src/components/Search/Common/Filters/types';

import getEmployerVacancySearchParams, {
    EmployerVacancySearchParams,
} from 'src/components/Search/Common/Filters/utils/getSearchParams/employerVacancy';
import getResumeSearchParams, {
    ResumeSearchParams,
} from 'src/components/Search/Common/Filters/utils/getSearchParams/resume';
import { GetSearchParamsArgs } from 'src/components/Search/Common/Filters/utils/getSearchParams/types';
import getVacancySearchParams, {
    VacancySearchParams,
} from 'src/components/Search/Common/Filters/utils/getSearchParams/vacancy';
import getVacancyMapSearchParams, {
    VacancyMapSearchParams,
} from 'src/components/Search/Common/Filters/utils/getSearchParams/vacancyMap';

const getSearchParams = (
    props: GetSearchParamsArgs
): VacancySearchParams | ResumeSearchParams | VacancyMapSearchParams | EmployerVacancySearchParams => {
    const { searchType } = props;

    let searchParams = {} as
        | VacancySearchParams
        | ResumeSearchParams
        | VacancyMapSearchParams
        | EmployerVacancySearchParams;
    if (searchType === SearchType.Resume) {
        searchParams = getResumeSearchParams(props);
    } else if (searchType === SearchType.Vacancy) {
        searchParams = getVacancySearchParams(props);
    } else if (searchType === SearchType.VacancyMap) {
        searchParams = getVacancyMapSearchParams(props);
    } else if (searchType === SearchType.EmployerVacancy) {
        searchParams = getEmployerVacancySearchParams(props);
    }

    return searchParams;
};

export default getSearchParams;
