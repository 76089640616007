import { FC, useCallback, useMemo } from 'react';

import { Checkbox } from '@hh.ru/magritte-ui';

import FilterItem from 'src/components/Search/Common/Filters/FilterItem';
import MobileViewControl from 'src/components/Search/Common/Filters/MobileViewControl';
import { useApplyFilter } from 'src/components/Search/Common/Filters/hooks/useApplyFilter';
import { toggleValueInArray } from 'src/components/Search/Common/Filters/utils/toggleValueInArray';
import SwitchItem from 'src/components/Search/Vacancy/Filters/FilterSwitchItem';
import { useSelector } from 'src/hooks/useSelector';
import { NovaFilterKey } from 'src/models/novaFilters';
import { LabelOption } from 'src/models/vacancySearchCriteria';

const EMPTY_ARRAY: Array<LabelOption> = [];

const Internship: FC = () => {
    // single filter group
    const group = useSelector((state) => state.searchClusters[NovaFilterKey.Label]?.groups?.internship);
    const selectedValues =
        useSelector((state) => state.searchClusters[NovaFilterKey.Label]?.selectedValues) || EMPTY_ARRAY;
    const isInternshipChecked = useMemo(() => selectedValues.includes(LabelOption.Internship), [selectedValues]);

    // callbacks
    const applyFilter = useApplyFilter(NovaFilterKey.Label);
    const toggleInternship = useCallback(
        () => applyFilter(toggleValueInArray(LabelOption.Internship, selectedValues)),
        [applyFilter, selectedValues]
    );

    // can be empty
    if (!group) {
        return null;
    }

    return (
        <MobileViewControl
            mobileView={
                <SwitchItem
                    title={group.title}
                    checked={isInternshipChecked}
                    onClick={toggleInternship}
                    dataQa={`serp__novafilter-${LabelOption.Internship}-${group.id}`}
                />
            }
        >
            <FilterItem
                left={
                    <Checkbox
                        name={LabelOption.Internship}
                        value={group.id}
                        dataQaCheckbox={`serp__novafilter-${LabelOption.Internship}-${group.id}`}
                        onChange={toggleInternship}
                        checked={isInternshipChecked}
                    />
                }
                title={group.title}
                disabled={group.disabled}
                count={group.count}
            />
        </MobileViewControl>
    );
};

export default Internship;
