import { ComponentType } from 'react';

import { Button, useBreakpoint } from '@hh.ru/magritte-ui';

import translation from 'src/components/translation';

const TrlKeys = {
    again: 'zp.importResume.v2.import.again',
    another: 'zp.importResume.import.another',
    okay: 'zp.importResume.import.okay',
};

interface Props {
    value: string;
    error: boolean;
    onAuth: () => void;
    onAgain: () => void;
    onSelect: () => Promise<void>;
}

const ImportFooter: ComponentType<Props> = translation(({ trls, value, error, onAgain, onAuth, onSelect }) => {
    const { isMobile } = useBreakpoint();

    const buttons = [
        <Button key="another" style="accent" mode="secondary" onClick={onAuth}>
            {trls[TrlKeys.another]}
        </Button>,
        <Button
            key="okay"
            style="accent"
            mode="primary"
            onClick={onSelect}
            data-qa="import_resume-resume-import_button"
            disabled={!value}
        >
            {trls[TrlKeys.okay]}
        </Button>,
    ];

    return error ? (
        <Button style="accent" mode="primary" onClick={onAgain}>
            {trls[TrlKeys.again]}
        </Button>
    ) : (
        <>{(isMobile ? buttons.reverse() : buttons).map((button) => button)}</>
    );
});

export default ImportFooter;
