import { AdfoxMock } from '@hh.ru/banner-front-utils/entities/AdfoxMock';
import Tip from 'bloko/blocks/drop/Tip/tip';
import Cookies from 'bloko/common/Cookies';
import Template from 'bloko/common/template';
import urlParser from 'bloko/common/urlParser';

import YandexAdfoxBanner from 'HH/YandexAdfoxBanner';
import { COOKIE_AGREEMENT_NAME } from 'src/components/CookiesPolicy/utils';
import { CountryId } from 'src/models/countryId';
import { sendRealBannerViewAnalytics } from 'src/utils/sendAdvSpyAnalytics';

import axios from 'HHC/Axios';
import clickmeTemplate from 'HHC/Banners/clickme.mustache';
import imageTemplate from 'HHC/Banners/image.mustache';
import Components from 'HHC/Components';
import Debug from 'HHC/Debug';

export const EVENT_NAME_INIT = 'HH-Banners-Init';
export const EVENT_NAME_PREPARE = 'HH-Banners-Prepare';
export const EVENT_NAME_ERROR = 'HH-Banners-Error';
const IS_SMALL_BANNER = 'isSmallBanner';
const IS_MEDIUM_BANNER = 'isMediumBanner';
const IS_LARGE_BANNER = 'isLargeBanner';

const promiseWithResolvers = () => {
    let resolve;
    let reject;

    const promise = new Promise((_resolve, _reject) => {
        resolve = _resolve;
        reject = _reject;
    });

    return { promise, resolve, reject };
};

// кажется, что лучше обойтись без обращения к глобальным переменным таким образом
// но из всех доступных способов определить страну, в данном контексте, этот - не самый плохой
export const isExternalBannersRestricted = (isBelarus = window?.globalVars?.country === CountryId.Belarus) => {
    // на сервере нет доступа к кукам, поэтому проверка не имеет смысла, всегда будет true, а значит вёрстка прилетит
    // с пустым дивом, как следствие первые баннеры (которые строятся на сервере) не построятся на клиенте
    // если же мы всегда строим баннер на сервере, но на клиенте понимаем, что баннер не нужен, то запрос в баннерку не летит
    // и баннер не строится
    if (process.env.SSR) {
        return false;
    }

    try {
        return isBelarus && Cookies.get(COOKIE_AGREEMENT_NAME) !== 'true';
    } catch (_) {
        return isBelarus;
    }
};

const isExternalBannerYandex = (body) => body && typeof body === 'string' && body.includes('yandex');

const setBannerSize = (bannerJson) => {
    const { width, height } = bannerJson;

    if (width <= 140 && height <= 140) {
        bannerJson[IS_SMALL_BANNER] = true;
    } else if (width > 140 && height > 140 && width <= 250 && height <= 160) {
        bannerJson[IS_MEDIUM_BANNER] = true;
    } else {
        bannerJson[IS_LARGE_BANNER] = true;
    }
    return bannerJson;
};

const setHoverTipOnAdvertiserName = (bannerJson, advertiserCssClass) => {
    // используем querySelectorAll, т.к. один и тот же баннер может быть использован n-раз на странице
    const elements = document.querySelectorAll(`.${advertiserCssClass}-${bannerJson.place}`);
    elements.forEach((element) => {
        if (element.dataset.marked) {
            return;
        }
        element.dataset.marked = true;

        const tipClickable = Components.make(Tip, element, {
            host: 'body',
            html: bannerJson.advertiserLegalName,
        });
        element.addEventListener('mouseover', tipClickable.show);
        element.addEventListener('mouseout', tipClickable.hide);
    });
};

const modifyIfExternalAdvertiser = ({ bannerJson, bannerPlace, advertiserNameCssClass, setSize = false }) => {
    const { isExternalAdvertising, advertiserLegalName, width, height } = bannerJson;
    if (!isExternalAdvertising || !advertiserLegalName || !width || !height) {
        return bannerJson;
    }

    bannerPlace.classList.add(`banner-place-${bannerJson.place}_with-adv-mark`);

    if (setSize) {
        setBannerSize(bannerJson);
    }
    window.addEventListener(EVENT_NAME_INIT, () => setHoverTipOnAdvertiserName(bannerJson, advertiserNameCssClass));
    return bannerJson;
};

const safeOpenWindow = function (bannerJson, logIfBlocked = false) {
    const popup = window.open(bannerJson.click);
    if (popup) {
        try {
            popup.opener = null;
        } catch (_) {} // eslint-disable-line no-empty
    } else if (logIfBlocked) {
        Debug.log('error out', new Error('Banner blocked'), bannerJson);
    }
};
const createClickableArea = function (bannerJson, bannerPlace) {
    const div = document.createElement('div');
    div.style.width = `${bannerJson.width}px`;
    div.style.height = `${bannerJson.height}px`;
    div.className = `banner-place__clickable-area banner-place__clickable-area_${bannerJson.place}`;
    div.onclick = function () {
        safeOpenWindow(bannerJson);
    };
    bannerPlace.appendChild(div);
};
const processClickable = function (bannerJson, bannerPlace, isCommonBanner) {
    if (!bannerJson.clickable || isCommonBanner) {
        return;
    }
    createClickableArea(bannerJson, bannerPlace);
};
const addClassToBanner = function (bannerPlace, classNameProp) {
    const wrapper = bannerPlace.closest('.HHC-Banner-Wrapper');
    bannerPlace.classList.add(bannerPlace.dataset[classNameProp]);
    if (wrapper) {
        wrapper.classList.add(wrapper.dataset[classNameProp]);
    }
};
const INVISIBLE_BANNERS = [651, 652, 653];
const handlers = {
    external(bannerJson, bannerPlace, callbacks) {
        const resultBannerJson = modifyIfExternalAdvertiser({
            bannerJson,
            bannerPlace,
            advertiserNameCssClass: 'banner-iframe-advertiser',
        });

        const isCommonBanner =
            window.globalVars.features.iframe_fix_size_banners &&
            window.globalVars.features.iframe_fix_size_banners.split(/[, ]+/).includes(String(resultBannerJson.place));

        processClickable(resultBannerJson, bannerPlace, isCommonBanner);

        const iframe = document.createElement('iframe');
        iframe.style.width = `${resultBannerJson.width || 0}px`;
        iframe.style.height = `${resultBannerJson.height || 0}px`;
        iframe.src = resultBannerJson.src;
        iframe.frameBorder = 'no';
        iframe.scrolling = 'no';
        iframe.className = `${resultBannerJson.additionalClass} banner-place-iframe-${resultBannerJson.place}`;

        bannerPlace.classList.add('banner-place_iframe');
        bannerPlace.appendChild(iframe);

        if (resultBannerJson.isExternalAdvertising) {
            const bannerIframeAdvMark = document.createElement('div');
            const advLimit = document.createElement('div');
            const advLegalName = document.createElement('div');

            bannerIframeAdvMark.classList.add('banner-iframe-adv-mark');
            advLimit.classList.add('banner-iframe-adv-limit');
            advLegalName.classList.add(
                'banner-iframe-advertiser',
                `banner-iframe-advertiser-${resultBannerJson.place}`
            );
            iframe.classList.add('banner-place-iframe_with-adv-mark');

            advLimit.innerText = `${resultBannerJson.advMarkTrls} ∙ 18+`;
            advLegalName.innerText = resultBannerJson.advertiserLegalName;

            bannerIframeAdvMark.appendChild(advLimit);
            bannerIframeAdvMark.appendChild(advLegalName);
            bannerPlace.appendChild(bannerIframeAdvMark);
        }

        iframe.addEventListener('load', () => {
            if (bannerPlace.dataset.postMessage || isCommonBanner) {
                const parser = document.createElement('a');
                parser.href = resultBannerJson.src;
                iframe.contentWindow.postMessage(resultBannerJson, `${parser.protocol}//${parser.host}`);
            }
            callbacks.success();
        });
    },
    image(bannerJson, bannerPlace, callbacks) {
        bannerPlace.classList.add(`banner-place-${bannerJson.place}_image`, 'banner-place_image');
        const resultBannerJson = modifyIfExternalAdvertiser({
            bannerJson,
            bannerPlace,
            advertiserNameCssClass: 'banner-image-advertiser',
            setSize: true,
        });
        bannerPlace.insertAdjacentHTML('beforeend', imageTemplate.render(resultBannerJson));
        callbacks.success();
    },
    clickme(bannerJson, bannerPlace, callbacks) {
        const resultBannerJson = modifyIfExternalAdvertiser({
            bannerJson,
            bannerPlace,
            advertiserNameCssClass: 'clickme-banner__advertiser',
        });

        if (INVISIBLE_BANNERS.includes(bannerJson.place)) {
            bannerJson.linkClass += ' vacancy-sidebar-clickme-banner-default-cursor';
        }

        bannerPlace.insertAdjacentHTML('beforeend', clickmeTemplate.render(resultBannerJson));
        callbacks.success();
    },
    adfox(bannerJson, bannerPlace, callbacks) {
        // необходимо из-за библиотеки securePortal, которая переопределяет метод sendBeacon
        if (typeof navigator?.sendBeacon === 'function') {
            navigator.sendBeacon = navigator.sendBeacon.bind(navigator);
        }

        Components.make(YandexAdfoxBanner, bannerPlace, { ...bannerJson, callbacks });
    },
};

const getExtraHeaders = () => {
    const hhuid = Cookies.get('hhuid');
    const hhid = Cookies.get('_hi');

    const headers = {
        Hhuid: hhuid,
        Token: hhuid,
    };

    if (hhid) {
        headers.Hhid = hhid;
    }

    return headers;
};

export const getPVRequestHeaders = () => ({
    'X-Referer': window.location.href,
    ...getExtraHeaders(),
});

/**
 * Модуль для работы с баннерами. <br>
 *
 * Обрабатывает имеющиеся на странице json запросы в баннерку, и расставляет полученые баннеры по нодам. <br>
 *
 * @type {Object}
 * @exports HHC/Banners
 */
const Banners = {
    requestedBanners: [],
    batchUrlRetriesCount: 10,
    requestedSystems: {
        adfox: null,
        adfoxMock: null,
    },
    /**
     * Инициализация баннерной системы
     *
     * @member
     * @method
     */
    init() {
        requestBanners([...document.querySelectorAll('.HHC-Banners-Place')]);
    },
    /**
     * Функция для размещение пришедшего баннера в заранее подготовленную ноду. <br>
     * Нода находится по классу banner-place_{id}.
     *
     * @param {object} json Ответ от баннерной системы
     *
     * @member
     * @method
     */
    put({ banners }, bannerPlacesAll, startTime) {
        if (!Array.isArray(banners)) {
            return;
        }
        banners.forEach((bannerJson) => {
            if (
                !bannerJson ||
                (bannerJson.type === 'external' &&
                    isExternalBannersRestricted() &&
                    // Adfox баннеры могут прилететь как external и сюда в том числе, не только через React
                    isExternalBannerYandex(bannerJson.body))
            ) {
                return;
            }
            bannerPlacesAll.forEach((bannerPlace) => {
                if (
                    bannerPlace.classList.contains(`HHC-Banner-${bannerJson.place}`) === false ||
                    bannerPlace.dataset.loaded === 'true'
                ) {
                    return;
                }
                if (bannerJson.empty) {
                    addClassToBanner(bannerPlace, 'emptyClass');
                } else if (typeof handlers[bannerJson.type] !== 'function') {
                    Debug.log('error out', 'Unsupported banner type', bannerJson);
                    addClassToBanner(bannerPlace, 'emptyClass');
                } else {
                    if (bannerJson.viewUrl) {
                        sendRealBannerViewAnalytics({ element: bannerPlace, viewUrl: bannerJson.viewUrl });
                    }
                    addClassToBanner(bannerPlace, 'loadedClass');
                    bannerJson.additionalClass = bannerPlace.dataset.bannerAdditionalClass || '';
                    bannerJson.advMarkTrls = bannerPlace?.dataset?.bannerAdvMarkTrl;

                    const eventDetail = {
                        detail: {
                            startTime,
                            bannerPlace: bannerJson.place,
                            bannerId: bannerJson.id,
                            bannerType: bannerJson.type,
                        },
                    };

                    handlers[bannerJson.type](bannerJson, bannerPlace, {
                        success: () => Banners._dispatchEvent(bannerPlace, EVENT_NAME_INIT, eventDetail),
                        error: () => Banners._dispatchEvent(bannerPlace, EVENT_NAME_ERROR, eventDetail),
                    });
                    bannerPlace.dataset.loaded = 'true';
                    Banners._dispatchEvent(bannerPlace, EVENT_NAME_PREPARE);
                }
            });
        });
    },
    /**
     * Создает в указанной ноде баннеры с id из переданного списка.
     *
     * @param {Element} element         Элемент в который будут положены баннеры
     * @param {Array}   ids             ID баннеров
     * @param {String}  [templateName]  Имя шаблона для баннеров
     * @param {Object}  adfoxParams     Adfox параметры баннера
     *
     * @member
     * @method
     */
    create(element, ids, templateName, adfoxParams = {}) {
        if (templateName) {
            const bannersTemplate = Template.fromElement(document.querySelector(`.${templateName}`));
            const groupBannersPlaces = element.querySelectorAll('.HHC-Group-Banners-Place');
            const bannerPlaceClass = 'HHC-Banners-Place';
            let places = [];
            if (groupBannersPlaces.length > 0) {
                groupBannersPlaces.forEach((place) => {
                    place.insertAdjacentHTML(
                        'beforeend',
                        bannersTemplate.replace(/%id%/g, place.dataset.bannerId).trim()
                    );
                    places.push(place.querySelector(`.${bannerPlaceClass}`));
                });
            } else {
                element.insertAdjacentHTML(
                    'beforeend',
                    ids.map((id) => bannersTemplate.replace(/%id%/g, id).trim()).join('')
                );
                places = [...element.childNodes].filter((child) => child.classList.contains(bannerPlaceClass));
            }
            requestBanners(places, adfoxParams);
        } else {
            requestBanners([element], adfoxParams);
        }
    },
    waitingForBatchUrlReady() {
        const { promise, resolve } = promiseWithResolvers();

        if (window.globalVars?.bannersBatchUrl) {
            resolve();
        } else {
            let currentRetryNumber = 0;
            const intervalId = setInterval(() => {
                currentRetryNumber += 1;
                if (window.globalVars?.bannersBatchUrl) {
                    clearInterval(intervalId);
                    resolve();
                }
                if (currentRetryNumber >= Banners.batchUrlRetriesCount) {
                    clearInterval(intervalId);
                }
            }, 1_000);
        }

        return promise;
    },
    _dispatchEvent(bannerPlace, eventName, eventData) {
        bannerPlace.dispatchEvent(
            new CustomEvent(eventName, {
                bubbles: true,
                ...eventData,
            })
        );
    },
};

function createAsyncScript(src) {
    const { promise, resolve } = promiseWithResolvers();
    const script = document.createElement('script');
    script.setAttribute('src', src);
    script.setAttribute('async', 'async');
    document.body.appendChild(script);

    script.addEventListener('load', resolve);

    return promise;
}

function requestBanners(bannerPlaces, adfoxParams = {}) {
    if (!window.globalVars.bannersBatchUrl || !bannerPlaces.length) {
        return;
    }
    bannerPlaces = bannerPlaces.filter(
        (bannerPlace) => bannerPlace.dataset.requested !== 'true' && Boolean(bannerPlace.dataset.bannerId)
    );
    bannerPlaces.forEach((bannerPlace) => (bannerPlace.dataset.requested = 'true'));
    const ids = bannerPlaces.map((bannerPlace) => bannerPlace.dataset.bannerId);
    if (ids.length === 0) {
        return;
    }
    const requestBannersUrl = urlParser(window.globalVars.bannersBatchUrl);
    requestBannersUrl.params.format = ['json'];
    requestBannersUrl.params.place = ids;
    const startTime = performance.now();

    if (Object.keys(adfoxParams).length > 0) {
        if (adfoxParams.useAdfoxMock) {
            AdfoxMock();
            Banners.put({ banners: [adfoxParams] }, bannerPlaces, startTime);
            return;
        }

        // Для корректной работы Adfox на SPA страницах
        if (!window.Ya?.adfoxCode && !Banners.requestedSystems.adfox) {
            Banners.requestedSystems.adfox = createAsyncScript('https://yandex.ru/ads/system/context.js');
        }

        if (adfoxParams.headerBidding && !window.YaHeaderBiddingSettings && !Banners.requestedSystems.adfoxMock) {
            Banners.requestedSystems.adfoxMock = createAsyncScript('https://yandex.ru/ads/system/header-bidding.js');
        }

        Promise.all(Object.values(Banners.requestedSystems))
            .then(() => Banners.put({ banners: [adfoxParams] }, bannerPlaces, startTime))
            .catch(() => {});
        return;
    }

    axios
        .get(requestBannersUrl.href, {
            withCredentials: true,
            headers: getPVRequestHeaders(),
        })
        .then((response) => {
            Banners.put(response.data, bannerPlaces, startTime);
        })
        .catch(console.error);
}

export default Banners;
