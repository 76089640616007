import { ButtonProps } from '@hh.ru/magritte-ui';
import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';

interface EmployerDeedOrderModalState {
    submitButtonProps: ButtonProps;
}

const UPDATE_SUBMIT_BUTTON_PROPS = 'UPDATE_SUBMIT_BUTTON_PROPS';

interface PayloadTypes {
    [UPDATE_SUBMIT_BUTTON_PROPS]: {
        props: ButtonProps;
    };
}

const actionCreator = ActionCreatorHelper<PayloadTypes>();

export const updateSubmitButtonPropsAction = actionCreator(UPDATE_SUBMIT_BUTTON_PROPS);

export const INITIAL_STATE: EmployerDeedOrderModalState = {
    submitButtonProps: {
        mode: 'primary',
        children: null,
    },
};

export default createReducer<EmployerDeedOrderModalState, PayloadTypes>(INITIAL_STATE, {
    [UPDATE_SUBMIT_BUTTON_PROPS]: (oldState, action) => {
        const { props } = action.payload;

        return {
            ...oldState,
            submitButtonProps: props,
        };
    },
});
