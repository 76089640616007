import classNames from 'classnames';

import { Text, Link } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { type ContactPhoneItem } from 'src/models/contactPhones';

import styles from './styles.less';

const TrlKeys = {
    city: 'personalManager.region.city',
    mobile: 'personalManager.region.mobile',

    msk: 'supernova.modal.help.phone.region.msk',
    other: 'supernova.modal.help.phone.region.other',
    regions: 'supernova.modal.help.phone.region.regions',
    spb: 'supernova.modal.help.phone.region.spb',

    zarplata: 'personalManager.region.zarplata',
};

interface SupportContactProps {
    stretched?: boolean;
    isLargeContactNumber?: boolean;
}

const SupportContact: TranslatedComponent<ContactPhoneItem & SupportContactProps> = ({
    trls,
    postfix,
    country,
    city,
    number,
    stretched,
    isLargeContactNumber,
}) => {
    return (
        <div
            className={classNames(styles.supportContactItem, {
                [styles.stretched]: stretched,
            })}
        >
            <Text typography="label-3-regular" style="secondary">
                {trls[TrlKeys[postfix]]}
            </Text>
            <Link
                Element="a"
                style="neutral"
                typography={isLargeContactNumber ? 'title-4-semibold' : 'label-2-regular'}
                href={`tel:${country}${city}${number.replaceAll('-', '')}`}
            >
                {`${country} ${city} ${number}`}
            </Link>
        </div>
    );
};

export default translation(SupportContact);
