import configureAndInitSentry, { ConfiguredSentryResult, Config } from '@hh.ru/sentry-js';

let initedSentryClient: ConfiguredSentryResult | undefined;

const initSentry = (options: Config) => {
    initedSentryClient = configureAndInitSentry(options);
};

export const getSentry = () => initedSentryClient;

export default initSentry;
