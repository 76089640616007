import { FC, useRef } from 'react';

import { Avatar, Cell, CellText, TooltipHover, Text } from '@hh.ru/magritte-ui';
import { Link } from '@hh.ru/redux-spa-middleware';

import Compensation from 'src/components/Compensation';
import { getVacancyCompanyLink } from 'src/components/RainbowCatalog/utils';
import { getVacancyLink } from 'src/components/VacanciesOfTheDay/Utils';
import { sendAnalytics } from 'src/components/VacanciesOfTheDay/analytics';
import useIsTextClamped from 'src/hooks/useIsTextClamped';
import { useSelector } from 'src/hooks/useSelector';
import { VacancyOfTheDay } from 'src/models/vacanciesOfTheDay';
import { VacancyOfTheDayLocation } from 'src/utils/sendAdvSpyAnalytics';

interface VacancyOfTheDayProps {
    vacancy: VacancyOfTheDay;
}

const VacancyOfTheDayComponent: FC<VacancyOfTheDayProps> = ({ vacancy }) => {
    const vacancyNameRef = useRef(null);
    const isVacancyNameClamped = useIsTextClamped(vacancyNameRef);
    const hhtmSource = useSelector((state) => state.analyticsParams.hhtmSource);

    return (
        <Cell
            key={vacancy.vacancyId}
            align="top"
            avatar={
                <Avatar
                    shapeCircle
                    size={40}
                    mode="image"
                    fallbackMode="placeholder"
                    image={vacancy.company.logoUrl || ''}
                    placeholder="city"
                    aria-label="company-logo"
                />
            }
        >
            <CellText>
                <Link
                    to={getVacancyLink(vacancy, VacancyOfTheDayLocation.RainbowCatalog)}
                    additionalQueryParams={{
                        source: 'rainbowCatalog',
                        from: hhtmSource,
                        hhtmFromLabel: 'recommended_vacancies',
                    }}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => sendAnalytics(vacancy.vacancyId, 'rainbow_catalog')}
                >
                    <Text typography="label-2-regular" maxLines={1}>
                        <div ref={vacancyNameRef}>{vacancy.name}</div>
                    </Text>
                </Link>

                {isVacancyNameClamped && (
                    <TooltipHover placement="top-center" activatorRef={vacancyNameRef}>
                        {vacancy.name}
                    </TooltipHover>
                )}
                <Text typography="label-3-regular" style="secondary">
                    <Compensation {...vacancy.compensation} analyticsContext="VacancyOfTheDay 2" showNoSalaryMessage />
                </Text>

                <Link to={getVacancyCompanyLink(vacancy)}>
                    <Text typography="label-3-regular" style="tertiary">
                        {vacancy.company.visibleName} · {vacancy.area.name}
                    </Text>
                </Link>
            </CellText>
        </Cell>
    );
};

export default VacancyOfTheDayComponent;
