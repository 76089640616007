import { createElement } from 'react';
import classnames from 'classnames';
import { domToReact } from 'html-react-parser';

import { HHA_TAGS } from 'src/components/IndexPageAnonymous/WorkInCity/consts';
import { TagMapperComponent, TagMapperProps } from 'src/hooks/useParseHtml';

import styles from 'src/components/IndexPageAnonymous/WorkInCity/styles.less';

const Paragraph: TagMapperComponent<TagMapperProps> = ({ options, originalElement: { name, children, parent } }) => (
    <>
        {!!children &&
            !!name &&
            createElement(
                name,
                {
                    className: classnames(styles.workInCityParagraph, {
                        [styles.workInCityParagraphToggling]: parent?.name === HHA_TAGS.toggle,
                    }),
                },
                domToReact(children, options)
            )}
    </>
);

export default Paragraph;
