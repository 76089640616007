import { FC, useState, PropsWithChildren } from 'react';

import { CrossOutlinedSize16 } from '@hh.ru/magritte-ui/icon';
import { IconLink } from 'bloko/blocks/icon';
import Text from 'bloko/blocks/text';
import Cookies from 'bloko/common/Cookies';

import BlokoIconReplaceContainer from 'src/components/BlokoIconReplaceContainer';
import ImportantNotifications from 'src/components/ImportantNotifications';

import styles from './site-exceptions.less';

interface SiteExceptionProps {
    hasRemoveIcon?: boolean;
    cookieName?: string;
}

const HIDE_PERIOD_IN_MINUTES = 15;

const SiteException: FC<SiteExceptionProps & PropsWithChildren> = ({ children, hasRemoveIcon, cookieName }) => {
    const needShow = process.env.SSR ? false : !!cookieName && Cookies.get(cookieName) !== 'true';

    const [show, setShow] = useState(hasRemoveIcon ? needShow : true);

    if (!show) {
        return null;
    }

    return (
        <ImportantNotifications>
            <div className={styles.siteExceptionsContent}>
                <Text>{children}</Text>
                {hasRemoveIcon && (
                    <IconLink
                        Element="button"
                        type="button"
                        onClick={() => {
                            Cookies.set('ignoreException', 'true', HIDE_PERIOD_IN_MINUTES / 60);
                            setShow(false);
                        }}
                    >
                        <BlokoIconReplaceContainer>
                            <CrossOutlinedSize16 initial="contrast" />
                        </BlokoIconReplaceContainer>
                    </IconLink>
                )}
            </div>
        </ImportantNotifications>
    );
};

export default SiteException;
