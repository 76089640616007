import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { format } from 'bloko/common/trl';

import translation from 'src/components/translation';
import { ShortVacancy } from 'src/models/applicantVacancyResponseStatuses';
import NegotiationTopic from 'src/models/negotiationTopic.types';
import { ResumeAccessType } from 'src/models/resume/resumeAccess.types';

const TrlKeys = {
    noOne: 'vacancy.responded.visibility.noOne',
    addToWhiteList: 'vacancy.responded.visibility.noOne.addToWhiteList',
    info: 'vacancy.response.letter.informer.info',
    infoAboutLetter: 'vacancy.response.letter.informer.aboutLetter',
    infoAttached: 'vacancy.response.letter.attached.info',
};

interface DescriptionProps {
    responseStatus: ShortVacancy;
    lastTopic: NegotiationTopic;
    hasResponseLetter?: boolean;
    defaultView?: string;
    isMagritteExp?: boolean;
}

const Description: TranslatedComponent<DescriptionProps> = ({
    trls,
    responseStatus,
    lastTopic,
    hasResponseLetter,
    defaultView,
    isMagritteExp,
}) => {
    const resumeVisibility = responseStatus?.resumeVisibility?.[lastTopic.resumeId];
    const infoTrl = isMagritteExp ? trls[TrlKeys.infoAboutLetter] : trls[TrlKeys.info];
    const defaultHint = defaultView && hasResponseLetter ? trls[TrlKeys.infoAttached] : infoTrl;

    if (!resumeVisibility) {
        return <>{defaultHint}</>;
    }

    const { accessType, whitelist } = resumeVisibility;

    if (accessType === ResumeAccessType.NoOne && !(!whitelist.containsEmployer && whitelist.canAdd < 0)) {
        return (
            <>
                {format(trls[TrlKeys.noOne], {
                    '{0}': responseStatus.shortVacancy.company.visibleName,
                })}
            </>
        );
    }

    if (accessType === ResumeAccessType.WhiteList && !whitelist.containsEmployer && whitelist.canAdd > 0) {
        return (
            <>
                {format(trls[TrlKeys.addToWhiteList], {
                    '{0}': responseStatus.shortVacancy.company.visibleName,
                })}
            </>
        );
    }

    return <>{defaultHint}</>;
};

export default translation(Description);
