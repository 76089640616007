import type { HhcaptchaType, Otp, RecaptchaType } from '@hh.ru/hhcaptcha';

import type { AccountType } from 'src/models/accountLogin';
import type { Phone } from 'src/models/resume/resumeCommon.types';

export enum OtpOperationType {
    OtpGenerate = 'OTP_GENERATE',
    OtpAuth = 'otp_auth',
    ApplicantOtpAuth = 'applicant_otp_auth',
    EmployerOtpAuth = 'employer_otp_auth',
    EmployerFirstAuthOtp = 'employer_first_auth_otp',
    MultiaccountGroupingEmailVerification = 'multiaccount_grouping_email_verification',
}

export type OtpType = 'email' | 'phone';

export type ExtendedOtp = Otp & {
    phoneFormatted?: Phone;
    phone?: string;
};

export interface OtpResponse {
    codeLength: number;
    recaptcha?: RecaptchaType;
    hhcaptcha?: HhcaptchaType;
    otp: ExtendedOtp;
    success: boolean;
    key: string;
    redirectURL?: string;
    nextConfirmTime: { '@timestamp'?: number; timestamp: number };
    accountType?: AccountType;
    isMultiAccount?: boolean;
}

export interface OtpRequest {
    queryParams: void;
    body: {
        login?: string;
        otpType: string;
        operationType?: string;
        'g-recaptcha-response'?: string;
        captchaText?: string;
        captchaKey?: string;
        captchaState?: string;
        formatPhone?: string;
    };
    response: OtpResponse;
}

declare global {
    interface FetcherPostApi {
        '/shards/otp_generate': OtpRequest;
        '/account/otp_generate': OtpRequest;
    }
}

export interface OtpFormDataError {
    response: {
        data?: {
            key?: string;
        };
    };
}
