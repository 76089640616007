import React, { MouseEventHandler } from 'react';
import { useDispatch } from 'react-redux';
import classnames from 'classnames';

import { makeSetStoreField } from '@hh.ru/redux-create-reducer';
import DropBase from 'bloko/blocks/drop/Base';
import { BASE_CLASS_NAMES, PADDING_WRAPPER_CLASS_NAME } from 'bloko/blocks/drop/Menu/common';
import { KeyCode } from 'bloko/common/constants/keyboard';
import LayerCssClass from 'bloko/common/constants/layersCssClasses';
import { Breakpoint, getBreakpoint } from 'bloko/common/media';
import metrics, { Placement } from 'bloko/common/metrics';
import requestAnimation from 'bloko/common/requestAnimation';

import { useSelector } from 'src/hooks/useSelector';

import SupernovaDropdownContext from 'src/components/SupernovaMainMenu/SupernovaDropdownContext';

interface SupernovaDropdownProps {
    activator: React.ReactElement;
    anchorFullHeight?: boolean;
    arrowBreakpoints?: Breakpoint[];
    fullWidthBreakpoints?: Breakpoint[];
    onClose?: VoidFunction;
    placement?: Placement;
    render: (close: VoidFunction) => React.ReactNode;
    onShown?: () => void;
    activatorClick?: React.MouseEventHandler | (() => void);
}

const BEHAVIOR = {
    alignToActivatorBorders: true,
    arrowSize: 0,
    fullScreenOnXS: false,
    placementOffset: 0,
    setupFullWidthOnXS: false,
    showArrow: false,
};

const mainContentVisibleAction = makeSetStoreField('mainContentVisible');

const SupernovaDropdown: React.VFC<SupernovaDropdownProps> = ({
    activator,
    anchorFullHeight,
    arrowBreakpoints = [Breakpoint.XS],
    fullWidthBreakpoints = [Breakpoint.XS],
    onClose,
    placement = Placement.BottomStart,
    render,
    onShown,
    activatorClick,
}) => {
    // удалить логику блокирования, когда будет переписано на магритт
    const blockCloseByDropClose = useSelector((state) => state.blockCloseMobileDrop);
    const activatorRef = React.useRef<HTMLElement>(null);
    const dropActivatorRef = React.useRef(null);
    const arrowRef = React.useRef<HTMLDivElement>(null);
    const breakpointRef = React.useRef<Breakpoint>();
    const contentRef = React.useRef<HTMLDivElement>(null);
    const dropdownOptionsRef = React.useRef<Record<string, HTMLElement>>({});
    const dropdownOptionsSort = React.useRef<(arr: string[]) => string[]>(null);
    const dropdownVisibleRef = React.useRef(false);

    const [arrowVisible, setArrowVisible] = React.useState(false);
    const [dropdownVisible, setDropdownVisible] = React.useState(false);

    const dispatch = useDispatch();

    const fullWidthOnS = React.useMemo(() => fullWidthBreakpoints.includes(Breakpoint.S), [fullWidthBreakpoints]);
    const fullWidthOnM = React.useMemo(() => fullWidthBreakpoints.includes(Breakpoint.M), [fullWidthBreakpoints]);

    const setDropdownVisibleCallback = React.useCallback(
        (value: boolean) => {
            if (value && !dropdownVisibleRef.current) {
                onShown?.();
                // TODO eventOnShow
                if (breakpointRef.current === Breakpoint.XS) {
                    dispatch(mainContentVisibleAction(false));
                }
            }
            if (!value && dropdownVisibleRef.current) {
                dispatch(mainContentVisibleAction(true));
                onClose && onClose();
            }
            dropdownVisibleRef.current = value;
            setDropdownVisible(value);
        },
        [dispatch, onClose, onShown]
    );

    const close = React.useCallback(() => setDropdownVisibleCallback(false), [setDropdownVisibleCallback]);

    const handleClick = React.useCallback<MouseEventHandler>(
        (event) => {
            activatorClick && activatorClick(event);
            setDropdownVisibleCallback(!dropdownVisible);
        },
        [activatorClick, dropdownVisible, setDropdownVisibleCallback]
    );

    const handleCloseByClickOutside = React.useCallback(() => {
        if (blockCloseByDropClose) {
            return;
        }
        setDropdownVisibleCallback(false);
    }, [blockCloseByDropClose, setDropdownVisibleCallback]);

    const handleActivatorKeydown = React.useCallback(
        (event: React.KeyboardEvent) => {
            if (!dropdownVisible) {
                return;
            }

            if (event.keyCode === KeyCode.Tab && !event.shiftKey) {
                event.preventDefault();
                let dropdownOptionsKeys = Object.keys(dropdownOptionsRef.current).filter(
                    (key) => !!dropdownOptionsRef.current[key]
                );
                dropdownOptionsKeys = dropdownOptionsSort.current
                    ? dropdownOptionsSort.current?.(dropdownOptionsKeys)
                    : dropdownOptionsKeys;

                if (dropdownOptionsKeys.length > 0) {
                    let index = 0;
                    while (dropdownOptionsRef.current[dropdownOptionsKeys[index]].offsetWidth === 0) {
                        index += 1;
                    }
                    dropdownOptionsRef.current[dropdownOptionsKeys[index]].focus();
                    dropdownOptionsRef.current[dropdownOptionsKeys[index]].classList.add('focus-visible');
                }
            }

            if (event.keyCode === KeyCode.ESC) {
                event.preventDefault();
                setDropdownVisibleCallback(false);
            }
        },
        [dropdownVisible, setDropdownVisibleCallback]
    );

    const handleContentKeydown = React.useCallback(
        (event: React.KeyboardEvent) => {
            if (!dropdownVisible) {
                return;
            }

            if (event.keyCode === KeyCode.Tab) {
                let dropdownOptionsKeys = Object.keys(dropdownOptionsRef.current).filter(
                    (key) => !!dropdownOptionsRef.current[key]
                );
                dropdownOptionsKeys = dropdownOptionsSort.current
                    ? dropdownOptionsSort.current?.(dropdownOptionsKeys)
                    : dropdownOptionsKeys;

                let index = dropdownOptionsKeys.findIndex((key) => dropdownOptionsRef.current[key] === event.target);
                if (index !== -1) {
                    event.preventDefault();
                    do {
                        if (!event.shiftKey) {
                            index += 1;
                            if (index === dropdownOptionsKeys.length) {
                                index = 0;
                            }
                        } else {
                            index -= 1;
                            if (index === -1) {
                                index = dropdownOptionsKeys.length - 1;
                            }
                        }
                    } while (dropdownOptionsRef.current[dropdownOptionsKeys[index]].offsetWidth === 0);
                    dropdownOptionsRef.current[dropdownOptionsKeys[index]].focus();
                }
            }

            if (event.keyCode === KeyCode.ESC) {
                event.preventDefault();
                activatorRef.current?.focus();
                setDropdownVisibleCallback(false);
            }
        },
        [dropdownVisible, setDropdownVisibleCallback]
    );

    const setArrowPosition = React.useMemo(
        () =>
            requestAnimation(() => {
                if (activatorRef.current === null || arrowRef.current === null || contentRef.current === null) {
                    return;
                }

                if (arrowBreakpoints.includes(breakpointRef.current)) {
                    const arrowAnchorMetrics = metrics.getMetrics(activatorRef.current);
                    const contentMetrics = metrics.getMetrics(contentRef.current);
                    if (contentMetrics.left < 0) {
                        setArrowPosition();
                        return;
                    }
                    arrowRef.current.style.left = `${
                        arrowAnchorMetrics.left + arrowAnchorMetrics.width / 2 - contentMetrics.left
                    }px`;
                    setArrowVisible(true);
                } else {
                    setArrowVisible(false);
                }
            }),
        [arrowBreakpoints]
    );

    React.useLayoutEffect(() => {
        if (breakpointRef.current === undefined) {
            breakpointRef.current = getBreakpoint();
        }

        setArrowPosition();

        const handleResize = () => {
            const nextBreakpoint = getBreakpoint();
            if (nextBreakpoint !== breakpointRef.current) {
                setDropdownVisibleCallback(false);
            }
            breakpointRef.current = nextBreakpoint;
            setArrowPosition();
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [setArrowPosition, setDropdownVisibleCallback]);

    const renderCallback = React.useCallback(() => {
        dropdownOptionsRef.current = {};
        return (
            <SupernovaDropdownContext.Provider
                value={{ sort: dropdownOptionsSort, elements: dropdownOptionsRef.current }}
            >
                <div
                    className="supernova-dropdown-content"
                    data-qa="supernova-dropdown-content"
                    onKeyDown={handleContentKeydown}
                    ref={contentRef}
                >
                    <div
                        className={classnames('supernova-dropdown-arrow', { 'g-hidden': !arrowVisible })}
                        ref={arrowRef}
                    />
                    <div
                        className={classnames('supernova-dropdown', {
                            'supernova-dropdown_full-width': fullWidthOnS,
                            'supernova-dropdown_full-width-m': fullWidthOnM,
                        })}
                    >
                        {fullWidthOnS || fullWidthOnM ? (
                            <div className="supernova-dropdown-full-width-wrapper">{render(close)}</div>
                        ) : (
                            render(close)
                        )}
                    </div>
                </div>
            </SupernovaDropdownContext.Provider>
        );
    }, [arrowVisible, close, fullWidthOnS, fullWidthOnM, handleContentKeydown, render]);

    const initialActive =
        typeof (activator.props as { active?: unknown }).active === 'boolean'
            ? (activator.props as { active?: unknown }).active
            : undefined;

    return (
        <DropBase
            baseClassNames={BASE_CLASS_NAMES}
            paddingWrapperClassName={PADDING_WRAPPER_CLASS_NAME}
            behavior={BEHAVIOR}
            closeByClickOutside
            flexible
            layer={LayerCssClass.Overlay}
            onClose={handleCloseByClickOutside}
            onlySetPlacement
            placement={placement}
            render={renderCallback}
            show={dropdownVisible}
            activatorRef={dropActivatorRef}
        >
            <div
                className={classnames('supernova-dropdown-anchor', {
                    'supernova-dropdown-anchor_full-height': anchorFullHeight,
                })}
                ref={dropActivatorRef}
            >
                {React.cloneElement(activator, {
                    onClick: handleClick,
                    onKeyDown: handleActivatorKeydown,
                    ref: activatorRef,
                    active: dropdownVisible || initialActive,
                })}
            </div>
        </DropBase>
    );
};

export default SupernovaDropdown;
