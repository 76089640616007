import { FC } from 'react';

import FilterContent, { FilterContentProps } from 'src/components/Search/Common/Filters/FilterContent';
import FilterWrapper from 'src/components/Search/Common/Filters/FilterWrapper';
import { useHasClusterGroups } from 'src/components/Search/Common/Filters/hooks/useHasClusterGroups';

type FilterProps = FilterContentProps & {
    title: string;
};

const Filter: FC<FilterProps> = ({ title, name, ...props }) => {
    const hasCluster = useHasClusterGroups(name);
    if (!hasCluster) {
        return null;
    }

    return (
        <FilterWrapper filterKey={name} title={title}>
            <FilterContent {...props} name={name} />
        </FilterWrapper>
    );
};

export default Filter;
