import React, { Fragment } from 'react';
import { HeadProvider, Title, Meta, Link } from 'react-head';

const AppHeadProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
    return !process.env.SSR ? <HeadProvider>{children}</HeadProvider> : <>{children}</>;
};

// Чисто семантический компонент, чтобы сгруппировать/выделить элементы для Head
const PageHead: React.FC<React.PropsWithChildren> = ({ children }) => {
    return <>{children}</>;
};

interface PageDescriptionProps {
    content: string;
    openGraphContent?: string;
}

const PageDescription: React.FC<PageDescriptionProps & React.PropsWithChildren> = ({ content, openGraphContent }) => {
    return (
        <Fragment>
            <Meta name="description" content={content} />
            <Meta property="og:description" content={openGraphContent || content} />
        </Fragment>
    );
};

export interface HreflangLinkItem {
    href: string;
    hreflang: string;
}

interface PageHreflangLinksProps {
    content: HreflangLinkItem[];
}

const PageHreflangLinks: React.FC<PageHreflangLinksProps> = ({ content }) => {
    return (
        <>
            {content.map((link) => (
                <Link key={link.href + link.hreflang} rel="alternate" href={link.href} hrefLang={link.hreflang} />
            ))}
        </>
    );
};

export {
    AppHeadProvider,
    PageHead,
    Title as PageTitle,
    Meta as PageMeta,
    PageDescription,
    Link as PageLink,
    PageHreflangLinks,
};
