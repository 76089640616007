import { FC } from 'react';

import { Text } from '@hh.ru/magritte-ui';

import { BaseEntityWithTrl, SavedSearch } from 'src/models/employer/employerLastSearches';

import SearchCriterion from 'src/components/IndexPageEmployer/components/LastSearches/SearchCriterion';

interface SearchDetailsProps {
    searchCriteria: SavedSearch['searchCriteria'];
}

const mapEntitiesToCriteries = (entities?: BaseEntityWithTrl[]) => {
    if (!entities) {
        return null;
    }

    return entities.map(({ id, text }) => <SearchCriterion key={id}>{text}</SearchCriterion>);
};

const SearchDetails: FC<SearchDetailsProps> = ({ searchCriteria = {} }) => {
    return (
        <Text typography="paragraph-3-regular" style="secondary" data-qa="last-searches-details">
            {mapEntitiesToCriteries(searchCriteria.area)}
            {mapEntitiesToCriteries(searchCriteria.employment)}
            {mapEntitiesToCriteries(searchCriteria.schedule)}
        </Text>
    );
};

export default SearchDetails;
