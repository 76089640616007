import { useEffect, FC } from 'react';

import NotificationManager, {
    NotificationManagerProps,
    NotificationManagerConsumer,
} from 'bloko/blocks/notificationManager';
import Metrics from 'bloko/common/metrics';
import throttle from 'bloko/common/throttle';

import sendNotificationManagerAnalytics from 'HH/SendNotificationManagerAnalytics';
import { ACTION_SMART_BANNER_LOADED } from 'src/components/AppSmartBanner/utils';
import CacheTranslationsContext from 'src/components/translation/CacheTranslationsContext';

const RESIZE_THROTTLE_MS = 100;

const SupernovaNotificationManager: FC = (props: NotificationManagerProps) => {
    useEffect(() => {
        const notificationManager = document.querySelector<HTMLDivElement>('.Bloko-Notification-Manager')!;
        if (!notificationManager) {
            return undefined;
        }
        const containers = [...document.querySelectorAll<HTMLElement>('.HH-Supernova-NotificationManager-Container')];
        const getVisibleContainer = () => containers.find((container) => container.offsetWidth)!;
        let container = getVisibleContainer();

        sendNotificationManagerAnalytics(notificationManager);
        let bottom: number;
        if (container) {
            bottom = Metrics.getMetrics(container).bottom;
        }

        const setPosition = () => {
            const scroll = Math.max(bottom - window.pageYOffset, 0);
            notificationManager.style.top = `${scroll}px`;
        };

        window.addEventListener('scroll', setPosition);
        setPosition();

        const updateBottomPosition = () => {
            container = getVisibleContainer();
            bottom = Metrics.getMetrics(container).bottom;
            setPosition();
        };
        const throttledUpdateBottomPosition = throttle(updateBottomPosition, RESIZE_THROTTLE_MS);

        window.addEventListener('resize', throttledUpdateBottomPosition);
        window.addEventListener(ACTION_SMART_BANNER_LOADED, updateBottomPosition);

        return function cleanup() {
            window.removeEventListener('resize', throttledUpdateBottomPosition);
            window.removeEventListener(ACTION_SMART_BANNER_LOADED, updateBottomPosition);
        };
    }, []);

    const { children, ...managerProps } = props;

    return (
        <NotificationManager {...managerProps} withConsumer={false}>
            <CacheTranslationsContext.Provider value={true}>
                <NotificationManagerConsumer />
            </CacheTranslationsContext.Provider>
            {children}
        </NotificationManager>
    );
};

export default SupernovaNotificationManager;
