import { useCallback, useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import type { AxiosError } from 'axios';
import { CONFLICT } from 'http-status-codes';

import createButtonClick from '@hh.ru/analytics-js-events/build/xhh/common/vacancy_search/saved_search/vacancy_saved_search_create_button_click';
import createdButtonClick from '@hh.ru/analytics-js-events/build/xhh/common/vacancy_search/saved_search/vacancy_saved_search_created_button_click';
import emailButtonClick from '@hh.ru/analytics-js-events/build/xhh/common/vacancy_search/saved_search/vacancy_saved_search_email_button_click';
import emailSubscribeButtonClick from '@hh.ru/analytics-js-events/build/xhh/common/vacancy_search/saved_search/vacancy_saved_search_email_subscribe_button_click';
import messengersButtonClick from '@hh.ru/analytics-js-events/build/xhh/common/vacancy_search/saved_search/vacancy_saved_search_messengers_button_click';
import switchMessengersButtonClick from '@hh.ru/analytics-js-events/build/xhh/common/vacancy_search/saved_search/vacancy_saved_search_switch_messengers_button_click';
import { VSpacing, Tooltip, Button } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import urlParser from 'bloko/common/urlParser';

import paths from 'src/app/routePaths';
import { useNotification } from 'src/components/Notifications/Provider';
import translation from 'src/components/translation';
import useOnOffState from 'src/hooks/useOnOffState';
import { useSelector } from 'src/hooks/useSelector';
import { SupernovaSearchName } from 'src/models/supernovaSearchName';
import { UserType } from 'src/models/userType';
import { saveAutoSearch } from 'src/models/vacancySearch/vacancySearchResult';
import fetcher from 'src/utils/fetcher';

import AutosearchModal from 'src/components/SupernovaSearch/VacancySavedSearchButton/AutosearchModal';
import Notification from 'src/components/SupernovaSearch/VacancySavedSearchButton/Notification';
import SavedSearchButton from 'src/components/SupernovaSearch/VacancySavedSearchButton/SavedSearchButton';
import SavedSearchInfo, {
    hideSavedSearchInfo,
} from 'src/components/SupernovaSearch/VacancySavedSearchButton/SavedSearchInfo';
import {
    FormState,
    FormStateType,
    SaveSearchError,
    SaveSearchErrorType,
} from 'src/components/SupernovaSearch/VacancySavedSearchButton/types';

import styles from './styles.less';

const TrlKeys = {
    title: 'search.vacancy.savedSearch.form.title',
    buttonEmail: 'search.vacancy.savedSearch.form.button.email',
    buttonMessengers: 'search.vacancy.savedSearch.form.button.messengers',
    tooltipOnlyEmailTitle: 'search.vacancy.savedSearch.tooltip.onlyEmail.title',
    tooltipOnlyEmailButtonText: 'search.vacancy.savedSearch.tooltip.onlyEmail.button',
};

const ANALYTICS_PLACE = 'supernova_search';

const URL_SAVE = '/vacancysavedsearch/save';
const URL_POSTPONE = '/shards/vacancy_saved_search/postpone';

interface VacancySavedSearchButtonProps {
    searchName: SupernovaSearchName;
    isMobile?: boolean;
}

const VacancySavedSearchButton: TranslatedComponent<VacancySavedSearchButtonProps> = ({
    trls,
    searchName,
    isMobile,
}) => {
    const isAnonymous = useSelector(({ userType }) => userType === UserType.Anonymous);
    const { addNotification } = useNotification();
    const isVacanciesMap = useSelector(({ router }) => router.location.pathname === paths.vacancySearchMap);
    const existChatBot = useSelector(({ chatBot }) => !!chatBot);
    const autosearch = useSelector(({ vacancySearchResult }) => vacancySearchResult.queries?.autosearch);
    const { pathname, search } = useSelector((state) => state.router.location);
    const { isSavedSearch, email, isFormOpen, isShowButton } = useSelector(
        ({ vacancySearchResult }) => vacancySearchResult.savedSearches
    );

    const dispatch = useDispatch();

    const [emailInputValue, setEmailInputValue] = useState('');
    const [emailError, setEmailError] = useState<SaveSearchErrorType | null>(null);

    const [isLoading, setLoading, resetLoading] = useOnOffState(false);
    const [isModalEmailFormVisible, openModalEmailForm, hideModalEmailForm] = useOnOffState(false);
    const [isModalMessengersVisible, openModalMessengers, hideModalMessengers] = useOnOffState(false);

    const existEmail = !!email;
    const emailValue = email || emailInputValue;
    const saveWithoutForm = existEmail && !existChatBot;

    const [formState, setFormState] = useState<FormStateType | null>(
        isFormOpen && !saveWithoutForm ? FormState.Saving : null
    );

    const activatorRef = useRef(null);

    const closeForm = () => {
        setFormState(null);
    };

    const hideModals = useCallback(() => {
        hideModalEmailForm();
        hideModalMessengers();
    }, [hideModalEmailForm, hideModalMessengers]);

    const saveSearch = useCallback(() => {
        setLoading();
        const parsedURL = urlParser(`?${autosearch || ''}`);
        const params = { ...parsedURL.params };
        if (isVacanciesMap) {
            delete params.geocode_type;
            delete params.precision;
            delete params.ored_clusters;
        }
        fetcher
            .post(URL_SAVE, { email: emailValue }, { params })
            .then(() => {
                if (isMobile) {
                    setFormState(FormState.Saved);
                } else {
                    addNotification(Notification, {
                        props: { email: emailValue },
                    });
                    hideModals();
                    closeForm();
                }

                dispatch(saveAutoSearch(emailValue));
                resetLoading();
            })
            .catch((error: AxiosError<{ state: string }>) => {
                if (error.response?.data?.state === SaveSearchError.WrongEmail) {
                    setEmailError(SaveSearchError.WrongEmail);
                } else if (error.response?.status === CONFLICT) {
                    setEmailError(SaveSearchError.DuplicateEmail);
                } else if (isMobile) {
                    setFormState(FormState.Error);
                } else {
                    addNotification(Notification, {
                        props: { email: emailValue, isError: true },
                    });
                }

                resetLoading();
            });
    }, [
        setLoading,
        autosearch,
        isVacanciesMap,
        emailValue,
        isMobile,
        dispatch,
        resetLoading,
        addNotification,
        hideModals,
    ]);

    useEffect(() => {
        if (isFormOpen && saveWithoutForm) {
            saveSearch();
        }
    }, [isFormOpen, saveSearch, saveWithoutForm]);

    const onSaveClick = useCallback(() => {
        createButtonClick({
            existEmail,
            existChatBot,
        });
        if (isAnonymous) {
            const parsedLoginUrl = urlParser('/account/login');
            parsedLoginUrl.params.postponed = true;
            void fetcher.post(URL_POSTPONE, { backUrl: `${pathname}${search}` }).then(() => {
                window.location.assign(parsedLoginUrl.href);
            });
        } else {
            hideSavedSearchInfo();
            if (saveWithoutForm) {
                saveSearch();
            } else {
                setFormState(FormState.Saving);
            }
        }
    }, [existChatBot, existEmail, isAnonymous, pathname, saveSearch, saveWithoutForm, search]);

    const onAlreadySavedClick = useCallback(() => {
        createdButtonClick();
        if (isMobile) {
            setFormState(FormState.AlreadySaved);
        } else {
            addNotification(Notification, {
                props: { email: emailValue, alreadySaved: true },
            });
        }
    }, [addNotification, emailValue, isMobile]);

    const onClickEmailSubscribe = useCallback(() => {
        if (existEmail) {
            emailButtonClick({
                existEmail: true,
                existChatBot,
                hhtmFromLabel: ANALYTICS_PLACE,
            });
        } else {
            emailSubscribeButtonClick({
                existChatBot,
                hhtmFromLabel: ANALYTICS_PLACE,
            });
        }
        saveSearch();
    }, [existChatBot, existEmail, saveSearch]);

    const onClickMessengers = useCallback(() => {
        if (existEmail) {
            messengersButtonClick({
                existEmail: true,
                hhtmFromLabel: ANALYTICS_PLACE,
            });
        } else {
            switchMessengersButtonClick({
                hhtmFromLabel: ANALYTICS_PLACE,
            });
        }
        openModalMessengers();
        closeForm();
    }, [existEmail, openModalMessengers]);

    const onEmailButtonClick = useCallback(() => {
        if (existEmail) {
            onClickEmailSubscribe();
        } else {
            openModalEmailForm();
            closeForm();
        }
    }, [existEmail, onClickEmailSubscribe, openModalEmailForm]);

    const onEmailInputChange = (value: string) => {
        setEmailInputValue(value);
        if (emailError) {
            setEmailError(null);
        }
    };

    if (!isShowButton || searchName !== SupernovaSearchName.Vacancies) {
        return null;
    }

    const showDrop = !isMobile && !!formState;
    const showBottomSheet = formState === FormState.Saving;
    const emailIsTheOnlyOption = !existChatBot;
    const tooltipTitle = trls[TrlKeys[emailIsTheOnlyOption ? 'tooltipOnlyEmailTitle' : 'title']];
    const tooltipEmailButtonText = trls[TrlKeys[emailIsTheOnlyOption ? 'tooltipOnlyEmailButtonText' : 'buttonEmail']];

    return (
        <>
            {isSavedSearch ? (
                <SavedSearchButton shortView={isMobile} onClick={onAlreadySavedClick} isAlreadySaved />
            ) : (
                <SavedSearchInfo forceClose={showDrop}>
                    <>
                        <Tooltip
                            placement="bottom-right"
                            visible={showDrop}
                            onClose={closeForm}
                            activatorRef={activatorRef}
                        >
                            {tooltipTitle}
                            <VSpacing default={16} />
                            <div className={styles.buttonsHorizontalWrapper}>
                                <Button
                                    mode="secondary"
                                    style="contrast"
                                    size="small"
                                    loading={isLoading}
                                    onClick={onEmailButtonClick}
                                    data-qa="vacancy-saved-search-email"
                                >
                                    {tooltipEmailButtonText}
                                </Button>
                                {!emailIsTheOnlyOption && (
                                    <Button
                                        mode="secondary"
                                        style="contrast"
                                        size="small"
                                        onClick={onClickMessengers}
                                        data-qa="vacancy-saved-search-messengers"
                                    >
                                        {trls[TrlKeys.buttonMessengers]}
                                    </Button>
                                )}
                            </div>
                        </Tooltip>
                        <div ref={activatorRef}>
                            <SavedSearchButton shortView={isMobile} onClick={onSaveClick} />
                        </div>
                    </>
                </SavedSearchInfo>
            )}
            <AutosearchModal
                isDropVisible={showBottomSheet}
                onDropClose={closeForm}
                isModalEmailFormVisible={isModalEmailFormVisible}
                isModalMessengersVisible={isModalMessengersVisible}
                hideModal={hideModals}
                existChatBot={existChatBot}
                userEmail={email}
                emailInputValue={emailInputValue}
                onEmailInputChange={onEmailInputChange}
                onClickEmailSubscribe={onClickEmailSubscribe}
                emailError={emailError}
                formState={formState}
                isLoading={isLoading}
            />
        </>
    );
};

export default translation(VacancySavedSearchButton);
