import { TemplateData } from '@hh.ru/branding-packages';
import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';
import {
    EmploymentForm,
    FlyInFlyOutDuration,
    WorkFormat,
    WorkingHours,
    WorkScheduleByDays,
} from '@hh.ru/types-hh-microcore';

import { VacancyConstructorTemplate } from 'src/components/VacancyView/brandingConstants';
import { ScheduleCamelCase } from 'src/utils/constants/schedule';

import { EmployerState } from 'src/models/employerStatus';
import { BillingPlatform } from 'src/models/price/product.types';
import { ExperienceOption } from 'src/models/search/searchCriteria.types';
import { VacancyCompensation } from 'src/models/vacancy/compensation.type';
import { VacancyPermission } from 'src/models/vacancy/permissions.types';
import { VacancyProperties } from 'src/models/vacancyProperties';
import {
    Badge,
    ContactInfo,
    EmploymentType,
    PartTimeOption,
    VacancyAddress,
    VacancyViewInsider,
    VacancyViewStatus,
    HrBrand,
    SystemInfo,
} from 'src/models/vacancyView.types';

const VACANCY_VIEW = 'VACANCY_VIEW';
const VACANCY_VIEW_SET_VISIBILITY_CONTACTS = 'VACANCY_VIEW_SET_VISIBILITY_CONTACTS';
const VACANCY_VIEW_SET_BRANDING_CONSTRUCTOR_EXAMPLE = 'VACANCY_VIEW_SET_BRANDING_CONSTRUCTOR_EXAMPLE';
const VACANCY_VIEW_REMOVE_BRANDING_CONSTRUCTOR_EXAMPLE = 'VACANCY_VIEW_REMOVE_BRANDING_CONSTRUCTOR_EXAMPLE';
const VACANCY_VIEW_UPDATE_MANAGERS = 'VACANCY_VIEW_UPDATE_MANAGERS';
const VACANCY_VIEW_ADD_HIRING_MANAGERS = 'VACANCY_VIEW_ADD_HIRING_MANAGERS';
const VACANCY_VIEW_DELETE_HIRING_MANAGER = 'VACANCY_VIEW_DELETE_HIRING_MANAGER';
const VACANCY_VIEW_UPDATE_HIRING_MANAGER_PERMISSIONS = 'VACANCY_VIEW_UPDATE_HIRING_MANAGER_PERMISSIONS';

const INITIAL_STATE = {
    platforms: [],
    vacancyOnMapLink: '',
    company: {
        visibleName: '',
        employerOrganizationFormId: null,
        '@trusted': false,
        '@state': null,
        accreditedITEmployer: false,
    },
    driverLicenseTypes: {},
    status: {},
    features: [],
    insider: null,
    confirmableKeySkills: { providers: [] },
    keySkills: null,
    contactInfo: null,
    employmentForm: EmploymentForm.Full,
    workFormats: [],
    workingHours: [],
    workScheduleByDays: [],
    flyInFlyOutDurations: [],
    nightShifts: false,
    internship: false,
    description: '',
    publicationDate: null,
    professionalRoleIds: [],
    validThroughTime: null,
    area: null,
    name: '',
    compensation: {},
    translations: null,
    parttimeOptions: {},
    '@acceptHandicapped': false,
    '@acceptKids': false,
    vacancyProperties: {
        properties: [],
    },
    userTestId: null,
    closedForApplicants: false,
    vacancyManagers: null,
};

export enum BrandingType {
    Constructor = 'CONSTRUCTOR',
    Chameleon = 'MAKEUP',
}

export interface ShortManagerData {
    id: string;
    fullName: string;
}

export interface ShortHiringManagerData extends ShortManagerData {
    withMcpPermission: boolean;
    permissions: VacancyPermission[];
}

export interface VacancyManagers {
    owner: ShortManagerData;
    hiringManagers: ShortHiringManagerData[];
    recruiterManagers: ShortManagerData[];
    currentManagerPermissions: {
        canManipulateHiringManagers: boolean;
    };
}

interface VacancyViewState {
    approved?: boolean;
    employerId?: number;
    acceptTemporary?: boolean;
    platforms: BillingPlatform[];
    multi?: boolean;
    address?: VacancyAddress;
    mapDisabled?: boolean;
    vacancyOnMapLink: string;
    showResumeForm?: boolean;
    responseInfo?: {
        doResponse?: boolean;
        isQuestion?: boolean;
        showResponse?: boolean;
        respondedWithTopicId?: string;
    };
    showSignupForm?: boolean;
    canDisableCallTracking?: boolean;
    features: string[];
    insider: VacancyViewInsider | null;
    vacancyId?: number;
    company: {
        id?: number;
        name?: string;
        logos?: {
            '@showInSearch': boolean;
            logo: { '@type': string; '@url': string }[];
        };
        badges?: {
            badge: Badge[];
        };
        department?: {
            '@code': string;
        };
        employerOrganizationFormId: number | null;
        visibleName: string;
        '@trusted': boolean;
        '@state': EmployerState | null;
        accreditedITEmployer: boolean;
    };
    isVisibleContacts?: boolean;
    isBrandingPreview?: boolean;
    branding?: TemplateData;
    brandingType?: BrandingType | null;
    status: VacancyViewStatus;
    vacancyConstructorTemplate?: VacancyConstructorTemplate | null;
    description: string;
    driverLicenseTypes: { driverLicenseType?: string[] };
    showSkillsSurvey?: boolean;
    videoVacancyUrl?: string;
    videoVacancyImageUrl?: string;
    skillsSurveyProfession?: string | null;
    confirmableKeySkills: {
        providers: {
            promoted: boolean;
            name: string;
        }[];
    };
    keySkills: {
        keySkill: string[];
    } | null;
    contactInfo: ContactInfo | null;
    publicationDate: string | null;
    professionalRoleIds: number[];
    validThroughTime: string | null;
    area: { areaCatalogTitle: string; name: string; areaNamePre: string } | null;
    name: string;
    compensation: VacancyCompensation;
    translations: {
        workExperience: string;
        employment: string;
    } | null;
    employment?: {
        ['@type']?: EmploymentType;
    };
    employmentForm: EmploymentForm;
    workFormats: WorkFormat[];
    workingHours: WorkingHours[];
    workScheduleByDays: WorkScheduleByDays[];
    flyInFlyOutDurations: FlyInFlyOutDuration[];
    nightShifts: boolean;
    internship: boolean;
    ['@workSchedule']?: ScheduleCamelCase;
    ['@vacancyCode']?: string;
    workExperience?: ExperienceOption;
    ['@acceptHandicapped']: boolean;
    ['@acceptKids']: boolean;
    ['hr-brand']?: HrBrand;
    parttimeOptions: Partial<Record<PartTimeOption, boolean>>;
    systemInfo?: Partial<SystemInfo>;
    hasQuestionInput?: boolean;
    // Перед использованием vacancyProperties убедитесь, что эндпоинт, который отдает вакансию умеет в проперти
    // Тем не менее поле помечено обязательным, т.к. цель до конца года повсеместный переход на проперти, а делается он на фронте и бэке сразу
    vacancyProperties: VacancyProperties;
    userTestId: number | null;
    brandSnippetExpirationTime?: number | null;
    vacancyManagers: VacancyManagers | null;
    closedForApplicants: boolean;
    canViewResponses?: boolean;
    managerId?: number;
}

interface PayloadTypes {
    [VACANCY_VIEW]: VacancyViewState;
    [VACANCY_VIEW_SET_VISIBILITY_CONTACTS]: boolean;
    [VACANCY_VIEW_SET_BRANDING_CONSTRUCTOR_EXAMPLE]: VacancyViewState['vacancyConstructorTemplate'];
    [VACANCY_VIEW_REMOVE_BRANDING_CONSTRUCTOR_EXAMPLE]: void;
    [VACANCY_VIEW_UPDATE_MANAGERS]: VacancyManagers | null;
    [VACANCY_VIEW_ADD_HIRING_MANAGERS]: ShortHiringManagerData[];
    [VACANCY_VIEW_DELETE_HIRING_MANAGER]: { managerId: string };
    [VACANCY_VIEW_UPDATE_HIRING_MANAGER_PERMISSIONS]: {
        managerId: string;
        permissions: ShortHiringManagerData['permissions'];
    };
}

const ActionCreator = ActionCreatorHelper<PayloadTypes>();

export const vacancyAction = ActionCreator(VACANCY_VIEW);

export const vacancySetVisibilityContactsAction = ActionCreator(VACANCY_VIEW_SET_VISIBILITY_CONTACTS);

export const vacancySetBrandingConstructorExample = ActionCreator(VACANCY_VIEW_SET_BRANDING_CONSTRUCTOR_EXAMPLE);
export const vacancyRemoveBrandingConstructorExample = ActionCreator(VACANCY_VIEW_REMOVE_BRANDING_CONSTRUCTOR_EXAMPLE);

export const vacancyUpdateManagersAction = ActionCreator(VACANCY_VIEW_UPDATE_MANAGERS);
export const vacancyAddHiringManagersAction = ActionCreator(VACANCY_VIEW_ADD_HIRING_MANAGERS);
export const vacancyDeleteHiringManagerAction = ActionCreator(VACANCY_VIEW_DELETE_HIRING_MANAGER);
export const vacancyUpdateHiringManagerPermissionsAction = ActionCreator(
    VACANCY_VIEW_UPDATE_HIRING_MANAGER_PERMISSIONS
);

export default createReducer<VacancyViewState, PayloadTypes>(INITIAL_STATE, {
    [VACANCY_VIEW]: (_, action) => action.payload,
    [VACANCY_VIEW_SET_VISIBILITY_CONTACTS]: (state, action) => ({
        ...state,
        isVisibleContacts: action.payload,
    }),
    [VACANCY_VIEW_SET_BRANDING_CONSTRUCTOR_EXAMPLE]: (state, { payload }) => ({
        ...state,
        brandingType: BrandingType.Constructor,
        vacancyConstructorTemplate: payload,
    }),
    [VACANCY_VIEW_REMOVE_BRANDING_CONSTRUCTOR_EXAMPLE]: (state) => ({
        ...state,
        brandingType: null,
        vacancyConstructorTemplate: null,
    }),
    [VACANCY_VIEW_UPDATE_MANAGERS]: (state, { payload }) => ({
        ...state,
        vacancyManagers: payload,
    }),
    [VACANCY_VIEW_ADD_HIRING_MANAGERS]: (state, { payload }) => {
        if (!state.vacancyManagers) {
            return state;
        }

        const { hiringManagers } = state.vacancyManagers;

        return {
            ...state,
            vacancyManagers: {
                ...state.vacancyManagers,
                hiringManagers: [...hiringManagers, ...payload],
            },
        };
    },
    [VACANCY_VIEW_DELETE_HIRING_MANAGER]: (state, { payload }) => {
        if (!state.vacancyManagers) {
            return state;
        }

        const { hiringManagers } = state.vacancyManagers;

        return {
            ...state,
            vacancyManagers: {
                ...state.vacancyManagers,
                hiringManagers: hiringManagers.filter(({ id }) => id !== payload.managerId),
            },
        };
    },
    [VACANCY_VIEW_UPDATE_HIRING_MANAGER_PERMISSIONS]: (state, { payload }) => {
        if (!state.vacancyManagers) {
            return state;
        }

        const { hiringManagers } = state.vacancyManagers;
        const managerIndex = hiringManagers.findIndex(({ id }) => id === payload.managerId);

        if (managerIndex === -1) {
            return state;
        }

        const manager = hiringManagers[managerIndex];

        return {
            ...state,
            vacancyManagers: {
                ...state.vacancyManagers,
                hiringManagers: Object.assign([], hiringManagers, {
                    [managerIndex]: { ...manager, permissions: payload.permissions },
                }),
            },
        };
    },
});
