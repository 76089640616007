import { ReactNode } from 'react';

import { Title, useBreakpoint, VSpacing as MagritteVSpacing, VSpacingContainer } from '@hh.ru/magritte-ui';
import { Link } from '@hh.ru/redux-spa-middleware';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import IndexCollapsibleBlock from 'src/components/IndexCollapsibleBlock';
import translation from 'src/components/translation';
import { useMagritte } from 'src/hooks/useMagritte';
import { useSelector, useSelectorNonNullable } from 'src/hooks/useSelector';
import {
    ProfessionalRoleCategory,
    ProfessionalRoleCategoryCounted,
} from 'src/models/professionalRoleCategoryCollection';

import Header from 'src/components/WorkInProfession/Header';
import ListItem from 'src/components/WorkInProfession/ListItem';

import styles from './work-in-profession.less';

export interface WorkInProfessionProps {
    customTitle?: string;
    disableTitleLink?: boolean;
    spacingBottom?: ReactNode;
}

type ProfessionalRoleCategoryOrCounted = ProfessionalRoleCategory | ProfessionalRoleCategoryCounted;

const TrlKeys = {
    bottomSheetTitle: 'index.anonymous.work.in.profession.bottomsheet.title',
};

const WorkInProfession: TranslatedComponent<WorkInProfessionProps> = ({
    trls,
    customTitle,
    disableTitleLink,
    spacingBottom,
}) => {
    const { title, titleNom, collection, collectionCounted } = useSelectorNonNullable(
        (state) => state.professionalRoleCategoryCollection
    );
    const isAnonymousMagritteExp = useSelector((state) => state.isAnonymousMagritteExp);
    const isMagritte = useMagritte() || isAnonymousMagritteExp;
    const { isMobile } = useBreakpoint();

    if (isMagritte) {
        const collectionOrCounted = collectionCounted.length ? collectionCounted : collection;
        const collectionToRender = isMobile ? collectionOrCounted : collection;
        const previewCount = isMobile ? 10 : Infinity;

        const renderItem = (category: ProfessionalRoleCategoryOrCounted, index: number) => {
            return <ListItem key={category.id} category={category} positionIndex={index} />;
        };
        const renderList = (collection: ProfessionalRoleCategoryOrCounted[]) => {
            return (
                <div className={styles.listWrapper} data-qa="index__work-in-profession-list">
                    <VSpacingContainer default={16}>{collection.map(renderItem)}</VSpacingContainer>
                </div>
            );
        };
        return (
            <>
                <Link to="/vacancies" isSeoLink>
                    <Title Element="h2" size="medium" data-qa="index__work-in-profession-header">
                        {title}
                    </Title>
                </Link>
                <MagritteVSpacing default={16} gteS={20} gteM={24} />
                <IndexCollapsibleBlock
                    renderPreview={() => renderList(collectionToRender.slice(0, previewCount))}
                    renderRest={() => renderList(collectionToRender.slice(previewCount))}
                    restSpacing={<MagritteVSpacing default={16} />}
                    needToExpand={collectionToRender.length > previewCount}
                    renderBottomSheetContent={() => (
                        <>
                            {renderList(collectionToRender)}
                            <MagritteVSpacing default={16} />
                        </>
                    )}
                    bottomSheetTitle={isAnonymousMagritteExp ? trls[TrlKeys.bottomSheetTitle] : title}
                    bottomSheetSubtitle={isAnonymousMagritteExp ? titleNom : undefined}
                />
                {spacingBottom}
            </>
        );
    }

    return (
        <div className={styles.wrapper}>
            <Header text={customTitle || title} disableTitleLink={disableTitleLink} />
            <VSpacing base={4} />
            <ul className="multiple-column-list multiple-column-list_narrow" data-qa="index__work-in-profession-list">
                {collection.map((category, index) => (
                    <ListItem key={category.id} category={category} positionIndex={index} />
                ))}
            </ul>
        </div>
    );
};

export default translation(WorkInProfession);
