import { useEffect } from 'react';

import Analytics from '@hh.ru/analytics-js';
import { TranslatedComponent } from '@hh.ru/front-static-app';
import { ActionBar, BottomSheet, Button, Modal, Title, VSpacing } from '@hh.ru/magritte-ui';
import Link from '@hh.ru/redux-spa-middleware/lib/Link';

import Notices from 'Modules/Notices';
import translation from 'src/components/translation';
import useOnOffState from 'src/hooks/useOnOffState';
import { useSelector } from 'src/hooks/useSelector';

import styles from './new-year-stats.less';

interface NewYearStatsModalProps {
    isShow: boolean;
}

const TrlKeys = {
    title: 'indexPage.employer.newYearStats.modal.title',
    description: 'indexPage.employer.newYearStats.modal.description',
    primaryAction: 'indexPage.employer.newYearStats.modal.primaryAction',
    secondaryAction: 'indexPage.employer.newYearStats.modal.secondaryAction',
};

const HEADER_IMAGE_PATH_DESKTOP = '/images/hh/employer/vacancies/employer-new-year-stats.jpg?v171224';
const HEADER_IMAGE_PATH_MOBILE = '/images/hh/employer/vacancies/employer-new-year-stats-mobile.jpg?v171224';

const NewYearStats: TranslatedComponent<NewYearStatsModalProps> = ({ trls, isShow }) => {
    const staticHost = useSelector(({ config }) => config.staticHost);
    const [visible, _, hide] = useOnOffState(isShow);

    useEffect(() => {
        if (visible) {
            Analytics.sendHHEvent('screen_shown', {
                screenName: 'employer_new_year_stats_modal',
            });
            Notices.markAsViewed('employer_new_year_stats_modal');
        }
    }, [visible]);

    const handleClose = () => {
        hide();
        Analytics.sendHHEventButtonClick('employer_new_year_stats_modal_close');
    };

    const actions = (
        <ActionBar
            primaryActions={
                <Button
                    mode="primary"
                    style="accent"
                    Element={Link}
                    to="/article/stats2024"
                    target="_blank"
                    onClick={() => {
                        hide();
                        Analytics.sendHHEventButtonClick('employer_new_year_stats_modal_link');
                    }}
                    additionalQueryParams={{ hhtmFrom: 'modalka_main' }}
                >
                    {trls[TrlKeys.primaryAction]}
                </Button>
            }
            secondaryActions={
                <Button mode="tertiary" style="accent" onClick={handleClose}>
                    {trls[TrlKeys.secondaryAction]}
                </Button>
            }
        />
    );

    return (
        <>
            <Modal
                onClose={handleClose}
                size="large"
                visible={visible}
                headerImage={`${staticHost}${HEADER_IMAGE_PATH_DESKTOP}`}
                headerHeight={267}
                footer={actions}
            >
                <Title size="large" description={trls[TrlKeys.description]} Element="h3">
                    {trls[TrlKeys.title]}
                </Title>
            </Modal>
            <BottomSheet visible={visible} onClose={handleClose} footer={actions}>
                <div className={styles.imageWrapper}>
                    <img
                        className={styles.image}
                        src={`${staticHost}${HEADER_IMAGE_PATH_MOBILE}`}
                        alt={trls[TrlKeys.title]}
                    />
                </div>
                <VSpacing default={16} />
                <Title size="large" description={trls[TrlKeys.description]} Element="h3">
                    {trls[TrlKeys.title]}
                </Title>
            </BottomSheet>
        </>
    );
};

export default translation(NewYearStats);
