import { useCallback } from 'react';
import classNames from 'classnames';

import createVacancyClick from '@hh.ru/analytics-js-events/build/xhh/employer/vacancies_widget/vacancies_widget_create_vacancy_button_click';
import { Button, Text, VSpacing } from '@hh.ru/magritte-ui';
import { Link as SPALink } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { useIsZarplataPlatform } from 'src/hooks/usePlatform';
import { useSelector } from 'src/hooks/useSelector';

import styles from './placeholders.less';

const TrlKeys = {
    title: 'employer.indexPage.widgets.myVacancies.placeholder.title',
    description: 'employer.indexPage.widgets.myVacancies.placeholder.description',
    button: 'employer.indexPage.widgets.myVacancies.placeholder.button',
};

const NoVacanciesPlaceholder: TranslatedComponent = ({ trls }) => {
    const { activeVacanciesAmount } = useSelector((state) => state.vacanciesWidget);
    const isZp = useIsZarplataPlatform();

    const handleCreateVacancyClick = useCallback(() => {
        createVacancyClick({ activeVacanciesAmount, hhtmSourceLabel: 'no_vacancies_placeholder' });
    }, [activeVacanciesAmount]);

    return (
        <div className={styles.vacanciesPlaceholder}>
            <div className={styles.vacanciesPlaceholderContent}>
                <Text typography="label-2-regular">{trls[TrlKeys.title]}</Text>
                <VSpacing default={4} />
                <Text typography="label-3-regular" style="secondary">
                    {trls[TrlKeys.description]}
                </Text>
                <VSpacing default={20} />
                <div className={styles.spacer} />
            </div>

            <div
                className={classNames(styles.vacanciesPlaceholderImage, { [styles.vacanciesPlaceholderImageZp]: isZp })}
            />

            <Button
                style="accent"
                mode="primary"
                Element={SPALink}
                to="/employer/vacancy/create"
                stretched
                onClick={handleCreateVacancyClick}
                data-qa="my-vacancies_no-vacancies-button"
            >
                {trls[TrlKeys.button]}
            </Button>
        </div>
    );
};

export default translation(NoVacanciesPlaceholder);
