import React, { MouseEventHandler, useCallback, useEffect } from 'react';
import classnames from 'classnames';

import { Link } from '@hh.ru/redux-spa-middleware';

import { useSelector } from 'src/hooks/useSelector';
import { NavItemAnalytics, NavItems } from 'src/models/supernovaNaviMenu';
import { UserStat } from 'src/models/userStats';

import Badge from 'src/components/SupernovaMainMenu/Badge';
import SupernovaCounter from 'src/components/SupernovaMainMenu/SupernovaCounter';
import SupernovaDropdownContext from 'src/components/SupernovaMainMenu/SupernovaDropdownContext';
import SupernovaModals, { SupernovaModalType } from 'src/components/SupernovaMainMenu/SupernovaModals';
import useTrackClicks, { TrackClicksProps } from 'src/components/SupernovaMainMenu/useTrackClicks';
import useTrackElementShown, { TrackElementShownProps } from 'src/components/SupernovaMainMenu/useTrackElementShown';

interface SupernovaDropdownOptionProps extends TrackClicksProps, TrackElementShownProps {
    Element?: 'button' | 'div';
    badge?: string;
    counter?: UserStat;
    'data-qa'?: string;
    disabled?: boolean;
    external?: boolean;
    highlight?: OptionHighlight;
    name: string;
    onClick?: React.MouseEventHandler;
    supportUnavailable?: boolean;
    tab?: boolean;
    analytics?: NavItemAnalytics;
    toggled?: boolean;
    url?: string;
    modalType?: SupernovaModalType;
    subItems?: NavItems;
    onClose?: () => void;
}

export enum OptionHighlight {
    Primary = 'primary',
    Warning = 'warning',
}

const SupernovaDropdownOption: React.FC<SupernovaDropdownOptionProps & React.PropsWithChildren> = ({
    Element = 'button',
    badge,
    children,
    counter,
    disabled,
    external,
    highlight,
    name,
    'data-qa': dataQa = `mainmenu_${name}`,
    onClick,
    supportUnavailable,
    tab,
    analytics,
    toggled,
    trackClicks,
    trackElementShown,
    url,
    modalType,
    subItems,
    onClose,
}) => {
    const { elements: dropdownContext } = React.useContext(SupernovaDropdownContext);

    const elementRef = React.useRef(null);

    const handleTrackClick = useTrackClicks(name, trackClicks, analytics);

    const userStat = useSelector(({ userStats }) => counter && userStats?.[counter]);

    const handleClick = useCallback<MouseEventHandler>(
        (event) => {
            onClick && onClick(event);
            handleTrackClick();
        },
        [handleTrackClick, onClick]
    );

    useEffect(() => {
        if (elementRef.current !== null) {
            dropdownContext[name] = elementRef.current;
        }
    }, [dropdownContext, name]);

    useTrackElementShown(elementRef, name, trackElementShown);

    const className = classnames('supernova-dropdown-option', {
        [`supernova-dropdown-option_highlight-${highlight!}`]: highlight,
        'supernova-dropdown-option_tab': tab,
        'supernova-dropdown-option_toggled': toggled,
        'supernova-dropdown-option_support-unavailable': supportUnavailable,
    });

    if (url) {
        return (
            <Link
                className={className}
                data-qa={dataQa}
                onClick={handleClick}
                target={external ? '_blank' : undefined}
                ref={elementRef}
                to={url}
            >
                {children}
                {!!userStat && <SupernovaCounter dropdown>{userStat}</SupernovaCounter>}
                <Badge badge={badge} name={name} />
            </Link>
        );
    }

    return (
        <>
            {modalType && (
                <SupernovaModals
                    modalType={modalType}
                    activatorRef={elementRef}
                    subItems={subItems}
                    onClose={onClose}
                />
            )}
            <Element className={className} data-qa={dataQa} disabled={disabled} onClick={handleClick} ref={elementRef}>
                {children}
                {!!userStat && <SupernovaCounter dropdown>{userStat}</SupernovaCounter>}
                <Badge badge={badge} name={name} />
            </Element>
        </>
    );
};

export default SupernovaDropdownOption;
