import { Text } from '@hh.ru/magritte-ui';
import { type TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';

interface ResumeSelectorTitleProps {
    isQuestionResponse: boolean;
}

const TrlKeys = {
    resumesTitle: 'vacancy.response.popup.resumes',
    questionResumesTitle: 'vacancy.questionResponse.popup.resumes',
};

const ResumeSelectorTitle: TranslatedComponent<ResumeSelectorTitleProps> = ({ trls, isQuestionResponse }) => {
    return (
        <Text typography="title-5-semibold">
            {trls[isQuestionResponse ? TrlKeys.questionResumesTitle : TrlKeys.resumesTitle]}
        </Text>
    );
};

export default translation(ResumeSelectorTitle);
