import { FC } from 'react';

const MetroPointIcon: FC<{ color: string; containerSize?: 22 | 24 }> = ({ color, containerSize = 24 }) => {
    return (
        <svg
            width={containerSize}
            height={containerSize}
            viewBox={`0 0 ${containerSize} ${containerSize}`}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx={containerSize / 2} cy={containerSize / 2} r={5} fill={color} />
        </svg>
    );
};

export default MetroPointIcon;
