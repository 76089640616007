import { VSpacing } from '@hh.ru/magritte-ui';
import { H2 } from 'bloko/blocks/header';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import useShowAdvertisementAgreement from 'src/components/AdvertisementAgreement/useShowAdvertisementAgreement';
import useShowAdvertisementSubscriptionApplicant from 'src/components/AdvertisementSubscription/useShowAdvertisementSubscriptionApplicant';
import GridColumn from 'src/components/MagritteRedesignComponents/GridColumn';
import Title from 'src/components/MagritteRedesignComponents/Header';
import translation from 'src/components/translation';
import { useMagritte } from 'src/hooks/useMagritte';

import SignUpForm, { SignUpSignFormBaseProps } from 'src/components/ApplicantSignInSignUpFlow/SignUpStep/SignUpForm';
import SignUpStepCard from 'src/components/ApplicantSignInSignUpFlow/SignUpStep/SignUpStepCard';

const TrlKeys = {
    header: 'otp.confirm.header.signup',
};

export interface SignUpProps extends SignUpSignFormBaseProps {
    errorPageLogin: boolean;
}

const SignUp: TranslatedComponent<SignUpProps> = ({ trls, errorPageLogin, ...formProps }) => {
    const showAdvertisementAgreement = useShowAdvertisementAgreement();
    const showAdvertisementSubscriptionApplicant = useShowAdvertisementSubscriptionApplicant();
    const isMagritte = useMagritte();

    return (
        <SignUpForm
            render={({
                renderFirstNameField,
                renderLastNameField,
                renderAccountDeleteInactive,
                renderSubmit,
                renderAdvertisementAgreement,
                renderAdvertisementSubscriptionApplicant,
            }) => {
                return (
                    <>
                        <GridColumn l="4" m={isMagritte ? '5' : '4'} s={isMagritte ? '8' : '4'} xs="4" container>
                            <SignUpStepCard>
                                <Title Element="h2" BlokoElement={H2} size="medium" alignment="left">
                                    {trls[TrlKeys.header]}
                                </Title>
                                <VSpacing default={isMagritte ? 24 : 16} />
                                {renderFirstNameField()}
                                <VSpacing default={isMagritte ? 12 : 8} />
                                {renderLastNameField()}
                                <VSpacing default={isMagritte ? 12 : 16} />
                                {renderAccountDeleteInactive()}
                                {showAdvertisementSubscriptionApplicant ? (
                                    <>
                                        {renderAdvertisementSubscriptionApplicant()}
                                        <VSpacing default={24} />
                                    </>
                                ) : (
                                    <VSpacing default={isMagritte ? 12 : 0} />
                                )}
                                {renderSubmit({ isLarge: !errorPageLogin })}
                            </SignUpStepCard>
                        </GridColumn>
                        {showAdvertisementAgreement && (
                            <div>
                                <GridColumn
                                    l={isMagritte ? '4' : '5'}
                                    m="5"
                                    s={isMagritte ? '8' : '5'}
                                    xs="4"
                                    container
                                >
                                    <VSpacing default={20} />
                                    {renderAdvertisementAgreement()}
                                </GridColumn>
                            </div>
                        )}
                    </>
                );
            }}
            {...formProps}
        />
    );
};

export default translation(SignUp);
