import { Fragment } from 'react';
import classnames from 'classnames';

import { Breakpoint, Card, Title, useBreakpoint, VSpacing } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { format } from 'bloko/common/trl';

import AdsExternalBanner from 'src/components/Banner/AdsExternalBanner';
import IndexCollapsibleBlock from 'src/components/IndexCollapsibleBlock';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { BELARUS_AREA_ID } from 'src/models/countryId';

import VacancyOfTheDay from 'src/components/IndexPageAnonymous/VacanciesOfTheDay/VacancyOfTheDay';

import styles from './styles.less';

const VACANCIES_PREVIEW_DEFAULT_COUNT = 8;
const VacanciesPreviewCountForBreakpoint = {
    [Breakpoint.XS]: 3,
    [Breakpoint.S]: 2,
    [Breakpoint.M]: 7,
    [Breakpoint.L]: 10,
};

const TrlKeys = {
    titleMain: 'index.vacancies',
    titleIn: 'wic-vod.in',
    titleXS: 'index.headers.vod',
};

const BANNER_PLACES_DEFAULT = [4, 8];

const BannerPlaces = {
    [Breakpoint.S]: [0, 1],
    [Breakpoint.M]: [1, 3],
    [Breakpoint.L]: [2, 5],
};

const VacanciesOfTheDay: TranslatedComponent = ({ trls }) => {
    const vacanciesOfTheDay = useSelector((state) => state.vacanciesOfTheDay);
    const hhtmSource = useSelector(({ analyticsParams }) => analyticsParams.hhtmSource);
    const banners = useSelector((state) => state.banners['index-vod']);
    const locale = useSelector((state) => state.locale);
    const domainRegionTrl = useSelector((state) => state.domainRegionTrl);
    const domainRegionTrlNom = useSelector((state) => state.domainRegionTrlNom);
    const showRegionTrl = !(locale.areaId === BELARUS_AREA_ID && !locale.isSeoDomain);

    const { breakpoint, isMobile } = useBreakpoint();
    const previewCount =
        VacanciesPreviewCountForBreakpoint[breakpoint as keyof typeof VacanciesPreviewCountForBreakpoint] ||
        VACANCIES_PREVIEW_DEFAULT_COUNT;

    const bannerIndexesForBreakpoint = BannerPlaces[breakpoint as keyof typeof BannerPlaces] || BANNER_PLACES_DEFAULT;

    return (
        <div>
            <Title Element="h3" size={isMobile ? 'small' : 'medium'}>
                {format(trls[TrlKeys.titleMain], {
                    '{0}': showRegionTrl ? trls[TrlKeys.titleIn] : '',
                    '{1}': showRegionTrl ? domainRegionTrl : '',
                })}
            </Title>
            <VSpacing default={16} gteS={20} gteM={24} />
            <IndexCollapsibleBlock
                needToExpand={vacanciesOfTheDay.vacancies.length > previewCount}
                renderPreview={() => (
                    <div className={styles.container}>
                        {vacanciesOfTheDay.vacancies.slice(0, previewCount).map((vacancy, index) => {
                            let banner;
                            if (banners?.length && bannerIndexesForBreakpoint.includes(index)) {
                                banner = banners[bannerIndexesForBreakpoint.indexOf(index)];
                            }
                            return (
                                <Fragment key={vacancy.vacancyId}>
                                    <VacancyOfTheDay
                                        vacancy={vacancy}
                                        key={vacancy.vacancyId}
                                        hhtmFromLabel="vacancies_of_the_day"
                                        hhtmSource={hhtmSource}
                                    />
                                    {!!banner && (
                                        <Card padding={0} borderRadius={24}>
                                            <div
                                                className={classnames('HHC-Banner-Wrapper', 'banner-place-wrapper')}
                                                data-empty-class="banner-place-wrapper_hide"
                                                key={banner.id}
                                                data-page-analytics-event={`advertising_banner.right${banner.id}`}
                                            >
                                                <AdsExternalBanner {...banner} />
                                            </div>
                                        </Card>
                                    )}
                                </Fragment>
                            );
                        })}
                    </div>
                )}
                renderRest={() => (
                    <>
                        <VSpacing default={24} />
                        <div className={styles.container}>
                            {vacanciesOfTheDay.vacancies.slice(previewCount).map((vacancy) => {
                                return (
                                    <VacancyOfTheDay
                                        vacancy={vacancy}
                                        key={vacancy.vacancyId}
                                        hhtmFromLabel="vacancies_of_the_day"
                                        hhtmSource={hhtmSource}
                                    />
                                );
                            })}
                        </div>
                    </>
                )}
                bottomSheetTitle={trls[TrlKeys.titleXS]}
                bottomSheetSubtitle={showRegionTrl ? domainRegionTrlNom : undefined}
                renderBottomSheetContent={() => (
                    <div className={styles.container}>
                        {vacanciesOfTheDay.vacancies.slice(previewCount).map((vacancy) => {
                            return (
                                <VacancyOfTheDay
                                    vacancy={vacancy}
                                    key={vacancy.vacancyId}
                                    hhtmFromLabel="vacancies_of_the_day"
                                    hhtmSource={hhtmSource}
                                />
                            );
                        })}
                    </div>
                )}
            />
        </div>
    );
};

export default translation(VacanciesOfTheDay);
