import { Button, Link as MagritteLink, Title, VSpacing } from '@hh.ru/magritte-ui';
import { Link } from '@hh.ru/redux-spa-middleware';
import ConversionNumber from 'bloko/blocks/conversion';
import format from 'bloko/common/format';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { formatToReactComponent } from 'bloko/common/trl';

import paths from 'src/app/routePaths';
import VacancySearchItem from 'src/components/VacancySearchItem';
import Source from 'src/components/VacancySearchItem/types/Source';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

import styles from './recommended-vacancies.less';

const TrlKeys = {
    header: 'index.applicant.recommendedVacancies.header',
    allShort: 'index.applicant.recommendedVacancies.all.short',
    allFull: 'index.applicant.recommendedVacancies.all.full',
    onMap: 'index.applicant.recommendedVacancies.onMap',
    subheader: {
        forResume: 'index.applicant.recommendedVacancies.subheader.forResume',
        noResume: 'index.applicant.recommendedVacancies.subheader.noResume',
    },
    vacancies: {
        one: 'index.applicant.recommendedVacancies.vacancies.one',
        some: 'index.applicant.recommendedVacancies.vacancies.some',
        many: 'index.applicant.recommendedVacancies.vacancies.many',
    },
};

const RecommendedVacancies: TranslatedComponent = ({ trls }) => {
    const recommendedVacancies = useSelector((state) => state.recommendedVacancies);
    const applicantLatestResume = useSelector((state) => state.applicantLatestResume);

    if (!recommendedVacancies?.resultsFound) {
        return null;
    }

    const renderSubheader = () => {
        if (applicantLatestResume?.title) {
            return format(trls[TrlKeys.subheader.forResume], {
                '{0}': applicantLatestResume.title,
            });
        }
        return trls[TrlKeys.subheader.noResume];
    };

    const hasVacanciesWithAddress = recommendedVacancies.hasVacanciesWithAddress;
    const queries = recommendedVacancies.queries;
    const fullSearchLink = `${paths.vacancySearch}?${queries.itemsOnPageBase}`;
    const showSearchLink = recommendedVacancies.resultsFound > recommendedVacancies.itemsOnPage;

    return (
        <>
            <div className={styles.headerWrapper}>
                <div className={styles.headerButtonsWrapper}>
                    <Title Element="h2" size="medium" description={renderSubheader()} descriptionMaxLines={2}>
                        {trls[TrlKeys.header]}
                        <div className={styles.headerSearchLinkExperiment}>
                            {showSearchLink && (
                                <MagritteLink Element={Link} to={fullSearchLink}>
                                    {format(trls[TrlKeys.allShort], {
                                        '{0}': recommendedVacancies.resultsFound,
                                    })}
                                </MagritteLink>
                            )}
                        </div>
                    </Title>
                </div>
                <div className={styles.headerMapButtonExperiment}>
                    {hasVacanciesWithAddress && (
                        <Button
                            data-qa="applicant-index-search-map-button"
                            mode="secondary"
                            Element={Link}
                            style="accent"
                            to={`${paths.vacancySearchMap}?${queries.map}`}
                            additionalQueryParams={{ hhtmFromLabel: 'rec_vacancy_show_all_map' }}
                        >
                            {trls[TrlKeys.onMap]}
                        </Button>
                    )}
                </div>
            </div>
            <VSpacing default={20} gteS={24} />
            {recommendedVacancies.vacancies.map((vacancy) => (
                <VacancySearchItem
                    key={vacancy.vacancyId}
                    vacancy={vacancy}
                    vacancySource={Source.RecommendedVacanciesOnMain}
                />
            ))}
            {showSearchLink && (
                <>
                    <VSpacing default={20} gteS={24} />
                    <Button
                        data-qa="applicant-index-search-all-results-button"
                        Element={Link}
                        to={fullSearchLink}
                        additionalQueryParams={{ hhtmFromLabel: 'rec_vacancy_show_all' }}
                        mode="secondary"
                        style="accent"
                        stretched
                    >
                        {formatToReactComponent(trls[TrlKeys.allFull], {
                            '{0}': (
                                <ConversionNumber
                                    hasValue={true}
                                    value={recommendedVacancies.resultsFound}
                                    many={trls[TrlKeys.vacancies.many]}
                                    some={trls[TrlKeys.vacancies.some]}
                                    one={trls[TrlKeys.vacancies.one]}
                                />
                            ),
                        })}
                    </Button>
                </>
            )}
        </>
    );
};

export default translation(RecommendedVacancies);
