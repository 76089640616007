import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';
import { LanguageLevel } from '@hh.ru/types-hh-microcore';

import { FilterType, LoadingActivityName, Paging } from 'src/components/SavedSearch/types';

import { MetroType } from 'src/models/novaFilters';
import { Criteria } from 'src/models/resumeSearchCriteria';
import { ExpPeriodOption, LogicOption, PosOption } from 'src/models/search/resume/searchQueries';
import { CriteriaKey } from 'src/models/search/searchCriteria.types';

export interface EmployerManager {
    employerId: string;
    firstName: string;
    lastName: string;
    middleName: string;
    employerManagerId: string;
}

export interface Query {
    [CriteriaKey.ExpPeriod]: ExpPeriodOption;
    [CriteriaKey.Logic]: LogicOption;
    [CriteriaKey.Pos]: PosOption[];
    [CriteriaKey.Text]: string;
    title: string;
}

interface Skill {
    id: number;
    text: string;
}

export interface Metro {
    id: string;
    title: string;
    type: MetroType;
}

interface SavedSearchParam {
    [CriteriaKey.Area]?: string[];
    [CriteriaKey.District]?: string[];
    [CriteriaKey.Schedule]?: string[];
    [CriteriaKey.Employment]?: string[];
    [CriteriaKey.Experience]?: string[];
    [CriteriaKey.EducationLevel]?: string[];
    [CriteriaKey.University]?: string[];
}

interface ProfRole {
    id: number;
    text: string;
}

export type ProfRoleCategory = Omit<ProfRole, 'id'> & {
    id: string | number;
    profRoles?: ProfRole[];
};

export interface SavedLanguages {
    degree: LanguageLevel;
    language: string;
}

export interface ResumeSavedSearchItem {
    creationTime: string;
    creationTimestamp: string;
    id: number;
    lastUsedTime: string;
    name: string;
    queries: Query[];
    resultsAll?: number;
    resultsNew?: number;
    searchAllQuery: string;
    searchNewQuery: string;
    searchCriteria: Criteria;
    params: SavedSearchParam;
    skills: Skill[];
    metros: Metro[];
    subscribed: boolean;
    todayResultsFound: number;
    totalResultsFound: number;
    savedLanguages: SavedLanguages[];
}

export interface ResumeSavedSearchState {
    isAllSelected: boolean;
    selectedItems: number[];
    error?: boolean;
    paging?: Paging;
    filterValue: FilterType;
    currentLoadingActivityName?: LoadingActivityName;
    employerManager?: EmployerManager;
    isSudo: boolean;
    savedSearch: ResumeSavedSearchItem[];
}

interface ResumeSavedSearchControls {
    isAllSelected: boolean;
    selectedItems: number[];
}

interface SetResumeSavedSearchCountersPayload {
    savedSearchId: number;
    resultsAll: number;
    resultsNew: number;
}

const SELECT_RESUME_SAVED_SEARCH = 'SELECT_RESUME_SAVED_SEARCH';
const RESUME_SAVED_SEARCH_LOADING = 'RESUME_SAVED_SEARCH_LOADING';
const SELECT_ALL_RESUME_SAVED_SEARCHES = 'SELECT_ALL_RESUME_SAVED_SEARCHES';
const SET_RESUME_SAVED_SEARCH_COUNTERS = 'SET_RESUME_SAVED_SEARCH_COUNTERS';

interface PayloadTypes {
    [SELECT_RESUME_SAVED_SEARCH]: ResumeSavedSearchControls;
    [RESUME_SAVED_SEARCH_LOADING]: LoadingActivityName | undefined;
    [SELECT_ALL_RESUME_SAVED_SEARCHES]: ResumeSavedSearchControls;
    [SET_RESUME_SAVED_SEARCH_COUNTERS]: SetResumeSavedSearchCountersPayload;
}

const actionCreator = ActionCreatorHelper<PayloadTypes>();

export const selectResumeSavedSearchAction = actionCreator(SELECT_RESUME_SAVED_SEARCH);
export const resumeSavedSearchLoadingAction = actionCreator(RESUME_SAVED_SEARCH_LOADING);
export const selectAllSavedSearchesAction = actionCreator(SELECT_ALL_RESUME_SAVED_SEARCHES);
export const setResumeSavedSearchCountersAction = actionCreator(SET_RESUME_SAVED_SEARCH_COUNTERS);

export const INITIAL_STATE: ResumeSavedSearchState = {
    isAllSelected: false,
    selectedItems: [],
    savedSearch: [],
    error: false,
    filterValue: FilterType.All,
    isSudo: false,
};

export default createReducer<ResumeSavedSearchState, PayloadTypes>(INITIAL_STATE, {
    [SELECT_RESUME_SAVED_SEARCH]: (state, action) => ({ ...state, ...action.payload }),
    [RESUME_SAVED_SEARCH_LOADING]: (state, action) => ({ ...state, currentLoadingActivityName: action.payload }),
    [SELECT_ALL_RESUME_SAVED_SEARCHES]: (state, action) => ({ ...state, ...action.payload }),
    [SET_RESUME_SAVED_SEARCH_COUNTERS]: (state, action) => {
        const updatedSavedSearchArray: ResumeSavedSearchItem[] = state.savedSearch.map((item) => {
            if (action.payload.savedSearchId === item.id) {
                return { ...item, resultsAll: action.payload.resultsAll, resultsNew: action.payload.resultsNew };
            }
            return item;
        });
        return { ...state, savedSearch: updatedSavedSearchArray };
    },
});
