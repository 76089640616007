import { useContext, useRef, useState } from 'react';

import resumeCreationButtonClick from '@hh.ru/analytics-js-events/build/xhh/applicant/resume/creation/resume_creation_button_click';
import { Breakpoint, TooltipHover } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { format } from 'bloko/common/trl';

import ImportResumeModal from 'src/components/ImportResumeModal';
import SupernovaButton from 'src/components/SupernovaMainMenu/SupernovaButton';
import SupernovaNaviItem from 'src/components/SupernovaMainMenu/SupernovaNaviItem';
import isRedesignActiveContext from 'src/components/SupernovaMainMenu/isRedesignActiveContext';
import useGoogleAnalytics from 'src/components/SupernovaMainMenu/useGoogleAnalytics';
import translation from 'src/components/translation';
import { useIsZarplataPlatform } from 'src/hooks/usePlatform';
import { useSelector } from 'src/hooks/useSelector';
import { NavItem } from 'src/models/supernovaNaviMenu';
import { UserType } from 'src/models/userType';

const TrlKeys = {
    maxResumes: 'resumeList.maxResumes',
};

const VISIBLE_ON = [Breakpoint.S, Breakpoint.M, Breakpoint.L];

const CreateResume: TranslatedComponent<NavItem> = ({
    active,
    name,
    translations,
    trackClicks,
    trls,
    url,
    analytics,
}) => {
    const isRedesignActive = useContext(isRedesignActiveContext);

    const resumeLimits = useSelector(({ resumeLimits }) => resumeLimits);
    const userType = useSelector(({ userType }) => userType);
    const isZp = useIsZarplataPlatform();
    const buttonRef = useRef<HTMLDivElement>(null);

    const [importZpVisible, setImportZpVisible] = useState(false);

    const handleClick = useGoogleAnalytics('create_resume', () => {
        resumeCreationButtonClick({
            hhtmSourceLabel: 'resume_creation_header',
        });
    });

    const handleZpClick = () => {
        setImportZpVisible(true);
    };

    const zpButton = (
        <SupernovaButton
            isMagritte={isRedesignActive}
            magritteStyle="contrast"
            magritteMode="secondary"
            active={active}
            data-qa={`mainmenu_${name}`}
            analytics={analytics}
            name={name}
            onClick={handleZpClick}
            secondary={userType !== UserType.NedoUser}
            tinted
            trackClicks={trackClicks}
        >
            {translations.name}
        </SupernovaButton>
    );

    const hhButton = (
        <SupernovaButton
            isMagritte={isRedesignActive}
            magritteStyle="contrast"
            magritteMode="secondary"
            active={active}
            data-qa={`mainmenu_${name}`}
            analytics={analytics}
            name={name}
            onClick={handleClick}
            secondary={userType !== UserType.NedoUser}
            tinted
            rel="nofollow"
            trackClicks={trackClicks}
            url={url}
        >
            {translations.name}
        </SupernovaButton>
    );

    let content;
    if (resumeLimits && resumeLimits.remaining === 0) {
        content = (
            <>
                <div className="supernova-button-wrapper">
                    <SupernovaButton
                        isMagritte={isRedesignActive}
                        magritteStyle="contrast"
                        magritteMode="secondary"
                        active={active}
                        data-qa={`mainmenu_${name}`}
                        disabled
                        name={name}
                        secondary
                        tinted
                        analytics={analytics}
                        trackClicks={trackClicks}
                    >
                        {translations.name}
                    </SupernovaButton>
                </div>
                <TooltipHover placement="bottom-right" activatorRef={buttonRef} data-qa="max-resume-warning">
                    {format(trls[TrlKeys.maxResumes], { '{0}': resumeLimits.max })}
                </TooltipHover>
            </>
        );
    } else {
        content = isZp ? zpButton : hhButton;
    }

    return (
        <SupernovaNaviItem active={active} isButton visibleOn={VISIBLE_ON} ref={buttonRef}>
            {content}

            <ImportResumeModal
                visible={importZpVisible}
                onClose={() => setImportZpVisible(false)}
                url={url}
                activatorRef={buttonRef}
            />
        </SupernovaNaviItem>
    );
};

export default translation(CreateResume);
