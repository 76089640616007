import { useMemo, ComponentProps } from 'react';

import { Hhcaptcha as HHcaptcha } from '@hh.ru/hhcaptcha';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { useMagritte as useRedesign } from 'src/hooks/useMagritte';
import fetcher from 'src/utils/fetcher';

const TrlKeys = {
    error: 'hhсaptcha.error',
    placeholder: 'hhсaptcha.placeholder',
    description: 'hhсaptcha.info',
    renewText: 'hhсaptcha.renew.text',
    lang: {
        RU: 'hhсaptcha.lang.RU',
        EN: 'hhсaptcha.lang.EN',
    },
};

type HhCaptchaProps = Pick<ComponentProps<typeof HHcaptcha>, 'size'>;

const Hhcaptcha: TranslatedComponent<HhCaptchaProps> = ({ trls, size }) => {
    const isMagritte = useRedesign();
    const captchaTrl = useMemo(
        () => ({
            error: trls[TrlKeys.error],
            renewText: trls[TrlKeys.renewText],
            description: trls[TrlKeys.description],
            placeholder: trls[TrlKeys.placeholder],
            lang: {
                RU: trls[TrlKeys.lang.RU],
                EN: trls[TrlKeys.lang.EN],
            },
        }),
        [trls]
    );

    return <HHcaptcha trls={captchaTrl} fetcher={fetcher} isMagritte={isMagritte} size={size} />;
};

export default translation(Hhcaptcha);
