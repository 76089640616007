import { FC } from 'react';

import { Card, Text } from '@hh.ru/magritte-ui';
import { Link } from '@hh.ru/redux-spa-middleware';

import Compensation from 'src/components/Compensation';
import VacanciesCounter from 'src/components/RainbowCatalog/VacanciesCounter';
import { getProfessionLink } from 'src/components/RainbowCatalog/utils';
import { CurrencyType } from 'src/models/currencies.types';
import { ProfessionsListProfession } from 'src/models/professionsList';

import styles from './styles.less';

interface BottomSheetProfessionsProps {
    professions: ProfessionsListProfession[];
    parentProfession: ProfessionsListProfession;
    currency: CurrencyType;
    showSeoLinksForBots?: boolean;
}

const BottomSheetProfessions: FC<BottomSheetProfessionsProps> = ({
    professions,
    parentProfession,
    currency,
    showSeoLinksForBots,
}) => {
    return (
        <div className={styles.container}>
            {[parentProfession, ...professions].map((profession, index) => (
                <Link
                    key={profession.name}
                    to={getProfessionLink(parentProfession, profession)}
                    additionalQueryParams={{ hhtmFromLabel: 'rainbow_profession' }}
                    isSeoLink={showSeoLinksForBots}
                    data-qa="profession-item-title"
                    data-page-analytics-value={profession.name}
                    data-page-analytics-position={index}
                    data-page-analytics-event="vacancy_rainbow_catalog_item_link.rainbowLink"
                >
                    <Card showBorder stretched padding={16} borderRadius={16} actionCard key={profession.name}>
                        <Text typography="subtitle-1-semibold">{profession.name}</Text>
                        <Text typography="label-3-regular" style="secondary">
                            <Compensation
                                from={profession.compensationFrom}
                                to={profession.compensationTo}
                                currencyCode={currency}
                                dash
                            />
                        </Text>
                        <Text typography="paragraph-3-regular" style="tertiary">
                            <VacanciesCounter value={profession.count} />
                        </Text>
                    </Card>
                </Link>
            ))}
        </div>
    );
};

export default BottomSheetProfessions;
