import { useEffect, useRef } from 'react';

import Analytics from '@hh.ru/analytics-js';
import { CompensationMode } from '@hh.ru/types-hh-microcore';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import NumberFormatter from 'bloko/common/numberFormatter';
import { formatToReactComponent } from 'bloko/common/trl';

import Currency from 'src/components/Currency';
import translation from 'src/components/translation';
import { CurrencyType } from 'src/models/currencies.types';
import { NARROW_NON_BREAKING_SPACE, NON_BREAKING_SPACE } from 'src/utils/constants/symbols';

export interface CompensationProps {
    /** @deprecated Зарплатные ожидания от */
    from?: number | null;
    /** @deprecated Зарплатные ожидания до */
    to?: number | null;

    /** Гранулярные зарплатные ожидания от */
    perModeFrom?: number | null;
    /** Гранулярные зарплатные ожидания до */
    perModeTo?: number | null;
    /** Код валюты */
    currencyCode?: CurrencyType;
    /** Гранулярность зарплатных ожиданий */
    mode?: CompensationMode;
    /** Выводить "–" между значениями вместо от и до */
    dash?: boolean;
    /** Выводить сообщение "з/п не указана" */
    showNoSalaryMessage?: boolean;
    /** Разделитель групп */
    groupSeparator?: string;
    /** Служебное поле. Нужно для определения контекста компонента при аналитике фоллбэка на старые поля */
    analyticsContext?: string;
}

const TrlKeys = {
    to: 'vacancy.compensation.to',
    fromTo: 'vacancy.compensation.from.to',
    from: 'vacancy.compensation.from',
    noCompensation: 'vacancySearchResults.compensationNotSet',
};

const Compensation: TranslatedComponent<CompensationProps> = ({
    from,
    to,
    perModeFrom,
    perModeTo,
    currencyCode,
    dash = false,
    showNoSalaryMessage = true,
    groupSeparator = NARROW_NON_BREAKING_SPACE,
    analyticsContext,
    trls,
}) => {
    const isAnalyticsSended = useRef(false);

    useEffect(() => {
        if (!isAnalyticsSended.current && analyticsContext && ((!perModeFrom && from) || (!perModeTo && to))) {
            Analytics.sendHHEvent('compensation_fallback', {
                pathname: window.location.pathname,
                context: analyticsContext,
            });

            isAnalyticsSended.current = true;
        }
    }, [analyticsContext, from, to, perModeFrom, perModeTo]);

    const fallbackFrom = perModeFrom ?? from ?? null;
    const fallbackTo = perModeTo ?? to ?? null;

    if (!fallbackFrom && !fallbackTo && !showNoSalaryMessage) {
        return null;
    }

    let content;
    const formattedFrom = fallbackFrom ? NumberFormatter.format(String(fallbackFrom), { groupSeparator }) : null;
    const formattedTo = fallbackTo ? NumberFormatter.format(String(fallbackTo), { groupSeparator }) : null;

    if (formattedFrom && formattedTo) {
        if (formattedFrom === formattedTo) {
            content = <Currency prefix={`${formattedFrom}${NON_BREAKING_SPACE}`} value={currencyCode} />;
        } else if (dash) {
            content = <Currency prefix={`${formattedFrom} – ${formattedTo} `} value={currencyCode} />;
        } else {
            content = formatToReactComponent(trls[TrlKeys.fromTo], {
                '{0}': <>{formattedFrom}</>,
                '{1}': <>{formattedTo}</>,
                '{2}': <Currency value={currencyCode} />,
            });
        }
    } else if (formattedFrom && !formattedTo) {
        content = formatToReactComponent(trls[TrlKeys.from], {
            '{0}': <>{formattedFrom}</>,
            '{1}': <Currency value={currencyCode} />,
        });
    } else if (!formattedFrom && formattedTo) {
        content = formatToReactComponent(trls[TrlKeys.to], {
            '{0}': <>{formattedTo}</>,
            '{1}': <Currency value={currencyCode} />,
        });
    } else {
        content = trls[TrlKeys.noCompensation];
    }

    return <>{content}</>;
};

export default translation(Compensation);
