import React from 'react';

import { ArrowRightOutlinedSize16 } from '@hh.ru/magritte-ui/icon';
import FormSpacer from 'bloko/blocks/form/FormSpacer';

import BlokoIconReplaceContainer from 'src/components/BlokoIconReplaceContainer';
import { NavItem } from 'src/models/supernovaNaviMenu';

import SupernovaDropdownOption, { OptionHighlight } from 'src/components/SupernovaMainMenu/SupernovaDropdownOption';
import SupernovaDropdownSection from 'src/components/SupernovaMainMenu/SupernovaDropdownSection';
import useHandleSwitchAccountClick from 'src/components/SupernovaMainMenu/hooks/useHandleSwitchAccountClick';

const SwitchAccount: React.FC<NavItem> = ({ name, trackClicks, translations, analytics }) => {
    const handleSwitchAccountClick = useHandleSwitchAccountClick();

    return (
        <SupernovaDropdownSection>
            <SupernovaDropdownOption
                name={name}
                trackClicks={trackClicks}
                highlight={OptionHighlight.Primary}
                onClick={handleSwitchAccountClick}
                analytics={analytics}
            >
                <div className="supernova-switch-account-content">
                    {translations.name}
                    <FormSpacer>
                        <BlokoIconReplaceContainer>
                            <ArrowRightOutlinedSize16 initial="accent" />
                        </BlokoIconReplaceContainer>
                    </FormSpacer>
                </div>
            </SupernovaDropdownOption>
        </SupernovaDropdownSection>
    );
};

export default SwitchAccount;
