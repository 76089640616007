import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

import { ArticleItemWithDate } from 'src/models/article.types';
import { Employer, EmployerBillingSummary } from 'src/models/oldEmployer/employerBillingSummary';

interface OldEmployer {
    eventsMain: {
        body: { events: ArticleItemWithDate[] };
    };
    billingSummary: EmployerBillingSummary;
    employer: Employer;
    resumePicksAvailable?: boolean;
    canViewStatPortal?: boolean;
}

interface EmployerIndexPage {
    oldEmployer?: OldEmployer;
}

export default autoGeneratedReducer<EmployerIndexPage>({});
