import { ApplicantResumeEditorStore } from 'src/models/applicant/resume/editor/store/slice';
import { Reducer } from 'src/models/applicant/resume/lib/slice/types';

import { EditorConfirmType } from 'src/models/applicant/resume/editor/store/confirm/types';

export const CONFIRM_INITIAL_STATE = {
    show: false,
    type: 'block-changed',
    title: '',
} as const;

export const showResumeEditorConfirmReducer: Reducer<
    ApplicantResumeEditorStore,
    { type: EditorConfirmType; title?: string }
> = (store, { payload }) => ({
    ...store,
    confirm: { show: true, type: payload.type, title: payload.title || '' },
});

export const hideResumeEditorConfirmReducer: Reducer<ApplicantResumeEditorStore> = (store) => ({
    ...store,
    confirm: { ...store.confirm, show: false, title: '' },
});
