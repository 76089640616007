/**
 * Returns a list containing the names of all the enumerable own properties of
 * the supplied object.
 */
export function keys<T extends object>(obj: T): Array<keyof T> {
    return Object.keys(obj) as Array<keyof T>;
}

/**
 * Returns a partial copy of an object omitting the keys specified.
 */
export function omit<T extends object, Key extends keyof T>(key: Key, obj: T): Omit<T, Key> {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const result: Omit<T, Key> = {};

    keys(obj).forEach((prop) => {
        if (prop !== key) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            result[prop] = obj[prop];
        }
    });

    return result;
}

/**
 * Determines whether an object has a property with the specified name.
 */
export function hasOwn<T extends object>(obj: T, key: keyof T): obj is Required<T> {
    return Object.hasOwn(obj, key);
}

/**
 * функция сужает union тип до одного по ключу
 */
export function isUnionMatch<T extends object, R extends T>(k: PropertyKey) {
    return function (obj: T): obj is R {
        return obj !== null && typeof obj === 'object' && k in obj;
    };
}
