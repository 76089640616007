import { FC, ReactNode, RefCallback, useEffect, useState, PropsWithChildren } from 'react';

import { ElementSpyInstance } from 'bloko/common/elementSpy';

interface ElementShownAnchorCommonProps {
    /** Элемент используемый в качестве якоря */
    Element?: 'div' | 'span' | 'p';
    /** Имя класса для якоря */
    className?: string;
    /** Дочерние элементы */
    children?: ReactNode;
}

/**
 * Общая сигнатура функции отправки аналитики при попадании элемента во вьюпорт
 */
type GenericSendElementShown<E = HTMLElement> = (element: E, params?: unknown) => ElementSpyInstance;

/**
 * Хелпер для создания интерфейса компонента из параметров аналитического метода
 */
type ElementShownAnchorPropsHelper<FN, E = HTMLElement> = FN extends (element: E, params: infer P) => ElementSpyInstance
    ? { fn: FN } & P
    : FN extends (element: E) => ElementSpyInstance
      ? { fn: FN }
      : never;

interface UseElementShown<E = HTMLElement> {
    (fn: (element: E) => ElementSpyInstance): RefCallback<E>;
    <P>(fn: (element: E, params: P) => ElementSpyInstance, params: P): RefCallback<E>;
}

/**
 * Хук для отправки события аналитики при попадании элемента во вьюпорт
 *
 * Возвращает ref-коллбек для размещения на нужном элементе
 *
 * При изменении параметров или маунте/анмаунте элемента событие переназначается
 */
export const useElementShown: UseElementShown = (...args: unknown[]) => {
    const fn = args[0] as GenericSendElementShown;
    const params = args[1] as Record<string, unknown> | undefined;
    const [element, setElement] = useState<HTMLElement | null>(null);

    // eslint-disable-next-line consistent-return
    useEffect(() => {
        if (element) {
            const instance = fn(element, params);
            return () => {
                instance.stopSpying();
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [element, ...(params ? Object.values(params) : [])]);

    return setElement;
};

/**
 * Компонент создаёт якорь для отправки аналитики при его попадании во вьюпорт
 *
 * Принимает типизированный аналитический метод и все его параметры в виде пропов
 *
 * При изменении изначений пропов событие инициализируется заново
 */
// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
const ElementShownAnchor = <M extends unknown = GenericSendElementShown>({
    Element = 'div',
    className,
    children,
    fn,
    ...params
}: ElementShownAnchorCommonProps & ElementShownAnchorPropsHelper<M>): ReturnType<FC<PropsWithChildren>> => {
    const ref = useElementShown(fn, params);
    return (
        <Element ref={ref} className={className}>
            {children}
        </Element>
    );
};

export default ElementShownAnchor;
