import useFeatureEnabled from 'src/hooks/useFeatureEnabled';

const Features = {
    enableCompensationWithModeAndFrequency: 'enable_compensation_with_mode_and_frequency',
};

const useEnableCompensationWithModeAndFrequency = (): boolean => {
    return useFeatureEnabled(Features.enableCompensationWithModeAndFrequency);
};

export default useEnableCompensationWithModeAndFrequency;
