import React from 'react';

import { CreateSupportChatInstance, SupportChatInstance } from 'HHC/SupportChat';

export interface UseSupportChatType {
    chatInstance: React.RefObject<SupportChatInstance | undefined>;
    isActive: boolean;
}

export type UseSupportChatFunction = (postfix: string, shouldRender?: boolean) => UseSupportChatType;

interface SupportChatContextType extends UseSupportChatType {
    setShouldRender: (shouldRender: boolean) => void;
}

export const SupportChatContext = React.createContext<SupportChatContextType>({
    chatInstance: { current: null },
    isActive: false,
    setShouldRender: () => null,
});

const useSupportChat: UseSupportChatFunction = (postfix: string, shouldRender = true) => {
    const chatInstance = React.useRef<SupportChatInstance>();
    const [isActive, setActive] = React.useState(false);

    React.useEffect(() => {
        if (!shouldRender) {
            return () => null;
        }

        chatInstance.current =
            chatInstance.current ||
            CreateSupportChatInstance(postfix, {
                onOnlineOperatorsAppear: () => setActive(true),
                onOnlineOperatorsLeave: () => setActive(false),
            });
        return () => {
            chatInstance.current && chatInstance.current.unsubscribe();
        };
    }, [shouldRender, postfix, setActive]);

    return { chatInstance, isActive };
};

export default useSupportChat;
