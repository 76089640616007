import { FC } from 'react';

import Announcement from 'src/components/IndexPageEmployer/components/Announcement';
import NewYearStats from 'src/components/IndexPageEmployer/components/NewYearStats';
import { useSelector } from 'src/hooks/useSelector';

const PromoModal: FC = () => {
    const mainAnnouncementEnabled = useSelector(
        ({ vacancyCreateNewFieldsMainAnnouncementEnabled }) => vacancyCreateNewFieldsMainAnnouncementEnabled
    );
    const newYearStatsModalEnabled = useSelector(({ employerNewYearStats }) => employerNewYearStats.showModal);

    return (
        <>
            <NewYearStats isShow={newYearStatsModalEnabled} />
            <Announcement isShow={mainAnnouncementEnabled && !newYearStatsModalEnabled} />
        </>
    );
};

export default PromoModal;
