import { FC, useEffect, useRef } from 'react';
import classNames from 'classnames';

import vacanciesWidgetElementShown from '@hh.ru/analytics-js-events/build/xhh/employer/vacancies_widget/vacancies_widget_element_shown';
import { Card } from '@hh.ru/magritte-ui';

import { useIsZarplataPlatform } from 'src/hooks/usePlatform';
import { useSelector } from 'src/hooks/useSelector';

import Footer from 'src/components/EmployerVacanciesWidget/components/Footer';
import Header from 'src/components/EmployerVacanciesWidget/components/Header';
import Vacancies from 'src/components/EmployerVacanciesWidget/components/Vacancies';

import styles from './styles.less';

const EmployerVacanciesWidget: FC = () => {
    const ref = useRef<HTMLDivElement | null>(null);
    const { vacancies, activeVacanciesAmount } = useSelector((state) => state.vacanciesWidget);
    const isZp = useIsZarplataPlatform();

    const renderFooter = () => {
        const shouldRenderAdditionalPlaceholder = vacancies.length === 1;

        if (vacancies.length === 0) {
            return null;
        }

        return (
            <div className={styles.growingFooter}>
                <Footer />
                {shouldRenderAdditionalPlaceholder && (
                    <div
                        className={classNames(styles.additionalPlaceholder, { [styles.additionalPlaceholderZp]: isZp })}
                    />
                )}
            </div>
        );
    };

    useEffect(() => {
        if (ref.current) {
            const { stopSpying } = vacanciesWidgetElementShown(ref.current, { activeVacanciesAmount });

            return stopSpying;
        }

        return undefined;
    }, [activeVacanciesAmount]);

    return (
        <Card showBorder borderRadius={24} stretched verticalStretched ref={ref}>
            <div className={styles.widgetContent}>
                <Header />
                <Vacancies />
                {renderFooter()}
            </div>
        </Card>
    );
};

export default EmployerVacanciesWidget;
