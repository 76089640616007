import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { makeSetStoreField } from '@hh.ru/redux-create-reducer';

import { CurrencyType } from 'src/models/currencies.types';

import { useApplyFilters } from 'src/components/Search/Common/Filters/hooks/useApplyFilters';

const criteriaCurrencyCodeAction = makeSetStoreField('criteriaCurrencyCode');

/**
 * Экшен для изменения валюты дохода в фильтрах.
 *
 * @param currency Код выбранной валюты.
 */
type UpdateCurrencyAction = (currency: CurrencyType) => void;

/**
 * Хук создания экшена для изменения валюты дохода в фильтрах.
 *
 * @returns UpdateCurrencyAction;
 */
export const useApplyCurrencyFilter = (): UpdateCurrencyAction => {
    const dispatch = useDispatch();
    const applyFilters = useApplyFilters();

    return useCallback(
        (currency) => {
            dispatch(criteriaCurrencyCodeAction(currency));
            applyFilters();
        },
        [applyFilters, dispatch]
    );
};
