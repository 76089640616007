import { ComponentType } from 'react';

import { Button, useBreakpoint } from '@hh.ru/magritte-ui';

import translation from 'src/components/translation';

const TrlKeys = {
    auth: 'zp.importResume.error.auth',
    choose: 'zp.importResume.error.choose',
};

interface Props {
    onChoose: () => void;
    onAuth: () => void;
}

const ErrorFooter: ComponentType<Props> = translation(({ trls, onChoose, onAuth }) => {
    const { isMobile } = useBreakpoint();

    const buttons = [
        <Button key="choose" style="accent" mode="secondary" onClick={onChoose}>
            {trls[TrlKeys.choose]}
        </Button>,
        <Button key="auth" style="accent" mode="primary" onClick={onAuth}>
            {trls[TrlKeys.auth]}
        </Button>,
    ];

    return <>{(isMobile ? buttons.reverse() : buttons).map((button) => button)}</>;
});

export default ErrorFooter;
