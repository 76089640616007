import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';

enum NotificationPriority {
    HIGH = 'HIGH',
    MEDIUM = 'MEDIUM',
    LOW = 'LOW',
}

export enum ComplexUserNotificationsTemplateKey {
    ApplicantCallTracking = 'call.tracking.applicant.call.response',
    ApplicantFeedbackAboutVacancy = 'applicant.feedback.about.vacancy',
    ApplicantWorkAddress = 'applicant.specify.work.search.address',
    BillingCartActivated = 'billing.cart.activated',
    BillingCartActivationReady = 'billing.cart.activation.ready',
    BillingCartInsufficientFunds = 'billing.cart.insufficient.funds',
    BillingCartIrrelevantStatus = 'billing.cart.irrelevant.status',
    BillingCartError = 'billing.cart.system.error',
    BillingDelayedCartActivationForbidden = 'billing.delayed.cart.activation.forbidden',
    BillingDelayedCartActivationExpired = 'billing.delayed.cart.activation.expired',
    FillEmployerPageConstructor = 'branding.reminder.fill.employer.page.constructor',
    FillEmployerVacancyPageConstructor = 'branding.reminder.fill.vacancy.constructor',
    BuyVacancyPageConstructor = 'branding.suggestion.buy.vacancy.constructor',
    BuyEmployerPageConstructor = 'branding.suggestion.buy.employer.page.constructor',
    SuggestionVacancyMakeup = 'branding.suggestion.vacancy.makeup',
    SuggestionEmployerMakeup = 'branding.suggestion.employer.page.makeup',
    CallTracking = 'vacancy.call.tracking.tele2owners.warning',
    CareerConsultPaid = 'career.consult.paid',
    CareerConsultwaitingForCall = 'career.consult.waitingForCall',
    CompleteResumeWorkDone = 'complete.resume.workDone',
    CompleteResumePaid = 'complete.resume.paid',
    CompleteResumeWaitingForCall = 'complete.resume.waitingForCall',
    Employer2fa = 'employer.2fa.enabled.soon',
    EmployerReviewsCanEnable = 'employer.reviews.notify.employer.can.enable',
    EmployerReviewFeedbackCreated = 'employer.reviews.notify.feedback.created',
    EmployerSwitchToRoundLogo = 'employer.notify.switch.to.round.logo',
    EmployerYearResults = 'employer.year.results',
    HHRatingRegistration = 'hhrating.registration.invitation',
    HHRatingVoteInvitation = 'hhrating.vote.invitation',
    HRSpacePromoITEmployer = 'hrspace.promo.it.employer',
    HRSpacePromoEmployer = 'hrspace.promo.employer',
    HRSpacePromoApplicant = 'hrspace.promo.applicant',
    // tempexp_28607_next-line
    WorkCloseToHome = 'marketing.special.work.close.to.home',
    InterviewPracticePaid = 'interview.practice.paid',
    InterviewPracticeForCall = 'interview.practice.waitingForCall',
    NegotiationInterview = 'negotiation.interview',
    PricesIncrease = 'employer.prices.increase',
    ResumeBlocked = 'resume.blocked',
    ResumeTargetEmployerDiscount = 'resume.targetEmployer.discount',
    ResumeRenewalExpired = 'resume.renewal.expired',
    ResumeRenewalSoonExpires = 'resume.renewal.soonExpires',
    EmployerLoyaltySurvey = 'employer.loyaltySurvey',
    EmployerItAccreditation = 'employer.it.accreditation',
    UserSupportNewEvents = 'user.support.new.events',
    VideoCall = 'video.call.info',
    ApplicantJobSearchStatus = 'applicant.job.search.status',
    // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
    EmployerYearResult = 'employer.year.results',
    AutoSearchVacancies = 'new.vacancies.in.autosearch',
    VacancyAutoProlongation = 'vacancy.autoprolongation.couldbecanceled',
    VacancyAutoPublicationFailure = 'vacancy.auto.publication.no.quotas.failure',
    VacancyAutoPublicationNoPermission = 'vacancy.auto.publication.no.permission.failure',
    VacancyAutoPublicationNoPublication = 'vacancy.auto.publication.no.publication.failure',
    VacancyAutoPublicationValidationFailure = 'vacancy.auto.publication.validation.failure',
    VacancyAutoPublicationUnknown = 'vacancy.auto.publication.unknown.failure',
    VacancyAutoPublicationSuccesses = 'vacancy.auto.publication.successes',
    VacancyAutoPublicationSuccess = 'vacancy.auto.publication.success',
    VacancyAutoUpdateSuccesses = 'vacancy.auto.update.successes',
    VacancyAutoUpdateSuccess = 'vacancy.auto.update.success',
    VacancyAutoUpdateNoPublications = 'vacancy.auto.update.no.publications.checks',
    VacancyAutoUpdateNoQuota = 'vacancy.auto.update.no.quota.checks',
    VacancyAutoUpdateNoPermissions = 'vacancy.auto.update.no.permissions.checks',
    VacancyAutoUpdateNoPublication = 'vacancy.auto.update.no.publications.check',
    VacancyAutoUpdateNoQuotas = 'vacancy.auto.update.no.quota.check',
    VacancyAutoUpdateNoPermission = 'vacancy.auto.update.no.permissions.check',
    VacancyAutoUpdateNoQuotaFailure = 'vacancy.auto.update.no.quota.failure',
    VacancyAutoUpdateNoQuotaFailures = 'vacancy.auto.update.no.quota.failures',
    VacancyAutoUpdateNoPublicationFailure = 'vacancy.auto.update.no.publications.failure',
    VacancyAutoUpdateNoPublicationFailures = 'vacancy.auto.update.no.publications.failures',
    VacancyAutoUpdateNoPermissionFailure = 'vacancy.auto.update.no.permissions.failure',
    VacancyAutoUpdateNoPermissionFailures = 'vacancy.auto.update.no.permissions.failures',
    VacancyAutoUpdateTooMuchFailure = 'vacancy.auto.update.too.much.failure',
    VacancyAutoUpdateTooMuchFailures = 'vacancy.auto.update.too.much.failures',
    VacancyScheduledPublicationNoQuotasFailure = 'vacancy.scheduled.publication.no.quotas.failure',
    VacancyScheduledPublicationNoPermissionFailure = 'vacancy.scheduled.publication.no.permission.failure',
    VacancyScheduledPublicationNoPublicationFailure = 'vacancy.scheduled.publication.no.publication.failure',
    VacancyScheduledPublicationValidationFailure = 'vacancy.scheduled.publication.validation.failure',
    VacancyScheduledPublicationUnknownFailure = 'vacancy.scheduled.publication.unknown.failure',
    VacancyScheduledPublicationSuccesses = 'vacancy.scheduled.publication.successes',
    VacancyScheduledPublicationSuccess = 'vacancy.scheduled.publication.success',
    VacancyDraftPublicationReady = 'employer.vacancy.draft.publicationready',
    // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
    EmployerPricesIncrease = 'employer.prices.increase',
    EmployerVerified = 'employer.status.changed.to.approved',
    EmployerNotVerified = 'employer.status.changed.to.not.verified',
    ChangeEmployerManagerEmailConfirmationReminder = 'change.employer.manager.email.confirmation.reminder',
    ChangeEmployerManagerEmailChangedNotification = 'change.employer.manager.email.changed.notification',
    AdvertisementAgreement = 'advertisement.agreement',
    VacancyBlockedDuePaidServices = 'vacancy.blocked.due.paid.services',
    VacancyBlockedDueBadDescription = 'vacancy.blocked.due.bad.description',
    VacancyBlockedDueTwoInOne = 'vacancy.blocked.due.two.in.one',
    VacancyBlockedDueTraining = 'vacancy.blocked.due.training',
    VacancyBlockedDueCaptionMissing = 'vacancy.blocked.due.caption.missing',
    VacancyBlockedDueModerator = 'vacancy.blocked.due.moderator',
    VacancyBlockedDueRelocateFromBelarus = 'vacancy.blocked.due.relocate.from.belarus',
    VacancyBlockedDueReplacement = 'vacancy.blocked.due.replacement',
    AutoInviteDisabled = 'auto.invite.disabled',
    AutoInviteEnabled = 'auto.invite.enabled',
    KingdomOfVacancy = 'resume.kingdomOfVacancy',
    SetkaPromo = 'marketing.setka.promo.applicant',
    YouthSpecialProject = 'marketing.youth.special.project',
}

export enum SimpleUserNotificationsTemplateKey {
    SiteWasUnavailable = 'site_was_unavailable',
    GreetingsFromZarplata = 'greetings_from_zarplata',
    EmployerOverspendingInfo = 'employerOverspendingInfo',
    EmailVerificationCompleted = 'email_verification_completed',
    EmailVerificationError = 'email_verification_error',
    EmailVerificationErrorAgain = 'email_verification_error_again',
    EmailVerificationWasSent = 'email_verification_was_sent',
    EmailVerificationWasSentAgain = 'email_verification_was_sent_again',
    EmailVerificationWasSentAgainTooOften = 'email_verification_was_sent_again_too_often',
    EmailVerificationWasSentAgainError = 'email_verification_was_sent_again_error',
    ManagerQuotaChangeFail = 'manager_quota_change_fail',
    EsiaAccountNotLinked = 'esiaAccountNotLinked',
    OauthConnectInternalError = 'oauthConnectInternalError',
    SurveyWasIgnored = 'survey_was_ignored',
    SurveyWasFinished = 'survey_was_finished',
    PhoneHasBeenVerified = 'phone_has_been_verified',
    ManagerQuotaChangeSuccess = 'manager_quota_change_success',
    AllSurveysWereIgnored = 'all_surveys_were_ignored',
    AccountDeleted = 'account_deleted',
    ScheduleInterview = 'scheduleInterview',
    LoyaltySurvey = 'loyalty_survey',
    VacancyDraftSaved = 'showDraftSaveNotification',
    ResumeMergeSuccess = 'resume_merge_success',
    EmployerHhRatingResultsForEmployers = 'employerHhRatingResultsForEmployers',
    EmployerHhRatingResultsForApplicants = 'employerHhRatingResultsForApplicants',
    SkillVerificationExpiring = 'skillVerificationExpiring',
}

export type UserNotificationsParams = Record<string, string>;
export type UserNotificationId = number;
type UserNotificationIdList = UserNotificationId[];

export interface ComplexUserNotification {
    id: UserNotificationId;
    templateKey: ComplexUserNotificationsTemplateKey;
    notificationCode: string;
    objectId: string;
    creationTime: number | null;
    creationTimeIso: string | null;
    receivingTime: number | null;
    receivingTimeIso: string | null;
    deleteTime: number | null;
    deleteTimeIso: string | null;
    priority: NotificationPriority;
    viewed: boolean;
    params: UserNotificationsParams;
    isPriority: boolean;
    isSupernova: boolean;
    close?: VoidFunction;
}

export interface SimpleUserNotification {
    templateKey: SimpleUserNotificationsTemplateKey;
    params: UserNotificationsParams;
}

export type UserNotification = ComplexUserNotification | SimpleUserNotification;
export type UserNotifications = UserNotification[];

const DELETE_USER_NOTIFICATION = 'DELETE_USER_NOTIFICATION';
const MARK_AS_VIEWED_USER_NOTIFICATIONS = 'MARK_AS_VIEWED_USER_NOTIFICATIONS';
const ADD_USER_NOTIFICATIONS = 'ADD_USER_NOTIFICATIONS';

interface PayloadTypes {
    [DELETE_USER_NOTIFICATION]: UserNotificationId;
    [MARK_AS_VIEWED_USER_NOTIFICATIONS]: UserNotificationIdList;
    [ADD_USER_NOTIFICATIONS]: UserNotification[];
}

const simpleNotificationsKeys = Object.values(SimpleUserNotificationsTemplateKey);
export const isSimpleUserNotification = (notification: UserNotification): notification is SimpleUserNotification => {
    const keys: Array<string> = simpleNotificationsKeys;
    return keys.includes(notification.templateKey);
};

const complexNotificationsKeys = Object.values(ComplexUserNotificationsTemplateKey);
export const isComplexUserNotification = (notification: UserNotification): notification is ComplexUserNotification => {
    const keys: Array<string> = complexNotificationsKeys;
    return keys.includes(notification.templateKey);
};

const actionCreator = ActionCreatorHelper<PayloadTypes>();

export const deleteUserNotification = actionCreator(DELETE_USER_NOTIFICATION);
export const markUserNotificationsAsViewedByIds = actionCreator(MARK_AS_VIEWED_USER_NOTIFICATIONS);
export const addUserNotifications = actionCreator(ADD_USER_NOTIFICATIONS);

export default createReducer<UserNotifications, PayloadTypes>([], {
    [DELETE_USER_NOTIFICATION]: (state, { payload }) =>
        state.filter((notification) => isComplexUserNotification(notification) && notification.id !== payload),
    [MARK_AS_VIEWED_USER_NOTIFICATIONS]: (state, { payload }) =>
        state.map((notification) => {
            if (isComplexUserNotification(notification) && payload.includes(notification.id)) {
                return { ...notification, viewed: true };
            }

            return notification;
        }),
    [ADD_USER_NOTIFICATIONS]: (state, { payload }) => [...state, ...payload],
});
