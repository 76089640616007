import { useMemo } from 'react';

import { Badge, HSpacingContainer, Title, Link, VSpacing } from '@hh.ru/magritte-ui';
import { Link as SpaLink } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import paths from 'src/app/routePaths';
import { NovaFilterSetting } from 'src/components/Search/Common/Filters/utils';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { NovaFilterKey } from 'src/models/novaFilters';

import styles from './styles.less';

const TrlKeys = {
    filterName: 'novafilters.similarSavedSearch.filter',
    clear: 'novafilters.similarSavedSearch.clear',
    explanation: 'novafilters.similarSavedSearch.explanation',
    confirm: 'novafilters.similarSavedSearch.confirm',
};

const TotalFiltersCount: TranslatedComponent = ({ trls }) => {
    const resumeCluster = useSelector((state) => state.searchClusters[NovaFilterKey.Resume]);
    const hash = useMemo(
        () => (resumeCluster && resumeCluster.selectedValues.length > 0 ? resumeCluster.selectedValues[0] : ''),
        [resumeCluster]
    );

    const totalUsedFilters = useSelector((state) => state.vacancySearchResult.totalUsedFilters);

    return (
        <>
            <div className={styles.filtersCountContainer}>
                <HSpacingContainer default={12}>
                    <Title Element="h3" size="small">
                        {trls[TrlKeys.filterName]}
                    </Title>
                    {!!totalUsedFilters && (
                        <div className={styles.filtersCount}>
                            <Badge>{`${totalUsedFilters}`}</Badge>
                        </div>
                    )}
                </HSpacingContainer>
                {!!totalUsedFilters && (
                    <Link
                        to={`${paths.vacancySearch}?resume=${hash}&${NovaFilterSetting.ForceFiltersSaving}=true`}
                        Element={SpaLink}
                    >
                        {trls[TrlKeys.clear]}
                    </Link>
                )}
            </div>
            <VSpacing default={12} />
        </>
    );
};

export default translation(TotalFiltersCount);
