import { ExperienceOption } from 'src/models/search/searchCriteria.types';
import { VacancyCompensation } from 'src/models/vacancy/compensation.type';
import { VacancyViewMetroStation } from 'src/models/vacancyView.types';
import { EmploymentLower } from 'src/utils/constants/employment';
import { ScheduleCamelCase } from 'src/utils/constants/schedule';

export enum RegionTypes {
    Current = 'currentRegion',
    Other = 'otherRegions',
}

export const REGION_PROP_NAMES = {
    [RegionTypes.Current]: 'CURRENT',
    [RegionTypes.Other]: 'OTHER',
};

interface Company {
    name: string;
    id: number;
    employerOrganizationFormId: number;
    department: {
        code: string;
    } | null;
    visibleName: string;
}

export interface Address {
    metroStations?: {
        metro: VacancyViewMetroStation[];
    } | null;
}
export interface Vacancy {
    vacancyId: number;
    name: string;
    company: Company;
    insider: {
        isVisible: boolean;
        id: number;
    } | null;
    publicationTime: {
        $: string;
        '@timestamp': number;
    };
    area: {
        name: string;
    };
    address: Address | null;
    links: {
        desktop: string;
    };
    compensation: VacancyCompensation;
}

export interface ProfAreaCounts {
    id: number;
    profAreaId: number;
    count: number;
    hasNextPage: boolean;
    name: string;
    vacancies: Array<Vacancy>;
    currentPage?: number;
    expanded?: boolean;
    firstPageLoaded?: boolean;
}

interface CountsByProfCategory {
    profCategoryId: number;
    count: number;
    hasNextPage: boolean;
    name: string;
    vacancies: Array<Vacancy>;
    currentPage?: number;
    expanded?: boolean;
    firstPageLoaded?: boolean;
}

export interface CountsByProfRole {
    profRoleId: number;
    count: number;
    hasNextPage: boolean;
    name: string;
    vacancies: Array<Vacancy>;
    currentPage?: number;
    expanded?: boolean;
    firstPageLoaded?: boolean;
}

export interface Region {
    name?: string;
    areaId?: number;
    count?: number;
    countsByProfArea?: ProfAreaCounts[];
    countsByProfCategory?: CountsByProfCategory[];
    countsByProfRole?: CountsByProfRole[];
    vacancies?: Array<Vacancy>;
}

export interface VacancyGroupsState {
    count?: number;
    [RegionTypes.Current]: Region;
    [RegionTypes.Other]?: Region;
}

interface PayloadData {
    profRoleId?: number;
    profCategoryId?: number;
    regionType: RegionTypes;
}
export interface DataResponseVacancies {
    count?: number;
    hasNextPage: boolean;
    vacancies: Array<Vacancy>;
}

export interface DataCurrentPage extends DataResponseVacancies {
    currentPage?: number;
}

export interface VacanciesToProfRole extends PayloadData {
    data: DataCurrentPage;
}

export interface ProfRoleExpanded extends PayloadData {
    expanded: boolean;
    currentPage?: number;
}

export interface FirstPageLoadedWithRoles extends PayloadData {
    profRoleId: number;
    regionType: RegionTypes;
}

export interface VacanciesGroup {
    count?: number;
    countsByProfArea?: ProfAreaCounts[];
    countsByProfCategory?: CountsByProfCategory[];
    countsByProfRole?: CountsByProfRole[];
    vacancies?: Array<Vacancy>;
}

export interface FilterParams {
    siteId?: string;
    profArea?: string;
    regionType?: RegionTypes;
    groupByRegion?: boolean;
    currentEmployerId: number;
    area?: string[];
    metro?: string;
    employment?: EmploymentLower[];
    schedule?: ScheduleCamelCase[];

    professional_role?: string;
    salary?: string;

    currency_code?: string;
    experience?: ExperienceOption[];
    text?: string;
    department?: string;
}

export interface PayloadFilterParams {
    detail: FilterParams;
}
