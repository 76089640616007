import { ReactElement } from 'react';

export enum ChipsSelectType {
    Checkbox = 'checkbox',
    Radio = 'radio',
}

export interface ChipsOption<T> {
    id: T;
    title: ReactElement | string;
    addon?: ReactElement;
}
