import { FC } from 'react';

const MetroLineIcon: FC<{ color: string; containerSize?: 22 | 24 }> = ({ color, containerSize = 24 }) => {
    const width = 6;
    const height = 16;
    return (
        <svg
            width={containerSize}
            height={containerSize}
            viewBox={`0 0 ${containerSize} ${containerSize}`}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                x={containerSize / 2 - width / 2}
                y={containerSize / 2 - height / 2}
                width={width}
                height={height}
                rx={width / 2}
                fill={color}
            />
        </svg>
    );
};

export default MetroLineIcon;
