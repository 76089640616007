import { Title, useBreakpoint } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import StaticImg from 'src/components/StaticImg';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

import styles from './footer-mobile-banner-redesign.less';

const TrlKeys = {
    title: 'mobile.index.qr.title',
    subtitle: 'mobile.index.qr.subtitle',
    subtitleVacancies: 'mobile.index.qr.subtitle.vacancies',
};

const AppBannerQR: TranslatedComponent = ({ trls }) => {
    const { isGtXS } = useBreakpoint();
    const isAnonymousMagritteExp = useSelector((state) => state.isAnonymousMagritteExp);

    return (
        <div className={styles.footerAppBannerRedesignQr}>
            <div className={styles.footerAppBannerRedesignQrImageContainer}>
                <StaticImg className={styles.footerAppBannerRedesignQrImage} path="/images/hh/appbanner/qr_app.png" />
            </div>
            <Title
                Element="h2"
                size="medium"
                alignment={isGtXS ? 'left' : 'center'}
                description={trls[isAnonymousMagritteExp ? TrlKeys.subtitleVacancies : TrlKeys.subtitle]}
                descriptionStyle={isAnonymousMagritteExp ? 'primary' : 'secondary'}
            >
                {trls[TrlKeys.title]}
            </Title>
        </div>
    );
};

export default translation(AppBannerQR);
