import { Fragment, useRef, useEffect, useState } from 'react';

import { Cell, CellText, Text as MagritteText, Link, Drop, BottomSheet, useBreakpoint } from '@hh.ru/magritte-ui';
import { BriefcaseOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import Conversion from 'bloko/blocks/conversion';
import Text, { TextImportance, TextSize } from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { format } from 'bloko/common/trl';

import paths from 'src/app/routePaths';
import indexPageEmployerAnalytics from 'src/components/IndexPageEmployer/components/analytics';
import translation from 'src/components/translation';
import useFeatureEnabled from 'src/hooks/useFeatureEnabled';
import { useIsZarplataPlatform } from 'src/hooks/usePlatform';
import { useSelector } from 'src/hooks/useSelector';
import { CountryId } from 'src/models/countryId';
import { ServiceEmployerDescription } from 'src/models/oldEmployer/employerBillingSummary';
import { publicationsCodes, ServiceItemCode } from 'src/models/price/productCodes';

const Features = {
    employerMainPageAnalyticsEnabled: 'employer_main_page_analytics_enabled',
};

const TrlKeys = {
    vacancies: 'index.employer.billing.vacancies',
    buy: 'index.employer.billing.buy',
    regionTitle: 'employer.service.regions.title',
    anyRegion: 'any.region',
    profRoleGroupTitle: 'employer.service.profRoleGroup.title',
    anyProfRoleGroup: 'any.profRoleGroup',
    zpCrossPosting: 'zarplata.crossposting',
    newWillBeAdded: 'employer.accountHistory.freeServices.newWillBeAdded',
    newWillBeAvailable: 'index.employer.billing.newWillBeAvailable',
    freeRegion: 'price.purchased.freeRegions.RFPN',
    genitive: {
        month: {
            1: 'genitive.month.1',
            2: 'genitive.month.2',
            3: 'genitive.month.3',
            4: 'genitive.month.4',
            5: 'genitive.month.5',
            6: 'genitive.month.6',
            7: 'genitive.month.7',
            8: 'genitive.month.8',
            9: 'genitive.month.9',
            10: 'genitive.month.10',
            11: 'genitive.month.11',
            12: 'genitive.month.12',
        },
    },
    days: {
        one: 'index.employer.billing.days.one',
        some: 'index.employer.billing.days.some',
        many: 'index.employer.billing.days.many',
    },
};

const BalanceVacancies: TranslatedComponent = ({ trls }) => {
    const employerMainPageAnalyticsEnabled = useFeatureEnabled(Features.employerMainPageAnalyticsEnabled);
    const employerIndexPage = useSelector(({ employerIndexPage }) => employerIndexPage);
    const isZp = useIsZarplataPlatform();
    const vacanciesLinkRef = useRef(null);
    const vacanciesBuyLinkRef = useRef(null);
    const countryIdBySeller = useSelector(({ employerCountryIdBySeller }) => employerCountryIdBySeller?.toString());
    const [dropOpen, setDropOpen] = useState(false);
    const { isMobile } = useBreakpoint();
    const rootRef = useRef(null);

    const isRussia = (countryId: number) => countryId === parseInt(CountryId.Russia, 10);

    const getPricePurchasedItemRegions = (item: ServiceEmployerDescription) => {
        const { code, count, areaIds, areaIdsTrl } = item;

        if (
            code === ServiceItemCode.FreeRegionalVacancy &&
            count &&
            parseInt(count, 10) > 0 &&
            countryIdBySeller !== CountryId.Belarus
        ) {
            return (
                <>
                    <VSpacing base={1} />
                    <Text size={TextSize.Small} importance={TextImportance.Tertiary}>
                        {trls[TrlKeys.freeRegion]}
                    </Text>
                </>
            );
        }

        return (
            <>
                <VSpacing base={1} />
                <Text size={TextSize.Small} importance={TextImportance.Tertiary}>
                    {areaIds && areaIds?.length > 0 ? (
                        <>
                            {`${trls[TrlKeys.regionTitle]}: `}
                            {areaIdsTrl?.map((trl, index) => (
                                <Fragment key={trl}>
                                    <span data-qa="price-purchased-item-region">{trl}</span>
                                    {index < areaIdsTrl.length - 1 && ', '}
                                </Fragment>
                            ))}
                        </>
                    ) : (
                        trls[TrlKeys.anyRegion]
                    )}
                </Text>
            </>
        );
    };

    const getPricePurchasedItemProfareas = (item: ServiceEmployerDescription) => {
        const { profRoleGroupIds, profRoleGroupIdsTrl } = item;

        return (
            <>
                <VSpacing base={1} />
                <Text size={TextSize.Small} importance={TextImportance.Tertiary}>
                    {profRoleGroupIds?.length > 0 ? (
                        <>
                            {`${trls[TrlKeys.profRoleGroupTitle]}: `}
                            {profRoleGroupIdsTrl?.map((trl, index) => (
                                <Fragment key={trl}>
                                    <span data-qa="price-purchased-item-profarea">{trl}</span>
                                    {index < profRoleGroupIdsTrl.length - 1 && ', '}
                                </Fragment>
                            ))}
                        </>
                    ) : (
                        trls[TrlKeys.anyProfRoleGroup]
                    )}
                </Text>
            </>
        );
    };

    const getPricePurchasedItemZPCrossPost = (item: ServiceEmployerDescription) =>
        item.zpCrossPostAvailable ? (
            <>
                <VSpacing base={1} />
                <Text size={TextSize.Small} importance={TextImportance.Tertiary}>
                    {trls[TrlKeys.zpCrossPosting]}
                </Text>
            </>
        ) : null;

    const getPricePurchasedVacancyNextUpdate = (
        nextFreeUpdate: ServiceEmployerDescription['nextFreeUpdate'],
        countryId: number
    ) => {
        if (isRussia(countryId) || !nextFreeUpdate) {
            return null;
        }

        if (nextFreeUpdate.remainingDays === 0) {
            return (
                <>
                    <VSpacing base={2} />
                    <Text>{trls[TrlKeys.newWillBeAvailable]}</Text>
                </>
            );
        }

        if (nextFreeUpdate.remainingDays > 0) {
            return (
                <>
                    <VSpacing base={2} />
                    <Text>
                        <Conversion
                            one={trls[TrlKeys.days.one]}
                            some={trls[TrlKeys.days.many]}
                            many={trls[TrlKeys.days.some]}
                            value={nextFreeUpdate.remainingDays}
                            hasValue={false}
                            format={(trl) =>
                                format(trls[TrlKeys.newWillBeAvailable], {
                                    '{0}': nextFreeUpdate.remainingDays,
                                    '{1}': trl,
                                })
                            }
                        />
                    </Text>
                </>
            );
        }

        return (
            <>
                <VSpacing base={2} />
                <Text>
                    {`${trls[TrlKeys.newWillBeAdded]} ${nextFreeUpdate.day} ${
                        trls[TrlKeys.genitive.month[nextFreeUpdate.month]]
                    } ${nextFreeUpdate.year}`}
                </Text>
            </>
        );
    };

    const getPurchasedVacancyPublicationsItem = (item: ServiceEmployerDescription, countryId: number) => {
        const { code, count, nextFreeUpdate, serviceEntryTrl } = item;

        if (code === ServiceItemCode.FreeRegionalVacancy && count && parseInt(count, 10) === 0 && isRussia(countryId)) {
            return null;
        }

        return (
            <div className="purchased-service" data-qa={`purchased-service-${code}`}>
                <div className="purchased-service-name">
                    <Text>{serviceEntryTrl}</Text>
                    {getPricePurchasedItemRegions(item)}
                    {getPricePurchasedItemProfareas(item)}
                    {getPricePurchasedItemZPCrossPost(item)}
                    {getPricePurchasedVacancyNextUpdate(nextFreeUpdate, countryId)}
                </div>
                <div className="purchased-service-count">{count}</div>
            </div>
        );
    };

    const getPurchasedVacancyPublicationsList = () => {
        if (employerIndexPage.oldEmployer) {
            const { serviceDescription, countryId } = employerIndexPage.oldEmployer.employer;
            return (
                <div className="purchased-services">
                    {serviceDescription
                        .filter(
                            (service) =>
                                publicationsCodes.includes(service.code) && service.code !== ServiceItemCode.ZpDemo
                        )
                        .map((service, index) => (
                            <Fragment key={index}>{getPurchasedVacancyPublicationsItem(service, countryId)}</Fragment>
                        ))}
                </div>
            );
        }

        return null;
    };

    const freeVacancyPublicationsCodesCount =
        employerIndexPage?.oldEmployer?.billingSummary?.serviceCategories?.freeVacancyPublicationsCodes?.count ?? 0;

    const nonFreeVacancyPublicationsCodesCount =
        employerIndexPage?.oldEmployer?.billingSummary?.serviceCategories?.nonFreeVacancyPublicationsCodes?.count ?? 0;

    const vacanciesCount = isZp
        ? nonFreeVacancyPublicationsCodesCount
        : freeVacancyPublicationsCodesCount + nonFreeVacancyPublicationsCodesCount;

    useEffect(() => {
        if (employerMainPageAnalyticsEnabled) {
            if (vacanciesLinkRef.current) {
                indexPageEmployerAnalytics.myEventsVacanciesShown(vacanciesLinkRef.current);
            }
            if (vacanciesBuyLinkRef.current) {
                indexPageEmployerAnalytics.myEventsVacanciesBuyShown(vacanciesBuyLinkRef.current);
            }
        }
    }, [employerMainPageAnalyticsEnabled]);

    if (vacanciesCount > 0) {
        return (
            <div ref={rootRef}>
                <Cell
                    right={
                        <MagritteText typography="label-2-regular" style="secondary">
                            {vacanciesCount}
                        </MagritteText>
                    }
                    left={<BriefcaseOutlinedSize24 />}
                    data-qa="my-events-publications"
                    onClick={() => {
                        setDropOpen((open) => !open);
                    }}
                >
                    <a
                        ref={vacanciesLinkRef}
                        data-qa="my-events-publications-name"
                        onClick={
                            employerMainPageAnalyticsEnabled
                                ? indexPageEmployerAnalytics.myEventsVacanciesClick
                                : undefined
                        }
                    >
                        <CellText>{trls[TrlKeys.vacancies]}</CellText>
                    </a>
                </Cell>

                <Drop
                    activatorRef={rootRef}
                    placement="right-top"
                    visible={dropOpen}
                    onClose={() => {
                        setDropOpen(false);
                    }}
                >
                    {getPurchasedVacancyPublicationsList()}
                </Drop>

                <BottomSheet
                    visible={isMobile && dropOpen}
                    onClose={() => {
                        setDropOpen(false);
                    }}
                >
                    {getPurchasedVacancyPublicationsList()}
                </BottomSheet>
            </div>
        );
    }

    const linkAddPublicationUrl = isZp ? paths.priceZpPublications : paths.priceRegionalPublications;
    return (
        <Cell
            data-qa="my-events-publications-access"
            right={
                <div ref={vacanciesBuyLinkRef}>
                    <Link
                        href={linkAddPublicationUrl}
                        data-qa="my-events-publications-access-buy"
                        onClick={
                            employerMainPageAnalyticsEnabled
                                ? indexPageEmployerAnalytics.myEventsVacanciesBuyClick
                                : undefined
                        }
                    >
                        {trls[TrlKeys.buy]}
                    </Link>
                </div>
            }
            left={<BriefcaseOutlinedSize24 />}
        >
            <a
                ref={vacanciesLinkRef}
                href={linkAddPublicationUrl}
                data-qa="my-events-publications-access-name"
                onClick={
                    employerMainPageAnalyticsEnabled ? indexPageEmployerAnalytics.myEventsVacanciesClick : undefined
                }
            >
                <CellText>{trls[TrlKeys.vacancies]}</CellText>
            </a>
        </Cell>
    );
};

export default translation(BalanceVacancies);
