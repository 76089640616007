import { ComponentType } from 'react';

import Notification, { NotificationKind } from 'bloko/blocks/notificationManager/Notification';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import SynchronizedNotification from 'src/components/UserNotifications/common/SynchronizedNotification';
import { SimpleNotificationComponentProps } from 'src/components/UserNotifications/common/notificationComponentProps';
import translation from 'src/components/translation';

const TrlKeys = {
    email_verification_was_sent_again_too_often: 'notifications.email_verification_was_sent_again_too_often',
    email_verification_was_sent_again_error: 'notifications.email_verification_was_sent_again_error',
    manager_quota_change_fail: 'notifications.manager_quota_change_fail',
    esiaAccountNotLinked: 'notifications.esiaAccountNotLinked',
    oauthConnectInternalError: 'notifications.oauth_connect_internal_error',
    email_verification_error: 'notifications.email_verification_error',
    email_verification_completed: 'notifications.email_verification_completed',

    survey_was_ignored: 'notifications.survey_was_ignored',
    survey_was_finished: 'notifications.survey_was_finished',
    phone_has_been_verified: 'notifications.phone_has_been_verified',
    manager_quota_change_success: 'notifications.manager_quota_change_success',
    all_surveys_were_ignored: 'notifications.all_surveys_were_ignored',
    account_deleted: 'notifications.account_deleted',
    resume_merge_success: 'resume_merge.notification.success',
};

const baseNotificationFactory = ({
    error = false,
    autoClose = false,
    synchronized = true,
} = {}): ComponentType<SimpleNotificationComponentProps> => {
    const NotificationComponent = synchronized ? SynchronizedNotification : Notification;

    const BaseNotification: TranslatedComponent<SimpleNotificationComponentProps> = ({
        trls,
        onClose,
        templateKey,
        ...notification
    }) => (
        <NotificationComponent
            kind={error ? NotificationKind.Error : NotificationKind.Ok}
            onClose={onClose}
            notification={{ templateKey, ...notification }}
            autoClose={autoClose}
        >
            {trls[TrlKeys[templateKey as keyof typeof TrlKeys]]}
        </NotificationComponent>
    );

    return translation(BaseNotification);
};

export default baseNotificationFactory;
