import { LangTrls, TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { CurrencyType } from 'src/models/currencies.types';

interface CurrencyProps {
    /** Префикс, добавляющийся перед выводом валюты*/
    prefix?: string;
    /** Валюта*/
    value?: CurrencyType;
    /** Выводить полное название валюты */
    fullLabel?: boolean;
    /** Выводить название валюты с большой буквы */
    capitalizeFirstLetter?: boolean;
}

const TrlKeys = {
    [CurrencyType.AZN]: 'AZN',
    [CurrencyType.BYR]: 'BYR',
    [CurrencyType.EUR]: 'EUR',
    [CurrencyType.KZT]: 'KZT',
    [CurrencyType.RUR]: 'RUR',
    [CurrencyType.UAH]: 'UAH',
    [CurrencyType.USD]: 'USD',
    [CurrencyType.UZS]: 'UZS',
    [CurrencyType.GEL]: 'GEL',
    [CurrencyType.KGS]: 'KGS',
    fullLabel: {
        [CurrencyType.AZN]: 'currency.AZN.label',
        [CurrencyType.BYR]: 'currency.BYR.label',
        [CurrencyType.EUR]: 'currency.EUR.label',
        [CurrencyType.KZT]: 'currency.KZT.label',
        [CurrencyType.RUR]: 'currency.RUR.label',
        [CurrencyType.UAH]: 'currency.UAH.label',
        [CurrencyType.USD]: 'currency.USD.label',
        [CurrencyType.UZS]: 'currency.UZS.label',
        [CurrencyType.GEL]: 'currency.GEL.label',
        [CurrencyType.KGS]: 'currency.KGS.label',
    },
    fullLabelCapitalize: {
        [CurrencyType.AZN]: 'currency.AZN.label.capitalize',
        [CurrencyType.BYR]: 'currency.BYR.label.capitalize',
        [CurrencyType.EUR]: 'currency.EUR.label.capitalize',
        [CurrencyType.KZT]: 'currency.KZT.label.capitalize',
        [CurrencyType.RUR]: 'currency.RUR.label.capitalize',
        [CurrencyType.UAH]: 'currency.UAH.label.capitalize',
        [CurrencyType.USD]: 'currency.USD.label.capitalize',
        [CurrencyType.UZS]: 'currency.UZS.label.capitalize',
        [CurrencyType.GEL]: 'currency.GEL.label.capitalize',
        [CurrencyType.KGS]: 'currency.KGS.label.capitalize',
    },
};

export const getCurrencyLabel = ({
    fullLabel,
    trls,
    value,
    capitalizeFirstLetter,
}: Omit<CurrencyProps, 'value'> & { value: CurrencyType; trls: LangTrls }): string => {
    if (fullLabel) {
        return trls[capitalizeFirstLetter ? TrlKeys.fullLabelCapitalize[value] : TrlKeys.fullLabel[value]];
    }
    return trls[TrlKeys[value]];
};

const Currency: TranslatedComponent<CurrencyProps> = ({
    trls,
    prefix,
    value,
    fullLabel = false,
    capitalizeFirstLetter = false,
}) => {
    if (!value) {
        return null;
    }

    return (
        <>
            {prefix || ''}
            {getCurrencyLabel({ trls, fullLabel, value, capitalizeFirstLetter })}
        </>
    );
};

export default translation(Currency);
