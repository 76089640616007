import Analytics, { useElementShown } from '@hh.ru/analytics-js';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { formatToReactComponent } from 'bloko/common/trl';

import { SupernovaUserNotificationsIconKind } from 'src/components/SupernovaMainMenu/UserNotifications/Icon';
import NotificationLink from 'src/components/SupernovaMainMenu/UserNotifications/Link';
import Notification from 'src/components/SupernovaMainMenu/UserNotifications/Notification';
import NotificationSpacer from 'src/components/SupernovaMainMenu/UserNotifications/Spacer';
import translation from 'src/components/translation';
import { ComplexUserNotification } from 'src/models/userNotifications';

const TrlKeys = {
    title: 'supernova.notification.menu.employer.roundLogo.title',
    description: 'supernova.notification.menu.employer.roundLogo.description',
    upgrade: 'supernova.notification.menu.employer.roundLogo.description.upgrade',
};

const EmployerSwitchToRoundLogo: TranslatedComponent<ComplexUserNotification> = ({ trls, ...notification }) => {
    const notificationShownRef = useElementShown(Analytics.sendHHEventElementShown, {
        name: notification.templateKey.split('.').join('_'),
    });

    return (
        <Notification {...notification} iconKind={SupernovaUserNotificationsIconKind.ExclamationCircle}>
            <NotificationSpacer>
                <b>{trls[TrlKeys.title]}</b>
            </NotificationSpacer>
            <NotificationSpacer>
                <p ref={notificationShownRef}>
                    {formatToReactComponent(trls[TrlKeys.description], {
                        '{0}': (
                            <NotificationLink
                                to="/employer/edit/simple?fromRoundLogoNotification=true"
                                target="_blank"
                                onClick={() =>
                                    Analytics.sendHHEventButtonClick(notification.templateKey.split('.').join('_'))
                                }
                            >
                                {trls[TrlKeys.upgrade]}
                            </NotificationLink>
                        ),
                    })}
                </p>
            </NotificationSpacer>
        </Notification>
    );
};

export default translation(EmployerSwitchToRoundLogo);
