import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

export interface TestsLibrary {
    category: number;
    pageNumber: number;
    pageSize: number;
    totalSize: number;
    tests: {
        commonTestId: number;
        copied: boolean;
        copyright: boolean;
        description: string;
        extraDescription: string;
        guid: string;
        modifiable: boolean;
        name: string;
        uidPk: number;
    }[];
}

export default autoGeneratedReducer<TestsLibrary>(null);
