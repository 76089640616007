import { useCallback } from 'react';
import { matchPath } from 'react-router';
import { useLocation } from 'react-router-dom';

import paths from 'src/app/routePaths';

import { useSelector } from 'src/hooks/useSelector';

interface BreadcrumbSearchData {
    resumeUrl: string;
    resumeTitle: string;
}

type CreatePath = () => string;

type GetResumeData = () => BreadcrumbSearchData | null;

const BREADCRUMB_SEARCH_DATA_KEYS = {
    RESUME_URL: 'resumeUrl',
    RESUME_TITLE: 'resumeTitle',
};

function createBreadcrumbsSearch(data: BreadcrumbSearchData) {
    const searchParams = new URLSearchParams();
    const { resumeUrl, resumeTitle } = data;

    searchParams.append(BREADCRUMB_SEARCH_DATA_KEYS.RESUME_URL, resumeUrl);

    searchParams.append(BREADCRUMB_SEARCH_DATA_KEYS.RESUME_TITLE, resumeTitle);

    return `?${searchParams.toString()}`;
}

const { resumeViewByID, resumeView, applicantSkillVerificationsMethods } = paths;

const supportedPathnames = {
    resume: [resumeViewByID, resumeView],
    skillsVerificationMethods: [applicantSkillVerificationsMethods],
};

function useSkillVerificationMethodsBreadcrumbs() {
    const resume = useSelector((state) => state.resume);
    const location = useLocation();

    const { pathname: locationPathname, search: locationSearch } = location ?? {};

    const isMatchResumeLocation = supportedPathnames.resume.some((url) =>
        matchPath(locationPathname, { path: url, exact: true })
    );
    const isMatchSkillsLocation = supportedPathnames.skillsVerificationMethods.some((url) =>
        matchPath(locationPathname, { path: url, exact: true })
    );

    const createSkillVerificationMethodsPath = useCallback<CreatePath>(() => {
        const skillsVerificationMethodPath = paths.applicantSkillVerificationsMethods;

        let searchParams = '';

        if (isMatchResumeLocation && resume) {
            const { title } = resume;
            const resumeUrl = `${locationPathname}${locationSearch}`;
            const resumeTitle = title.value ?? '';

            searchParams = createBreadcrumbsSearch({ resumeUrl, resumeTitle });
        } else if (isMatchSkillsLocation) {
            searchParams = locationSearch;
        }

        return `${skillsVerificationMethodPath}${searchParams}`;
    }, [isMatchResumeLocation, isMatchSkillsLocation, locationPathname, locationSearch, resume]);

    const getResumePageData = useCallback<GetResumeData>(() => {
        const searchParams = new URLSearchParams(locationSearch);

        const resumeUrl = searchParams.get(BREADCRUMB_SEARCH_DATA_KEYS.RESUME_URL);
        const resumeTitle = searchParams.get(BREADCRUMB_SEARCH_DATA_KEYS.RESUME_TITLE);

        if (!(resumeUrl && resumeTitle)) {
            return null;
        }

        return {
            resumeUrl,
            resumeTitle,
        };
    }, [locationSearch]);

    return {
        getResumePageData,
        createSkillVerificationMethodsPath,
    };
}

export default useSkillVerificationMethodsBreadcrumbs;
