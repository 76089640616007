import { FC } from 'react';

import FormatMoney from 'src/components/FormatMoney';
import { type ResponseStatusResume } from 'src/models/applicantVacancyResponseStatuses';
import { NON_BREAKING_SPACE } from 'src/utils/constants/symbols';

interface ResumeDetailProps {
    resume: ResponseStatusResume;
}

const ResumeDetail: FC<ResumeDetailProps> = ({ resume }) => {
    const areaTitle = resume?.area?.[0]?.title;
    const salary = resume?.salary?.[0];

    return (
        <>
            {areaTitle}
            {areaTitle && salary ? [NON_BREAKING_SPACE, '•', NON_BREAKING_SPACE].join('') : null}
            {salary ? <FormatMoney currency={salary.currency}>{salary.amount}</FormatMoney> : null}
        </>
    );
};

export default ResumeDetail;
