import { useMemo } from 'react';

import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { fromTree } from 'bloko/common/tree/treeCollectionHelper';

import MobileViewControl from 'src/components/Search/Common/Filters/MobileViewControl';
import { useApplyFilter } from 'src/components/Search/Common/Filters/hooks/useApplyFilter';
import ChildTree from 'src/components/Search/Vacancy/Filters/DepricatedCustomTreeSelector/ChildTree';
import ParentTree from 'src/components/Search/Vacancy/Filters/DepricatedCustomTreeSelector/ParentTree';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { NovaFilterKey } from 'src/models/novaFilters';

import IndustryMobile from 'src/components/Search/Vacancy/Filters/IndustryAndSubIndustry/IndustryMobile';

const EMPTY_STRING_ARRAY: Array<string> = [];

const TrlKeys = {
    industry: 'resumeBuilder.industry',
    subIndustry: 'clusters.subindustry',
};

const IndustryAndSubIndustry: TranslatedComponent = ({ trls }) => {
    const applyFilter = useApplyFilter(NovaFilterKey.Industry);
    const industryTree = useSelector((state) => state.industriesTree);
    const industryTreeCollection = useMemo(() => fromTree(industryTree), [industryTree]);

    const industry = useSelector((state) => state.searchClusters[NovaFilterKey.Industry]);
    const subIndustry = useSelector((state) => state.searchClusters[NovaFilterKey.SubIndustry]);
    const industryOrder =
        useSelector((state) => state.searchClustersOrder?.[NovaFilterKey.Industry]) || EMPTY_STRING_ARRAY;
    const subIndustryOrder =
        useSelector((state) => state.searchClustersOrder?.[NovaFilterKey.SubIndustry]) || EMPTY_STRING_ARRAY;

    return (
        <MobileViewControl
            mobileView={
                <IndustryMobile
                    title={trls[TrlKeys.industry]}
                    initialValues={industry.selectedValues}
                    collection={industryTreeCollection}
                />
            }
        >
            <ParentTree
                title={trls[TrlKeys.industry]}
                setCheckedValuesWithOnChange={applyFilter}
                treeCollection={industryTreeCollection}
                selectedValues={industry.selectedValues}
                parentFilter={industry}
                childrenFilter={subIndustry}
                order={industryOrder}
            />
            <ChildTree
                title={trls[TrlKeys.subIndustry]}
                selectedValues={industry.selectedValues}
                setCheckedValuesWithOnChange={applyFilter}
                parentFilter={industry}
                childrenFilter={subIndustry}
                parentField="industryId"
                childrenOrder={subIndustryOrder}
            />
        </MobileViewControl>
    );
};

export default translation(IndustryAndSubIndustry);
