import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

interface EmployerChangePassword {
    permissions: {
        canChangeEmail?: boolean;
        canChangePassword?: boolean;
        canCreatePassword?: boolean;
    };
}

export default autoGeneratedReducer<EmployerChangePassword>(null);
