import { ReactNode, type ReactElement } from 'react';

import { JobSearchStatus } from 'src/models/applicantUserStatuses';

export enum ModalStep {
    FoundOnHHStep = 'found-on-hh-step',
    ChooseOfferStep = 'choose-offer-step',
    ChooseReasonStep = 'choose-reason-step',
}

export enum ReasonVariants {
    WithoutResponse = 'got_message',
    ContactedByMyself = 'message_myself',
    Other = 'other',
}

export enum FoundOnHHVariants {
    FoundOnHH = 'found_on_hh',
    NotFoundOnHH = 'not_found_on_hh',
}

export interface Offer {
    employerId: number;
    vacancyId: number;
    resumeId: number;
}

export interface StepContent {
    [ModalStep.FoundOnHHStep]: {
        content: ReactNode;
    };
    [ModalStep.ChooseReasonStep]: {
        content: ReactNode;
    };
    [ModalStep.ChooseOfferStep]: {
        content: ReactNode;
        buttons: {
            [JobSearchStatus.HasJobOffer]: ReactElement;
            others: ReactElement;
        };
    };
}

export type FoundOnHHValue = FoundOnHHVariants | null;

export type ReasonValue = ReasonVariants | null;

export type FetchOffersFunction = (args: { setModalStep: (step: ModalStep) => void }) => void;
export type AddOfferFunction = (employerId: number, vacancyId: number, resumeId: number) => void;

export type UseFoundOnHHStep = (params: {
    fetchOffers: FetchOffersFunction;
    closeModal: () => void;
    setModalStep: (step: ModalStep) => void;
}) => {
    foundOnHHValue: FoundOnHHValue;
    updateFoundOnHHValue: (value: FoundOnHHValue) => void;
};

export type UseReasonStep = (params: { closeModal: () => void }) => {
    reasonValue: ReasonValue;
    updateReasonValue: (value: ReasonValue) => void;
};

export type UseOffersStep = (params: { userStatus: JobSearchStatus; closeModal: () => void }) => {
    selectedOffers: Offer[];
    addOffer: AddOfferFunction;
    finishChooseOffers: (offers: Offer[]) => void;
    updateSelectedOffers: (offers: Offer[]) => void;
};
