import { ResumeIdResponseStatuses } from 'src/hooks/useResumeIdResponseStatuses';

export enum RespondedText {
    Chat = 'chat',
}

interface ResponsedProps {
    text: RespondedText;
    chatId?: number;
}

export const getRespondedProps = (resumeIdResponseStatuses: ResumeIdResponseStatuses | null): ResponsedProps | null => {
    if (resumeIdResponseStatuses === null) {
        return null;
    }

    return {
        text: RespondedText.Chat,
        chatId: resumeIdResponseStatuses.lastModifiedTopic?.chatId || undefined,
    };
};
