import { RefObject, FC } from 'react';
import { useDispatch } from 'react-redux';

import { makeSetStoreField } from '@hh.ru/redux-create-reducer';

import Source from 'src/components/VacancySearchItem/types/Source';
import useMagritteResponseExp from 'src/hooks/useMagritteResponseExp';
import { useSelector } from 'src/hooks/useSelector';
import PopupType from 'src/models/vacancyResponsePopup/popupType.types';

import AdvertisingPopup from 'src/components/VacancyResponsePopup/AdvertisingPopup';
import MagrittePopup from 'src/components/VacancyResponsePopup/MagrittePopup';
import Popup from 'src/components/VacancyResponsePopup/Popup';

const vacancyResponsePopupVisibleAction = makeSetStoreField('vacancyResponsePopupVisible');

interface VacancyResponsePopupProps {
    vacancyId: number;
    vacancyBodyFooterNodeRef?: RefObject<HTMLDivElement>;
    vacancySource: Source;
    isXs: boolean;
}

const VacancyResponsePopup: FC<VacancyResponsePopupProps> = ({
    vacancyId,
    vacancyBodyFooterNodeRef,
    vacancySource,
    isXs = false,
}) => {
    const dispatch = useDispatch();
    const isPopupVisible = useSelector((state) => state.vacancyResponsePopupVisible);
    const responsePopup = useSelector((state) => state.vacancyResponsePopup);
    const popupType = responsePopup?.type;

    const { isMagritteResponseExpAny, isMagritteResponseExpC } = useMagritteResponseExp();

    if (responsePopup?.vacancy?.vacancyId !== vacancyId) {
        return null;
    }

    const closePopup = (isCancel = false) => {
        dispatch(vacancyResponsePopupVisibleAction(false));
        if (isCancel) {
            responsePopup?.onCancelCallback?.();
        }
    };

    if (popupType === PopupType.Advertising) {
        return <AdvertisingPopup visible={isPopupVisible} onClose={closePopup} />;
    }

    if (popupType === PopupType.Normal) {
        return isMagritteResponseExpAny ? (
            <MagrittePopup
                visible={isPopupVisible}
                vacancyId={vacancyId}
                vacancySource={vacancySource}
                vacancyBodyFooterNodeRef={vacancyBodyFooterNodeRef}
                isQuestionResponse={responsePopup.isQuestionResponse}
                isMagritteResponseExpC={isMagritteResponseExpC}
                startedWithQuestion={responsePopup.startedWithQuestion}
                onClose={closePopup}
            />
        ) : (
            <Popup
                visible={isPopupVisible}
                onClose={closePopup}
                vacancyId={vacancyId}
                vacancyBodyFooterNodeRef={vacancyBodyFooterNodeRef}
                vacancySource={vacancySource}
                isXs={isXs}
                isQuestionResponse={responsePopup.isQuestionResponse}
                startedWithQuestion={responsePopup.startedWithQuestion}
            />
        );
    }
    return null;
};

export default VacancyResponsePopup;
