import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

export interface VacancyToResumeSkillMatch {
    [resumeHash: string]: {
        match_by_skills_statistics: {
            match_percentage: number;
        } | null;
        verification_info: {
            has_verified_skills: boolean;
        };
        rsv_competencies: {
            has_rsv_competencies: boolean;
        };
        profession_info: {
            is_related_to_vacancy_profession: boolean;
        } | null;
    };
}

export default autoGeneratedReducer<VacancyToResumeSkillMatch>({});
