import { FC, ComponentProps, PropsWithChildren } from 'react';

import { Text as MagritteText, TextProps } from '@hh.ru/magritte-ui';
import BlokoText, { TextImportance as BlokoTextImportance, TextSize as BlokoTextSize } from 'bloko/blocks/text';

import { useMagritte as useRedesign } from 'src/hooks/useMagritte';

type BlokoTextProps = ComponentProps<typeof BlokoText>;

const getBlokoParams = (params: TextProps): BlokoTextProps => {
    const blokoParams: Partial<BlokoTextProps> = {};

    if (params.style === 'primary') {
        blokoParams.importance = BlokoTextImportance.Primary;
    }

    if (params.style === 'secondary') {
        blokoParams.importance = BlokoTextImportance.Secondary;
    }

    if (params.typography === 'label-4-regular') {
        blokoParams.size = BlokoTextSize.Small;
    }

    if (params.typography === 'label-3-regular' || params.typography === 'paragraph-2-regular') {
        blokoParams.size = BlokoTextSize.Medium;
    }

    return blokoParams;
};

const Text: FC<TextProps & PropsWithChildren & { blokoComponentProps?: BlokoTextProps }> = ({
    children,
    blokoComponentProps,
    ...props
}) => {
    const isRedesign = useRedesign();

    if (isRedesign) {
        return <MagritteText {...props}>{children}</MagritteText>;
    }

    return (
        <BlokoText {...getBlokoParams(props)} {...blokoComponentProps}>
            {children}
        </BlokoText>
    );
};

export default Text;
