import axios from 'HHC/Axios';

const ADD_URL = '/applicant/favorite_vacancies/add';
const REMOVE_URL = '/applicant/favorite_vacancies/remove';

export const getDataQa = (inFavorites: boolean | null, isSearch = false, isNarrowCard = false): string => {
    const prefix = isSearch ? 'vacancy-search' : 'vacancy-body';
    const postfix = isNarrowCard ? '_narrow-card' : '';
    return `${prefix}-mark-favorite_${inFavorites ? 'true' : 'false'}${postfix}`;
};

const POSTPONE_URL = '/applicant/favorite_vacancies/postpone';
const POSTPONE_REDIRECT = '/account/login?postponed';

type MakeToggleFavoriteAnonymous = () => (props: {
    vacancyId: number;
    employerId?: number;
}) => Promise<null | typeof POSTPONE_REDIRECT>;
export const makeToggleFavoriteAnonymous: MakeToggleFavoriteAnonymous = () => {
    let requestHasBeenSent = false;

    return async ({ vacancyId, employerId }) => {
        if (requestHasBeenSent) {
            return null;
        }

        try {
            await axios.postFormData(POSTPONE_URL, {
                vacancyId,
                employerId,
            });
        } catch (error) {
            requestHasBeenSent = false;
            throw error;
        }

        requestHasBeenSent = false;
        return POSTPONE_REDIRECT;
    };
};

type MakeToggleFavorite = () => (props: {
    isFavoriteVacancy: boolean | null;
    vacancyId: number;
    employerId?: number;
    cleanup: unknown;
}) => Promise<null | boolean>;
export const makeToggleFavorite: MakeToggleFavorite = () => {
    let requestHasBeenSent = false;

    return async ({ isFavoriteVacancy, vacancyId, employerId, cleanup }) => {
        if (requestHasBeenSent) {
            return null;
        }

        requestHasBeenSent = true;

        try {
            await axios.postFormData(isFavoriteVacancy ? REMOVE_URL : ADD_URL, {
                vacancyId,
                employerId,
                cleanup,
            });
        } catch (error) {
            requestHasBeenSent = false;
            throw error;
        }

        requestHasBeenSent = false;
        return !isFavoriteVacancy;
    };
};
