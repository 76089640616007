import { Store } from 'redux';

import { AppStore } from 'src/app/store';

let promiseResolveFunction: (store: Store) => void = () => {};
const storeInstancePromise = new Promise<Store<AppStore>>((resolve) => (promiseResolveFunction = resolve));

export const setStore = promiseResolveFunction;

export const getStore = (): Promise<Store<AppStore>> => storeInstancePromise;
