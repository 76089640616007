import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

import { LogoType } from 'src/models/applicant/jobSearchStatus';

interface Logo {
    type: LogoType;
    url: string;
}

export interface Logos {
    logo: Array<Logo>;
}

export interface EmployerHint {
    id: number;
    name: string;
    organizationFormId: number;
    vacanciesOpen: number;
    logos: Logos;
    checked?: boolean;
    areaId?: string;
}

interface VacancyWizard {
    totalEmployersFound: number;
    employerHits: Array<EmployerHint>;
    rootAreaId: string;
    position?: number;
    minSearchResult?: number;
}

const INITIAL_STATE: VacancyWizard = {
    totalEmployersFound: 0,
    employerHits: [],
    rootAreaId: '0',
};

export default autoGeneratedReducer<VacancyWizard>(INITIAL_STATE);
