import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import Analytics from '@hh.ru/analytics-js';
import { useBreakpoint } from '@hh.ru/magritte-ui';
import { makeSetStoreField } from '@hh.ru/redux-create-reducer';

import SearchType from 'src/components/Search/Common/Filters/types';
import { useSelector } from 'src/hooks/useSelector';
import { NovaFilters, NovaFilterKey } from 'src/models/novaFilters';
import { searchClustersUpdate } from 'src/models/searchClusters';

const lastUpdatedSearchClusterAction = makeSetStoreField('lastUpdatedSearchCluster');

const FILTER_ANALYTICS_LABELS: Partial<Record<NovaFilterKey, string>> = {
    [NovaFilterKey.Compensation]: 'SALARY',
    [NovaFilterKey.Company]: 'EMPLOYER',
};

const getGenericAnalyticsValue = <F extends keyof NovaFilters>(selectedValues: NovaFilters[F]['selectedValues']) => {
    if (Array.isArray(selectedValues)) {
        return selectedValues.length ? selectedValues.join(', ') : 'null';
    }
    return selectedValues || 'null';
};

const sendAnalytics = <F extends keyof NovaFilters>(
    filter: F,
    oldSelectedValues: NovaFilters[F]['selectedValues'],
    newSelectedValues: NovaFilters[F]['selectedValues'],
    searchType: SearchType,
    defaultHhtmSource: string,
    isMobile: boolean
) => {
    // Возможные значения buttonName:
    //   –  vacancy_search_filter_apply     /search/vacancy
    //   –  resume_search_filter_apply      /search/resume
    //   –  vacancy_search_filter_touch     /search/vacancy & XS | S filters
    //   –  resume_search_filter_touch      /search/resume  & XS | S filters
    const buttonName = `${searchType}_search_filter_${isMobile ? 'touch' : 'apply'}`;

    // Возможные значения hhtmSource:
    //   –  vacancy_search_list             /search/vacancy | /vacancies/
    //   –  resume_search_result            /search/resume
    //   –  vacancy_search_filter           /search/vacancy & XS | S filters
    //   –  resume_search_filter            /search/resume  & XS | S filters
    //   –  resumes_catalog                 /resumes/
    const hhtmSource = isMobile ? `${searchType}_search_filter` : defaultHhtmSource;

    Analytics.sendHHEventButtonClick(buttonName, {
        type: FILTER_ANALYTICS_LABELS[filter] || filter.toUpperCase(),
        oldValue: JSON.stringify(getGenericAnalyticsValue(oldSelectedValues)),
        newValue: JSON.stringify(getGenericAnalyticsValue(newSelectedValues)),
        hhtmSource,
    });
};

type UseUpdateFilterHook = <F extends NovaFilterKey>(
    filter: F
) => (newSelectedValues: NovaFilters[F]['selectedValues']) => void;

export const useUpdateFilter: UseUpdateFilterHook = (filter) => {
    const dispatch = useDispatch();

    const searchType = useSelector((state) => state.searchClustersSettings.type);
    const oldSelectedValues = useSelector((state) => state.searchClusters[filter]?.selectedValues);
    const defaultHhtmSource = useSelector((state) => state.analyticsParams.hhtmSource);
    const { isMobile } = useBreakpoint();

    return useCallback(
        (newSelectedValues) => {
            sendAnalytics(filter, oldSelectedValues, newSelectedValues, searchType, defaultHhtmSource, isMobile);

            dispatch([
                searchClustersUpdate({ filter, data: newSelectedValues }),
                lastUpdatedSearchClusterAction(filter),
            ]);
        },
        [filter, oldSelectedValues, searchType, defaultHhtmSource, isMobile, dispatch]
    );
};
