import { memo, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useChatik } from '@hh.ru/chatik-integration';
import Storage from 'bloko/common/storage/LocalStorageWrapper';

import { RESUME_SCENARIO_NOTIFICATION_LS_KEY } from 'src/components/ChatikIntegration/constants';
import putNotificationToBell from 'src/components/ChatikIntegration/utils/putNotificationToBell';
import { useNotification } from 'src/components/Notifications/Provider';
import { useSelector } from 'src/hooks/useSelector';

import resumeCreationScenarioNotification from 'src/components/ChatikIntegration/components/ResumeCreationScenarioNotification';

/**
 * Не показываем уведомление на работодательских страницах,
 * поиске по вакансиям и экране создания резюме.
 */
const RESTRICTED_ROUTES = [
    '/employer', // работодательские страницы
    '/profile', // экраны создания резюме
    '/search/vacancy', // поиск по вакансиям
    '/vacancy', // конкретная вакансия
];

const isRestrictedRoute = (pathname: string): boolean => {
    return RESTRICTED_ROUTES.some((routePath) => pathname.startsWith(routePath));
};

const handleNotification = (shouldPutToBell = false): void => {
    shouldPutToBell && putNotificationToBell();
    Storage.setItem(RESUME_SCENARIO_NOTIFICATION_LS_KEY, 'handled');
};

const ResumeCreationScenario = () => {
    const location = useLocation();
    const { openChatik } = useChatik();
    const { addNotification } = useNotification();

    const applicantInfo = useSelector(({ applicantInfo }) => applicantInfo);
    const totalResumeCount = applicantInfo.total;

    useEffect(() => {
        if (totalResumeCount !== 0 || isRestrictedRoute(location.pathname)) {
            return;
        }

        const notificationStatus = Storage.getItem(RESUME_SCENARIO_NOTIFICATION_LS_KEY);

        if (notificationStatus === 'handled') {
            return;
        }

        if (notificationStatus === 'shown') {
            handleNotification(true);
            return;
        }

        setTimeout(() => {
            Storage.setItem(RESUME_SCENARIO_NOTIFICATION_LS_KEY, 'shown');

            addNotification(resumeCreationScenarioNotification, {
                props: {
                    onClick: () => {
                        // @ts-expect-error экспериментайльный алиас не поддержан на уровне типов
                        openChatik({ view: 'compact', chatId: 'kingdom_of_vacancy' });
                        handleNotification();
                    },
                    onClose: () => handleNotification(true),
                },
            });
        }, 100);
    }, [addNotification, location.pathname, openChatik, totalResumeCount]);

    return null;
};

export default memo(ResumeCreationScenario);
