import { ComponentType, useRef, useEffect } from 'react';

import userStatusSelectOfferCompanyElementShown from '@hh.ru/analytics-js-events/build/xhh/applicant/user_status/user_status_select_offer_company_element_shown';
import userStatusSelectOfferCompanyScreenShown from '@hh.ru/analytics-js-events/build/xhh/applicant/user_status/user_status_select_offer_company_screen_shown';
import { Avatar } from '@hh.ru/magritte-ui';

import OptionCard from 'src/components/Applicant/JobSearchStatus/PostChangeModal/redesign/OptionCard';
import { Offer, AddOfferFunction } from 'src/components/Applicant/JobSearchStatus/PostChangeModal/redesign/types';
import { useSelector } from 'src/hooks/useSelector';
import { LogoType, CompanyLogo } from 'src/models/applicant/jobSearchStatus';
import { JobSearchStatus } from 'src/models/applicantUserStatuses';

import styles from 'src/components/Applicant/JobSearchStatus/PostChangeModal/redesign/styles.less';

const ACCEPTABLE_LOGOS = [LogoType.VacancyPage, LogoType.Medium] as const;

type FindAcceptableLogo = (logos: CompanyLogo[] | undefined) => string | null;

const findAcceptableLogo: FindAcceptableLogo = (logos) => {
    if (!logos) {
        return null;
    }

    const logo = logos.find((logo) => ACCEPTABLE_LOGOS.includes(logo['@type']));

    return logo?.['@url'] || null;
};

interface Props {
    userStatus: JobSearchStatus;
    selectedOffers: Offer[];
    addOffer: AddOfferFunction;
}

const OffersStep: ComponentType<Props> = ({ userStatus, selectedOffers, addOffer }) => {
    const possibleJobOffers = useSelector(({ applicantPossibleJobOffers }) => applicantPossibleJobOffers);
    const wrapperRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (wrapperRef.current) {
            userStatusSelectOfferCompanyScreenShown();
            userStatusSelectOfferCompanyElementShown(wrapperRef.current, {
                type: 'full',
                employerIdList: possibleJobOffers.map((offer) => offer.employerId).join(','),
                vacancyIdList: possibleJobOffers
                    .filter((offer) => Boolean(offer.vacancyId))
                    .map((offer) => offer.vacancyId)
                    .join(','),
            });
        }
    }, [possibleJobOffers]);

    const selectedVacanciesIds = selectedOffers.map(({ vacancyId }) => vacancyId);

    if (possibleJobOffers.length === 0) {
        return null;
    }

    return (
        <div className={styles.contentWrapper} ref={wrapperRef}>
            {possibleJobOffers.map((offer) => {
                const logoUrl = findAcceptableLogo(offer.logos?.logo);

                return (
                    <OptionCard
                        key={offer.vacancyId}
                        title={offer.name}
                        subtitle={offer.vacancyNames.join(', ')}
                        type={userStatus === JobSearchStatus.AcceptedJobOffer ? 'radio' : 'checkbox'}
                        checked={selectedVacanciesIds.includes(offer.vacancyId)}
                        onChange={() => {
                            addOffer(offer.employerId, offer.vacancyId || 0, offer.resumeId || 0);
                        }}
                        avatar={
                            <Avatar
                                mode="image"
                                image={logoUrl || ''}
                                size={48}
                                fallbackMode="placeholder"
                                placeholder="city"
                                shapeCircle
                                aria-label={offer.name}
                            />
                        }
                    />
                );
            })}
        </div>
    );
};

export default OffersStep;
