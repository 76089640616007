import { forwardRef, ForwardRefRenderFunction, PropsWithChildren } from 'react';
import classnames from 'classnames';

import { VSpacing } from '@hh.ru/magritte-ui';

import styles from './styles.less';

const MAX_GROUPS_NOT_SCROLLING_LEN = 10;

interface FilterListProps {
    expanded?: boolean;
    len?: number;
    scrollable?: boolean;
}

const FilterList: ForwardRefRenderFunction<HTMLUListElement, PropsWithChildren<FilterListProps>> = (
    { children, len = 0, expanded = false, scrollable = false },
    ref
) => {
    const showScrolling = scrollable || (expanded && len > MAX_GROUPS_NOT_SCROLLING_LEN);
    return (
        <div className={styles.container}>
            <ul ref={ref} className={classnames({ [styles.listScrolling]: showScrolling })}>
                {children}
                {showScrolling && <VSpacing default={8} />}
            </ul>
        </div>
    );
};

export default forwardRef(FilterList);
