import { FC, PropsWithChildren } from 'react';

import { Card } from '@hh.ru/magritte-ui';

import { useMagritte } from 'src/hooks/useMagritte';

// TODO: Удалить компонент вместе с useMagritte после фейслифта
const VerificationCard: FC<PropsWithChildren & { errorPageLogin?: boolean }> = ({ children, errorPageLogin }) => {
    const isMagritte = useMagritte();

    if (!isMagritte || errorPageLogin) {
        return <>{children}</>;
    }

    return (
        <Card stretched padding={32} borderRadius={40} showBorder>
            {children}
        </Card>
    );
};

export default VerificationCard;
