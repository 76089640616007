import { useRef } from 'react';

import { TooltipHover, Text } from '@hh.ru/magritte-ui';
import { ItAccreditationCircledSize16, ItAccreditationCircledSize24 } from '@hh.ru/magritte-ui/product';
import HoverTip, { TipLayer, TipPlacement } from 'bloko/blocks/drop/Tip/HoverTip';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import BlokoIconReplaceContainer from 'src/components/BlokoIconReplaceContainer';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

const TrlKeys = {
    label: 'vacancy.itAccreditation.label',
};

interface ItAccreditationProps {
    isHighlighted?: boolean;
    isInverted?: boolean;
    isMagritte?: boolean;
}

const Features = {
    enableItAccreditation: 'enable_accredited_it_employer_search',
};

const ItAccreditation: TranslatedComponent<ItAccreditationProps> = ({
    trls,
    isHighlighted,
    isInverted,
    isMagritte,
}) => {
    const activatorRef = useRef<HTMLSpanElement>(null);
    const enableItAccreditation = useSelector(({ features }) => features[Features.enableItAccreditation]);

    if (!enableItAccreditation) {
        return null;
    }

    const getIconColor = () => {
        if (isInverted) {
            return 'contrast';
        }
        if (isHighlighted) {
            return 'primary';
        }
        return 'secondary';
    };

    const renderIcon = () => {
        return (
            <BlokoIconReplaceContainer>
                {isHighlighted ? (
                    <ItAccreditationCircledSize24 initial={getIconColor()} />
                ) : (
                    <ItAccreditationCircledSize16 initial={getIconColor()} />
                )}
            </BlokoIconReplaceContainer>
        );
    };

    return (
        <span>
            {isMagritte ? (
                <>
                    <span ref={activatorRef}>{renderIcon()}</span>
                    <TooltipHover placement="top-center" maxWidth={320} activatorRef={activatorRef}>
                        <Text>{trls[TrlKeys.label]}</Text>
                    </TooltipHover>
                </>
            ) : (
                <HoverTip
                    render={() => trls[TrlKeys.label]}
                    placement={TipPlacement.Top}
                    layer={TipLayer.Overlay}
                    host={!process.env.SSR ? document.body : null}
                    activatorRef={activatorRef}
                >
                    <span ref={activatorRef}>{renderIcon()}</span>
                </HoverTip>
            )}
        </span>
    );
};

export default translation(ItAccreditation);
