import Analytics from '@hh.ru/analytics-js';
import { Card, Link as LinkMagritte, Title, useBreakpoint, VSpacing as VSpacingMagritte } from '@hh.ru/magritte-ui';
import { Link as LinkSpa } from '@hh.ru/redux-spa-middleware';
import { H2 } from 'bloko/blocks/header';
import Link, { LinkKind } from 'bloko/blocks/link';
import Text from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

import styles from './styles.less';

const TrlKeys = {
    title: 'index.workInOtherCities.header',
};

const WorkInOtherCities: TranslatedComponent = ({ trls }) => {
    const citiesList = useSelector((state) => state.workInOtherCity?.link);
    const isAnonymousMagritteExp = useSelector((state) => state.isAnonymousMagritteExp);
    const { isMobile } = useBreakpoint();

    const sendAnalytics = (itemId: number, index: number) => {
        Analytics.sendHHEventButtonClick('switch_area', {
            areaId: itemId,
            position: index,
        });
        Analytics.sendHHEventButtonClick('vacancy_area_catalog_list', {
            domainId: itemId,
        });
    };

    if (!citiesList) {
        return null;
    }

    if (isAnonymousMagritteExp) {
        return (
            <Card padding={isMobile ? 0 : 24} borderRadius={24} showBorder={!isMobile}>
                <Title Element="h3" size="small">
                    {trls[TrlKeys.title]}
                </Title>
                <VSpacingMagritte default={16} gteS={20} gteM={24} />
                <div className={styles.container}>
                    {citiesList?.map((item, index) => (
                        <div className={styles.item} key={item.id}>
                            <LinkMagritte
                                style="neutral"
                                Element={LinkSpa}
                                to={`//${item.domain}`}
                                isSeoLink
                                onClick={() => sendAnalytics(item.id, index)}
                            >
                                {item.name}
                            </LinkMagritte>
                        </div>
                    ))}
                </div>
            </Card>
        );
    }

    return (
        <div className="work-in-other-cities">
            <div className="work-in-other-cities__header">
                <H2 Element="h3">{trls[TrlKeys.title]}</H2>
                <VSpacing base={4} />
            </div>
            <ul>
                {citiesList?.map((item, index) => (
                    <li className="multiple-column-list-item" key={item.id}>
                        <Text Element={'span'}>
                            <Link
                                href={`//${item.domain}`}
                                kind={LinkKind.Tertiary}
                                onClick={() => sendAnalytics(item.id, index)}
                                suppressHydrationWarning
                            >
                                <span className="easy-tap-link easy-tap-link_with-arrow">{item.name}</span>
                            </Link>
                        </Text>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default translation(WorkInOtherCities);
