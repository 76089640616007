import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';

import { ClickmeProductType } from 'src/models/price/product.types';

import { ProductName } from 'src/models/clickme/clickmeProducts';

export enum ClickmeFieldName {
    AutoCampaignChecked = 'publication.clickmeProducts.autoCampaignChecked',
}

const SET_CLICKME_AUTO_CAMPAIGN = 'SET_CLICKME_AUTO_CAMPAIGN';
const SET_CLICKME_AUTO_CAMPAIGN_INFO = 'SET_CLICKME_AUTO_CAMPAIGN_INFO';
const SET_CLICKME_PRODUCT_FOR_CART = 'SET_CLICKME_PRODUCT_FOR_CART';
const SET_CLICKME_AUTO_CAMPAIGN_LOADING = 'SET_CLICKME_AUTO_CAMPAIGN_LOADING';
const SET_CLICKME_AUTO_CAMPAIGN_ERROR = 'SET_CLICKME_AUTO_CAMPAIGN_ERROR';
const SET_SAVE_CLICKME_AUTO_CAMPAIGN_LOADING = 'SET_SAVE_CLICKME_AUTO_CAMPAIGN_LOADING';
const SET_CLICKME_AUTO_CAMPAIGN_VALIDATION_ERRORS = 'SET_CLICKME_AUTO_CAMPAIGN_VALIDATION_ERRORS';
const SET_SAVE_CLICKME_AUTO_CAMPAIGN_ERROR = 'SET_SAVE_CLICKME_AUTO_CAMPAIGN_ERROR';

export interface AutoCampaignInfo {
    title: string;
    text: string;
    type: ProductName;
    autoCampaignDraftId: number | null;
}

export type SaveAutoCampaignInfoData = Omit<AutoCampaignInfo, 'autoCampaignDraftId'>;

export interface AutoCampaignResponse extends AutoCampaignInfo {
    validationErrors?: AutoCampaignErrors;
}

interface AutoCampaignField {
    fieldName: keyof AutoCampaignInfo;
    data: string | ProductName;
}

interface PayloadTypes {
    [SET_CLICKME_AUTO_CAMPAIGN]: AutoCampaignInfo | null;
    [SET_CLICKME_AUTO_CAMPAIGN_INFO]: AutoCampaignField;
    [SET_CLICKME_PRODUCT_FOR_CART]: ClickmeProductType | null;
    [SET_CLICKME_AUTO_CAMPAIGN_LOADING]: boolean;
    [SET_CLICKME_AUTO_CAMPAIGN_ERROR]: boolean;
    [SET_SAVE_CLICKME_AUTO_CAMPAIGN_LOADING]: boolean;
    [SET_SAVE_CLICKME_AUTO_CAMPAIGN_ERROR]: boolean;
    [SET_CLICKME_AUTO_CAMPAIGN_VALIDATION_ERRORS]: AutoCampaignErrors | null;
}

const ActionCreator = ActionCreatorHelper<PayloadTypes>();
export const setClickmeAutoCampaign = ActionCreator(SET_CLICKME_AUTO_CAMPAIGN);
export const setClickmeAutoCampaignInfo = ActionCreator(SET_CLICKME_AUTO_CAMPAIGN_INFO);
export const setClickmeAutoCampaignLoading = ActionCreator(SET_CLICKME_AUTO_CAMPAIGN_LOADING);
export const setClickmeAutoCampaignError = ActionCreator(SET_CLICKME_AUTO_CAMPAIGN_ERROR);
export const setClickmeProductForCart = ActionCreator(SET_CLICKME_PRODUCT_FOR_CART);
export const setSaveClickmeAutoCampaignLoading = ActionCreator(SET_SAVE_CLICKME_AUTO_CAMPAIGN_LOADING);
export const setAutoCampaignValidationErrors = ActionCreator(SET_CLICKME_AUTO_CAMPAIGN_VALIDATION_ERRORS);
export const setSaveAutoCampaignError = ActionCreator(SET_SAVE_CLICKME_AUTO_CAMPAIGN_ERROR);

export type AutoCampaignErrors = Partial<Record<keyof SaveAutoCampaignInfoData, string>>;

interface InitialState {
    autoCampaignInfo: AutoCampaignInfo | null;
    autoCampaignInfoError: boolean;
    loading: boolean;
    clickmeProductForCart: ClickmeProductType | null;
    saveAutoCampaignLoading: boolean;
    saveAutoCampaignValidationErrors: AutoCampaignErrors | null;
    saveAutoCampaignError: boolean;
}

export const INITIAL_STATE: InitialState = {
    autoCampaignInfo: null,
    clickmeProductForCart: null,
    loading: false,
    saveAutoCampaignLoading: false,
    saveAutoCampaignValidationErrors: null,
    saveAutoCampaignError: false,
    autoCampaignInfoError: false,
};

export default createReducer<InitialState, PayloadTypes>(INITIAL_STATE, {
    [SET_CLICKME_AUTO_CAMPAIGN]: (state, { payload }) => ({
        ...state,
        autoCampaignInfo: payload,
    }),
    [SET_CLICKME_AUTO_CAMPAIGN_INFO]: (state, { payload }) => ({
        ...state,
        autoCampaignInfo: {
            ...state.autoCampaignInfo!,
            [payload.fieldName]: payload.data,
        },
    }),
    [SET_CLICKME_AUTO_CAMPAIGN_LOADING]: (state, { payload }) => ({
        ...state,
        loading: payload,
    }),
    [SET_CLICKME_AUTO_CAMPAIGN_ERROR]: (state, { payload }) => ({
        ...state,
        autoCampaignInfoError: payload,
    }),
    [SET_CLICKME_PRODUCT_FOR_CART]: (state, { payload }) => ({
        ...state,
        clickmeProductForCart: payload,
    }),
    [SET_SAVE_CLICKME_AUTO_CAMPAIGN_LOADING]: (state, { payload }) => ({
        ...state,
        saveAutoCampaignLoading: payload,
    }),
    [SET_SAVE_CLICKME_AUTO_CAMPAIGN_ERROR]: (state, { payload }) => ({
        ...state,
        saveAutoCampaignError: payload,
    }),
    [SET_CLICKME_AUTO_CAMPAIGN_VALIDATION_ERRORS]: (state, { payload }) => ({
        ...state,
        saveAutoCampaignValidationErrors: payload,
    }),
});
