import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

export interface ApplicantInfo {
    total: number;
    visible: number;
    finished: number;
    searchable: number;
}

export interface BackofficeLinks {
    moderationLink?: string;
    loginAsUserLink?: string;
    userServicesLink?: string;
    visibilityHistoryLink?: string;
    contactsVisibilityHistoryLink?: string;
}

export const resumeViewBackoffice = autoGeneratedReducer<BackofficeLinks>({});
