import { FC } from 'react';
import classnames from 'classnames';

import { Link } from '@hh.ru/redux-spa-middleware';

import Logo from 'src/components/Logo';
import useFeatureEnabled from 'src/hooks/useFeatureEnabled';
import { useSelector } from 'src/hooks/useSelector';
import { SupernovaUserType } from 'src/models/supernovaUserType';

import useTrackClicks from 'src/components/SupernovaMainMenu/useTrackClicks';

const Features = {
    employerMainPageAnalyticsEnabled: 'employer_main_page_analytics_enabled',
};

interface SupernovaLogoProps {
    onLightBg: boolean;
    trackClicks?: boolean;
    isRedesign?: boolean;
    isTinyOnXs?: boolean;
}

const SupernovaLogo: FC<SupernovaLogoProps> = ({ onLightBg, isRedesign, isTinyOnXs, trackClicks }) => {
    const employerMainPageAnalyticsEnabled = useFeatureEnabled(Features.employerMainPageAnalyticsEnabled);
    const supernovaUserType = useSelector(({ supernovaUserType }) => supernovaUserType);

    const trackClick = useTrackClicks('logo', employerMainPageAnalyticsEnabled || trackClicks, {
        name: 'hh_header',
    });

    return (
        <Link
            className={classnames('supernova-logo-wrapper', {
                'supernova-logo-wrapper-redesign': isRedesign,
                'supernova-logo-wrapper-xs-tiny': isTinyOnXs,
            })}
            to={supernovaUserType === SupernovaUserType.Employer ? '/employer' : '/'}
            onClick={trackClick}
        >
            <Logo inverse={!onLightBg} tabIndex={-1} />
        </Link>
    );
};

export default SupernovaLogo;
