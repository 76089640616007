import { FC, ComponentProps } from 'react';

import { Checkbox as MagritteCheckbox, Text } from '@hh.ru/magritte-ui';
import BlokoCheckbox from 'bloko/blocks/checkbox';

import { useMagritte } from 'src/hooks/useMagritte';
import { useSelector } from 'src/hooks/useSelector';

import AdvertisementAgreementText from 'src/components/AdvertisementAgreement/Text';

import styles from './styles.less';

interface AdvertisementAgreementCheckboxProps extends ComponentProps<typeof MagritteCheckbox> {
    articleId?: string;
}

const AdvertisementAgreementCheckbox: FC<AdvertisementAgreementCheckboxProps> = (props) => {
    const isMagritte = useMagritte();
    const { articleId, ...checkboxProps } = props;

    const isAnonymousMagritteExp = useSelector(({ isAnonymousMagritteExp }) => isAnonymousMagritteExp);

    if (isMagritte) {
        return (
            <Text typography={isAnonymousMagritteExp ? 'paragraph-3-regular' : 'label-3-regular'} style="secondary">
                <label className={styles.label}>
                    <MagritteCheckbox {...checkboxProps} />
                    <div>
                        <AdvertisementAgreementText articleId={articleId} />
                    </div>
                </label>
            </Text>
        );
    }

    return (
        <BlokoCheckbox {...checkboxProps}>
            <AdvertisementAgreementText articleId={articleId} />
        </BlokoCheckbox>
    );
};

export default AdvertisementAgreementCheckbox;
