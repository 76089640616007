import { useEffect, useState, FC } from 'react';
import { createPortal } from 'react-dom';

import WebcallWrapper from 'src/components/Webcall/WebcallWrapper';
import { WEBCALL_CUSTOM_EVENTS } from 'src/components/Webcall/const';
import { WebCallData } from 'src/components/Webcall/types';

/**
    компонент-обертка для WebcallWrapper
    1. подписывается на событие WEBCALL_INIT_CALL(например может послать чат)
    2. Рендерит микрофронт Webcall и ждет события WEBCALL_DYNAMIC_READY
    3. Посылает событие WEBCALL_SET_DYNAMIC_PARAMS с данными для Webcall
 */

const WebcallDynamicWrapper: FC = () => {
    const [webCallData, setWebcallData] = useState<null | WebCallData>(null);

    useEffect(() => {
        const handleOpen = (evt: CustomEvent<WebCallData>) => {
            setWebcallData(evt.detail);
        };

        window.addEventListener(WEBCALL_CUSTOM_EVENTS.INIT_CALL, handleOpen as EventListener);
        return () => window.removeEventListener(WEBCALL_CUSTOM_EVENTS.INIT_CALL, handleOpen as EventListener);
    }, []);

    useEffect(() => {
        const provideDetails = () => {
            window.dispatchEvent(new CustomEvent(WEBCALL_CUSTOM_EVENTS.SET_DYNAMIC_PARAMS, { detail: webCallData }));
        };

        window.addEventListener(WEBCALL_CUSTOM_EVENTS.WEBCALL_DYNAMIC_READY, provideDetails as EventListener);
        return () =>
            window.removeEventListener(WEBCALL_CUSTOM_EVENTS.WEBCALL_DYNAMIC_READY, provideDetails as EventListener);
    }, [webCallData]);

    if (webCallData !== null) {
        return createPortal(
            <WebcallWrapper
                place="webcall-front-dynamic"
                onClose={() => {
                    setWebcallData(null);
                }}
                hhtmSource={webCallData.source}
                resumeId={webCallData.resumeId}
                topicId={webCallData.topicId || undefined}
                callType={webCallData.callType}
                canSwitchCallType={webCallData.canSwitchCallType}
                requestReCall={(newData) => {
                    setWebcallData({
                        ...webCallData,
                        ...newData,
                    });
                }}
            />,
            document.body
        );
    }

    return null;
};

export default WebcallDynamicWrapper;
