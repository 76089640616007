import React from 'react';

import { useSelector } from 'src/hooks/useSelector';
import { MessengerType } from 'src/models/messengers';
import { SupernovaUserType } from 'src/models/supernovaUserType';
import { UserType } from 'src/models/userType';

import { sendSupportAnalytics } from 'src/components/SupernovaMainMenu/useTrackClicks';

const MESSENGERS = [
    {
        href: 'https://telegram.me/hh_applicant_bot',
        messenger: 'telegram',
    },
    {
        href: 'https://chats.viber.com/headhunter',
        messenger: 'viber',
    },
];

const EMPLOYER_MESSENGERS = [
    {
        href: 'https://telegram.me/hh_ru_bot',
        messenger: 'telegram',
    },
    {
        href: 'https://chats.viber.com/headhunter',
        messenger: 'viber',
    },
];

const NEW_EMPLOYER_MESSENGERS = [
    {
        href: 'https://telegram.me/reg_hh_bot',
        messenger: 'telegram',
    },
    {
        href: 'https://chats.viber.com/headhunter',
        messenger: 'viber',
    },
];

interface Props {
    onClick?: (userType: string, messenger: string) => void;
}
const SupportChatMessengers: React.VFC<Props> = ({ onClick = sendSupportAnalytics }) => {
    const employerStatus = useSelector(({ employerStatus }) => employerStatus);
    const messengers = useSelector(({ messengers }) => messengers);
    const userType = useSelector(({ userType }) => userType);
    const supernovaUserType = useSelector(({ supernovaUserType }) => supernovaUserType);

    let messengersToRender;
    if (userType === UserType.Employer || supernovaUserType === SupernovaUserType.Employer) {
        messengersToRender = EMPLOYER_MESSENGERS;
        if (employerStatus.state === 'NEW') {
            const whatsapp = messengers.find(({ messengerType }) => messengerType === MessengerType.Whatsapp);
            if (whatsapp) {
                messengersToRender = [
                    ...NEW_EMPLOYER_MESSENGERS,
                    {
                        href: `https://wa.me/${whatsapp.country}${whatsapp.city}${whatsapp.number}`,
                        messenger: 'whatsapp',
                    },
                ];
            }
        }
    } else {
        messengersToRender = MESSENGERS;
    }

    return (
        <React.Fragment>
            {messengersToRender.map(({ href, messenger }) => {
                return (
                    <a
                        className={`helpphone-messenger helpphone-messenger_supernova helpphone-messenger_${messenger}`}
                        href={href}
                        key={messenger}
                        onClick={() => onClick(userType, messenger)}
                        target="_blank"
                        rel="noreferrer"
                    />
                );
            })}
        </React.Fragment>
    );
};

export default SupportChatMessengers;
