import Text, { TextSize } from 'bloko/blocks/text';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

const EMPLOYER_REVIEW_SETTING = 'employerReviewsSettings';

const badgeType = {
    new: 'new',
    enabled: 'enabled',
    absolute: 'absolute',
};

const TrlKeys = {
    [badgeType.new]: 'supernova.navi.badge.new',
    [badgeType.enabled]: 'supernova.navi.badge.enabled',
};
interface BadgeProps {
    badge?: string;
    name?: string;
}

const BadgeElement: TranslatedComponent<BadgeProps> = ({ trls, badge, name, children }) => {
    const hasForceReloadDreamJob = useSelector((state) => state.hasForceReloadDreamJob);
    if (!badge || !badgeType.hasOwnProperty(badge)) {
        return null;
    }
    const badgeTypeName: string =
        hasForceReloadDreamJob && name === EMPLOYER_REVIEW_SETTING
            ? badgeType.enabled
            : badgeType[badge as keyof typeof badgeType];

    return (
        <Text size={TextSize.Small} Element={'span'}>
            <span className={`supernova-dropdown-badge supernova-dropdown-badge_${badgeTypeName}`}>
                {badgeTypeName !== badgeType.absolute ? trls[TrlKeys[badgeTypeName as keyof typeof TrlKeys]] : children}
            </span>
        </Text>
    );
};

export default translation(BadgeElement);
