import { useMemo } from 'react';

import { Title, VSpacing } from '@hh.ru/magritte-ui';
import { H2 } from 'bloko/blocks/header';
import { TextImportance } from 'bloko/blocks/text';
import useBreakpoint, { Breakpoint } from 'bloko/common/hooks/useBreakpoint';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { format } from 'bloko/common/trl';

import GridColumn from 'src/components/MagritteRedesignComponents/GridColumn';
import Text from 'src/components/MagritteRedesignComponents/Text';
import translation from 'src/components/translation';
import { useMagritte } from 'src/hooks/useMagritte';
import { useSelector } from 'src/hooks/useSelector';

import VerificationCard from 'src/components/Verification/VerificationCard';
import VerificationForm, { VerificationFormBaseProps } from 'src/components/Verification/VerificationForm';

const TrlKeys = {
    header: 'otp.confirm.header',
    whatsAppHeader: 'otp.confirm.whatsapp.header',
    subHeader: 'otp.confirm.sub.header',
    firstName: 'newApplicant.firstName',
    lastName: 'newApplicant.lastName',
    send: 'account.connect.merge_by_code.send',
    codePlaceholder: 'account.connect.merge_by_code.codePlaceholder',
    confirmCode: 'account.connect.merge_by_code.confirm',
    submitSignup: 'signupForm.create.submit',
};

export interface VerificationProps extends VerificationFormBaseProps {
    onCancel?: () => void;
    cancelText?: string;
    codePlaceholder?: string;
    errorPageLogin?: boolean;
    loginFormatted?: string;
}

const Verification: TranslatedComponent<VerificationProps> = ({
    trls,
    errorPageLogin,
    codePlaceholder,
    onCancel,
    cancelText,
    loginFormatted,
    ...formProps
}) => {
    const isXs = useBreakpoint() === Breakpoint.XS;
    const { isSignupPage, login } = formProps;
    const isMagritte = useMagritte();

    const authType = useSelector(({ otp }) => otp?.authType);

    const title = useMemo(() => {
        const header =
            authType === 'WHATS_APP'
                ? format(trls[TrlKeys.whatsAppHeader], {
                      '{0}': loginFormatted! || login!,
                  })
                : format(trls[TrlKeys.header], {
                      '{0}': loginFormatted! || login!,
                  });
        return isMagritte ? (
            <Title Element="h2" size="small" alignment="left" description={trls[TrlKeys.subHeader]}>
                {header}
            </Title>
        ) : (
            <>
                <H2>
                    <div className="verification-header">{header}</div>
                </H2>
                <VSpacing default={12} />
                <p>{trls[TrlKeys.subHeader]}</p>
            </>
        );
    }, [authType, isMagritte, login, loginFormatted, trls]);

    return (
        <VerificationForm
            render={({
                renderInput,
                renderCodeError,
                renderCodeSender,
                renderSubmit,
                renderCancelButton,
                hasVerificationError,
            }) => {
                return (
                    <GridColumn
                        xs="4"
                        s={isMagritte ? '8' : '6'}
                        m={isMagritte ? '5' : '6'}
                        l={isMagritte ? '4' : '6'}
                        container
                    >
                        <VerificationCard errorPageLogin={errorPageLogin}>
                            {title}
                            <VSpacing default={24} />
                            <div className={isMagritte ? '' : 'verification-content'}>
                                <div className={isMagritte ? '' : 'verification-input-wrapper'}>
                                    {renderInput({
                                        placeholder: codePlaceholder,
                                        isAutofocus: isSignupPage,
                                    })}
                                </div>
                            </div>
                            <div>
                                <VSpacing default={12} />
                                {isXs && renderCodeError()}
                                <Text
                                    typography="label-2-regular"
                                    style="tertiary"
                                    blokoComponentProps={{
                                        importance: TextImportance.Tertiary,
                                    }}
                                >
                                    {renderCodeSender()}
                                </Text>
                            </div>
                            {!isXs && (
                                <>
                                    <VSpacing default={!hasVerificationError ? 12 : 0} />
                                    <div>{renderCodeError()}</div>
                                </>
                            )}
                            <VSpacing default={isMagritte ? 12 : 0} xs={isMagritte ? 24 : 20} />
                            <div className="verification-submit">{renderSubmit({ isStretched: !errorPageLogin })}</div>
                            {onCancel && (
                                <>
                                    <VSpacing default={12} />
                                    <div className="verification-submit">
                                        {renderCancelButton({
                                            isStretched: !errorPageLogin,
                                            onClick: onCancel,
                                            trl: cancelText!,
                                        })}
                                    </div>
                                </>
                            )}
                        </VerificationCard>
                    </GridColumn>
                );
            }}
            {...formProps}
        />
    );
};

export default translation(Verification);
