import { MouseEventHandler } from 'react';

import { useChatik } from '@hh.ru/chatik-integration';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { SupernovaUserNotificationsIconKind } from 'src/components/SupernovaMainMenu/UserNotifications/Icon';
import NotificationLink from 'src/components/SupernovaMainMenu/UserNotifications/Link';
import Notification from 'src/components/SupernovaMainMenu/UserNotifications/Notification';
import NotificationSpacer from 'src/components/SupernovaMainMenu/UserNotifications/Spacer';
import translation from 'src/components/translation';
import { ComplexUserNotification } from 'src/models/userNotifications';

const TrlKeys = {
    title: 'resumeCreationScenario.notification.title',
    text: 'resumeCreationScenario.notification.text',
    button: 'resumeCreationScenario.notification.button',
};

const UserNotificationsKingdomOfVacancy: TranslatedComponent<ComplexUserNotification> = ({
    trls,
    close,
    ...notification
}) => {
    const { openChatik } = useChatik();

    const onClick: MouseEventHandler<HTMLAnchorElement> = (ev) => {
        ev.preventDefault();
        close?.();
        // @ts-expect-error экспериментайльный алиас не поддержан на уровне типов
        openChatik({ view: 'compact', chatId: 'kingdom_of_vacancy' });
    };

    return (
        <Notification iconKind={SupernovaUserNotificationsIconKind.Info} {...notification}>
            <NotificationSpacer>
                <NotificationSpacer>
                    {trls[TrlKeys.title]} {trls[TrlKeys.text]}
                </NotificationSpacer>
                <NotificationLink to="" onClick={onClick}>
                    {trls[TrlKeys.button]}
                </NotificationLink>
            </NotificationSpacer>
        </Notification>
    );
};

export default translation(UserNotificationsKingdomOfVacancy);
