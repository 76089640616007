import { MouseEventHandler } from 'react';
import classnames from 'classnames';

import { Text, Link as UiLink } from '@hh.ru/magritte-ui';
import { Link } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { formatToReactComponent } from 'bloko/common/trl';

import translation from 'src/components/translation';
import { useIsRussia } from 'src/hooks/useCountries';
import { useIsZarplataPlatform } from 'src/hooks/usePlatform';
import { useSelectorNonNullable } from 'src/hooks/useSelector';

interface AgreementTextProps {
    text?: string;
    link?: string;
    isIframeView?: boolean;
}

const TrlKeys = {
    bare: {
        common: {
            text: 'signup.agreementText.text',
        },
        russia: {
            text: 'signup.agreementText.russia.text.short',
            personalLink: 'signup.agreementText.russia.personal.data.short',
        },
        zp: {
            text: 'signup.agreementText.zp.text.short',
            signup: 'signup.agreementText.zp.signup',
            continue: 'signup.agreementText.zp.continue',
            link: 'signup.agreementText.zp.link',
            pd: 'signup.agreementText.zp.pd',
        },
        agreementLink: 'signup.agreementText.russia.agreement.link.short',
    },
};

const agreementDataQa = 'account-signup-agreement';
const personalDataQa = 'account-signup-personal-data-russia';

const AgreementTextMagritte: TranslatedComponent<AgreementTextProps> = ({ trls, isIframeView = false, ...props }) => {
    const agreementURL = useSelectorNonNullable((state) => state.authUrl['agreement-form']);
    const personalDataRussiaURL = useSelectorNonNullable((state) => state.authUrl['policy-url']);
    const isRussia = useIsRussia();
    const isZP = useIsZarplataPlatform();

    const handleClickForVKminiApp: MouseEventHandler<HTMLAnchorElement> = (event) => {
        if (!isIframeView) {
            return;
        }
        event.preventDefault();
        window.location.href = event.currentTarget.href;
    };

    const renderLink = (text: string, dataQa: string, to: string) => {
        return (
            <Link
                {...props}
                onClick={handleClickForVKminiApp}
                className={classnames({ 'vk-mini-apps-link-disable': isIframeView })}
                to={to}
                data-qa={dataQa}
            >
                {text}
            </Link>
        );
    };

    const getText = () => {
        if (isZP) {
            return trls[TrlKeys.bare.zp.text];
        }
        if (isRussia) {
            return trls[TrlKeys.bare.russia.text];
        }
        return trls[TrlKeys.bare.common.text];
    };

    const getFormat = (): Record<string, JSX.Element | string> => {
        if (isZP) {
            return {
                '{0}': (
                    <UiLink
                        Element="a"
                        inline
                        typography="paragraph-3-regular"
                        href={agreementURL}
                        data-qa={agreementDataQa}
                    >
                        {trls[TrlKeys.bare.zp.link]}
                    </UiLink>
                ),
                '{1}': (
                    <UiLink
                        Element="a"
                        style="accent"
                        inline
                        typography="paragraph-3-regular"
                        href="https://hhcdn.ru/file/17755941.doc"
                        data-qa="account-signup-zp-pd"
                    >
                        {trls[TrlKeys.bare.zp.pd]}
                    </UiLink>
                ),
            };
        }
        if (isRussia) {
            return {
                '{0}': renderLink(trls[TrlKeys.bare.agreementLink], agreementDataQa, agreementURL),
                '{1}': renderLink(trls[TrlKeys.bare.russia.personalLink], personalDataQa, personalDataRussiaURL),
            };
        }
        return {
            '{0}': renderLink(trls[TrlKeys.bare.agreementLink], agreementDataQa, agreementURL),
        };
    };

    return (
        <div>
            <Text typography="paragraph-3-regular" style="secondary">
                {formatToReactComponent(getText(), getFormat())}
            </Text>
        </div>
    );
};

export default translation(AgreementTextMagritte);
