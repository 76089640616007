import { FC, PropsWithChildren } from 'react';

import closeNotificationEvent from '@hh.ru/analytics-js-events/build/xhh/employer/price_notifications/user_notifications/billing_delayed_cart_activation_notification_close';
import { NotificationKind } from 'bloko/blocks/notificationManager/constants';

import BillingCartActivationNotification, { TemplateKeys } from 'src/components/BillingCartActivationNotification';
import SynchronizedNotification from 'src/components/UserNotifications/common/SynchronizedNotification';
import { ComplexUserNotification } from 'src/models/userNotifications';

const BillingCartActivation: FC<ComplexUserNotification & PropsWithChildren> = (props) => {
    const key = props.templateKey as TemplateKeys;

    return (
        <SynchronizedNotification
            notification={props}
            kind={NotificationKind.Error}
            analyticsElementName={key.split('.').join('_').concat('_notification')}
            onClose={() => closeNotificationEvent({ type: key })}
        >
            <BillingCartActivationNotification {...props} />
        </SynchronizedNotification>
    );
};

export default BillingCartActivation;
