import { FC } from 'react';
import classnames from 'classnames';

import { VSpacing } from '@hh.ru/magritte-ui';

import RespondedSuccess from 'src/components/Applicant/Response/RespondedSuccess';
import VacancySearchItemDebug from 'src/components/Debug/SearchDebug';
import ActionButtons from 'src/components/VacancySearchItem/ActionButtons';
import AdvMarkContainer from 'src/components/VacancySearchItem/AdvMarkContainer';
import Company from 'src/components/VacancySearchItem/Company';
import Compensation from 'src/components/VacancySearchItem/Compensation';
import Description from 'src/components/VacancySearchItem/Description';
import ExperienceAndSchedule from 'src/components/VacancySearchItem/ExperienceAndSchedule';
import Labels from 'src/components/VacancySearchItem/Labels';
import MainButtons from 'src/components/VacancySearchItem/MainButtons';
import TopRowInfo from 'src/components/VacancySearchItem/TopRowInfo';
import VacancyName from 'src/components/VacancySearchItem/VacancyName';
import { useCardContainerClassNames } from 'src/components/VacancySearchItem/hooks/useCardContainerClassNames';
import Source from 'src/components/VacancySearchItem/types/Source';
import translation from 'src/components/translation';
import { useIsZarplataPlatform } from 'src/hooks/usePlatform';
import usePropertiesBundle from 'src/hooks/usePropertiesBundle';
import { useSelector } from 'src/hooks/useSelector';
import { UserType } from 'src/models/userType';
import { VacancyProperty } from 'src/models/vacancyProperties';
import {
    VacancySearchItem as VacancySearchItemType,
    ACTIVITY_ONLINE,
} from 'src/models/vacancySearch/vacancySearchItem.types';

import styles from './styles.less';

interface VacancySearchItemProps {
    vacancy: VacancySearchItemType;
    vacancySource: Source;
    hhtmFromLabel?: string;
    criteriaText?: string;
}

const VacancySearchItem: FC<VacancySearchItemProps> = ({ vacancy, vacancySource, hhtmFromLabel, criteriaText }) => {
    const {
        vacancyId,
        '@click': advClickUrl,
        '@isAdv': isAdv,
        '@showContact': showContact,
        show_question_input: showQuestionInput,
        '@workSchedule': workSchedule,
        area,
        address,
        'hr-brand': hrBrand,
        name,
        employerManager,
        archived,
        compensation,
        workExperience,
        immediateRedirectUrl: vrImmediateRedirectUrl,
        chatWritePossibility,
        vacancyProperties,
        isVacancyOfTheDay,
        type,
        searchDebug,
        snippet,
        company,
        clickUrl,
        insider,
        online_users_count: onlineUsersCount,
        closedForApplicants,
    } = vacancy;
    const { wideCardContainerClassName, narrowCardContainerClassName } = useCardContainerClassNames();
    const propertiesBundle = usePropertiesBundle();
    const userType = useSelector((state) => state.userType);
    const isEmployerOnline = employerManager.latestActivity === ACTIVITY_ONLINE;
    const isEmployer = useSelector((state) => state.userType === UserType.Employer);
    const shouldShowAdv = isAdv && isEmployer;
    const employerManagerName = [
        employerManager['@lastName'],
        employerManager['@firstName'],
        employerManager['@middleName'],
    ]
        .filter(Boolean)
        .join(' ');

    const shouldShowFireLabel = Boolean(
        isVacancyOfTheDay ||
            vacancyProperties.calculatedStates?.[propertiesBundle]?.filteredPropertyNames.some((property) =>
                [VacancyProperty.HhSearchResultsLabel, VacancyProperty.ZpSearchResultsLabel].includes(property)
            )
    );
    const shouldShowLogo = Boolean(
        isVacancyOfTheDay ||
            vacancyProperties.calculatedStates?.[propertiesBundle]?.filteredPropertyNames.some((property) =>
                [VacancyProperty.HhSearchResultsLogo, VacancyProperty.ZpSearchResultsLogo].includes(property)
            )
    );
    const shouldDisableCompanyLink =
        useIsZarplataPlatform() && !!vacancyProperties?.calculatedStates?.ZP?.crosspostedFrom;
    const vacancyOfTheDayClickUrl = isVacancyOfTheDay && vacancy.clickUrl ? vacancy.clickUrl : null;
    const hasCompensationAndExperience = (compensation.perModeFrom || compensation.perModeTo) && workExperience;

    const vacancyLinkProps = {
        vacancySource,
        hhtmFromLabel,
        criteriaText: criteriaText ?? '',
        vacancy,
    };

    const isAnonymousVacancy = vacancyProperties.calculatedStates?.[propertiesBundle]?.anonymous || false;
    const isMapVacancySearch = useSelector((state) => state.isMapVacancySearch);

    const vacancyInfoElement = (
        <>
            <ActionButtons
                vacancyId={vacancyId}
                employerId={company.id}
                isAnonymousVacancy={isAnonymousVacancy}
                isClosedVacancy={closedForApplicants}
                clickUrl={clickUrl ?? ''}
                isAdv={Boolean(isAdv)}
                vacancySource={vacancySource}
            />

            <TopRowInfo onlineUsersCount={onlineUsersCount} shouldShowAdv={shouldShowAdv} />

            <AdvMarkContainer isAdv={isAdv}>
                <VacancyName
                    name={name}
                    vacancyLinkProps={vacancyLinkProps}
                    shouldShowFireLabel={shouldShowFireLabel}
                />
                <VSpacing default={8} />

                <div className={wideCardContainerClassName}>
                    <div className={classnames(styles.compensationLabels, styles.compensationLabelsMagritte)}>
                        <Compensation compensation={compensation} />
                        <ExperienceAndSchedule
                            workExperience={workExperience}
                            workSchedule={workSchedule}
                            compensation={compensation}
                        />
                    </div>
                </div>
                <div className={narrowCardContainerClassName}>
                    <Compensation compensation={compensation} />
                    {hasCompensationAndExperience && <VSpacing default={8} />}
                    <div className={classnames(styles.compensationLabels, styles.compensationLabelsMagritte)}>
                        <ExperienceAndSchedule
                            workExperience={workExperience}
                            workSchedule={workSchedule}
                            compensation={compensation}
                        />
                    </div>
                </div>
            </AdvMarkContainer>
            <Company
                company={company}
                vacancySource={vacancySource}
                hrBrand={hrBrand}
                insider={insider}
                area={area}
                address={address}
                advClickUrl={advClickUrl}
                isEmployerOnline={isEmployerOnline}
                shouldShowLogo={shouldShowLogo}
                shouldDisableCompanyLink={shouldDisableCompanyLink}
            />

            <>
                <Labels vacancy={vacancy} />
                <Description snippet={snippet} highlightedShort={vacancySource === Source.VacancySearch} />
            </>
        </>
    );

    return (
        <div className={classnames(styles.vacancyCard, 'font-inter', { 'archived-vacancy-card': Boolean(archived) })}>
            <>
                <div className={styles.vacancyInfo}>{vacancyInfoElement}</div>

                <div className={classnames(!isMapVacancySearch && styles.vacancyCardFooter)}>
                    <MainButtons
                        vacancyId={vacancyId}
                        vacancySource={vacancySource}
                        companyId={company.id}
                        companyName={company.visibleName}
                        showContact={showContact}
                        showQuestionInput={showQuestionInput}
                        chatWritePossibility={chatWritePossibility}
                        vrImmediateRedirectUrl={vrImmediateRedirectUrl ?? ''}
                        vacancyType={type}
                        clickUrl={clickUrl ?? ''}
                        vacancyOfTheDayClickUrl={vacancyOfTheDayClickUrl}
                        address={address}
                        isAdv={Boolean(isAdv)}
                        isArchived={Boolean(archived)}
                        employerManagerName={employerManagerName}
                    />
                    {searchDebug && <VacancySearchItemDebug {...searchDebug} />}
                    {userType === UserType.Applicant && (
                        <RespondedSuccess vacancyId={vacancyId} defaultView="search" isMagritteExp />
                    )}
                </div>
            </>
        </div>
    );
};

export default translation(VacancySearchItem);
