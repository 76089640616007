import Layout from 'src/components/IndexPageEmployer/components/Layout';
import PromoModal from 'src/components/IndexPageEmployer/components/PromoModal';

const IndexEmployer = () => {
    return (
        <>
            <Layout />
            <PromoModal />
        </>
    );
};

export default IndexEmployer;
