import { useCallback, useState, useEffect } from 'react';

import { Enter } from '@hh.ru/magritte-common-keyboard/keys';
import { FormLabel, VSpacing, Input } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import FilterWrapper from 'src/components/Search/Common/Filters/FilterWrapper';
import ListItem from 'src/components/Search/Common/Filters/ListItem';
import MobileViewControl from 'src/components/Search/Common/Filters/MobileViewControl';
import { useApplyFilter } from 'src/components/Search/Common/Filters/hooks/useApplyFilter';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { NovaFilterKey } from 'src/models/novaFilters';

const TrlKeys = {
    title: 'clusters.excluded_text',
    placeholder: 'novaFilters.excludedText',
};

const ExcludedText: TranslatedComponent = ({ trls }) => {
    const applyFilter = useApplyFilter(NovaFilterKey.ExcludedText);

    const cluster = useSelector((state) => state.searchClusters?.[NovaFilterKey.ExcludedText]);
    const selectedValue = cluster?.selectedValues?.[0] || '';

    const [userInput, setUserInput] = useState(selectedValue);
    useEffect(() => setUserInput(selectedValue), [selectedValue]);

    const handleInputChange = useCallback((value: string) => setUserInput(value), []);

    const handleFilterApply = useCallback(
        () => selectedValue !== userInput && applyFilter([userInput]),
        [applyFilter, userInput, selectedValue]
    );

    if (!cluster) {
        return null;
    }

    return (
        <MobileViewControl
            mobileView={
                <>
                    <FormLabel>{trls[TrlKeys.title]}</FormLabel>
                    <VSpacing default={12} />
                    <Input
                        clearable
                        value={userInput}
                        placeholder={trls[TrlKeys.placeholder]}
                        data-qa="novafilters-mobile-excluded-text-input"
                        onChange={handleInputChange}
                    />
                    <VSpacing default={24} />
                </>
            }
        >
            <FilterWrapper filterKey={NovaFilterKey.ExcludedText} title={trls[TrlKeys.title]}>
                <ListItem>
                    <Input
                        value={userInput}
                        data-qa="novafilters-excluded-text-input"
                        placeholder={trls[TrlKeys.placeholder]}
                        onChange={handleInputChange}
                        onBlur={handleFilterApply}
                        onKeyDown={(event) => event.code === Enter.code && handleFilterApply()}
                    />
                </ListItem>
            </FilterWrapper>
        </MobileViewControl>
    );
};

export default translation(ExcludedText);
