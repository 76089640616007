import { FC } from 'react';

import { Breakpoint } from '@hh.ru/magritte-ui';
import { PlusOutlinedSize16 } from '@hh.ru/magritte-ui/icon';

import SupernovaButton from 'src/components/SupernovaMainMenu/SupernovaButton';
import SupernovaNaviItem from 'src/components/SupernovaMainMenu/SupernovaNaviItem';
import { useIsZarplataPlatform } from 'src/hooks/usePlatform';
import { NavItem } from 'src/models/supernovaNaviMenu';

const VISIBLE_ON = [Breakpoint.S, Breakpoint.M, Breakpoint.L];

const VacancyCreate: FC<NavItem> = ({ active, name, translations, trackClicks, url, analytics }) => {
    const isZp = useIsZarplataPlatform();

    return (
        <SupernovaNaviItem active={active} isButton visibleOn={VISIBLE_ON}>
            <SupernovaButton
                isMagritte
                magritteStyle={isZp ? 'neutral' : 'contrast'}
                magritteMode="secondary"
                active={active}
                data-qa={`mainmenu_${name}`}
                analytics={analytics}
                name={name}
                trackClicks={trackClicks}
                url={url}
                magritteIcon={<PlusOutlinedSize16 />}
            >
                {translations.name}
            </SupernovaButton>
        </SupernovaNaviItem>
    );
};

export default VacancyCreate;
