import { CurrencyType } from 'src/models/currencies.types';

export interface Salary {
    amount: number;
    currency: CurrencyType;
}

export interface Area {
    id: number;
    title: string;
}

export interface RelocationArea {
    id: number;
    title: string;
    districts: District[];
    hasAllDistricts: boolean;
}

export interface District {
    id: number;
    areaId?: number;
    title: string;
}

export interface PersonalSites {
    value: PersonalSite[];
}

interface PersonalSite {
    type: PersonalSiteTypes;
    url?: string;
    text?: string;
}

export interface Language {
    degree: LanguageLevel;
    id: number;
    title: string;
}

export interface Phone {
    city?: string;
    comment?: string;
    country?: string;
    type?: string;
    verified?: boolean;
    number?: string | null;
    formatted?: string;
    raw?: string;
    canVerify?: boolean;
}

export interface Recommendation {
    id: number;
    name: string;
    organization: string;
    position: string;
}

export interface ShortExperience {
    id: number;
    startDate: string;
    endDate: string | null;
    interval?: Interval;
    companyName: string;
    companyIndustryId: number | null;
    companyIndustries: number[];
    companyAreaId: number | null;
    industries: Industry[];
    companyUrl: string | null;
    companyId: number | null;
    position: string;
    employerId: string | null;
    companyState: string | null;
    companyAreaTitle: string;
}

export interface Experience extends ShortExperience {
    description: string;
    prettyUrl: string;
    companyLogos: CompanyLogos | null;
}

export interface CompanyLogos {
    logo: CompanyLogo[];
}

export interface CompanyLogo {
    type: CompanyLogoTypes;
    url: string;
}

export enum CompanyLogoTypes {
    Original = 'ORIGINAL',
    Medium = 'medium',
    Small = 'small',
    EmployerPage = 'employerPage',
    VacancyPage = 'vacancyPage',
    SearchResultsPage = 'searchResultsPage',
}

export interface Industry {
    id: number;
    name: string;
    parentId: number | null;
    children: Industry[];
    translit: string;
    title: string;
}

export interface Interval {
    years?: number;
    months?: number;
}

export interface PhotoUrl {
    id?: string;
    state?: PhotoState;
    type?: string;
    artifactType?: string;
    title?: string | null;
    big?: string;
    preview?: string;
    avatar?: string;
}

export interface Metro {
    id: number;
    title: string;
    color: string;
}

export interface Education {
    id: number;
    name: string;
    organization: string;
    result: string;
    year: number;
    universityId: number | null;
    specialityId: number | null;
    facultyId: number | null;
}

export interface AdditionalEducation {
    id: number;
    name: string;
    organization: string;
    result: string;
    year: number;
}

export interface Proftest {
    resultsReceived: boolean;
    attached: boolean;
    visible: boolean;
    link: string;
}

export interface KeySkill {
    // TODO: id может быть только number
    id: number | string;
    general?: boolean;
    name: string;
    isConfirmedByExperience?: boolean | null;
}

export interface ProfessionalRole {
    id: number;
    string: boolean;
    trl: string;
}

export enum EmploymentType {
    Probation = 'probation',
    Full = 'full',
    Part = 'part',
    Volunteer = 'volunteer',
    Project = 'project',
}

export enum WorkSchedule {
    FullDay = 'full_day',
    Shift = 'shift',
    Flexible = 'flexible',
    Remote = 'remote',
    FlyInFlyOut = 'fly_in_fly_out',
}

export interface Provider {
    id: number;
    name: string;
}

export interface CertificateData {
    id: number;
    ownerName: string;
    transcriptionId: string;
    password: string;
    type: string;
    title: string;
    achievementDate: string;
    url: string;
    selected: boolean;
    year: number;
}

export interface Certificates {
    data: Record<number, CertificateData>;
    grouped: {
        type: string;
        years: {
            year: number;
            certificateIds: number[];
        }[];
    }[];
}

export enum DistanceValues {
    FiveHundredMetres = 'FIVE_HUNDRED_METRES',
    OneThousandMetres = 'ONE_THOUSAND_METRES',
    ThreeThousandMetres = 'THREE_THOUSAND_METRES',
    FiveThousandMetres = 'FIVE_THOUSAND_METRES',
    TenThousandMetres = 'TEN_THOUSAND_METRES',
    FifteenThousandMetres = 'FIFTEEN_THOUSAND_METRES',
}

export enum PreferredContact {
    CellPhone = 'cell',
    HomePhone = 'home_phone',
    WorkPhone = 'work_phone',
    Email = 'email',
}

export enum Relocation {
    NoRelocation = 'no_relocation',
    RelocationPossible = 'relocation_possible',
    RelocationDesirable = 'relocation_desirable',
}

export enum TravelTime {
    Any = 'any',
    LessThanHour = 'less_than_hour',
    FromHourToOneAndHalf = 'from_hour_to_one_and_half',
}

export enum TripsReadiness {
    Ready = 'ready',
    Never = 'never',
    Sometimes = 'sometimes',
}

export enum Gender {
    Male = 'male',
    Female = 'female',
    Unknown = 'unknown',
}

export type KnownGender = Gender.Male | Gender.Female;

export enum LangsList {
    AZ = 'AZ',
    EN = 'EN',
    KZ = 'KZ',
    RU = 'RU',
    UA = 'UA',
    UZ = 'UZ',
    KG = 'KG',
}

export enum Permission {
    ViewWithoutContacts = 'view_without_contacts',
    Edit = 'edit',
    View = 'view',
}

export enum Statuses {
    /** Не закончено */
    NotFinished = 'not_finished',
    /** Опубликовано, но не проверено */
    New = 'new',
    /** Изменено после проверки */
    Modified = 'modified',
    /** Проверено модератором */
    Approved = 'approved',
    /** Заблокировано модератором */
    Blocked = 'blocked',
    /** Изменено после блокировки модератором */
    Corrected = 'corrected',
    /** Проверено автомодератором */
    AutoApproved = 'auto_approved',
}

export enum Recommendations {
    /** Хорошее резюме, откликайтесь */
    Respond = 'respond',
    /** Дополните резюме */
    EditResume = 'editResume',
    /** Обновите резюме */
    UpdateResume = 'updateResume',
    /** Смените видимость */
    ChangeVisibility = 'changeVisibility',
    /** Исправьте заблокированное резюме */
    CorrectResume = 'correctResume',
    /** Модератор проверяет ваше резюме */
    OnModeration = 'onModeration',
    /** Верифицируйте телефон в резюме */
    NeedVerifiedPhone = 'needVerifiedPhone',
}

export enum HiddenFields {
    NamesAndPhoto = 'names_and_photo',
    Phones = 'phones',
    Email = 'email',
    OtherContacts = 'other_contacts',
    Experience = 'experience',
}

export enum EducationLevel {
    None = 'none',
    Higher = 'higher',
    Bachelor = 'bachelor',
    Master = 'master',
    Candidate = 'candidate',
    Doctor = 'doctor',
    UnfinishedHigher = 'unfinished_higher',
    Secondary = 'secondary',
    SpecialSecondary = 'special_secondary',
}

export enum LanguageLevel {
    Basic = 'basic',
    Fluent = 'fluent',
    CanPassInterview = 'can_pass_interview',
    Native = 'native',
    CanRead = 'can_read',
    A1 = 'a1',
    A2 = 'a2',
    B1 = 'b1',
    B2 = 'b2',
    C1 = 'c1',
    C2 = 'c2',
    L1 = 'l1',
}

export enum PersonalSiteTypes {
    Freelance = 'freelance',
    Personal = 'personal',
    Livejournal = 'livejournal',
    MoiKrug = 'moi_krug',
    Facebook = 'facebook',
}

export enum PhotoState {
    Processed = 'processed',
    New = 'new',
    Corrupted = 'corrupted',
}

export enum AutoHideTime {
    FiveYears = 'year_5',
    ThreeYears = 'year_3',
    TwoYears = 'year_2',
    TwelveMonths = 'month_12',
    TenMonths = 'month_10',
    EightMonths = 'month_8',
}
