import { useDispatch } from 'react-redux';

import SearchType from 'src/components/Search/Common/Filters/types';
import getSearchParams from 'src/components/Search/Common/Filters/utils/getSearchParams';
import { EmployerVacancySearchParams } from 'src/components/Search/Common/Filters/utils/getSearchParams/employerVacancy';
import { ResumeSearchParams } from 'src/components/Search/Common/Filters/utils/getSearchParams/resume';
import { VacancySearchParams } from 'src/components/Search/Common/Filters/utils/getSearchParams/vacancy';
import { VacancyMapSearchParams } from 'src/components/Search/Common/Filters/utils/getSearchParams/vacancyMap';
import { useSelector } from 'src/hooks/useSelector';
import { CriteriaKey } from 'src/models/search/searchCriteria.types';

export const useSearchParams = ():
    | VacancySearchParams
    | ResumeSearchParams
    | VacancyMapSearchParams
    | EmployerVacancySearchParams => {
    const dispatch = useDispatch();
    const criteriaCurrencyCode = useSelector(({ criteriaCurrencyCode }) => criteriaCurrencyCode);
    const criteriaTextUpdated = useSelector(({ criteriaTextUpdated }) => criteriaTextUpdated);
    const employerVacancySearch = useSelector(({ employerVacancySearch }) => employerVacancySearch);
    const employerVacancyTemplateFilter = useSelector((state) => state.employerVacancyTemplateFilter);
    const hasChameleon = useSelector((state) => state.hasChameleon);
    const employerInfo = useSelector(({ employerInfo }) => employerInfo);
    const currencies = useSelector(({ currencies }) => currencies);
    const enableVacancySnippets = useSelector(({ enableVacancySnippets }) => enableVacancySnippets);
    const novaSorts = useSelector(({ novaSorts }) => novaSorts);
    const resumeSearchResult = useSelector(({ resumeSearchResult }) => resumeSearchResult);
    const router = useSelector(({ router }) => router);
    const searchCatalogRedirect = useSelector(({ searchCatalogRedirect }) => searchCatalogRedirect);
    const searchClusters = useSelector(({ searchClusters }) => searchClusters);
    const searchClustersSettings = useSelector(({ searchClustersSettings }) => searchClustersSettings);
    const searchSessionId = useSelector(({ searchSessionId }) => searchSessionId);
    const vacancySearchResult = useSelector(({ vacancySearchResult }) => vacancySearchResult);
    const isAdjustDetectedRegionResumeExpEnabled = useSelector(
        ({ isAdjustDetectedRegionResumeExpEnabled }) => isAdjustDetectedRegionResumeExpEnabled
    );
    const { type: searchType } = searchClustersSettings;

    const searchParams = getSearchParams({
        dispatch,
        criteriaCurrencyCode,
        criteriaTextUpdated:
            searchType === SearchType.EmployerVacancy
                ? employerVacancySearch.criteria?.[CriteriaKey.Text] || ''
                : criteriaTextUpdated,
        currencies,
        enableVacancySnippets,
        router,
        novaSorts,
        resumeSearchResult,
        searchCatalogRedirect,
        searchClusters,
        searchType,
        searchSessionId,
        vacancySearchResult,
        employerVacancySearch,
        employerInfo,
        isAdjustDetectedRegionResumeExpEnabled,
        employerVacancyTemplateFilter,
        hasChameleon,
    });

    return searchParams;
};
