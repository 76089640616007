import { FC, PropsWithChildren, ComponentProps } from 'react';

import { GridColumn as MagritteGridColumn, GridColumnProps } from '@hh.ru/magritte-ui';
import Column from 'bloko/blocks/column';

import { useMagritte as useRedesign } from 'src/hooks/useMagritte';

type BlokoColumnProps = ComponentProps<typeof Column>;
type MagritteGridColumnProps = (GridColumnProps & { container?: boolean }) | BlokoColumnProps;

const GridColumn: FC<MagritteGridColumnProps & PropsWithChildren> = ({ children, container, ...props }) => {
    const isMagritte = useRedesign();

    if (isMagritte) {
        return <MagritteGridColumn {...(props as GridColumnProps)}>{children}</MagritteGridColumn>;
    }

    return (
        <Column {...(props as BlokoColumnProps)} container={container}>
            {children}
        </Column>
    );
};

export default GridColumn;
