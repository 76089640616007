import { useCallback, useEffect, useState } from 'react';
import { RouterLocation } from 'connected-react-router';

import urlParser from 'bloko/common/urlParser';

import { useSearchParams } from 'src/components/Search/Common/Filters/hooks/useSearchParams';
import SearchType from 'src/components/Search/Common/Filters/types';
import { VacancySearchParams } from 'src/components/Search/Common/Filters/utils/getSearchParams/vacancy';
import { VacancyMapSearchParams } from 'src/components/Search/Common/Filters/utils/getSearchParams/vacancyMap';
import { ItemMap } from 'src/components/Search/Vacancy/Filters/FilterWithAdditionalList/types';
import { useSelector } from 'src/hooks/useSelector';
import { NovaFilterKey } from 'src/models/novaFilters';
import fetcher from 'src/utils/fetcher';

const URL = '/shards/search_additional_list';

declare global {
    interface FetcherGetApi {
        [k: `${typeof URL}${string}`]: {
            queryParams: never;
            body: never;
            response: ItemMap;
        };
    }
}

const fetchAdditionalList = async (
    searchType: SearchType,
    location: RouterLocation<unknown>,
    searchParams: VacancySearchParams | VacancyMapSearchParams,
    filterName: NovaFilterKey
) => {
    const query = urlParser.stringify(searchParams as never);
    const urlObj = urlParser(`${location.pathname}?${query}`);
    urlObj.params = { ...urlObj.params, searchType, filterName };

    return fetcher.get(`${URL}${urlObj.search}`);
};

type UseAdditionalListHook = (filterName: NovaFilterKey) => {
    showAdditionalList: boolean;
    toggleShowAdditionalList: () => void;
    isLoading: boolean;
    list: ItemMap | null;
};

// Additional list is fetched on first 'show all' button click
// If other filters are applied, additional list is not valid anymore and we collapse them
// It will be fetched again on next 'show all' click
export const useAdditionalList: UseAdditionalListHook = (filterName) => {
    const [list, setList] = useState<ItemMap | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [showAdditionalList, setShowAdditionalList] = useState(false);

    const searchType = useSelector((state) => state.searchClustersSettings.type);
    const location = useSelector((state) => state.router.location);
    const searchParams = useSearchParams();

    const lastUpdatedSearchCluster = useSelector((state) => state.lastUpdatedSearchCluster);

    useEffect(() => {
        // If any filter other than this filter changes, we collapse list and fetch again on next 'show all' click
        if (lastUpdatedSearchCluster === filterName) {
            return;
        }

        setList(null);
        setShowAdditionalList(false);
    }, [filterName, lastUpdatedSearchCluster]);

    const toggleShowAdditionalList = useCallback(async () => {
        if (isLoading) {
            return;
        }

        if (list) {
            setShowAdditionalList((prevState) => !prevState);
            return;
        }

        setIsLoading(true);

        try {
            const updatedList = await fetchAdditionalList(
                searchType,
                location,
                searchParams as VacancySearchParams | VacancyMapSearchParams,
                filterName
            );
            setList(updatedList);
            setShowAdditionalList(true);
        } catch (error) {
            setList(null);
            setShowAdditionalList(false);
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    }, [isLoading, list, searchType, location, searchParams, filterName]);

    return { showAdditionalList, toggleShowAdditionalList, isLoading, list };
};
