import { FormLabel, VSpacing, Radio } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import FilterItem from 'src/components/Search/Common/Filters/FilterItem';
import FilterWrapper from 'src/components/Search/Common/Filters/FilterWrapper';
import MobileViewControl from 'src/components/Search/Common/Filters/MobileViewControl';
import { useApplyFilter } from 'src/components/Search/Common/Filters/hooks/useApplyFilter';
import ChipsSelect from 'src/components/Search/Vacancy/Filters/FilterChips/Select';
import { ChipsSelectType } from 'src/components/Search/Vacancy/Filters/FilterChips/types';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { NovaFilterKey } from 'src/models/novaFilters';

const EMPTY_ARRAY = [] as const;

const TrlKeys = {
    doesNotMatter: 'vacancySearch.experience.doesNotMatter',
    title: 'searchvacancy.clusters.experience',
};

const Experience: TranslatedComponent = ({ trls }) => {
    const applyFilter = useApplyFilter(NovaFilterKey.Experience);

    const groups = useSelector((state) => state.searchClusters[NovaFilterKey.Experience].groups);
    const selectedValues =
        useSelector((state) => state.searchClusters[NovaFilterKey.Experience].selectedValues) || EMPTY_ARRAY;

    return (
        <MobileViewControl
            mobileView={
                <>
                    <FormLabel>{trls[TrlKeys.title]}</FormLabel>
                    <VSpacing default={12} />
                    <ChipsSelect
                        name={NovaFilterKey.Experience}
                        selected={selectedValues}
                        options={Object.values(groups)}
                        selectType={ChipsSelectType.Radio}
                        onChange={(experience) => applyFilter(experience)}
                    />
                    <VSpacing default={24} />
                </>
            }
        >
            <FilterWrapper filterKey={NovaFilterKey.Experience} title={trls[TrlKeys.title]}>
                <FilterItem
                    left={<Radio onChange={() => applyFilter([])} checked={!selectedValues.length} />}
                    title={trls[TrlKeys.doesNotMatter]}
                />
                {Object.values(groups).map(({ id, title, count, disabled }) => (
                    <FilterItem
                        key={id}
                        left={
                            <Radio value={id} onChange={() => applyFilter([id])} checked={selectedValues[0] === id} />
                        }
                        title={title}
                        disabled={disabled}
                        count={count}
                    />
                ))}
            </FilterWrapper>
        </MobileViewControl>
    );
};

export default translation(Experience);
