import { useCallback } from 'react';

import {
    useDebouncedCountsRequestMobile,
    useDebouncedSendFilterFormDesktop,
} from 'src/components/Search/Common/Filters/hooks/useSendFilterForm';

export const useApplyFilters = (): (() => void) => {
    const debouncedCountsRequestMobile = useDebouncedCountsRequestMobile();
    const debouncedSendFilterFormDesktop = useDebouncedSendFilterFormDesktop();

    return useCallback(() => {
        debouncedCountsRequestMobile();
        debouncedSendFilterFormDesktop();
    }, [debouncedCountsRequestMobile, debouncedSendFilterFormDesktop]);
};
