import { FC } from 'react';

import { MagnifierOutlinedSize16 } from '@hh.ru/magritte-ui/icon';
import useBreakpoint, { Breakpoint } from 'bloko/common/hooks/useBreakpoint';

import BlokoIconReplaceContainer from 'src/components/BlokoIconReplaceContainer';

const InputIcon: FC = () => {
    const isXs = useBreakpoint() === Breakpoint.XS;

    if (isXs) {
        return (
            <span className="supernova-search-bold-icon">
                <span className="supernova-icon supernova-icon_search-primary" />
            </span>
        );
    }

    return (
        <BlokoIconReplaceContainer>
            <MagnifierOutlinedSize16 />
        </BlokoIconReplaceContainer>
    );
};

export default InputIcon;
