import { FC, PropsWithChildren } from 'react';

import { Avatar, Card, Cell, CellText, Link as MagritteLink, useBreakpoint } from '@hh.ru/magritte-ui';
import { Link } from '@hh.ru/redux-spa-middleware';

import { useMagritte } from 'src/hooks/useMagritte';
import { useSelector } from 'src/hooks/useSelector';
import { ArticleItemWithImage } from 'src/models/article.types';

const NewsBoxItem: FC<ArticleItemWithImage & PropsWithChildren> = ({ href, image, title, isArticle = false }) => {
    const analyticsType = isArticle ? 'article_item.articleItem' : 'news_item.newsItem';
    const isAnonymousMagritteExp = useSelector((state) => state.isAnonymousMagritteExp);
    const isMagritte = useMagritte() || isAnonymousMagritteExp;
    const { isMobile } = useBreakpoint();

    if (isMagritte) {
        return (
            <Card borderRadius={16} padding={isMobile ? 16 : 0} showBorder={isMobile}>
                <Cell
                    left={
                        <Avatar
                            image={image}
                            size={48}
                            mode="image"
                            fallbackMode="placeholder"
                            placeholder="city"
                            aria-label={title}
                        />
                    }
                >
                    <CellText maxLines={2}>
                        <MagritteLink
                            Element={Link}
                            to={href}
                            style="neutral"
                            data-page-analytics-event={analyticsType}
                        >
                            {title}
                        </MagritteLink>
                    </CellText>
                </Cell>
            </Card>
        );
    }

    return (
        <li className="news-box-item">
            <Link to={href} data-page-analytics-event={analyticsType}>
                <span className="news-box-item__link">
                    <img loading="lazy" className="news-box-item__image" src={image} />
                    {title}
                </span>
            </Link>
        </li>
    );
};

export default NewsBoxItem;
