import { type ReactElement, useState, useRef } from 'react';

import { Card, Link, VSpacing, VSpacingContainer, Text as MagritteText, useCollapsible } from '@hh.ru/magritte-ui';
import { ChevronUpOutlinedSize16, ChevronDownOutlinedSize16 } from '@hh.ru/magritte-ui/icon';
import { FormItem } from 'bloko/blocks/form';
import Text from 'bloko/blocks/text';
import { type TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { formatToReactComponent } from 'bloko/common/trl';

import translation from 'src/components/translation';
import useMagritteResponseExp from 'src/hooks/useMagritteResponseExp';
import type { ResponseStatusResume, ShortVacancy } from 'src/models/applicantVacancyResponseStatuses';

import VacancyResponseFormResume from 'src/components/VacancyResponseForm/Resume';
import { RESUME_SELECTOR_LIMIT_TO_COLLAPSE } from 'src/components/VacancyResponseForm/constants';

const TrlKeys = {
    resumesTitle: 'vacancy.response.popup.resumes',
    questionResumesTitle: 'vacancy.questionResponse.popup.resumes',
    hiddenResumes: 'vacancy.response.popup.hidden.resumes',
    showMore: 'vacancy.response.popup.resumes.action.showMore',
    showLess: 'vacancy.response.popup.resumes.action.showLess',
};

interface ResumeSelectorProps {
    responseStatus: ShortVacancy;
    resumes: ResponseStatusResume[];
    selectedResume: ResponseStatusResume;
    isQuestionResponse?: boolean;
    setSelectedResume: (value: ResponseStatusResume) => void;
    onResumeSelected?: () => void;
    isBottomSheet?: boolean;
}

const ResumeSelector: TranslatedComponent<ResumeSelectorProps> = ({
    trls,
    responseStatus,
    resumes,
    selectedResume,
    isQuestionResponse,
    setSelectedResume,
    onResumeSelected,
    isBottomSheet,
}) => {
    const vacancy = responseStatus.shortVacancy;
    const hasHiddenResumes = responseStatus.hiddenResumeIds.length > 0;
    const isSingleResume = resumes.length === 1;

    const { isMagritteResponseExpAny } = useMagritteResponseExp();
    const hasCollapsibleItems = !isBottomSheet && resumes.length > RESUME_SELECTOR_LIMIT_TO_COLLAPSE;
    const collapsibleElemRef = useRef<HTMLDivElement>(null);

    // We store resumes as list with preselected resume as first item to always show it on top of the list.
    const sortedResumes = useRef(
        resumes.reduce((result, resume) => {
            if (resume.id === selectedResume.id) {
                result.unshift(resume);
            } else {
                result.push(resume);
            }

            return result;
        }, [] as ResponseStatusResume[])
    );

    // As we store selected resume on top of the list we can display list collapsed by default,
    // so selected value will never be collapsed.
    const [isCollapsed, setIsCollapsed] = useState(true);
    const { collapsibleClasses } = useCollapsible(collapsibleElemRef, !isCollapsed);

    const itemRenderer = (resume: ResponseStatusResume): ReactElement<typeof VacancyResponseFormResume> => {
        const selectResume = () => {
            setSelectedResume(resume);
            onResumeSelected?.();

            // Хак, чтобы побороть "Магритовское расстройство" (c) @e.savin
            window.requestAnimationFrame(() => {
                if (collapsibleElemRef.current) {
                    collapsibleElemRef.current.style.maxHeight = 'max-content';
                }
            });
        };

        return (
            <Card
                key={resume.hash}
                padding={16}
                borderRadius={16}
                showBorder
                stretched
                actionCard
                onClick={selectResume}
            >
                <VacancyResponseFormResume
                    resume={resume}
                    single={isSingleResume}
                    hidden={responseStatus.hiddenResumeIds.includes(String(resume.id))}
                    selected={resume.id === selectedResume.id}
                    onSelect={selectResume}
                    visibility={responseStatus.resumeVisibility?.[resume.id]}
                    vacancy={vacancy}
                    isQuestion={isQuestionResponse}
                    isBottomSheet={isBottomSheet}
                />
            </Card>
        );
    };

    if (isMagritteResponseExpAny) {
        return (
            <div>
                <VSpacingContainer default={12}>
                    {hasHiddenResumes && (
                        <MagritteText typography="label-3-regular">{trls[TrlKeys.hiddenResumes]}</MagritteText>
                    )}
                    {sortedResumes.current
                        .slice(0, hasCollapsibleItems ? RESUME_SELECTOR_LIMIT_TO_COLLAPSE : Infinity)
                        .map(itemRenderer)}
                </VSpacingContainer>

                {hasCollapsibleItems && (
                    <VSpacingContainer default={12}>
                        <div className={collapsibleClasses} ref={collapsibleElemRef}>
                            <VSpacing default={12} />
                            <VSpacingContainer default={12}>
                                {sortedResumes.current.slice(RESUME_SELECTOR_LIMIT_TO_COLLAPSE).map(itemRenderer)}
                            </VSpacingContainer>
                        </div>
                        <Link
                            Element="button"
                            iconRight={isCollapsed ? ChevronDownOutlinedSize16 : ChevronUpOutlinedSize16}
                            disabled={
                                !isCollapsed &&
                                sortedResumes.current.findIndex((resume) => resume.id === selectedResume.id) >=
                                    RESUME_SELECTOR_LIMIT_TO_COLLAPSE
                            }
                            onClick={(event) => {
                                event.preventDefault();
                                setIsCollapsed(!isCollapsed);
                            }}
                        >
                            {isCollapsed
                                ? formatToReactComponent(trls[TrlKeys.showMore], {
                                      '{0}': resumes.length - RESUME_SELECTOR_LIMIT_TO_COLLAPSE,
                                  })
                                : trls[TrlKeys.showLess]}
                        </Link>
                    </VSpacingContainer>
                )}
            </div>
        );
    }

    return (
        <>
            <div className="vacancy-response-popup-subtitle">
                <Text strong>{trls[isQuestionResponse ? TrlKeys.questionResumesTitle : TrlKeys.resumesTitle]}</Text>
            </div>
            <div className="vacancy-response-popup-resume-list" data-qa="vacancy-response-resume-list">
                {hasHiddenResumes && <FormItem>{trls[TrlKeys.hiddenResumes]}</FormItem>}
                {resumes.map((resume) => {
                    return (
                        <VacancyResponseFormResume
                            resume={resume}
                            key={resume.hash}
                            selected={resume.id === selectedResume.id}
                            single={isSingleResume}
                            hidden={responseStatus.hiddenResumeIds.includes(String(resume.id))}
                            onSelect={() => setSelectedResume(resume)}
                            visibility={responseStatus.resumeVisibility?.[resume.id]}
                            vacancy={vacancy}
                            isQuestion={isQuestionResponse}
                        />
                    );
                })}
            </div>
        </>
    );
};

export default translation(ResumeSelector);
