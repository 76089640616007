import { FC, ReactNode } from 'react';

import styles from './styles.less';

interface BlokoIconReplaceContainerProps {
    children: ReactNode;
}

/**
 * Компонент нужен для замены блоко иконок на магритовские, чтобы при этом не посыпалась верстка,
 * так как позиционирование у этих иконок разное
 */
const BlokoIconReplaceContainer: FC<BlokoIconReplaceContainerProps> = ({ children }) => {
    return (
        <div className="bloko-icon">
            <div className={styles.container}>{children}</div>
        </div>
    );
};

export default BlokoIconReplaceContainer;
