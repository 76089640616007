import { useCallback, useEffect, RefObject, MouseEventHandler, MouseEvent, ReactNode, ComponentType } from 'react';
import classnames from 'classnames';

import { Button as MagritteButton, useBreakpoint as useMagritteBreakpoint } from '@hh.ru/magritte-ui';
import { Link } from '@hh.ru/redux-spa-middleware';
import Button, { ButtonKind, ButtonAppearance, ButtonScale, ButtonType } from 'bloko/blocks/button';
import Loading, { LoadingScale, LoadingColor } from 'bloko/blocks/loading';
import useBreakpoint, { Breakpoint } from 'bloko/common/hooks/useBreakpoint';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import VacancyResponseLink from 'src/components/VacancyResponseLink';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { UserType } from 'src/models/userType';

import { scrollWithAttention, RESUME_FORM_ATTENTION_CLASS } from 'src/components/VacancyView/scrolling';

export enum VacancyActionResponseKind {
    Main = 'main',
    AfterBody = 'afterBody',
    ResponseSummary = 'responseSummary',
    VideoVacancy = 'videoVacancy',
    Notification = 'notification',
}

const TrlKeys = {
    response: 'button.placeResponse',
};

interface VacancyActionResponseProps {
    vacancyBodyFooterNodeRef?: RefObject<HTMLDivElement>;
    resumeFormNodeRef?: RefObject<HTMLDivElement>;
    hasForceQuickResponse?: boolean;
    isSecondary?: boolean;
    responseButtonProps?: Record<string, unknown>;
    isRedesign?: boolean;
    isMagritteOnVacancyResponseExp?: boolean;
    isMagritteVacancy?: boolean;
    kind?: VacancyActionResponseKind;
    forceFullSize?: boolean;
    onResponseComplete?: () => void;
    renderCustomWrapper?: (content: ReactNode) => JSX.Element;
    hideResponded?: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    RespondedNode?: ComponentType<any>;
    onScrollToSignupForm?: () => void;
    onPopupCancelCallback?: () => void;
    onRespondClick?: () => void;
}

const VacancyActionResponsePlace = {
    [VacancyActionResponseKind.Main]: 'vacancy_top_button',
    [VacancyActionResponseKind.AfterBody]: 'vacancy_bottom_button',
    [VacancyActionResponseKind.ResponseSummary]: 'vacancy_response_summary',
    [VacancyActionResponseKind.VideoVacancy]: 'vacancy_video_vacancy_modal',
    [VacancyActionResponseKind.Notification]: 'vacancy_notification',
};

const VacancyActionResponse: TranslatedComponent<VacancyActionResponseProps> = ({
    trls,
    vacancyBodyFooterNodeRef,
    resumeFormNodeRef,
    hasForceQuickResponse = false,
    isSecondary = false,
    responseButtonProps,
    hideResponded = false,
    RespondedNode = Button,
    kind,
    forceFullSize,
    onResponseComplete,
    isRedesign,
    isMagritteOnVacancyResponseExp = false,
    isMagritteVacancy,
    renderCustomWrapper,
    onScrollToSignupForm,
    onPopupCancelCallback,
    onRespondClick,
}) => {
    const vacancyView = useSelector((state) => state.vacancyView);

    const contactInfo = useSelector((state) => state.vacancyView.contactInfo);
    const vacancyId = useSelector((state) => state.vacancyView.vacancyId);
    const employerId = useSelector((state) => state.vacancyView.company?.id);
    const showSignupForm = useSelector((state) => state.vacancyView.showSignupForm);
    const isBrandingPreview = useSelector((state) => state.vacancyView.isBrandingPreview);
    const responseStatus = useSelector((state) =>
        vacancyView.vacancyId ? state.applicantVacancyResponseStatuses[vacancyView.vacancyId] : undefined
    );
    const hasResponseStatus = Boolean(responseStatus && Object.keys(responseStatus).length > 1);
    const userType = useSelector((state) => state.userType);
    const doResponse = !!vacancyView.responseInfo?.doResponse;
    const isQuestion = !!vacancyView.responseInfo?.isQuestion;
    const showResponse = !!vacancyView.responseInfo?.showResponse;
    const { shortResume } = useSelector(({ router }) => router.location.query);
    const breakpoint = useBreakpoint();
    const isXs = breakpoint === Breakpoint.XS;
    const showContactsInfo = contactInfo && !(userType === UserType.Anonymous && isXs);
    const allowShowContactsButton = showContactsInfo || isBrandingPreview;
    const alreadyResponded = Boolean(responseStatus?.usedResumeIds?.length);
    const { isMobile } = useMagritteBreakpoint();

    const loadScrollHandler = useCallback(() => {
        if (resumeFormNodeRef?.current) {
            scrollWithAttention(resumeFormNodeRef.current, RESUME_FORM_ATTENTION_CLASS);
        }
    }, [resumeFormNodeRef]);

    const loadScrollHandlerToFooter = useCallback(() => {
        if (vacancyBodyFooterNodeRef?.current) {
            scrollWithAttention(vacancyBodyFooterNodeRef.current, RESUME_FORM_ATTENTION_CLASS);
        }
    }, [vacancyBodyFooterNodeRef]);

    useEffect(() => {
        if (resumeFormNodeRef?.current && shortResume) {
            window.addEventListener('load', loadScrollHandler);
        } else if (vacancyBodyFooterNodeRef?.current && showResponse) {
            window.addEventListener('load', loadScrollHandlerToFooter);
        }

        return () => {
            window.removeEventListener('load', loadScrollHandler);
            window.removeEventListener('load', loadScrollHandlerToFooter);
        };
    }, [
        resumeFormNodeRef,
        shortResume,
        loadScrollHandler,
        showResponse,
        vacancyBodyFooterNodeRef,
        loadScrollHandlerToFooter,
    ]);

    const renderWrapper = (content: ReactNode) => {
        if (renderCustomWrapper) {
            return renderCustomWrapper(content);
        }

        const isFullSizeDisabled = isMagritteVacancy && alreadyResponded;
        const isFullSize =
            forceFullSize ||
            (!isFullSizeDisabled && allowShowContactsButton && kind === VacancyActionResponseKind.Main);

        return (
            <div
                className={classnames('vacancy-action', {
                    'vacancy-action_full-size': isFullSize,
                    'vacancy-action_full-size-redesigned': isRedesign && isFullSize,
                    'vacancy-action_stretched':
                        !forceFullSize && !allowShowContactsButton && kind === VacancyActionResponseKind.Main,
                    'vacancy-action_stretched-redesigned': isRedesign,
                    'vacancy-action_after-body':
                        kind !== VacancyActionResponseKind.Main && kind !== VacancyActionResponseKind.Notification,
                    'vacancy-action_after-body-no-margin': isMagritteOnVacancyResponseExp,
                })}
            >
                {content}
            </div>
        );
    };

    if (isBrandingPreview) {
        return renderWrapper(
            <Button type={ButtonType.Button} scale={ButtonScale.Large} disabled kind={ButtonKind.Success} stretched>
                {trls[TrlKeys.response]}
            </Button>
        );
    }

    if (!vacancyId) {
        return null;
    }

    if (userType === UserType.Applicant && !hasResponseStatus) {
        return null;
    }

    const onButtonClick = (event: MouseEvent, onClickCallback: MouseEventHandler) => {
        event.preventDefault();
        onRespondClick?.();

        onClickCallback(event);
    };

    const renderButton = (onClickCallback: (event?: MouseEvent) => void) => {
        const toUrl = `/applicant/vacancy_response?vacancyId=${vacancyId || ''}${
            employerId ? `&employerId=${employerId}` : ''
        }`;
        const isLoading = responseStatus?.isFetching && !responseStatus?.isFetchingWithQuestionResponse;

        if (isMagritteVacancy) {
            return (
                <MagritteButton
                    size={isMobile ? 'small' : 'medium'}
                    style="accent"
                    mode="primary"
                    stretched
                    Element={Link}
                    to={toUrl}
                    rel="nofollow"
                    onClick={(event) => onButtonClick(event, onClickCallback)}
                    loading={isLoading}
                    disabled={!!responseStatus?.isFetching}
                    data-qa="vacancy-response-link-top"
                >
                    {trls[TrlKeys.response]}
                </MagritteButton>
            );
        }

        return (
            <Button
                stretched
                scale={isRedesign ? ButtonScale.Large : undefined}
                Element={Link}
                rel="nofollow"
                to={toUrl}
                data-qa="vacancy-response-link-top"
                loading={isLoading && <Loading initial={LoadingColor.White} scale={LoadingScale.Small} />}
                disabled={!!responseStatus?.isFetching}
                onClick={(event) => onButtonClick(event, onClickCallback)}
                kind={ButtonKind.Success}
                {...responseButtonProps}
            >
                {trls[TrlKeys.response]}
            </Button>
        );
    };

    const renderRespondedButton = ({ text, onClick }: { text: string; onClick: () => void }) => {
        if (hideResponded) {
            return null;
        }

        const dataQa = 'vacancy-response-link-view-topic';

        if (isMagritteVacancy) {
            return (
                <MagritteButton
                    mode="secondary"
                    style="accent"
                    size={isMobile ? 'small' : 'medium'}
                    stretched
                    onClick={onClick}
                    data-qa={dataQa}
                >
                    {text}
                </MagritteButton>
            );
        }

        return (
            <RespondedNode
                Element="button"
                scale={isRedesign ? ButtonScale.Large : undefined}
                kind={isRedesign || kind === VacancyActionResponseKind.VideoVacancy ? ButtonKind.Success : undefined}
                onClick={onClick}
                data-qa={dataQa}
                {...(RespondedNode === Button && { stretched: true })}
                {...(responseButtonProps || { appearance: ButtonAppearance.Outlined })}
            >
                {text}
            </RespondedNode>
        );
    };

    return renderWrapper(
        <VacancyResponseLink
            vacancyId={vacancyId}
            employerId={employerId}
            doResponse={!isSecondary && doResponse}
            isQuestion={isQuestion}
            hasForceQuickResponse={hasForceQuickResponse}
            render={renderButton}
            renderResponded={renderRespondedButton}
            scrollToSignupForm={showSignupForm}
            vacancyBodyFooterNodeRef={vacancyBodyFooterNodeRef}
            enableRelocationWarning
            onResponseComplete={onResponseComplete}
            onScrollToSignupForm={onScrollToSignupForm}
            onPopupCancelCallback={onPopupCancelCallback}
            place={kind && VacancyActionResponsePlace[kind]}
        />
    );
};

export default translation(VacancyActionResponse);
