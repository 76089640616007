import React, { useContext } from 'react';

import { Breakpoint } from 'bloko/common/media';

import useFeatureEnabled from 'src/hooks/useFeatureEnabled';
import { useSelector } from 'src/hooks/useSelector';
import { NavItem } from 'src/models/supernovaNaviMenu';

import DropdownContent from 'src/components/SupernovaMainMenu/DropdownContent';
import SupernovaActionLink from 'src/components/SupernovaMainMenu/SupernovaActionLink';
import SupernovaCounter from 'src/components/SupernovaMainMenu/SupernovaCounter';
import SupernovaDropdown from 'src/components/SupernovaMainMenu/SupernovaDropdown';
import SupernovaDropdownSection from 'src/components/SupernovaMainMenu/SupernovaDropdownSection';
import SupernovaNaviItem from 'src/components/SupernovaMainMenu/SupernovaNaviItem';
import mainMenuAnalytics from 'src/components/SupernovaMainMenu/analytics';
import useIsAuthorizedRedesign from 'src/components/SupernovaMainMenu/hooks/useIsAuthorizedRedesign';
import isRedesignActiveContext from 'src/components/SupernovaMainMenu/isRedesignActiveContext';

const NAVI_ITEM_PROPS: Record<string, Record<string, unknown>> = {
    employerServices: {
        visibleOn: [Breakpoint.L],
    },
    vacancy: {
        visibleOn: [Breakpoint.M, Breakpoint.L],
    },
};

const NAVI_REDESIGN_ANONYMOUS_ITEM_PROPS: Record<string, Record<string, unknown>> = {
    employerServices: {
        visibleOn: [Breakpoint.M, Breakpoint.L],
    },
    vacancy: {},
};

const DROPDOWN_PROPS: Record<string, Record<string, unknown>> = {
    vacancy: {
        arrowBreakpoints: [Breakpoint.XS, Breakpoint.S],
    },
};

const VISIBLE_ON = [Breakpoint.S, Breakpoint.M, Breakpoint.L];

const Features = {
    employerMainPageAnalyticsEnabled: 'employer_main_page_analytics_enabled',
};

const NavItemWithDropdown: React.FC<NavItem> = ({
    active,
    counter,
    name,
    subItems,
    translations,
    analytics,
    trackClicks,
}) => {
    const employerMainPageAnalyticsEnabled = useFeatureEnabled(Features.employerMainPageAnalyticsEnabled);
    const userStat = useSelector(({ userStats }) => counter && userStats?.[counter]);
    const isRedesignActive = useContext(isRedesignActiveContext);
    const isRedesignAuthorized = useIsAuthorizedRedesign();

    const render = React.useCallback(() => {
        return (
            <SupernovaDropdownSection mainContent>
                <DropdownContent items={subItems} />
            </SupernovaDropdownSection>
        );
    }, [subItems]);

    const handleSubMenuItemsShown = () => {
        if (!subItems?.length) {
            return;
        }

        if (employerMainPageAnalyticsEnabled) {
            const subMenuItems = subItems.map((item) => item.name);
            mainMenuAnalytics.mainMenuSubItemsShown(subMenuItems);
        }
    };

    let activator = (
        <button className="supernova-link" data-qa={`mainmenu_${name}`} type="button">
            {translations.name}
            {!!userStat && <SupernovaCounter>{userStat}</SupernovaCounter>}
        </button>
    );

    if (isRedesignActive) {
        activator = (
            <SupernovaActionLink
                name={name}
                active={active}
                badgeCount={userStat}
                showBadge={!!userStat}
                analytics={analytics}
                trackClicks={trackClicks}
                data-qa={`mainmenu_${name}`}
            >
                {translations.name}
            </SupernovaActionLink>
        );
    }

    return (
        <SupernovaNaviItem
            active={active}
            visibleOn={VISIBLE_ON}
            {...(isRedesignActive && !isRedesignAuthorized
                ? NAVI_REDESIGN_ANONYMOUS_ITEM_PROPS?.[name]
                : NAVI_ITEM_PROPS?.[name])}
        >
            <SupernovaDropdown
                activator={activator}
                anchorFullHeight
                render={render}
                onShown={handleSubMenuItemsShown}
                {...DROPDOWN_PROPS?.[name]}
            />
            {!isRedesignActive && <div className="supernova-navi-underline">{translations.name}</div>}
        </SupernovaNaviItem>
    );
};

export default NavItemWithDropdown;
