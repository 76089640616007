import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

export enum ManagerInactiveTrlCode {
    Online = 'online',
    Today = 'today',
    Yesterday = 'yesterday',
    Week = 'week',
    Month = 'month',
    WeekExact = 'weekExact',
}

export interface ManagerActivityData {
    '@managerHhid'?: string;

    trl_code: ManagerInactiveTrlCode;

    inactive_days: number;
}

export default autoGeneratedReducer<ManagerActivityData[]>([]);
