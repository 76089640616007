import { createRef, Component, Fragment, StrictMode } from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import PropTypes from 'prop-types';

import StoreDevHelperProvider from '@hh.ru/front-static-app/lib/browserApp/components/StoreDevHelperProvider';
import DevBuildLoader from '@hh.ru/front-static-app/lib/browserApp/components/devLoader';
import {
    BreakpointProvider as MagritteBreakpointProvider,
    GridHighlighter,
    SnackbarContextProvider,
} from '@hh.ru/magritte-ui';
import BlokoBreakpointProvider from 'bloko/blocks/breakpointProvider';
import Column, { ColumnsWrapper } from 'bloko/blocks/column';
import { TipProvider } from 'bloko/blocks/drop';
import { TranslationLangContext } from 'bloko/common/hooks/useTranslations';

import Debug from 'HHC/Debug';
import AppPromoBanner from 'src/components/AppPromoBanner';
import BannersLayer from 'src/components/BannersLayer';
import ChatikIntegration from 'src/components/ChatikIntegration';
import ContainerForXSL from 'src/components/CodeInjector/ContainerForXSL';
import DeepLinkCommand from 'src/components/DeepLinkCommand';
import FetcherErrorHandler from 'src/components/FetcherErrorHandler';
import LinearProgress from 'src/components/LinearProgress';
import MagritteThemeProvider from 'src/components/MagritteThemeProvider';
import NotificationContextProvider, { NotificationsRenderer } from 'src/components/Notifications/Provider';
import ReduxNotifications from 'src/components/Notifications/ReduxNotifications';
import { AppHeadProvider } from 'src/components/PageHead';
import ResumesCountriesVisibilitySettingsModalContainer from 'src/components/ResumesCountriesVisibility/ResumesCountriesVisibilitySettingsModalContainer';
import SupernovaNotificationManager from 'src/components/SupernovaNotificationManager';
import UserNotifications from 'src/components/UserNotifications';
import VacancyTargetingViewer from 'src/components/VacancyTargetingViewer';
import translation from 'src/components/translation';
import { findNotIsoDates } from 'src/utils/findNotIsoDates';
import { getStore } from 'src/utils/typedStore';

import FullPageLayout from 'src/app/layouts/FullPageLayout';
import routes, { forbiddenPageRoute, errorPageRoute } from 'src/app/routes';

const DATA_QA_RENDER_STATUS = 'lux-container lux-container-rendered';
const DATA_QA_FETCHING_STATUS = 'lux-container lux-container-fetching';
const RENDER_TIMEOUT = 50;

const Features = {
    highlightMagritteGrid: 'highlight_magritte_grid',
    notIsoDatesLogging: 'not_iso_dates_logging',
    // tempexp_31848_next-line
    enableAlternativeHHTMParams: 'enable_alternative_hhtm_params',
};

const AppError = ({ trls }) => (
    <Fragment>
        <LinearProgress />
        <ColumnsWrapper>
            <div className="row-content">
                <Column xs="4" s="8" m="12" l="16">
                    {trls[AppError.trls.error]}
                </Column>
            </div>
        </ColumnsWrapper>
    </Fragment>
);

AppError.trls = {
    error: 'lux.error',
};

AppError.propTypes = {
    trls: PropTypes.object,
};

const ErrorPage = translation(AppError);

class AppContainer extends Component {
    state = {
        hasError: false,
    };
    static trls = {
        error: 'lux.error',
    };

    static propTypes = {
        isFetching: PropTypes.bool,
        location: PropTypes.object,
        lang: PropTypes.string,
        forbidden: PropTypes.bool,
        renderRestriction: PropTypes.shape({
            breakpoint: PropTypes.string,
            magritteBreakpoint: PropTypes.string,
            force: PropTypes.bool,
            renderRestriction: PropTypes.bool,
        }),
        errorCode: PropTypes.number,
        isLegacyPage: PropTypes.bool,
        renderCallback: PropTypes.func,
        showGridHighlighter: PropTypes.bool,
        notIsoDatesLogging: PropTypes.bool,
        errorPage: PropTypes.shape({
            isError: PropTypes.bool,
        }),
    };

    element = createRef();

    rootElementRef = (rootElement) => {
        this.element.current = rootElement;
        if (this.props.renderCallback && rootElement) {
            this.props.renderCallback();
        }
    };

    setDataQa(dataQa) {
        if (this.element.current) {
            this.element.current.dataset.qa = dataQa;
        }
    }

    logNotIsoDates() {
        if (!this.props.notIsoDatesLogging) {
            return;
        }

        requestIdleCallback(async () => {
            const start = performance.now();
            const store = await getStore();
            const state = store.getState();
            const notIsoDates = findNotIsoDates(state);
            const elapsedTime = performance.now() - start;

            notIsoDates?.forEach((dateString) => {
                Debug.log('out', 'Date without a time zone was found in the initial state', {
                    explain: dateString,
                    elapsedTime: `${elapsedTime}ms`,
                    dateString,
                });
            });
        });
    }

    componentDidMount() {
        this.setDataQa(DATA_QA_RENDER_STATUS);
        this.logNotIsoDates();
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.isFetching) {
            this.setDataQa(DATA_QA_FETCHING_STATUS);
        } else {
            setTimeout(() => {
                this.setDataQa(DATA_QA_RENDER_STATUS);
            }, RENDER_TIMEOUT);
        }

        return nextProps.location !== this.props.location || nextState.hasError;
    }

    componentDidCatch(error, errorInfo) {
        if (window.globalVars.features.sentry_logging) {
            Sentry.withScope((scope) => {
                scope.setExtra('origin', 'componentDidCatch');
                scope.setExtras(errorInfo);
                Sentry.captureException(error);
            });
        }

        this.setState({
            hasError: true,
        });
    }

    render() {
        if (this.state.hasError) {
            return (
                <TranslationLangContext.Provider value={this.props.lang}>
                    <ErrorPage />
                </TranslationLangContext.Provider>
            );
        }

        const { errorCode, isLegacyPage } = this.props;
        const shouldShow4xxErrorPage =
            isLegacyPage && errorCode >= 400 && errorCode < 500 && ![401, 403].includes(errorCode);

        return (
            <div ref={this.rootElementRef} data-qa="lux-container">
                <TranslationLangContext.Provider value={this.props.lang}>
                    <AppHeadProvider>
                        <BlokoBreakpointProvider
                            storedBreakpointValue={this.props.renderRestriction.breakpoint}
                            forceUseStoredBreakpoint={this.props.renderRestriction.force}
                            renderRestriction={!!this.props.renderRestriction}
                        >
                            <MagritteThemeProvider>
                                <MagritteBreakpointProvider
                                    storeActualBreakpointValueInCookie
                                    storedBreakpointValue={this.props.renderRestriction.magritteBreakpoint}
                                    force={this.props.renderRestriction.force}
                                    renderRestriction={!!this.props.renderRestriction}
                                >
                                    <NotificationContextProvider>
                                        <SnackbarContextProvider>
                                            <FetcherErrorHandler />
                                            <StoreDevHelperProvider serviceName="xhh">
                                                <StrictMode>
                                                    <DevBuildLoader serviceName="xhh" />
                                                    <GridHighlighter show={!!this.props.showGridHighlighter} />
                                                </StrictMode>
                                                <TipProvider>
                                                    <ChatikIntegration>
                                                        <SupernovaNotificationManager>
                                                            <StrictMode>
                                                                <DeepLinkCommand />
                                                                <LinearProgress />
                                                                <ReduxNotifications />
                                                                <NotificationsRenderer />
                                                                <UserNotifications />
                                                                <AppPromoBanner />
                                                                <VacancyTargetingViewer />
                                                                <BannersLayer />
                                                            </StrictMode>

                                                            {shouldShow4xxErrorPage ? (
                                                                <FullPageLayout>
                                                                    <ContainerForXSL place="legacy-page-layout-xsl" />
                                                                </FullPageLayout>
                                                            ) : (
                                                                /* Из-за использования push react-router нужно явно указывать location */
                                                                /* так как мы ловим его в redux-spa-middleware */
                                                                <Switch location={this.props.location}>
                                                                    {this.props.forbidden && (
                                                                        <Route exact {...forbiddenPageRoute} />
                                                                    )}
                                                                    {this.props.errorPage.isError && (
                                                                        <Route exact {...errorPageRoute} />
                                                                    )}
                                                                    {!this.props.forbidden &&
                                                                        !this.props.errorPage.isError &&
                                                                        routes.map((route) => (
                                                                            <Route
                                                                                exact
                                                                                key={route.path || 'LegacyPage'}
                                                                                {...route}
                                                                            />
                                                                        ))}
                                                                </Switch>
                                                            )}

                                                            <StrictMode>
                                                                <ResumesCountriesVisibilitySettingsModalContainer />
                                                            </StrictMode>
                                                        </SupernovaNotificationManager>
                                                    </ChatikIntegration>
                                                </TipProvider>
                                            </StoreDevHelperProvider>
                                        </SnackbarContextProvider>
                                    </NotificationContextProvider>
                                </MagritteBreakpointProvider>
                            </MagritteThemeProvider>
                        </BlokoBreakpointProvider>
                    </AppHeadProvider>
                </TranslationLangContext.Provider>
            </div>
        );
    }
}

export default connect((state) => ({
    isFetching: state.fetchingData,
    location: state.router.location,
    lang: state.langs[0],
    forbidden: state.forbidden,
    errorPage: state.errorPage,
    renderRestriction: state.renderRestriction,
    errorCode: state.errorCode,
    isLegacyPage: state.request?.luxPageName === 'LegacyPage',
    showGridHighlighter: state.features[Features.highlightMagritteGrid],
    notIsoDatesLogging: state.features[Features.notIsoDatesLogging],
}))(AppContainer);
