import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import FilterContent from 'src/components/Search/Common/Filters/FilterContent';
import FilterWrapper from 'src/components/Search/Common/Filters/FilterWrapper';
import MobileViewControl from 'src/components/Search/Common/Filters/MobileViewControl';
import { useApplyFilter } from 'src/components/Search/Common/Filters/hooks/useApplyFilter';
import { hasClusterGroups } from 'src/components/Search/Common/Filters/utils/hasClusterGroups';
import ChipsItem from 'src/components/Search/Vacancy/Filters/FilterChips/Item';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { NovaFilterKey, NovaFilterCompany } from 'src/models/novaFilters';

const getCompanyName = (cluster: NovaFilterCompany) => {
    const { groups, selectedValues } = cluster;
    return selectedValues.map((id) => groups[id].name).join(', ');
};

const TrlKeys = {
    title: 'searchvacancy.clusters.company',
};

const Company: TranslatedComponent = ({ trls }) => {
    const applyFilter = useApplyFilter(NovaFilterKey.Company);
    const cluster = useSelector((state) => state.searchClusters[NovaFilterKey.Company]);

    if (!hasClusterGroups(cluster)) {
        return null;
    }

    return (
        <MobileViewControl
            mobileView={
                <ChipsItem
                    title={trls[TrlKeys.title]}
                    value={getCompanyName(cluster)}
                    onDelete={() => applyFilter([])}
                />
            }
        >
            <FilterWrapper filterKey={NovaFilterKey.Company} title={trls[TrlKeys.title]}>
                <FilterContent name={NovaFilterKey.Company} />
            </FilterWrapper>
        </MobileViewControl>
    );
};

export default translation(Company);
