import { Button, Card, Cell, CellText, CellRightLabel, Link, Badge as MargitteBadge } from '@hh.ru/magritte-ui';
import { LiferingOutlinedSize16, LiferingOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import Badge, { BadgeAppearance } from 'bloko/blocks/badge';
import BlokoText, { TextImportance } from 'bloko/blocks/text';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import BlokoIconReplaceContainer from 'src/components/BlokoIconReplaceContainer';
import translation from 'src/components/translation';
import { useMagritte } from 'src/hooks/useMagritte';

import useSupportChatik from 'src/components/SupportChatPlate/useSupportChatik';

import styles from './SupportChatPlate.less';

export interface SupportChatPlateProps {
    fromLabel?: string;
    onClick?: () => void;
    view?: 'plate' | 'inline' | 'button';
}

const TrlKeys = {
    title: 'supernova.supportChat.title',
    subtitle: 'supernova.supportChat.online',
    inlineTitle: 'supportChat.inlineView.title',
};

const SupportChatPlate: TranslatedComponent<SupportChatPlateProps> = ({ trls, fromLabel, onClick, view }) => {
    const isMagritte = useMagritte();

    const { dataQa, handleChatikClick, unreadCount } = useSupportChatik(fromLabel, onClick);

    const blokoInline = (
        <div className={styles.inline} onClick={handleChatikClick} data-qa={dataQa}>
            <BlokoIconReplaceContainer>
                <LiferingOutlinedSize24 initial="accent" />
            </BlokoIconReplaceContainer>
            <span>{trls[TrlKeys.inlineTitle]}</span>
        </div>
    );

    const blokoCommon = (
        <div className={styles.plate} onClick={handleChatikClick} data-qa={dataQa}>
            <div className={styles.content}>
                <BlokoIconReplaceContainer>
                    <LiferingOutlinedSize24 initial="negative" />
                </BlokoIconReplaceContainer>

                <div className={styles.text}>
                    <BlokoText>{trls[TrlKeys.title]}</BlokoText>
                    <BlokoText importance={TextImportance.Secondary}>{trls[TrlKeys.subtitle]}</BlokoText>
                </div>
            </div>

            {Boolean(unreadCount) && <Badge value={unreadCount || 0} appearance={BadgeAppearance.Warning} />}
        </div>
    );

    const magritteInline = (
        <Link iconLeft={LiferingOutlinedSize16} onClick={handleChatikClick} data-qa={dataQa}>
            {trls[TrlKeys.inlineTitle]}
        </Link>
    );

    const magritteButton = (
        <Button
            mode="secondary"
            style="neutral"
            icon={<LiferingOutlinedSize24 />}
            stretched
            onClick={handleChatikClick}
            data-qa={dataQa}
        >
            {trls[TrlKeys.title]}
        </Button>
    );

    const magritteCommon = (
        <Card
            actionCard
            stretched
            padding={16}
            borderRadius={16}
            style="warning"
            data-qa={dataQa}
            onClick={handleChatikClick}
        >
            <Cell
                left={
                    <LiferingOutlinedSize24
                        initial="warning-secondary"
                        highlighted="warning-secondary"
                        disabled="warning-secondary"
                    />
                }
                right={
                    unreadCount ? (
                        <CellRightLabel badge={<MargitteBadge>{`${unreadCount}`}</MargitteBadge>} />
                    ) : undefined
                }
            >
                <CellText>{trls[TrlKeys.title]}</CellText>
                <CellText type="subtitle">{trls[TrlKeys.subtitle]}</CellText>
            </Cell>
        </Card>
    );

    switch (view) {
        case 'inline':
            return isMagritte ? magritteInline : blokoInline;
        case 'button':
            return isMagritte ? magritteButton : null;
        case 'plate':
        default:
            return isMagritte ? magritteCommon : blokoCommon;
    }
};

export default translation(SupportChatPlate);
