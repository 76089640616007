import { FC, ReactNode, PropsWithChildren } from 'react';

import { useBreakpoint } from '@hh.ru/magritte-ui';

const MobileViewControl: FC<{ mobileView?: ReactNode } & PropsWithChildren> = ({ mobileView, children }) => {
    const { isMobile } = useBreakpoint();

    return <>{isMobile ? mobileView : children}</>;
};

export default MobileViewControl;
