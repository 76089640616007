import { useState, ChangeEvent, ReactElement, useCallback } from 'react';

import { CheckableChip, ChipsContainer } from '@hh.ru/magritte-ui';

import { ChipsSelectType, ChipsOption } from 'src/components/Search/Vacancy/Filters/FilterChips/types';

interface ChipsSelectProps<T> {
    name: string;
    options: ChipsOption<T>[];
    selected: T[];
    onChange: (values: T[]) => void;
    selectType?: ChipsSelectType;
}

const ChipsSelect = <T extends string>({
    name,
    options,
    selected,
    onChange,
    selectType = ChipsSelectType.Checkbox,
}: ChipsSelectProps<T>): ReactElement => {
    const [values, setValues] = useState([...selected]);

    const handleChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            const value = event.target.value as T;
            if (values.includes(value)) {
                if (selectType === ChipsSelectType.Radio) {
                    return;
                }
                const newValues = values.filter((item) => item !== value);
                setValues(newValues);
                onChange(newValues);
            } else {
                if (selectType === ChipsSelectType.Radio) {
                    setValues([value]);
                    onChange([value]);
                    return;
                }
                const newValues = [...values, value];
                setValues(newValues);
                onChange(newValues);
            }
        },
        [onChange, selectType, values]
    );

    return (
        <ChipsContainer scrollable noWrap>
            {options.map(({ id, title }) => (
                <CheckableChip
                    type={selectType}
                    key={`${name}_${id}`}
                    data-qa={`serp__novafilter-xs-${name}-${id}`}
                    name={name}
                    onChange={handleChange}
                    checked={values.includes(id)}
                    value={id}
                    id={id}
                >
                    {title}
                </CheckableChip>
            ))}
        </ChipsContainer>
    );
};

export default ChipsSelect;
