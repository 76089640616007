import { ApplicantResumeEditorFields, EditorFormType } from 'src/models/applicant/resume/editor/types';
import { PayloadType } from 'src/models/applicant/resume/editor/types/config';
import { EditorDictionaries } from 'src/models/applicant/resume/editor/types/dictionaries';
import { EditorFormErrors } from 'src/models/applicant/resume/editor/types/error';
import { omit } from 'src/models/applicant/resume/lib/common/object';
import { createSlice } from 'src/models/applicant/resume/lib/slice';
import { ReducerAction } from 'src/models/applicant/resume/lib/slice/types';

import {
    CONFIRM_INITIAL_STATE,
    hideResumeEditorConfirmReducer,
    showResumeEditorConfirmReducer,
} from 'src/models/applicant/resume/editor/store/confirm/reducer';
import { EditorConfirmType } from 'src/models/applicant/resume/editor/store/confirm/types';
import {
    hideNotificationReducer,
    NOTIFICATIONS_INITIAL_STATE,
    showNotificationReducer,
} from 'src/models/applicant/resume/editor/store/notifications/reducer';
import { ResumeEditorNotifications } from 'src/models/applicant/resume/editor/store/notifications/types';

export interface ApplicantResumeEditorStore {
    // тип модала, который в данный момент открыт
    type: EditorFormType;

    // открыт ли редактор в данный момент
    opened: boolean;
    // сохраняем данные на бэк
    saving: boolean;
    // подгружаем данные при открытии модала
    loading: boolean;

    // стейт алерта для подтверждения операции
    confirm: { show: boolean; type: EditorConfirmType; title: string };

    // текущие редактируемые поля. Копируются из резюме
    fields: ApplicantResumeEditorFields;
    // текущие ошибки на форме
    errors: EditorFormErrors;

    /**
     * доп инфа, которая нужна при редактировании.
     * Пример: индекс текущего редактируемого элемента из массива
     * */
    payload: PayloadType;

    // словари, для полей с выбором
    dictionaries: EditorDictionaries;

    //
    notifications: ResumeEditorNotifications;
}

const INITIAL_STATE: ApplicantResumeEditorStore = {
    type: 'position',

    saving: false,
    opened: false,
    confirm: CONFIRM_INITIAL_STATE,
    loading: false,

    fields: {},
    errors: {},

    payload: {},

    dictionaries: {},

    notifications: NOTIFICATIONS_INITIAL_STATE,
};

const resumeEditorSlice = createSlice({
    initialState: INITIAL_STATE,
    reducers: {
        showNotification: showNotificationReducer,
        hideNotification: hideNotificationReducer,

        showResumeEditorConfirm: showResumeEditorConfirmReducer,
        hideResumeEditorConfirm: hideResumeEditorConfirmReducer,

        setResumeEditorType: (store, { payload }: ReducerAction<{ type: EditorFormType; payload?: PayloadType }>) => ({
            ...store,
            type: payload.type,
            payload: payload.payload || {},
        }),

        toggleResumeEditorSaving: (store, { payload }: ReducerAction<boolean>) => ({ ...store, saving: payload }),
        toggleResumeEditorLoading: (store, { payload }: ReducerAction<boolean>) => ({ ...store, loading: payload }),
        toggleResumeEditorVisibility: (store, { payload }: ReducerAction<boolean>) => ({
            ...store,
            opened: payload,
            payload: payload ? store.payload : {},
        }),

        setResumeEditorFields: (store, { payload }: ReducerAction<ApplicantResumeEditorFields>) => ({
            ...store,
            fields: payload,
        }),

        setResumeEditorFieldValue: (store, { payload }: ReducerAction<ApplicantResumeEditorFields>) => ({
            ...store,
            fields: { ...store.fields, ...payload },
        }),

        setResumeEditorFormErrors: (store, { payload }: ReducerAction<EditorFormErrors>) => ({
            ...store,
            errors: payload,
        }),
        resetResumeEditorFormErrors: (store) => ({ ...store, errors: {} }),
        clearResumeEditorErrorByKey: (store, { payload }: ReducerAction<keyof ApplicantResumeEditorFields>) => ({
            ...store,
            errors: omit(payload, store.errors),
        }),
        clearResumeEditorErrorByNamePath: (
            store,
            { payload }: ReducerAction<{ key: keyof ApplicantResumeEditorFields; namePath: string }>
        ) => ({
            ...store,
            errors: {
                ...store.errors,
                [payload.key]: store.errors[payload.key]?.filter(
                    ({ fieldNamePath }) => !fieldNamePath.startsWith(payload.namePath)
                ),
            },
        }),

        setResumeEditorDictionaries: (store, { payload }: ReducerAction<Partial<EditorDictionaries>>) => ({
            ...store,
            dictionaries: { ...store.dictionaries, ...payload },
        }),
    },
});

export const {
    setResumeEditorType,

    toggleResumeEditorSaving,
    showResumeEditorConfirm,
    hideResumeEditorConfirm,
    toggleResumeEditorLoading,
    toggleResumeEditorVisibility,

    setResumeEditorFormErrors,
    resetResumeEditorFormErrors,
    clearResumeEditorErrorByKey,
    clearResumeEditorErrorByNamePath,

    setResumeEditorFields,
    setResumeEditorFieldValue,

    setResumeEditorDictionaries,

    showNotification,
    hideNotification,
} = resumeEditorSlice.actions;

export const resumeEditor = resumeEditorSlice.reducer;
