import { FC } from 'react';

import Analytics from '@hh.ru/analytics-js';

import SupportMessengers from 'src/components/Support/SupportMessengers/SupportMessengers';
import { MessengerType } from 'src/models/messengers';

const logMessengerClick = (messenger: MessengerType) => {
    Analytics.sendHHEventButtonClick('menu_support_item', { item: messenger.toLowerCase() });
};

const HelpModalMessengers: FC = () => {
    return <SupportMessengers onMessengerClick={logMessengerClick} />;
};

export default HelpModalMessengers;
