import { domToReact } from 'html-react-parser';

import { Title } from '@hh.ru/magritte-ui';
import { VSpacing as VSpacingMagritte } from '@hh.ru/magritte-ui-spacing/VSpacing';
import { H2 } from 'bloko/blocks/header';
import VSpacing from 'bloko/blocks/vSpacing';

import { TagMapperComponent, TagMapperProps } from 'src/hooks/useParseHtml';
import { useSelector } from 'src/hooks/useSelector';

const Header: TagMapperComponent<TagMapperProps> = ({ options, originalElement: { children } }) => {
    const isAnonymousMagritteExp = useSelector((state) => state.isAnonymousMagritteExp);
    if (!children) {
        return null;
    }
    if (isAnonymousMagritteExp) {
        return (
            <>
                <Title size="large" Element="h2">
                    {domToReact(children, options)}
                </Title>
                <VSpacingMagritte default={12} />
            </>
        );
    }
    return (
        <>
            <H2>{domToReact(children, options)}</H2>
            <VSpacing base={4} />
        </>
    );
};

export default Header;
