import { type ReactNode, type RefObject, useEffect, useRef, useState, useMemo } from 'react';

import vacancyResponseButtonClick from '@hh.ru/analytics-js-events/build/xhh/applicant/response/vacancy_response_button_click';
import vacancyResponseScreenShown from '@hh.ru/analytics-js-events/build/xhh/applicant/response/vacancy_response_screen_shown';
import {
    Action,
    ActionBar,
    BottomSheet,
    BottomSheetFooter,
    Button,
    Card,
    Cell,
    CellText,
    Modal,
    NavigationBar,
    VSpacing,
    VSpacingContainer,
    useBreakpoint,
} from '@hh.ru/magritte-ui';
import { ChevronRightOutlinedSize24, CrossOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { type TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import VacancyResponseForm, { getUserResumes } from 'src/components/VacancyResponseForm';
import ResumeAvatar from 'src/components/VacancyResponseForm/ResumeAvatar';
import ResumeDetail from 'src/components/VacancyResponseForm/ResumeDetail';
import ResumeSelector from 'src/components/VacancyResponseForm/ResumeSelector';
import useLetter from 'src/components/VacancyResponseForm/hooks/useLetter';
import type Source from 'src/components/VacancySearchItem/types/Source';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { type ResponseStatusResume } from 'src/models/applicantVacancyResponseStatuses';

import { ResponseStep } from 'src/components/VacancyResponsePopup/BottomSheet/responseSteps';

interface MagrittePopupProps {
    visible: boolean;
    vacancyId: number;
    vacancySource: Source;
    vacancyBodyFooterNodeRef?: RefObject<HTMLDivElement>;
    isQuestionResponse?: boolean;
    isMagritteResponseExpC?: boolean;
    startedWithQuestion?: boolean;
    onClose: (cancel: boolean) => void;
}

const TrlKeys = {
    titleCommon: 'vacancy.response.popup.title',
    titleRepeat: 'vacancy.response.popup.title.again',
    titleLetter: 'vacancy.response.popup.letter',
    titleQuestion: 'vacancy.questionResponse.popup.title',
    titleQuestionCompact: 'vacancy.questionResponse.magrittePopup.title.compact',
    titleResumes: 'vacancy.response.popup.resumes',
    titleResumesQuestion: 'vacancy.questionResponse.popup.resumes',
    descriptionQuestion: 'vacancy.questionResponse.popup.description',
    descriptionQuestionCompact: 'vacancy.questionResponse.magrittePopup.description.compact',
    actionSubmitCommon: 'vacancy.response.popup.submit',
    actionSubmitRepeat: 'vacancy.response.popup.submitAgain',
    actionCancel: 'vacancy.response.popup.cancel',
    actionSendQuestion: 'vacancy.questionResponse.magrittePopup.submit',
    actionAddLetter: 'vacancy.response.popup.letter.button.add',
    actionSaveLetter: 'vacancy.response.popup.letter.button.save',
    defaultQuestion: 'vacancy.question.greeting',
    expandLetter: 'vacancy.response.expandLetter',
};

const MagrittePopup: TranslatedComponent<MagrittePopupProps> = ({
    trls,
    visible,
    vacancyId,
    vacancySource,
    vacancyBodyFooterNodeRef,
    isQuestionResponse,
    isMagritteResponseExpC,
    startedWithQuestion,
    onClose,
}) => {
    const responseStatus = useSelector((state) => state.applicantVacancyResponseStatuses[vacancyId]);
    const vacancyName = responseStatus.shortVacancy.name;
    const isFirstResponse = responseStatus.usedResumeIds.length === 0;
    const isResponseImpossible = !responseStatus.unusedResumeIds.length;
    const [responseErrorCode, setResponseErrorCode] = useState<string | null>(null);
    const [submitting, setSubmitting] = useState(false);
    const { isMobile } = useBreakpoint();
    const lastResumeHash = useSelector((state) => state.lastVacancyResponseInfo?.resumeHash);
    const resumes = useMemo(() => getUserResumes(responseStatus), [responseStatus]);
    const [selectedResume, setSelectedResume] = useState<ResponseStatusResume>(
        resumes.find((resume) => resume.hash === lastResumeHash) || resumes[0]
    );
    const [responseStep, setResponseStep] = useState(ResponseStep.Initial);
    const postponedActions = useRef<(() => void)[]>([]);
    const {
        expandLetter,
        isLetterExpanded,
        letterValue,
        setLetterValue,
        isLetterValueEmpty,
        isLetterRequired,
        letterMaxLength,
    } = useLetter({
        vacancyId,
        responseStep,
        defaultQuestionTrl: trls[TrlKeys.defaultQuestion],
        isQuestionResponse,
    });

    const isCustomLetterExpander = isMobile || (!isMobile && isMagritteResponseExpC);
    const hasLetterExpander = isCustomLetterExpander && !isLetterExpanded;

    useEffect(() => {
        if (visible) {
            vacancyResponseScreenShown({
                screenType: 'regular',
                responseType: startedWithQuestion ? 'question' : 'simple',
                vacancyId: `${vacancyId}`,
                hhtmSource: 'vacancy_response',
            });
        }
    }, [vacancyId, startedWithQuestion, visible]);

    const handleClose = (isResponse = false): void => {
        if (isResponse) {
            setTimeout(() => {
                postponedActions.current.forEach((action) => action());
                postponedActions.current = [];
            }, 300);
        }

        onClose(!isResponse);
    };

    const handleButtonClick = () => {
        vacancyResponseButtonClick({
            buttonName: 'vacancy_response',
            screenType: 'regular',
            responseType: startedWithQuestion ? 'question' : 'simple',
            vacancyId: `${vacancyId}`,
            hhtmSource: 'vacancy_response',
        });
    };

    const renderModalSubmitButton = (form: string, isLetterTextEmpty: boolean) => {
        return (
            <Button
                form={form}
                type="submit"
                mode="primary"
                style="accent"
                data-qa="vacancy-response-submit-popup"
                disabled={
                    isResponseImpossible ||
                    submitting ||
                    ((isLetterRequired || isQuestionResponse) && isLetterTextEmpty)
                }
                loading={submitting}
                onClick={handleButtonClick}
            >
                {isQuestionResponse
                    ? trls[TrlKeys.actionSendQuestion]
                    : trls[isFirstResponse ? TrlKeys.actionSubmitCommon : TrlKeys.actionSubmitRepeat]}
            </Button>
        );
    };

    const renderBottomSheetSelectedResume = () => {
        const canSelectResume = resumes.length > 1;
        const selectedResumeTitle = selectedResume?.title?.[0]?.string;
        const handleClick = () => {
            if (canSelectResume) {
                setResponseStep(ResponseStep.ResumeSelect);
            }
        };

        return (
            <>
                <Card
                    actionCard={canSelectResume}
                    onClick={handleClick}
                    stretched
                    showBorder
                    padding={16}
                    borderRadius={16}
                >
                    <Cell
                        avatar={<ResumeAvatar resume={selectedResume} />}
                        right={canSelectResume ? <ChevronRightOutlinedSize24 initial="secondary" /> : undefined}
                    >
                        <CellText type="title" maxLines={1}>
                            {selectedResumeTitle}
                        </CellText>
                        <CellText type="subtitle" maxLines={1}>
                            <ResumeDetail resume={selectedResume} />
                        </CellText>
                    </Cell>
                </Card>
                <VSpacing default={16} />
            </>
        );
    };

    const renderBottomSheetResumeSelector = () => {
        const onResumeSelected = () => {
            setResponseStep(ResponseStep.Initial);
        };

        if (!resumes.length) {
            return null;
        }

        return (
            <>
                <ResumeSelector
                    responseStatus={responseStatus}
                    resumes={resumes}
                    selectedResume={selectedResume}
                    setSelectedResume={setSelectedResume}
                    isQuestionResponse={isQuestionResponse}
                    onResumeSelected={onResumeSelected}
                    isBottomSheet
                />
                <VSpacing default={16} />
            </>
        );
    };

    const renderModal: (props: {
        renderForm: () => ReactNode;
        formId: string;
        isLetterTextEmpty: boolean;
    }) => ReactNode = (props) => {
        const isResumeSelectStep = responseStep === ResponseStep.ResumeSelect;
        const submitButton = renderModalSubmitButton(props.formId, props.isLetterTextEmpty);
        const expandLetterButton = hasLetterExpander && (
            <Button
                mode="secondary"
                style="accent"
                stretched={isMobile}
                onClick={() => {
                    // Без отложенного расхлопа сопроводительного Final Form сабмитит отклик
                    // TODO: найти более красивое решение
                    setTimeout(() => expandLetter(), 0);
                }}
            >
                {trls[TrlKeys.expandLetter]}
            </Button>
        );

        let bottomSheetTitle = trls[isQuestionResponse ? TrlKeys.titleQuestionCompact : TrlKeys.titleCommon];
        let bottomSheetSubtitle: string | undefined = isQuestionResponse
            ? trls[TrlKeys.descriptionQuestionCompact]
            : vacancyName;

        if (isResumeSelectStep) {
            bottomSheetTitle = trls[isQuestionResponse ? TrlKeys.titleResumesQuestion : TrlKeys.titleResumes];
            bottomSheetSubtitle = undefined;
        }

        const bottomSheetFooter = !isResumeSelectStep && (
            <BottomSheetFooter>
                <VSpacingContainer default={12}>
                    {submitButton}
                    {expandLetterButton}
                </VSpacingContainer>
            </BottomSheetFooter>
        );
        const bottomSheetContent = isResumeSelectStep ? (
            renderBottomSheetResumeSelector()
        ) : (
            <>
                {renderBottomSheetSelectedResume()}
                {props.renderForm()}
            </>
        );

        return (
            <>
                <Modal
                    visible={!isMobile && visible}
                    title={trls[isQuestionResponse ? TrlKeys.titleQuestion : TrlKeys.titleCommon]}
                    titleSize="large"
                    titleMaxLines={1}
                    titleDescription={isQuestionResponse ? trls[TrlKeys.descriptionQuestion] : vacancyName}
                    titleDescriptionStyle="secondary"
                    titleDescriptionMaxLines={1}
                    actions={
                        <Action
                            icon={CrossOutlinedSize24}
                            mode="secondary"
                            aria-label={trls[TrlKeys.actionCancel]}
                            onClick={() => handleClose()}
                        />
                    }
                    footer={<ActionBar primaryActions={submitButton} secondaryActions={expandLetterButton} />}
                    onClose={() => handleClose()}
                >
                    {props.renderForm()}
                </Modal>
                <BottomSheet
                    visible={isMobile && visible}
                    header={
                        <NavigationBar
                            title={bottomSheetTitle}
                            subtitle={bottomSheetSubtitle}
                            right={<Action icon={CrossOutlinedSize24} onClick={() => handleClose()} />}
                            showDivider="always"
                        />
                    }
                    footer={bottomSheetFooter}
                    onClose={() => handleClose()}
                    onAfterExit={() => {
                        setResponseStep(ResponseStep.Initial);
                    }}
                >
                    {bottomSheetContent}
                </BottomSheet>
            </>
        );
    };

    return (
        <VacancyResponseForm
            isQuestionResponse={isQuestionResponse}
            isFromPopup
            isBottomSheet={isMobile}
            isModal={!isMobile}
            vacancyId={vacancyId}
            resumes={resumes}
            submitting={submitting}
            setSubmitting={setSubmitting}
            responseStep={responseStep}
            onResponse={() => handleClose(true)}
            errorCode={responseErrorCode ?? ''}
            onError={setResponseErrorCode}
            vacancySource={vacancySource}
            vacancyBodyFooterNodeRef={vacancyBodyFooterNodeRef}
            selectedResume={selectedResume}
            setSelectedResume={setSelectedResume}
            postponedActions={postponedActions}
            render={renderModal}
            expandLetter={expandLetter}
            isLetterExpanded={isLetterExpanded}
            letterValue={letterValue}
            setLetterValue={setLetterValue}
            isLetterValueEmpty={isLetterValueEmpty}
            isLetterRequired={isLetterRequired}
            letterMaxLength={letterMaxLength}
        />
    );
};

export default translation(MagrittePopup);
