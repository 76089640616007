import { useCallback, useMemo, useState } from 'react';

import callAlertCloseButtonClick from '@hh.ru/analytics-js-events/build/xhh/employer/calls/web_call/call_alert_close_button_click';
import callTypeBtnClick from '@hh.ru/analytics-js-events/build/xhh/employer/calls/web_call/call_alert_repeat_button_click';
import disabledMicCloseButtonClick, {
    HhtmSource,
} from '@hh.ru/analytics-js-events/build/xhh/employer/calls/web_call/mic_hh_alert_close_button_click';
import disabledMicInfoClick from '@hh.ru/analytics-js-events/build/xhh/employer/calls/web_call/mic_hh_alert_info_button_click';
import { Alert, Button } from '@hh.ru/magritte-ui';
import { MicCrossedOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { Link } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import ContainerForMicroFrontend from 'src/components/CodeInjector/ContainerForMicroFrontend';
import translation from 'src/components/translation';

import WebcallUserDataError from 'src/components/Webcall/WebcallUserDataError';
import { CallType, WebCallData } from 'src/components/Webcall/types';
import useWatchWebcallClose from 'src/components/Webcall/useWatchWebcallClose';

import styles from './styles.less';

interface WebcallWrapperProps {
    place: string;
    onClose: VoidFunction;
    hhtmSource: HhtmSource;
    resumeId: number;
    topicId?: string;
    callType?: CallType | null;
    canSwitchCallType?: boolean;
    requestReCall?: (newData: Partial<WebCallData>) => void;
}

const TrlKeys = {
    alertTitle: 'webcall.micDisabledPopup.title',
    alertDescription: 'webcall.micDisabledPopup.description',
    alertOpenArticleAction: 'webcall.micDisabledPopup.openArticleAction',
    alertCancelAction: 'webcall.micDisabledPopup.cancelAction',
    callTypePopupTitle: 'webcall.switchCallTypePopup.title',
    callTypePopupDescription: 'webcall.switchCallTypePopup.description',
    callTypePopupRepeatApp: 'webcall.switchCallTypePopup.repeatAction.app',
    callTypePopupRepeatPhone: 'webcall.switchCallTypePopup.repeatAction.phone',
    callTypePopupCancelAction: 'webcall.switchCallTypePopup.cancelAction',
};

const mapCallTypeForAnalytics = (callType?: string | null) => {
    if (callType === 'pstn') {
        return 'phone';
    }
    if (callType === 'user') {
        return 'in-app';
    }

    return null;
};

const WebcallWrapper: TranslatedComponent<WebcallWrapperProps> = ({
    place,
    trls,
    onClose,
    topicId,
    resumeId,
    hhtmSource,
    callType,
    requestReCall,
    canSwitchCallType,
}) => {
    const [localCallType, setLocalCallType] = useState(callType);
    const callTypeForAnalytics = useMemo(() => mapCallTypeForAnalytics(callType), [callType]);
    const {
        isMicPermissionError,
        micAlertRef,
        isUserDataError,
        resetUserDataError,
        isCallTypeAlertShown,
        resetCallTypeAlert,
        callTypeAlertRef,
    } = useWatchWebcallClose({
        closeFn: onClose,
        widgetId: place,
        topicId,
        resumeId,
        source: hhtmSource,
        callType: callTypeForAnalytics,
        canSwitchCallType,
    });

    const onMicAlertClose = useCallback(() => {
        onClose();
        disabledMicCloseButtonClick({
            hhtmSource,
            resumeId,
            topicId,
            callType: callTypeForAnalytics,
        });
    }, [hhtmSource, onClose, resumeId, topicId, callTypeForAnalytics]);

    const onMicAlertInfoClick = useCallback(() => {
        disabledMicInfoClick({
            hhtmSource,
            resumeId,
            topicId,
        });
    }, [hhtmSource, resumeId, topicId]);

    const onCallTypeAlertClose = useCallback(() => {
        onClose();
        callAlertCloseButtonClick({
            hhtmSource,
            resumeId,
            topicId,
            callType: callTypeForAnalytics,
        });
    }, [hhtmSource, onClose, resumeId, topicId, callTypeForAnalytics]);

    const onCallTypeChange = useCallback(() => {
        if (requestReCall) {
            const newCallType = callType === 'pstn' ? 'user' : 'pstn';
            const newAnalyticsCallType = mapCallTypeForAnalytics(newCallType);
            requestReCall({
                callType: newCallType,
            });
            resetCallTypeAlert();
            setTimeout(() => {
                setLocalCallType(newCallType);
            }, 300);
            if (newAnalyticsCallType) {
                callTypeBtnClick({
                    hhtmSource,
                    resumeId,
                    topicId,
                    repeatType: newAnalyticsCallType,
                });
            }
        }
    }, [hhtmSource, resumeId, callType, topicId, requestReCall, resetCallTypeAlert]);

    return (
        <div className={styles.webcallWrapper}>
            <div ref={micAlertRef}>
                <Alert
                    title={trls[TrlKeys.alertTitle]}
                    description={trls[TrlKeys.alertDescription]}
                    iconStyle="negative"
                    layout="vertical"
                    icon={<MicCrossedOutlinedSize24 />}
                    buttons={
                        <>
                            <Button
                                Element={Link}
                                to="https://feedback.hh.ru/knowledge-base/article/09227"
                                mode="secondary"
                                style="accent"
                                stretched
                                target="_blank"
                                onClick={onMicAlertInfoClick}
                            >
                                {trls[TrlKeys.alertOpenArticleAction]}
                            </Button>
                            <Button mode="tertiary" style="accent" stretched onClick={onMicAlertClose}>
                                {trls[TrlKeys.alertCancelAction]}
                            </Button>
                        </>
                    }
                    visible={isMicPermissionError}
                    onClose={onMicAlertClose}
                />
            </div>
            <div ref={callTypeAlertRef}>
                <Alert
                    title={trls[TrlKeys.callTypePopupTitle]}
                    description={trls[TrlKeys.callTypePopupDescription]}
                    iconStyle="negative"
                    layout="vertical"
                    icon={<MicCrossedOutlinedSize24 />}
                    buttons={
                        <>
                            <Button mode="primary" style="accent" stretched onClick={onCallTypeChange}>
                                {/* using local state to prevent text flashing in the button when call type is changed */}
                                {localCallType === 'pstn'
                                    ? trls[TrlKeys.callTypePopupRepeatApp]
                                    : trls[TrlKeys.callTypePopupRepeatPhone]}
                            </Button>
                            <Button mode="secondary" style="accent" stretched onClick={onCallTypeAlertClose}>
                                {trls[TrlKeys.callTypePopupCancelAction]}
                            </Button>
                        </>
                    }
                    visible={isCallTypeAlertShown}
                    onClose={onCallTypeAlertClose}
                />
            </div>
            {isUserDataError && (
                <WebcallUserDataError
                    onClose={() => {
                        onClose();
                        resetUserDataError();
                    }}
                />
            )}
            {!isMicPermissionError && !isUserDataError && !isCallTypeAlertShown && (
                <ContainerForMicroFrontend place={place} preloadContent={null} />
            )}
        </div>
    );
};

export default translation(WebcallWrapper);
