import { ComponentType, useEffect, useRef } from 'react';

import userStatusSelectOfferCompanyElementShown from '@hh.ru/analytics-js-events/build/xhh/applicant/user_status/user_status_select_offer_company_element_shown';
import userStatusSelectOfferCompanyScreenShown from '@hh.ru/analytics-js-events/build/xhh/applicant/user_status/user_status_select_offer_company_screen_shown';

import OptionCard from 'src/components/Applicant/JobSearchStatus/PostChangeModal/redesign/OptionCard';
import { ReasonValue, ReasonVariants } from 'src/components/Applicant/JobSearchStatus/PostChangeModal/redesign/types';
import translation from 'src/components/translation';

import styles from 'src/components/Applicant/JobSearchStatus/PostChangeModal/redesign/styles.less';

const TrlKeys = {
    [ReasonVariants.WithoutResponse]: 'jobSearchStatus.postChangeModal.notOnHH.reason.withoutResponse',
    [ReasonVariants.ContactedByMyself]: 'jobSearchStatus.postChangeModal.notOnHH.reason.contactedByMyself',
    [ReasonVariants.Other]: 'jobSearchStatus.postChangeModal.notOnHH.reason.other',
};

interface Props {
    checkedValue: ReasonValue;
    setValue: (value: ReasonValue) => void;
}

const OPTIONS = [
    { value: ReasonVariants.WithoutResponse },
    { value: ReasonVariants.ContactedByMyself },
    { value: ReasonVariants.Other },
];

const ReasonsStep: ComponentType<Props> = translation(({ trls, checkedValue, setValue }) => {
    const wrapperRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (wrapperRef.current) {
            userStatusSelectOfferCompanyScreenShown();
            userStatusSelectOfferCompanyElementShown(wrapperRef.current, {
                type: 'nothing',
                employerIdList: '',
            });
        }
    }, []);

    const handleCardChange = (value: string) => {
        setValue(value as ReasonValue);
    };

    return (
        <div className={styles.contentWrapper} ref={wrapperRef}>
            {OPTIONS.map((option) => (
                <OptionCard
                    key={option.value}
                    value={option.value}
                    title={trls[TrlKeys[option.value]]}
                    checked={checkedValue === option.value}
                    onChange={handleCardChange}
                />
            ))}
        </div>
    );
});

export default ReasonsStep;
