import { useRef, useEffect } from 'react';

import Analytics from '@hh.ru/analytics-js';
import { Tabs as MagritteTabs, Tab } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import indexPageEmployerAnalytics from 'src/components/IndexPageEmployer/components/analytics';
import translation from 'src/components/translation';
import useFeatureEnabled from 'src/hooks/useFeatureEnabled';

import { TAB_IDS, TabValue } from 'src/components/IndexPageEmployer/components/Updates/constants';

const Features = {
    employerMainPageAnalyticsEnabled: 'employer_main_page_analytics_enabled',
};

const TrlKeys = {
    newTab: 'index.employerUpdatesInfo.new',
    soonTab: 'index.employerUpdatesInfo.soon',
};

interface TabsProps {
    activeTabId: TabValue;
    onChange?: (tabId: TabValue) => void;
}

const Tabs: TranslatedComponent<TabsProps> = ({ trls, activeTabId, onChange = () => {} }) => {
    const employerMainPageAnalyticsEnabled = useFeatureEnabled(Features.employerMainPageAnalyticsEnabled);
    const newTabRef = useRef(null);
    const comingSoonTabRef = useRef(null);

    const handleTabChange = (id: TabValue) => {
        Analytics.sendEvent('employer', 'click', id);

        if (employerMainPageAnalyticsEnabled) {
            indexPageEmployerAnalytics.updatesTabClick(id);
        }

        onChange(id);
    };

    useEffect(() => {
        if (employerMainPageAnalyticsEnabled && newTabRef.current && comingSoonTabRef.current) {
            indexPageEmployerAnalytics.updatesTabShown(
                activeTabId === TAB_IDS.NEW_TAB_ID ? newTabRef.current : comingSoonTabRef.current,
                activeTabId
            );
        }
    }, [activeTabId, employerMainPageAnalyticsEnabled]);

    return (
        <div className="updates-head-tabs">
            <MagritteTabs
                activeItemId={activeTabId}
                mode="secondary"
                onChange={(tab) => handleTabChange(tab as TabValue)}
            >
                <Tab id={TAB_IDS.NEW_TAB_ID} data-qa="updates-new" mode="secondary">
                    <span ref={newTabRef}>{trls[TrlKeys.newTab]}</span>
                </Tab>

                <Tab id={TAB_IDS.COMING_SOON_TAB_ID} data-qa="updates-soon" mode="secondary">
                    <span ref={comingSoonTabRef}>{trls[TrlKeys.soonTab]}</span>
                </Tab>
            </MagritteTabs>
        </div>
    );
};

export default translation(Tabs);
