import { useMemo, useCallback } from 'react';

import {
    createDataProvider,
    decorateWithQueryTransofrmer,
    decorateWithResponseFormatter,
    createStaticDataFetcher,
    DataProviderResult,
    CellText,
    DataProvider,
    combineProviders,
    defaultFetcher,
} from '@hh.ru/magritte-ui';

import { updateUrl } from 'Modules/url';
import { useSelector } from 'src/hooks/useSelector';
import { SupernovaSearchName } from 'src/models/supernovaSearchName';
import { SuggestUrl } from 'src/utils/suggest/buildDataProvider';

import {
    SuggestItem,
    Remote,
    SuggestResponse,
    CancelCallback,
} from 'src/components/SupernovaSearch/SearchSuggest/types';

const fetchData = (url: string, onCancel: CancelCallback): Promise<SuggestItem[]> =>
    defaultFetcher<SuggestResponse<SuggestItem>>(url, onCancel).then((data) => data?.items ?? []);

const removeDuplicates = (items: SuggestItem[]): SuggestItem[] =>
    items.filter(
        (item, index) =>
            !items.slice(0, index).some((previousItem) => previousItem.text.toLowerCase() === item.text.toLowerCase())
    );

const constructItemFromQuery = (query: string): SuggestItem => {
    return {
        id: query,
        text: query,
    };
};

const useProvider = (searchName: SupernovaSearchName): DataProvider<SuggestItem> => {
    const locale = useSelector(({ locale }) => locale);
    const employerLastSearches = useSelector(({ employerLastSearches }) => employerLastSearches);

    const formatter = useCallback((items: SuggestItem[] | null): DataProviderResult<SuggestItem> => {
        if (!items) {
            return [];
        }

        return items.map((item) => ({
            type: 'cells',
            items: [
                {
                    data: item,
                    value: item.text,
                    componentProps: {
                        children: <CellText>{item.text}</CellText>,
                    },
                },
            ],
        }));
    }, []);

    const remote: Remote = useMemo(() => {
        const remotes = {
            [SupernovaSearchName.Resumes]: { url: SuggestUrl.Vacancy },
            [SupernovaSearchName.Vacancies]: { url: SuggestUrl.Vacancy },
            [SupernovaSearchName.Employers]: {
                url: SuggestUrl.Auto,
                params: {
                    f: locale.platform,
                    d: `companies_${locale.siteLanguage}`,
                },
            },
        };
        return remotes[searchName];
    }, [locale.siteLanguage, locale.platform, searchName]);

    const { url, params } = remote;
    const queryString = (q: string) => updateUrl(url, { q, ...params });
    const decoratedFetcher = decorateWithQueryTransofrmer(fetchData, queryString);

    const mixDataWithQuery = useCallback(
        (items: SuggestItem[] | null, query: string) => {
            const queryItem = constructItemFromQuery(query);

            return formatter(removeDuplicates([queryItem, ...(items || [])]));
        },
        [formatter]
    );

    const remoteDataProvider = useMemo(
        () =>
            createDataProvider({
                fetcher: decorateWithResponseFormatter(decoratedFetcher, mixDataWithQuery),
                minCharsCount: 2,
                debounceTimeout: 300,
            }),
        [decoratedFetcher, mixDataWithQuery]
    );

    const staticDataProvider = useMemo(
        () =>
            createDataProvider({
                fetcher: decorateWithResponseFormatter(
                    createStaticDataFetcher(removeDuplicates(employerLastSearches)),
                    formatter
                ),
                minCharsCount: 0,
                debounceTimeout: 0,
            }),
        [formatter, employerLastSearches]
    );

    const mixDataWithLastSearches = useCallback(
        (items: SuggestItem[] | null, query: string) => {
            const filteredLastSearches = employerLastSearches.filter(
                (item) => item.text !== '' && item.text.toLowerCase().startsWith(query.toLowerCase())
            );
            const queryItem = constructItemFromQuery(query);

            return formatter(removeDuplicates([queryItem, ...filteredLastSearches, ...(items || [])]));
        },
        [formatter, employerLastSearches]
    );

    const remoteDataProviderWithLastSearches = useMemo(
        () =>
            createDataProvider({
                fetcher: decorateWithResponseFormatter(decoratedFetcher, mixDataWithLastSearches),
                minCharsCount: 2,
                debounceTimeout: 300,
            }),
        [mixDataWithLastSearches, decoratedFetcher]
    );

    if (searchName === SupernovaSearchName.Resumes && employerLastSearches) {
        return combineProviders([remoteDataProviderWithLastSearches, staticDataProvider]);
    }

    return remoteDataProvider;
};

export default useProvider;
