import { ApplicantResumeEditorStore } from 'src/models/applicant/resume/editor/store/slice';
import { Reducer } from 'src/models/applicant/resume/lib/slice/types';

import { ResumeEditorNotificationType } from 'src/models/applicant/resume/editor/store/notifications/types';

export const NOTIFICATIONS_INITIAL_STATE = {
    show: false,
    type: 'success-save',
} as const;

export const showNotificationReducer: Reducer<ApplicantResumeEditorStore, ResumeEditorNotificationType> = (
    store,
    { payload }
) => ({
    ...store,
    notifications: { show: true, type: payload },
});

export const hideNotificationReducer: Reducer<ApplicantResumeEditorStore> = (store) => ({
    ...store,
    notifications: { ...store.notifications, show: false },
});
