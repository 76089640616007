import { FC } from 'react';
import { useDispatch } from 'react-redux';
import classnames from 'classnames';

import { Text, Link as MagritteLink } from '@hh.ru/magritte-ui';
import { Link } from '@hh.ru/redux-spa-middleware';

import { markUpdateViewed } from 'src/models/oldEmployer/oldEmployerUpdates';

interface ItemLinkProps {
    text: string;
    href: string;
    viewed?: boolean;
    date?: string;
}

const ItemLink: FC<ItemLinkProps> = ({ date, text, href, viewed = false }) => {
    const dispatch = useDispatch();

    return (
        <div className="updates-item-link">
            <MagritteLink
                Element={Link}
                to={href}
                onClick={() => {
                    dispatch(markUpdateViewed({ href }));
                }}
                style="neutral"
            >
                <div
                    className={classnames('updates-item-link-inner', {
                        'updates-item-link-inner_not-viewed': !viewed,
                    })}
                >
                    <div className="updates-text">
                        <Text typography="label-2-regular">{text}</Text>
                    </div>
                </div>
            </MagritteLink>

            {date && (
                <Text typography="label-3-regular" style="secondary">
                    {date}
                </Text>
            )}
        </div>
    );
};

export default ItemLink;
