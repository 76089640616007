import { ReactNode, ComponentPropsWithoutRef } from 'react';

import { Link as LinkMagritte } from '@hh.ru/magritte-ui';
import { Link } from '@hh.ru/redux-spa-middleware';
import TextBloko, { TextSize } from 'bloko/blocks/text';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { useMagritte } from 'src/hooks/useMagritte';
import { VacancyViewInsider } from 'src/models/vacancyView.types';

import styles from './style.less';

const TrlKeys = {
    interviewInsider: 'contents.interviewInsider',
    interviewInsiderShort: 'contents.interviewInsiderShort',
    interviewAboutLifeInCompany: 'vacancySearchResults.vacancy.interviewAboutLifeInCompany',
};

interface InsiderInterviewLink extends Omit<ComponentPropsWithoutRef<typeof Link>, 'to'> {
    renderIcon?: () => ReactNode;
    insider: VacancyViewInsider | null;
    companyId: number;
    isShortText?: boolean;
}

const InsiderInterviewLink: TranslatedComponent<InsiderInterviewLink> = ({
    insider,
    trls,
    companyId,
    renderIcon,
    isShortText,
    ...otherProps
}) => {
    const isMagritte = useMagritte();

    if (!insider || !(insider['@isVisible'] || insider.isVisible) || !insider.id) {
        return null;
    }

    if (isMagritte) {
        return (
            <LinkMagritte
                typography="label-3-regular"
                Element={Link}
                to={`/interview/${insider.id}?employerId=${companyId}`}
            >
                {trls[TrlKeys.interviewAboutLifeInCompany]}
            </LinkMagritte>
        );
    }

    return (
        <Link to={`/interview/${insider.id}?employerId=${companyId}`} {...otherProps}>
            {isShortText ? (
                <TextBloko Element={'span'}>
                    <span className={styles.interviewInsiderTextRedesign}>{trls[TrlKeys.interviewInsiderShort]}</span>
                </TextBloko>
            ) : (
                <TextBloko size={TextSize.Small} Element={'div'}>
                    {renderIcon?.()}
                    {trls[TrlKeys.interviewInsider]}
                </TextBloko>
            )}
        </Link>
    );
};

export default translation(InsiderInterviewLink);
