import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import formatDate from 'Modules/formatDate';
import translation from 'src/components/translation';

const YESTERDAY_MAX_MSECONDS = 48 * 60 * 60 * 1000;

export enum ShowYear {
    Always = 'ALWAYS',
    Never = 'NEVER',
    NotCurrent = 'NOT_CURRENT',
}

const TrlKeys = {
    today: 'date.today.lowercase',
    yesterday: 'date.yesterday.lowercase',
    preposition: 'mobile.date.at',
    months: {
        0: 'calendar.genitive.month.0',
        1: 'calendar.genitive.month.1',
        2: 'calendar.genitive.month.2',
        3: 'calendar.genitive.month.3',
        4: 'calendar.genitive.month.4',
        5: 'calendar.genitive.month.5',
        6: 'calendar.genitive.month.6',
        7: 'calendar.genitive.month.7',
        8: 'calendar.genitive.month.8',
        9: 'calendar.genitive.month.9',
        10: 'calendar.genitive.month.10',
        11: 'calendar.genitive.month.11',
    },
};

interface HumanDateProps {
    textify?: boolean;
    date: string | number;
    showClock?: boolean;
    showYear?: ShowYear;
    preposition?: boolean;
}

const isCorrectMonth = (month: number): month is keyof typeof TrlKeys.months => month in TrlKeys.months;

const HumanDate: TranslatedComponent<HumanDateProps> = ({
    textify,
    date,
    showClock,
    showYear = ShowYear.Always,
    trls,
    preposition,
}) => {
    if (!date) {
        return null;
    }

    const currentDate = new Date();
    const dateObject = new Date(date);
    let text = null;

    if (textify && Math.abs(currentDate.valueOf() - dateObject.valueOf()) < YESTERDAY_MAX_MSECONDS) {
        if (currentDate.getDate() === dateObject.getDate()) {
            text = trls[TrlKeys.today];
        }

        currentDate.setDate(currentDate.getDate() - 1);

        if (currentDate.getDate() === dateObject.getDate()) {
            text = trls[TrlKeys.yesterday];
        }
    }

    const day = dateObject.getDate();

    let content = text;

    if (!text) {
        const month = dateObject.getMonth();
        content = isCorrectMonth(month) ? `${day}\u00a0${trls[TrlKeys.months[month]]}` : '';
        if (
            showYear === ShowYear.Always ||
            (showYear === ShowYear.NotCurrent && dateObject.getFullYear() !== new Date().getFullYear())
        ) {
            content += `\u00a0${dateObject.getFullYear()}`;
        }
    }

    return (
        <span suppressHydrationWarning>
            {content}
            {preposition && `\u00a0${trls[TrlKeys.preposition]}`}
            {showClock && `\u00a0${formatDate(dateObject, 'HH:mm')}`}
        </span>
    );
};

export default translation(HumanDate);
