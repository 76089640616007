import { FC, PropsWithChildren } from 'react';
import classNames from 'classnames';

import { Avatar } from '@hh.ru/magritte-ui';
import {
    ExclamationTriangleOutlinedSize24,
    DrawerOutlinedSize24,
    InfoCircleOutlinedSize24,
    EnvelopeOutlinedSize24,
    BoltOutlinedSize24,
    QuestionCircleOutlinedSize24,
    CalendarOutlinedSize24,
    CheckCircleOutlinedSize24,
    VerifiedBadgeOutlinedSize24,
    ExclamationCircleOutlinedSize24,
} from '@hh.ru/magritte-ui/icon';

import styles from './styles.less';

export enum SupernovaUserNotificationsIconKind {
    Warning = 'warning',
    Empty = 'empty',
    Info = 'info',
    Mail = 'mail',
    New = 'new',
    Question = 'question',
    Calendar = 'calendar',
    Success = 'success',
    ChristmasTree = 'christmas-tree',
    Setka = 'setka',
    Verified = 'verified',
    Exclamation = 'exclamation',
    ExclamationCircle = 'exclamationCircle',
}

interface SupernovaUserNotificationsIconProps {
    kind: SupernovaUserNotificationsIconKind;
}

const iconComponent: Record<string, JSX.Element> = {
    [SupernovaUserNotificationsIconKind.Warning]: <ExclamationTriangleOutlinedSize24 initial="tertiary" />,
    [SupernovaUserNotificationsIconKind.Empty]: <DrawerOutlinedSize24 initial="tertiary" />,
    [SupernovaUserNotificationsIconKind.Info]: <InfoCircleOutlinedSize24 initial="tertiary" />,
    [SupernovaUserNotificationsIconKind.Mail]: <EnvelopeOutlinedSize24 initial="tertiary" />,
    [SupernovaUserNotificationsIconKind.New]: <BoltOutlinedSize24 initial="tertiary" />,
    [SupernovaUserNotificationsIconKind.Question]: <QuestionCircleOutlinedSize24 initial="tertiary" />,
    [SupernovaUserNotificationsIconKind.Calendar]: <CalendarOutlinedSize24 initial="tertiary" />,
    [SupernovaUserNotificationsIconKind.Success]: <CheckCircleOutlinedSize24 initial="tertiary" />,
    [SupernovaUserNotificationsIconKind.Exclamation]: <ExclamationTriangleOutlinedSize24 initial="warning" />,
    [SupernovaUserNotificationsIconKind.Verified]: <VerifiedBadgeOutlinedSize24 initial="tertiary" />,
    [SupernovaUserNotificationsIconKind.ExclamationCircle]: <ExclamationCircleOutlinedSize24 initial="tertiary" />,
};

const SupernovaUserNotificationsIcon: FC<SupernovaUserNotificationsIconProps & PropsWithChildren> = ({ kind }) => {
    return (
        <Avatar
            mode="icon"
            size={48}
            icon={iconComponent[kind] || <div className={classNames(styles.icon, styles[kind])} />}
            style="secondary"
            aria-label="notification-icon"
        />
    );
};

export default SupernovaUserNotificationsIcon;
