import { RefObject, useCallback } from 'react';

import {
    Action,
    Card,
    Cell,
    CellText,
    NavigationBar,
    Select,
    Text,
    VSpacingContainer,
    type RenderOptionTypeProps,
} from '@hh.ru/magritte-ui';
import { ChevronDownOutlinedSize24, ChevronUpOutlinedSize24, CrossOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { type TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import type { ResponseStatusResume, ShortVacancy } from 'src/models/applicantVacancyResponseStatuses';

import ResumeAvatar from 'src/components/VacancyResponseForm/ResumeAvatar';
import ResumeDetail from 'src/components/VacancyResponseForm/ResumeDetail';

interface ResumeSelectorV2Props {
    responseStatus: ShortVacancy;
    resumes: ResponseStatusResume[];
    selectedResume: ResponseStatusResume;
    setSelectedResume: (value: ResponseStatusResume) => void;
}

const TrlKeys = {
    titleResumes: 'vacancy.response.popup.resumes',
    hiddenResumes: 'vacancy.response.popup.hidden.resumes',
};

const ResumeSelectorV2: TranslatedComponent<ResumeSelectorV2Props> = ({
    trls,
    responseStatus,
    selectedResume,
    resumes,
    setSelectedResume,
}) => {
    const hasHiddenResumes = responseStatus.hiddenResumeIds.length > 0;
    const canSelectResume = resumes.length > 1;
    const selectedResumeTitle = selectedResume?.title?.[0]?.string;

    const renderSelectedResume = (
        isSelectExpanded?: boolean,
        onSelectChange?: (isExpanded: boolean) => void,
        selectRef?: RefObject<HTMLDivElement>
    ) => {
        const handleClick = () => {
            if (!canSelectResume) {
                return;
            }

            if (typeof onSelectChange === 'function') {
                onSelectChange(!isSelectExpanded);
            }
        };

        let icon;
        if (canSelectResume) {
            icon = isSelectExpanded ? <ChevronUpOutlinedSize24 /> : <ChevronDownOutlinedSize24 />;
        }

        return (
            <Card
                ref={selectRef}
                stretched
                showBorder
                padding={16}
                borderRadius={16}
                actionCard={canSelectResume}
                onClick={handleClick}
            >
                <Cell avatar={<ResumeAvatar resume={selectedResume} />} right={icon}>
                    <CellText type="title" maxLines={1}>
                        {selectedResumeTitle}
                    </CellText>
                    <CellText type="subtitle" maxLines={1}>
                        <ResumeDetail resume={selectedResume} />
                    </CellText>
                </Cell>
            </Card>
        );
    };

    const handleSelectedHash = useCallback(
        (selectedHash: string) => {
            const nextSelectedResume = resumes.find((resume) => resume.hash === selectedHash);
            if (nextSelectedResume) {
                setSelectedResume(nextSelectedResume);
            }
        },
        [resumes, setSelectedResume]
    );

    const renderSelect = () => {
        if (!canSelectResume) {
            return renderSelectedResume();
        }

        const selectOptions = resumes.map((resume) => ({
            value: resume.hash,
            label: resume?.title?.[0]?.string ?? '',
            data: resume,
        }));

        const renderSelectItem = ({ input, label, data: resume }: RenderOptionTypeProps<ResponseStatusResume>) => (
            <Cell left={input} avatar={<ResumeAvatar resume={resume!} />}>
                <CellText type="title" maxLines={1}>
                    {label}
                </CellText>
                <CellText type="subtitle" maxLines={1}>
                    <ResumeDetail resume={resume!} />
                </CellText>
            </Cell>
        );

        return (
            <Select
                type="radio"
                value={selectedResume.hash}
                renderTrigger={({ expanded, onChange, ref }) =>
                    renderSelectedResume(expanded, onChange, ref as RefObject<HTMLDivElement>)
                }
                triggerProps={{
                    size: 'medium',
                }}
                renderDesktopItem={renderSelectItem}
                renderMobileItem={renderSelectItem}
                widthEqualToActivator
                options={selectOptions}
                onChange={handleSelectedHash}
                renderHeader={({ onCloseBottomSheet }) => (
                    <NavigationBar
                        title={trls[TrlKeys.titleResumes]}
                        right={<Action icon={CrossOutlinedSize24} onClick={onCloseBottomSheet} />}
                    />
                )}
            />
        );
    };

    return (
        <VSpacingContainer default={12}>
            {hasHiddenResumes && <Text typography="label-3-regular">{trls[TrlKeys.hiddenResumes]}</Text>}
            {renderSelect()}
        </VSpacingContainer>
    );
};

export default translation(ResumeSelectorV2);
