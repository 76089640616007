import { useCallback } from 'react';

import { NovaFilters, NovaFilterKey } from 'src/models/novaFilters';

import { useApplyFilters } from 'src/components/Search/Common/Filters/hooks/useApplyFilters';
import { useUpdateFilter } from 'src/components/Search/Common/Filters/hooks/useUpdateFilter';

type UseApplyFilterHook = <F extends NovaFilterKey>(
    filter: F
) => (newSelectedValues: NovaFilters[F]['selectedValues']) => void;

export const useApplyFilter: UseApplyFilterHook = (filter) => {
    const updateFilter = useUpdateFilter(filter);
    const applyFilter = useApplyFilters();

    return useCallback(
        (newSelectedValues) => {
            updateFilter(newSelectedValues);
            applyFilter();
        },
        [applyFilter, updateFilter]
    );
};
