import { FC, SyntheticEvent } from 'react';

import { Action, BottomSheet, NavigationBar, Modal, VSpacing } from '@hh.ru/magritte-ui';
import { CrossOutlinedSize24 } from '@hh.ru/magritte-ui/icon';

import { useIsZarplataPlatform } from 'src/hooks/usePlatform';
import { useSelector } from 'src/hooks/useSelector';
import { NavItems } from 'src/models/supernovaNaviMenu';
import { UserType } from 'src/models/userType';

import HelpModalButtons from 'src/components/SupernovaMainMenu/HelpModal/HelpModalButtons';
import HelpModalContent from 'src/components/SupernovaMainMenu/HelpModal/HelpModalContent';
import { HelpTitle, HelpSubTitle } from 'src/components/SupernovaMainMenu/HelpModal/HelpModalTitle';

const HelpModal: FC<{
    isVisible: boolean;
    handleCloseModal: (event?: SyntheticEvent) => void;
    subItems?: NavItems;
    onClose?: () => void;
}> = ({ isVisible, handleCloseModal, subItems, onClose }) => {
    const vacation = useSelector(({ vacation }) => vacation);
    const isZarplata = useIsZarplataPlatform();
    const userType = useSelector(({ userType }) => userType);
    const isEmployer = UserType.Employer === userType;

    const isContactPhoneEnabled = subItems?.some((item) => item.name === 'contactPhone');
    const hasMessengersEnabled = subItems?.some((item) => item.name === 'messengers');
    const personalManagerItem = subItems?.find((item) => item.name === 'personalManager');
    const buttonLinksItem = subItems?.find((item) => item.name === 'helpButtonLinks');

    const showContactPhonesVSpacing =
        buttonLinksItem?.subItems?.length || personalManagerItem || hasMessengersEnabled || isEmployer;

    const showContactPhones = isContactPhoneEnabled && (vacation === null || isZarplata);

    const closeBottomSheet = () => {
        handleCloseModal();
    };

    return (
        <>
            <Modal
                visible={isVisible}
                onClose={handleCloseModal}
                actions={<Action mode="secondary" onClick={handleCloseModal} icon={CrossOutlinedSize24} />}
                titleAlignment="center"
                titleElement="h4"
                titleSize="medium"
                titleDescriptionStyle="secondary"
                title={<HelpTitle />}
                titleDescription={<HelpSubTitle isContactPhoneEnabled={isContactPhoneEnabled} />}
                footer={showContactPhones ? <HelpModalButtons /> : undefined}
            >
                <HelpModalContent subItems={subItems} handleCloseModal={handleCloseModal} onClose={onClose} />
                {showContactPhones && showContactPhonesVSpacing && <VSpacing default={24} xs={16} s={16} />}
            </Modal>
            <BottomSheet
                visible={isVisible}
                header={
                    <NavigationBar
                        title={<HelpTitle />}
                        subtitle={<HelpSubTitle isContactPhoneEnabled={isContactPhoneEnabled} />}
                        right={<Action icon={CrossOutlinedSize24} onClick={closeBottomSheet} />}
                    />
                }
                footer={showContactPhones ? <HelpModalButtons /> : undefined}
                onClose={closeBottomSheet}
            >
                <HelpModalContent subItems={subItems} handleCloseModal={handleCloseModal} onClose={onClose} />
                {showContactPhonesVSpacing && <VSpacing default={24} xs={16} s={16} />}
            </BottomSheet>
        </>
    );
};

export default HelpModal;
