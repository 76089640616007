import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';
import TreeCollection from 'bloko/common/tree/treeCollection';
import { AdditionalDefault } from 'bloko/common/tree/types';

import { CurrencyType } from 'src/models/currencies.types';
import DriverLicenseType from 'src/models/driverLicense.types';

export enum Gender {
    Male = 'male',
    Female = 'female',
    Unknown = 'unknown',
}

export enum EducationLevel {
    None = 'none',
    Higher = 'higher',
    Bachelor = 'bachelor',
    Master = 'master',
    Candidate = 'candidate',
    Doctor = 'doctor',
    UnfinishedHigher = 'unfinished_higher',
    Secondary = 'secondary',
    SpecialSecondary = 'special_secondary',
}

export enum ExperienceOption {
    None = 'none',
    One2Three = 'one2three',
    Three2Six = 'three2six',
    MoreThanSix = 'moreThanSix',
}

export enum RelocationOption {
    Living = 'living',
    LivingButRelocation = 'living_but_relocation',
    LivingOrRelocation = 'living_or_relocation',
    Relocation = 'relocation',
}

export enum OrderOption {
    Date = 'DATE',
    Relevance = 'RELEVANCE',
}

export enum FilterName {
    Age = 'age',
    Gender = 'gender',
    Areas = 'area',
    Relocation = 'relocation',
    Citizenship = 'citizenship',
    Education = 'education',
    Universities = 'university',
    Experience = 'experience',
    DriverLicenses = 'driverLicense',
    Languages = 'language',
    Salary = 'salary',
    Distance = 'distance',
    ShowOnlyWithVehicle = 'showOnlyWithVehicle',
    ShowOnlyNew = 'showOnlyNew',
    ShowOnlyNewResponses = 'showOnlyNewResponses',
    Order = 'order',
    SearchText = 'searchText',
}

export interface Range {
    from: string;
    to: string;
}

export interface SalaryRange extends Range {
    currencyCode: CurrencyType;
}

export interface University {
    id: string;
    text: string;
    acronym?: string;
}

export interface FilterValues {
    [FilterName.Age]: Range;
    [FilterName.Gender]: Gender;
    [FilterName.Areas]: string[];
    [FilterName.Relocation]: RelocationOption;
    [FilterName.Citizenship]: string[];
    [FilterName.Education]: EducationLevel;
    [FilterName.Universities]: University[];
    [FilterName.DriverLicenses]: DriverLicenseType[];
    [FilterName.Languages]: string[];
    [FilterName.Salary]: SalaryRange;
    [FilterName.Experience]: ExperienceOption[];
    [FilterName.Distance]: string;
    [FilterName.ShowOnlyWithVehicle]: boolean;
    [FilterName.ShowOnlyNew]: boolean;
    [FilterName.ShowOnlyNewResponses]: boolean;
    [FilterName.SearchText]: string;
    // инициализация и значения опций для фильтра хранятся в candidatesList.sortingFilters
    [FilterName.Order]?: OrderOption;
}

export type GetFilterValueType<T extends FilterName> = FilterValues[T];
export type GetFilterValueSimpleType<T extends FilterName> = FilterValues[T] extends (infer I)[] ? I : FilterValues[T];

export type Filter<V, A = unknown> = {
    value: V;
    onChange: (val: V) => void;
    shouldUseXs?: boolean;
} & A;

export interface AdditionalFilters {
    [FilterName.Areas]?: Filter<
        GetFilterValueType<FilterName.Areas>,
        {
            collection: TreeCollection<AdditionalDefault>;
            [FilterName.Relocation]: Filter<GetFilterValueType<FilterName.Relocation>>;
        }
    >;
    [FilterName.Citizenship]?: Filter<GetFilterValueType<FilterName.Citizenship>>;
    [FilterName.DriverLicenses]?: Filter<GetFilterValueType<FilterName.DriverLicenses>>;
    [FilterName.Languages]?: Filter<GetFilterValueType<FilterName.Languages>>;
    [FilterName.Salary]?: Filter<GetFilterValueType<FilterName.Salary>>;
    [FilterName.ShowOnlyWithVehicle]?: Filter<GetFilterValueType<FilterName.ShowOnlyWithVehicle>>;
    [FilterName.Distance]?: Filter<GetFilterValueType<FilterName.Distance>>;
    [FilterName.Order]?: Filter<GetFilterValueType<FilterName.Order>>;
}

export interface MainFilters {
    [FilterName.Age]: Filter<GetFilterValueType<FilterName.Age>>;
    [FilterName.Gender]: Filter<GetFilterValueType<FilterName.Gender>>;
    [FilterName.Education]: Filter<GetFilterValueType<FilterName.Education>>;
    [FilterName.Universities]: Filter<GetFilterValueType<FilterName.Universities>>;
    [FilterName.Experience]: Filter<GetFilterValueType<FilterName.Experience>>;
    [FilterName.ShowOnlyNew]: Filter<GetFilterValueType<FilterName.ShowOnlyNew>>;
    [FilterName.ShowOnlyNewResponses]: Filter<GetFilterValueType<FilterName.ShowOnlyNewResponses>>;
    [FilterName.SearchText]: Filter<GetFilterValueType<FilterName.SearchText>>;
}

interface ActiveFiltersCount {
    all: number;
    minFiltersInDialog: number;
    maxFiltersInDialog: number;
}

export interface VacancyResponsesFilters {
    dicts: {
        genderTypes: Gender[];
        educationLevelTypes: EducationLevel[];
        experienceOptions: ExperienceOption[];
        relocationOptions: RelocationOption[];
        driverLicenseTypes: DriverLicenseType[];
    };
    initialValues: FilterValues;
    emptyValues: FilterValues;
    isLoading: boolean;
    isResettingFilters: boolean;
    isApplyingFilters: boolean;
    distanceFilterEnabled: boolean;
    isDirtyDistanceFilter: boolean;
    activeFiltersCount: ActiveFiltersCount;
}

const VACANCY_FILTERS_START_LOADING = 'VACANCY_FILTERS_START_LOADING';
const VACANCY_FILTERS_FINISH_LOADING = 'VACANCY_FILTERS_FINISH_LOADING';
const VACANCY_FILTERS_START_RESET = 'VACANCY_FILTERS_START_RESET';
const VACANCY_FILTERS_START_APPLY = 'VACANCY_FILTERS_START_APPLY';
const VACANCY_FILTERS_UPDATE_STATE = 'VACANCY_FILTERS_UPDATE_STATE';

interface PayloadTypes {
    [VACANCY_FILTERS_START_LOADING]: void;
    [VACANCY_FILTERS_FINISH_LOADING]: void;
    [VACANCY_FILTERS_START_RESET]: void;
    [VACANCY_FILTERS_START_APPLY]: void;
    [VACANCY_FILTERS_UPDATE_STATE]: Omit<VacancyResponsesFilters, 'dicts'>;
}

const ActionCreator = ActionCreatorHelper<PayloadTypes>();

export const vacancyFiltersStartLoading = ActionCreator(VACANCY_FILTERS_START_LOADING);
export const vacancyFiltersFinishLoading = ActionCreator(VACANCY_FILTERS_FINISH_LOADING);
export const vacancyFiltersStartReset = ActionCreator(VACANCY_FILTERS_START_RESET);
export const vacancyFiltersStartApply = ActionCreator(VACANCY_FILTERS_START_APPLY);
export const vacancyFiltersUpdateState = ActionCreator(VACANCY_FILTERS_UPDATE_STATE);

const fallbackFilterValues: FilterValues = {
    [FilterName.Age]: { from: '', to: '' },
    [FilterName.Gender]: Gender.Unknown,
    [FilterName.Areas]: [],
    [FilterName.Relocation]: RelocationOption.LivingOrRelocation,
    [FilterName.Citizenship]: [],
    [FilterName.Education]: EducationLevel.None,
    [FilterName.Universities]: [],
    [FilterName.DriverLicenses]: [],
    [FilterName.Languages]: [],
    [FilterName.Salary]: { from: '', to: '', currencyCode: CurrencyType.RUR },
    [FilterName.Experience]: [],
    [FilterName.ShowOnlyWithVehicle]: false,
    [FilterName.ShowOnlyNew]: false,
    [FilterName.ShowOnlyNewResponses]: false,
    [FilterName.Order]: OrderOption.Relevance,
    [FilterName.SearchText]: '',
    [FilterName.Distance]: '',
};

const initialValues: VacancyResponsesFilters = {
    dicts: {
        genderTypes: [],
        educationLevelTypes: [],
        experienceOptions: [],
        relocationOptions: [],
        driverLicenseTypes: [],
    },
    initialValues: fallbackFilterValues,
    emptyValues: fallbackFilterValues,
    isLoading: false,
    isResettingFilters: false,
    isApplyingFilters: false,
    distanceFilterEnabled: false,
    isDirtyDistanceFilter: false,
    activeFiltersCount: {
        all: 0,
        minFiltersInDialog: 0,
        maxFiltersInDialog: 0,
    },
};

export default createReducer<VacancyResponsesFilters, PayloadTypes>(initialValues, {
    [VACANCY_FILTERS_START_LOADING]: (state) => ({ ...state, isLoading: true }),
    [VACANCY_FILTERS_FINISH_LOADING]: (state) => ({
        ...state,
        isLoading: false,
        isResettingFilters: false,
        isApplyingFilters: false,
    }),
    [VACANCY_FILTERS_START_RESET]: (state) => ({
        ...state,
        isLoading: true,
        isResettingFilters: true,
        isApplyingFilters: false,
    }),
    [VACANCY_FILTERS_START_APPLY]: (state) => ({
        ...state,
        isLoading: true,
        isResettingFilters: false,
        isApplyingFilters: true,
    }),
    [VACANCY_FILTERS_UPDATE_STATE]: (state, action) => ({ ...state, ...action.payload }),
});

export { DriverLicenseType };
