import { FC } from 'react';

import { useSelector } from 'src/hooks/useSelector';
import { CriteriaKey } from 'src/models/search/searchCriteria.types';
import { SupernovaSearchName } from 'src/models/supernovaSearchName';

import { useHiddenAreaList } from 'src/components/SupernovaSearch/useHiddenAreaList';

const VacancySearchHiddenFields: FC = () => {
    const proxiedVacancySearchFormParams = useSelector(
        ({ vacancySearchResult }) => vacancySearchResult.proxiedSearchFormParams
    );

    const similarSavedSearchEnabled = useSelector(({ similarSavedSearchEnabled }) => similarSavedSearchEnabled);
    const hiddenAreaList = useHiddenAreaList();
    const hiddenAreaListElement = (
        <>
            {hiddenAreaList.map((savedAreaId) => (
                <input key={savedAreaId} type="hidden" name="area" value={savedAreaId} />
            ))}
        </>
    );

    const ignoredParams = [CriteriaKey.Text, CriteriaKey.Salary, CriteriaKey.Area, CriteriaKey.SearchSessionId];

    if (proxiedVacancySearchFormParams) {
        return (
            <>
                {similarSavedSearchEnabled && <input type="hidden" name="forceFiltersSaving" value="true" />}
                <input
                    name="salary"
                    type="hidden"
                    value={proxiedVacancySearchFormParams.salary || ''}
                    data-qa="vacancy-serp__compensation"
                />
                {Object.entries(proxiedVacancySearchFormParams)
                    .filter(([key]) => !ignoredParams.includes(key))
                    .map(([key, value]) =>
                        Array.isArray(value) ? (
                            value.map((value, index) => (
                                <input type="hidden" name={key} key={`${key}${index}`} value={value.toString()} />
                            ))
                        ) : (
                            <input type="hidden" name={key} key={key} value={value.toString()} />
                        )
                    )}

                <fieldset data-search-type={SupernovaSearchName.Vacancies}>{hiddenAreaListElement}</fieldset>
            </>
        );
    }

    return (
        <fieldset data-search-type={SupernovaSearchName.Vacancies}>
            {hiddenAreaListElement}
            {similarSavedSearchEnabled && <input type="hidden" name="forceFiltersSaving" value="true" />}
        </fieldset>
    );
};

export default VacancySearchHiddenFields;
