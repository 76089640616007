interface ClearXSLMetaTagsProps {
    title?: string;
    ogTitle?: string;
    removeTitle?: boolean;
    description?: string;
    ogDescription?: string;
    canonical?: string;
}

export const clearXSLMetaTags = ({
    title,
    ogTitle,
    removeTitle,
    description,
    ogDescription,
    canonical,
}: ClearXSLMetaTagsProps): void => {
    const tagsToRemove: Array<Element | null> = [];
    const addTagToRemove = (selector: string): void => {
        const tag = document.querySelector(selector);
        if (tag) {
            tagsToRemove.push(tag);
        }
    };

    if (removeTitle || title) {
        ['.HH-PageLayout-Title', '.HH-PageLayout-OgTitle'].forEach(addTagToRemove);
    }
    if (!title && ogTitle) {
        addTagToRemove('.HH-PageLayout-OgTitle');
    }
    if (description) {
        ['.HH-PageLayout-Description', '.HH-PageLayout-OgDescription'].forEach(addTagToRemove);
    }
    if (!description && ogDescription) {
        addTagToRemove('.HH-PageLayout-OgDescription');
    }
    if (canonical) {
        addTagToRemove('.HH-PageLayout-Canonical');
    }

    tagsToRemove.forEach((item) => item?.parentNode?.removeChild(item));
};
