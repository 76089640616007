import { FC } from 'react';

import Form from 'src/components/Search/Common/Filters/Form';
import ResetAll from 'src/components/Search/Common/Filters/ResetAll';
import SearchLanguageLink from 'src/components/Search/Common/Filters/SearchLanguageLink';
import { useIsZarplataPlatform } from 'src/hooks/usePlatform';

import Area from 'src/components/Search/Vacancy/Filters/Area';
import Company from 'src/components/Search/Vacancy/Filters/Company';
import Compensation from 'src/components/Search/Vacancy/Filters/Compensation';
import District from 'src/components/Search/Vacancy/Filters/District';
import Education from 'src/components/Search/Vacancy/Filters/Education';
import Employment from 'src/components/Search/Vacancy/Filters/Employment';
import ExcludedText from 'src/components/Search/Vacancy/Filters/ExcludedText';
import Experience from 'src/components/Search/Vacancy/Filters/Experience';
import IndustryAndSubIndustry from 'src/components/Search/Vacancy/Filters/IndustryAndSubIndustry';
import Label from 'src/components/Search/Vacancy/Filters/Labels';
import Metro from 'src/components/Search/Vacancy/Filters/Metro';
import MobileSorts from 'src/components/Search/Vacancy/Filters/MobileSorts';
import Neighbours from 'src/components/Search/Vacancy/Filters/Neighbours';
import PartTime from 'src/components/Search/Vacancy/Filters/PartTime';
import ProfessionalRole from 'src/components/Search/Vacancy/Filters/ProfessionalRole';
import Schedule from 'src/components/Search/Vacancy/Filters/Schedule';
import SearchField from 'src/components/Search/Vacancy/Filters/SearchField';
import ResumeContext from 'src/components/Search/Vacancy/Filters/SimilarResume';
import SimilarVacancy from 'src/components/Search/Vacancy/Filters/VacancyId';

interface VacancySearchFiltersProps {
    isIndexAnonSearchRedirectDisabled?: boolean;
    withSorting?: boolean;
}

const VacancySearchFilters: FC<VacancySearchFiltersProps> = ({
    isIndexAnonSearchRedirectDisabled = false,
    withSorting = false,
}) => {
    const isZpPlatform = useIsZarplataPlatform();

    return (
        <>
            {withSorting && <MobileSorts />}
            <Form>
                <PartTime />
                <ExcludedText />
                <Company />
                <ResumeContext />
                <SimilarVacancy />
                <Compensation />
                <Area />
                {!isZpPlatform && <District />}
                <Neighbours />
                <Metro />
                <ProfessionalRole />
                <IndustryAndSubIndustry />
                <Education />
                <Experience />
                <Employment />
                <Schedule />
                <Label />
                <SearchField />
                {!isIndexAnonSearchRedirectDisabled && <ResetAll />}
                <SearchLanguageLink />
            </Form>
        </>
    );
};

export default VacancySearchFilters;
