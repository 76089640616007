import { FC } from 'react';

import { useBreakpoint } from '@hh.ru/magritte-ui';

import SupportContacts from 'src/components/Support/SupportContacts/SupportContacts';

import styles from './help-modal-buttons.less';

const HelpLeftButtonsComponent: FC = () => {
    const { isMobile } = useBreakpoint();

    return (
        <div className={isMobile ? styles.helpModalBottomSheetButtons : styles.helpModalLeftButtons}>
            <SupportContacts />
        </div>
    );
};

export default HelpLeftButtonsComponent;
