import { useBreakpoint, Badge, Button, ComponentWithBadge } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import useTrackClicks from 'src/components/SupernovaMainMenu/useTrackClicks';
import SupportAction from 'src/components/Support/SupportActions/SupportAction';
import SupportActionCell from 'src/components/Support/SupportActions/SupportActionCell';
import { SUPPORT_ACTIONS_ICONS } from 'src/components/Support/SupportActions/constants';
import useSupportChatik from 'src/components/SupportChatPlate/useSupportChatik';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { NavItem } from 'src/models/supernovaNaviMenu';
import { UserType } from 'src/models/userType';

const TrlKeys = {
    supportChatContact: 'supernova.modal.help.supportChat.contact',
};

interface HelpModalButtonLinkProps {
    isWide?: boolean;
    chatik: ReturnType<typeof useSupportChatik>;
}

const HelpModalButtonLink: TranslatedComponent<HelpModalButtonLinkProps & NavItem> = ({
    trls,
    chatik,
    isWide = false,
    ...supportAction
}) => {
    const commonProps = {
        url: supportAction.url,
        external: supportAction.external,
        icon: SUPPORT_ACTIONS_ICONS[supportAction.name as keyof typeof SUPPORT_ACTIONS_ICONS],
        iconAriaLabel: supportAction.name,
        children: supportAction.translations.name,
    };
    const { isMobile } = useBreakpoint();
    const { dataQa, handleChatikClick, unreadCount } = chatik;
    const userType = useSelector(({ userType }) => userType);
    const isEmployer = UserType.Employer === userType;
    const isChatik = supportAction.name === 'supportChat';
    const trackClick = useTrackClicks(supportAction.name, supportAction.trackClicks, supportAction.analytics);
    return (
        <SupportActionCell isWide={isWide}>
            {isChatik ? (
                <SupportAction
                    {...commonProps}
                    onClick={handleChatikClick}
                    data-qa={dataQa}
                    right={
                        !isEmployer && !isMobile ? (
                            <ComponentWithBadge
                                badge={unreadCount ? <Badge size="medium">{`${unreadCount}`}</Badge> : undefined}
                                offset={-4}
                            >
                                <Button onClick={handleChatikClick} mode="secondary" data-qa={dataQa}>
                                    {trls[TrlKeys.supportChatContact]}
                                </Button>
                            </ComponentWithBadge>
                        ) : undefined
                    }
                    badgeCount={isMobile ? unreadCount : undefined}
                />
            ) : (
                <SupportAction data-qa={`mainmenu_${supportAction.name}`} onClick={trackClick} {...commonProps} />
            )}
        </SupportActionCell>
    );
};

export default translation(HelpModalButtonLink);
