import { FormLabel, VSpacing } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { useApplySort } from 'src/components/Search/Common/Filters/hooks/useApplySort';
import ChipsSelect from 'src/components/Search/Vacancy/Filters/FilterChips/Select';
import { ChipsSelectType } from 'src/components/Search/Vacancy/Filters/FilterChips/types';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import useIsEmployerVacancySearch from 'src/models/employerVacancySearch/useIsEmployerVacancySearch';
import { SearchPeriodOption } from 'src/models/vacancySearchCriteria';

const TrlKeys = {
    title: 'vacancySearch.searchPeriod',
    options: {
        [SearchPeriodOption.AllTime]: 'vacancySearch.searchPeriod.all',
        [SearchPeriodOption.Month]: 'vacancySearch.searchPeriod.30',
        [SearchPeriodOption.Week]: 'vacancySearch.searchPeriod.7',
        [SearchPeriodOption.ThreeDays]: 'vacancySearch.searchPeriod.3',
        [SearchPeriodOption.Day]: 'vacancySearch.searchPeriod.1',
    },
};

const SearchPeriod: TranslatedComponent = ({ trls }) => {
    const options = useSelector((state) => state.vacancySearchDictionaries.searchPeriod);
    const value = useSelector((state) => state.novaSorts.searchPeriod);
    const applySort = useApplySort('searchPeriod');
    const isEmployerVacancySearch = useIsEmployerVacancySearch();

    if (!options || isEmployerVacancySearch) {
        return null;
    }

    return (
        <>
            <FormLabel>{trls[TrlKeys.title]}</FormLabel>
            <VSpacing default={12} />
            <ChipsSelect
                name="search_period"
                selected={[value]}
                data-qa="serp-settings__search-period-menu"
                options={options.map((option) => ({
                    id: option,
                    title: trls[TrlKeys.options[option]],
                }))}
                selectType={ChipsSelectType.Radio}
                onChange={(values) => applySort(values[0])}
            />
            <VSpacing default={24} />
        </>
    );
};

export default translation(SearchPeriod);
