import Link from 'bloko/blocks/link';
import { NotificationKind } from 'bloko/blocks/notificationManager/Notification';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { formatToReactComponent } from 'bloko/common/trl';

import SynchronizedNotification from 'src/components/UserNotifications/common/SynchronizedNotification';
import { SimpleNotificationComponentProps } from 'src/components/UserNotifications/common/notificationComponentProps';
import translation from 'src/components/translation';

const TrlKeys = {
    email_verification_was_sent: 'notifications.email_verification_was_sent',
    email_verification_was_sent_again: 'notifications.email_verification_was_sent_again',
};

const EmailVerificationWasSent: TranslatedComponent<SimpleNotificationComponentProps> = ({
    trls,
    onClose,
    params,
    templateKey,
}) => (
    <SynchronizedNotification kind={NotificationKind.Ok} onClose={onClose} notification={{ params, templateKey }}>
        {formatToReactComponent(trls[TrlKeys[templateKey as keyof typeof TrlKeys]], {
            '{0}': (
                <Link href={params.mailerUrl} target="_blank">
                    {params.email}
                </Link>
            ),
        })}
    </SynchronizedNotification>
);

export default translation(EmailVerificationWasSent);
