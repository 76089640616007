import Analytics from '@hh.ru/analytics-js';
import { getBreakpoint } from 'bloko/common/media';

interface EventData {
    value?: string;
    position?: string;
    type?: string;
    buttonName: string;
    event?: string;
}

// название (event) и тип события (type) можно указать, разделив их точкой: "createResume.top", "createResume.bottom"
const getElementEventData = (element: HTMLElement): EventData => {
    const dataset = element.dataset;
    const eventRaw = dataset.pageAnalyticsEvent;
    let eventName = '';
    let eventType = null;
    if (eventRaw) {
        const eventData = eventRaw.split('.');
        eventName = eventData[0];
        eventType = eventData[1];
    }
    const value = dataset.pageAnalyticsValue;
    const position = dataset.pageAnalyticsPosition;
    return {
        buttonName: eventName,
        ...(eventType && { type: eventType }),
        ...(value && { value }),
        ...(position && { position }),
    };
};

const sendAnalytics = (eventData: EventData) => {
    Analytics.sendHHEventButtonClick(eventData.event || '', {
        breakpoint: getBreakpoint(),
        ...eventData,
    });
};

export const sendInternalAnalytics = ({ target }: { target: HTMLElement }): void => {
    const matchedElement: HTMLElement | null = target.closest('[data-page-analytics-event]');
    if (matchedElement) {
        const eventData = getElementEventData(matchedElement);
        sendAnalytics(eventData);
    }
};
