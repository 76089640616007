import { FC, ReactNode, PropsWithChildren, useContext, useCallback } from 'react';

import { Divider, Text, VSpacing } from '@hh.ru/magritte-ui';

import { SearchFilterTipContext } from 'src/components/Search/Common/Filters/TipContext';
import { NovaFilterKey } from 'src/models/novaFilters';

import styles from './styles.less';

interface FilterWrapperProps {
    filterKey?: NovaFilterKey;
    title?: ReactNode;
    titleSideElement?: ReactNode;
    withDivider?: boolean;
}

const FilterWrapper: FC<FilterWrapperProps & PropsWithChildren> = ({
    filterKey,
    title,
    titleSideElement,
    withDivider,
    children,
}) => {
    const filtersRefs = useContext(SearchFilterTipContext);

    const setFilterRefs = useCallback(
        (ref: HTMLDivElement | null): void => {
            if (!filtersRefs.current || !filterKey || !ref) {
                return;
            }
            filtersRefs.current[filterKey] = ref;
        },
        [filtersRefs, filterKey]
    );

    return (
        <fieldset style={{ minWidth: 'auto' }} data-qa="serp__novafilter-group">
            {title && (
                <>
                    <div
                        ref={(ref) => setFilterRefs(ref)}
                        className={styles.title}
                        data-qa="serp__novafilter-group-title"
                    >
                        <Text typography="subtitle-1-semibold">
                            <legend>{title}</legend>
                        </Text>
                        {!!titleSideElement && titleSideElement}
                    </div>
                    <VSpacing default={12} />
                </>
            )}
            <div>{children}</div>
            {withDivider ? <Divider marginTop={12} marginBottom={12} /> : <VSpacing default={24} />}
        </fieldset>
    );
};

export default FilterWrapper;
