import { isNumber } from 'Utils/Utils';
import { BrandGradient } from 'src/models/brandSnippetEditor/brandSnippet.types';

const getGradient = (gradient?: BrandGradient): string | undefined => {
    if (!gradient?.colorList) {
        return undefined;
    }

    const colorList = gradient.colorList
        .map(({ color, position }) => {
            if (!color) {
                return undefined;
            }
            return `${color}${isNumber(position) ? ` ${position}%` : ''}`;
        })
        .filter((element) => !!element);

    if (colorList.length === 0) {
        return undefined;
    }

    const gradientList = isNumber(gradient.angle) ? [`${gradient.angle}deg`, ...colorList] : colorList;
    return `linear-gradient(${gradientList.join(', ')})`;
};

export default getGradient;
