import { useEffect, useState, FC } from 'react';

import ContainerForMicroFrontend from 'src/components/CodeInjector/ContainerForMicroFrontend';
import { useSelector } from 'src/hooks/useSelector';

const PRELOAD_PLACE = 'webcall-front-preload';
const DYNAMIC_PRELOAD_PLACE = 'webcall-front-dynamic-preload';

interface WebcallPreloaderProps {
    dynamic?: boolean;
}

/**
 * Так как прокси компонент находится за кликом
 * и если скачивать всю статику после клика, то получается достаточно долго.
 * Поэтому делаем предзагрузку статики заранее.
 */
const WebcallPreloader: FC<WebcallPreloaderProps> = ({ dynamic = false }) => {
    const [shouldPreload, setSouldPreload] = useState(false);
    const preloadPlace = dynamic ? DYNAMIC_PRELOAD_PLACE : PRELOAD_PLACE;
    const preloadableContent = useSelector(({ microFrontends }) => microFrontends[preloadPlace]);

    useEffect(() => {
        if (!preloadableContent) {
            return;
        }

        requestIdleCallback(() => setSouldPreload(true));
    }, [preloadableContent]);

    return shouldPreload ? (
        <div className="g-hidden">
            <ContainerForMicroFrontend place={preloadPlace} preloadContent={null} />
        </div>
    ) : null;
};

export default WebcallPreloader;
