import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';
import { Breakpoint } from 'bloko/common/hooks/useBreakpoint';

import { ArticleRubric, ArticleTag, ArticleTopic, ArticleType } from 'src/models/articlePage/types';
import { Paging } from 'src/models/paging.types';

interface BlogCategory<T> {
    name: string;
    code: T;
    priority: number;
    type: string | null;

    visible_on: string[];
}

export type BlogTag = BlogCategory<ArticleTag>;

export interface BlogTopic extends BlogCategory<ArticleTopic> {
    tags: BlogTag[];
}

export interface BlogRubric extends BlogCategory<ArticleRubric> {
    topics: BlogTopic[];
}

export interface BlogArticleItemStatic {
    title: string;
    preview: string;
    href: string;
    label?: string;
}

interface BlogArticleImage {
    type: string;
    url: string;
    caption: string;
}

export interface BlogDictionaryItem<T = string> {
    name: string;
    code: T;
}

export interface BlogArticle {
    title: string;
    titleWithHtml?: string;
    code: string;
    images?: BlogArticleImage[];
    preview?: string;
    announce?: string;
    eventStartDate?: string;
    eventStartDateShort?: string;
    eventEndDate?: string;
    publicationDate: string;
    articleType: ArticleType;
    topics: BlogDictionaryItem<ArticleTopic>[];
    employerId: string;
    archiveHash: string;
}

interface BlogEventsCalendar {
    regionOptions: BlogDictionaryItem[];
    profareaOptions?: BlogDictionaryItem[];
    eventTypeOptions: BlogDictionaryItem[];
    region: string;
    profarea: string;
    eventType: string;
}

interface BlogSearchData {
    query: string;
    resultTopics: ArticleTopic[];
}

interface BlogCompanyItem {
    companyName: string;
    url: string;
}

export interface BlogArticleListLayout {
    name: string;
    layout?: Record<Breakpoint, string>;
    articleListCapacity?: Record<Breakpoint, number>;
    viewType: (typeof BlogArticleItemViewType)[keyof typeof BlogArticleItemViewType];
}

export interface BlogProject {
    title: string;
    url: string;
    image: string;
}

interface BlogPage {
    rubrics: BlogRubric[];
    rubric: ArticleRubric | null;
    topic: ArticleTopic | null;
    articles: Record<string, BlogArticle[]>;
    layout: BlogArticleListLayout[];
    eventsCalendar?: BlogEventsCalendar;
    paging: Paging | null;
    isDenseCategory: boolean;
    isViewsCountEnabled: boolean;
    data: {
        [key: string]: unknown;
        slider: {
            items: BlogArticleItemStatic[];
        };
        promo: {
            items: BlogArticleItemStatic[];
        };
    };
    pressReleaseData: Record<string, BlogCompanyItem>;
    projects: BlogProject[];
    isSearchEnabled: boolean;
    searchData: BlogSearchData;
}

export const BlogArticleItemViewType = {
    Large: 'large',
    Medium: 'medium',
    Special: 'special',
    Mini: 'mini',
    Promo: 'promo',
    Event: 'event',
} as const;

export const LayoutCode = {
    ItProjects: 'it-projects',
    Insider: 'insider',
    Events: 'events',
} as const;

export default autoGeneratedReducer<BlogPage>({
    rubrics: [],
    rubric: null,
    topic: null,
    articles: {
        main: [],
    },
    paging: null,
    layout: [],
    isDenseCategory: false,
    isViewsCountEnabled: false,
    data: {
        slider: {
            items: [],
        },
        promo: {
            items: [],
        },
    },
    pressReleaseData: {},
    projects: [],
    isSearchEnabled: false,
    searchData: {
        query: '',
        resultTopics: [],
    },
});
