import Analytics from '@hh.ru/analytics-js';
import { ElementSpyInstance } from '@hh.ru/browser-api-utils';

export const sendNewsBoxTitleClick = (type: string): void => Analytics.sendHHEventButtonClick(`news_box_${type}`);

export const sendNewsBoxItemsShown = (element: HTMLElement, type: string, items: string[]): ElementSpyInstance => {
    return Analytics.sendHHEventElementShown(element, {
        name: `news_box_${type}_items`,
        item: items,
    });
};

export default {
    sendNewsBoxTitleClick,
    sendNewsBoxItemsShown,
};
