import { UserType } from 'src/models/userType';

import useExperiment from 'src/hooks/useExperiment';
import { useSelector } from 'src/hooks/useSelector';

// Варианты экспа: отклики 2.0
// https://jira.hh.ru/browse/PORTFOLIO-35894
interface MagritteResponseExpInfo {
    // Вариант B: редизайн отклика на мобильном вебе
    isMagritteResponseExpB: boolean;

    // Вариант C: десктоп выглядит как редизайнутый мобильный веб
    isMagritteResponseExpC: boolean;

    // Любой из вариантов (для общих алертов, баннеров и тд)
    isMagritteResponseExpAny: boolean;
}

export default function useMagritteResponseExp(): MagritteResponseExpInfo {
    const isApplicant = useSelector((state) => state.userType === UserType.Applicant);
    const isMagritteResponseExpB = useExperiment('web_magritte_response_b', isApplicant);
    const isMagritteResponseExpC = useExperiment('web_magritte_response_c', isApplicant);

    return {
        isMagritteResponseExpB,
        isMagritteResponseExpC,
        isMagritteResponseExpAny: isMagritteResponseExpB || isMagritteResponseExpC,
    };
}
