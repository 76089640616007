import { routerMiddleware } from 'connected-react-router';
import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';

import batchMiddleware, { batchReducer } from '@hh.ru/redux-batch-middleware';
import { makeSetStoreField, SET_FIELD_DATA_AS_IS } from '@hh.ru/redux-create-reducer';
import makeSPAMiddleware from '@hh.ru/redux-spa-middleware';
import Cookies from 'bloko/common/Cookies';

import statsSender from 'HHC/Performance/StatsSender';
import { getSentry } from 'HHC/sentry';
import { extractRequestError } from 'src/api/notifications/defaultRequestErrorHandler';
import { DEFAULT_ERROR } from 'src/components/Notifications/DefaultError';
import { NETWORK_ERROR } from 'src/components/Notifications/NetworkError';
import { addNotificationAction } from 'src/models/notifications';
import fetcher from 'src/utils/fetcher';
import { createHistory, getHistory } from 'src/utils/history';
import mutate from 'src/utils/state/mutators';
import { setStore } from 'src/utils/typedStore';

import locationChangeMiddleware from 'src/app/middlewares/locationChangeMiddleware';
import routes from 'src/app/routes';
import appReducers, { store } from 'src/app/store';

let storeInstance;
let _getOrCreateStore;

const MAGRITTE_OLD_LAYOUT_CLASSNAME = 'magritte-old-layout';
let composeEnhancers = compose;

if (
    (process.env.HH_BUILD_MODE === 'development' || Cookies.get('enable_redux_dev_tools') !== null) &&
    typeof window !== 'undefined' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
) {
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
}

function getNoTrlValue() {
    try {
        const value = new URLSearchParams(getHistory()?.location?.search).get('notrl');
        return value !== null && value !== 'false';
    } catch (_) {} // eslint-disable-line no-empty
    return false;
}

const showErrorWithMetricsSending = (error) => {
    return (dispatch) => {
        const errorInfo = extractRequestError(error);
        if (errorInfo.needToShowNotification) {
            const notification = errorInfo.errorType === 'networkError' ? NETWORK_ERROR : DEFAULT_ERROR;
            dispatch(addNotificationAction({ type: notification }));
        }
    };
};

if (!process.env.SSR) {
    window.globalVars = window.globalVars || {};
    window.globalVars.lux = window.globalVars.lux || {};

    const stateNode = document.getElementById('HH-Lux-InitialState');
    let stateError = null;

    try {
        window.globalVars.lux.initialState = JSON.parse(stateNode.content?.textContent || stateNode.innerText);
    } catch (_) {
        if (process.env.NODE_ENV !== 'test') {
            stateError = new Error('React initial state error');
            stateError.code = 'REACT_INITIAL_STATE_ERROR';
        }
    }

    createHistory();

    const onSpaError = ({ isCancelled, isRedirected }) => {
        if (isCancelled || isRedirected) {
            return;
        }

        statsSender.sendMetrics([
            {
                metricName: 'spa-attempt',
                count: 1,
            },
            {
                metricName: 'spa-error',
                count: 1,
            },
        ]);
    };

    const handleMagritteLayoutClassName = () => {
        const isUseMagritteLayout = storeInstance.getState().isUseMagritteLayout;

        if (isUseMagritteLayout) {
            document.body.classList.remove(MAGRITTE_OLD_LAYOUT_CLASSNAME);
        } else {
            document.body.classList.add(MAGRITTE_OLD_LAYOUT_CLASSNAME);
        }
    };

    const onSpaSuccess = () => {
        if (storeInstance) {
            const currentPage = window.globalVars.luxPageName;
            window.globalVars.luxPageName = storeInstance.getState().request.luxPageName;

            statsSender.sendMetrics([
                {
                    metricName: 'spa-attempt',
                    count: 1,
                    pageName: currentPage,
                },
                {
                    metricName: 'spa-success',
                    count: 1,
                },
            ]);

            handleMagritteLayoutClassName();
        }
    };

    _getOrCreateStore = () => {
        if (stateError !== null) {
            throw stateError;
        }

        if (!storeInstance) {
            const initialState = window.globalVars.lux.initialState;

            const spaMiddleware = makeSPAMiddleware({
                fetcher,
                routes,
                headers: {},
                rootReducer: store,
                makeSetStoreField,
                setFieldDataAsIs: SET_FIELD_DATA_AS_IS,
                showDefaultError: showErrorWithMetricsSending,
                resetSelectors: [
                    'forbidden',
                    'searchLoading',
                    'applicantResponseStreaks',
                    'applicantVacancyResponseStatuses',
                    'searchPreference',
                    'vacancyResponsePopup',
                    'supernovaSearchName',
                    'resumeSearchResult',
                    'vacancySearchResult',
                    'showResumeSearchConditions',
                    'viewDuration',
                    'errorPage',
                ],
                onSuccess: onSpaSuccess,
                onError: onSpaError,
            });

            const middleware = [
                spaMiddleware,
                thunkMiddleware,
                locationChangeMiddleware,
                batchMiddleware,
                routerMiddleware(getHistory()),
            ];

            const enhancers = [applyMiddleware(...middleware)];
            if (process.env.NODE_ENV !== 'test' && window.globalVars.features.sentry_logging) {
                const createSentryReduxEnhancer = getSentry()?.createSentryReduxEnhancer;
                if (createSentryReduxEnhancer) {
                    enhancers.push(
                        createSentryReduxEnhancer({
                            attachReduxState: false,
                        })
                    );
                }
            }

            const composedEnhancers = composeEnhancers(...enhancers);
            storeInstance = createStore(
                batchReducer(appReducers(getHistory())),
                mutate(initialState, { noTrl: getNoTrlValue() }),
                composedEnhancers
            );
            setStore(storeInstance);
        }
        if (window.globalVars?.features?.store_debug) {
            const storeInitialState = createStore(batchReducer(appReducers(getHistory()))).getState();
            import('@hh.ru/redux-dev-helper')
                .then(({ default: storeDevToolInit }) => storeDevToolInit(storeInstance, storeInitialState))
                .catch(console.error);
        }

        return storeInstance;
    };
}

let serverStoreInstance;

if (process.env.SSR) {
    _getOrCreateStore = function (initialState) {
        if (initialState) {
            const composedEnhancers = composeEnhancers(
                applyMiddleware(batchMiddleware, routerMiddleware(getHistory()))
            );
            serverStoreInstance = createStore(
                batchReducer(appReducers(getHistory())),
                mutate(initialState, { noTrl: getNoTrlValue() }),
                composedEnhancers
            );
        }

        return serverStoreInstance;
    };
}

const getOrCreateStore = _getOrCreateStore;

export default getOrCreateStore;
