import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

export default autoGeneratedReducer({
    header: '',
    title: '',
    ogTitle: '',
    description: '',
    ogDescription: '',
    rssHref: '',
    rssTitle: '',
    canonical: '',
    h1: '',
    hreflangItems: [],
    verifications: [],
});
