import { useMemo } from 'react';

import { Checkbox, Link } from '@hh.ru/magritte-ui';
import CompositeSelection from 'bloko/blocks/compositeSelection';
import BlokoTranslateGuard from 'bloko/blocks/translateGuard';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import TreeCollection from 'bloko/common/tree/treeCollection';

import FilterCount from 'src/components/Search/Common/Filters/FilterCount';
import FilterItem from 'src/components/Search/Common/Filters/FilterItem';
import FilterList from 'src/components/Search/Common/Filters/FilterList';
import FilterWrapper from 'src/components/Search/Common/Filters/FilterWrapper';
import LinkMore from 'src/components/Search/Common/Filters/LinkMore';
import { useFilterGroups } from 'src/components/Search/Common/Filters/hooks/useFilterGroups';
import useChangeParentTreeValue from 'src/components/Search/Vacancy/Filters/DepricatedCustomTreeSelector/hooks/useChangeParentTreeValue';
import translation from 'src/components/translation';
import { NovaFilterGroupMap, NovaFilterKey } from 'src/models/novaFilters';

export const isCheckedOrIndeterminate = (
    id: string,
    checkedValues: string[]
): { checked: boolean; indeterminate: boolean } => {
    const checked = checkedValues.includes(id);
    let indeterminate = false;
    if (!checked) {
        indeterminate = checkedValues.some((item) => item === id);
    }
    return {
        checked,
        indeterminate,
    };
};

interface Filter {
    groups: NovaFilterGroupMap<string>;
}

export const getChildrenWithCounts = (parent?: Filter, children?: Filter): Record<string, number> => {
    const result: Record<string, number> = {};
    if (!parent) {
        return result;
    }
    Object.values(parent.groups).forEach(({ id, count }) => {
        result[id] = count;
    });
    if (children) {
        Object.values(children.groups).forEach(({ id, count }) => {
            result[id] = count;
        });
    }
    return result;
};

const EMPTY_GROUP = {};
const TrlKeys = {
    chooseMore: 'clusters.chooseMore',
    placeholder: 'treeselector.quicksearch',
    notFound: 'treeselector.notFound',
    save: 'treeselector.save',
    cancel: 'treeselector.cancel',
};

interface ParentTreeProps {
    title?: string;
    setCheckedValuesWithOnChange: (newValues: string[]) => void;
    treeCollection: TreeCollection;
    selectedValues: string[];
    parentFilter?: Filter;
    childrenFilter?: Filter;
    order?: string[];
}

const ParentTree: TranslatedComponent<ParentTreeProps> = ({
    title,
    trls,
    setCheckedValuesWithOnChange,
    treeCollection,
    selectedValues,
    parentFilter,
    childrenFilter,
    order = [],
}) => {
    const groups = parentFilter?.groups || EMPTY_GROUP;
    const { items, breakpoint } = useFilterGroups(groups || EMPTY_GROUP, selectedValues.length, order);
    const counts = useMemo(() => getChildrenWithCounts(parentFilter, childrenFilter), [parentFilter, childrenFilter]);
    const onChangeParent = useChangeParentTreeValue(selectedValues, setCheckedValuesWithOnChange);

    return (
        <FilterWrapper filterKey={NovaFilterKey.Industry} title={title}>
            <FilterList>
                {items.map(({ title, id }, index) => {
                    if (index > breakpoint - 1) {
                        return null;
                    }
                    const { checked, indeterminate } = isCheckedOrIndeterminate(id, selectedValues);
                    const group = parentFilter?.groups[id];
                    return (
                        <FilterItem
                            key={id}
                            left={
                                <Checkbox
                                    value={id}
                                    onChange={({ target }) => {
                                        onChangeParent(target.value);
                                    }}
                                    checked={checked}
                                    indeterminate={indeterminate}
                                    dataQaCheckbox="serp__novafilter-item-text"
                                />
                            }
                            title={title}
                            count={group?.count}
                            disabled={!group}
                        />
                    );
                })}
            </FilterList>
            <LinkMore>
                {/* TODO: перевести на магритт в https://jira.hh.ru/browse/PORTFOLIO-30300 */}
                <CompositeSelection
                    collection={treeCollection}
                    value={selectedValues}
                    onChange={setCheckedValuesWithOnChange}
                    LabelComponent={({ id, text }) => (
                        <BlokoTranslateGuard useSpan>
                            {text}
                            <FilterCount count={counts[id]} isBloko />
                        </BlokoTranslateGuard>
                    )}
                    title={title}
                    trl={{
                        submit: trls[TrlKeys.save],
                        cancel: trls[TrlKeys.cancel],
                        searchPlaceholder: trls[TrlKeys.placeholder],
                        notFound: trls[TrlKeys.notFound],
                    }}
                >
                    {({ showTreeSelectorPopup }) => (
                        <Link Element="button" onClick={showTreeSelectorPopup}>
                            {trls[TrlKeys.chooseMore]}
                        </Link>
                    )}
                </CompositeSelection>
            </LinkMore>
        </FilterWrapper>
    );
};

export default translation(ParentTree);
