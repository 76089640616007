import { FC, ReactNode, RefObject, useRef } from 'react';
import classNames from 'classnames';

import styles from './tooltip-host.less';

interface ToolTipHost {
    children: (host: RefObject<HTMLSpanElement>, tipVisible: boolean) => ReactNode;
    tipVisible: boolean;
}

/**
 *
 * Компонент обёртка над Tooltip из magritte-ui. Генерирует DOM-ноду с <code>position: relative</code> и
 * <code>z-index: 999</code> для передачи в качестве host.
 * Таким образом уменьшает дефолтное позиционирование по оси z Tooltip из magritte-ui до значений дропов из bloko.
 *
 * Нужен если на странице есть элементы с позиционированием по оси z из bloko и их перекрывают Tooltip из magritte-ui.
 *
 *
 * @param children - рендер функция, в которую передаётся host нода
 *
 * @param tipVisible - флаг, указывающий отображается Tooltip или нет
 */

const TooltipHost: FC<ToolTipHost> = ({ children, tipVisible }) => {
    const host = useRef<HTMLDivElement>(null);

    return (
        <div ref={host} className={classNames(styles.tooltipHost, { [styles.tooltipHostBlokoLayer]: tipVisible })}>
            {children(host, tipVisible)}
        </div>
    );
};

export default TooltipHost;
