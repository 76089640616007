import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';

import {
    BrandSnippetEdit,
    PictureScalableAttribute,
    PictureScalableType,
    ScreenSize,
} from 'src/models/brandSnippetEditor/brandSnippet.types';

export interface BrandSnippetEditor {
    employerId: string;
    templates?: {
        id: number;
        name: string;
    }[];
    remainingApplyCount?: number;
    editableSnippet?: BrandSnippetEdit;
    editableSnippetName?: string;
    isApplyAllowed?: boolean;
    isEditAllowed?: boolean;
}

const SET_EDITABLE_BRAND_SNIPPET = 'SET_EDITABLE_BRAND_SNIPPET';
const SET_EDITABLE_SNIPPET_NAME = 'SET_EDITABLE_SNIPPET_NAME';
const SET_EDITABLE_SNIPPET_FIELD = 'SET_EDITABLE_SNIPPET_FIELD';
const SET_EDITABLE_SNIPPEN_PICTURE = 'SET_EDITABLE_SNIPPEN_PICTURE';
const SET_REMAINING_APPLY_COUNT = 'SET_REMAINING_APPLY_COUNT';

type SingleKeyOf<T> = keyof T extends infer K ? (K extends keyof T ? Pick<T, K> : never) : never;

interface PayloadTypes {
    [SET_EDITABLE_BRAND_SNIPPET]: BrandSnippetEdit;
    [SET_EDITABLE_SNIPPET_NAME]?: string;
    [SET_EDITABLE_SNIPPET_FIELD]: SingleKeyOf<BrandSnippetEdit>;
    [SET_EDITABLE_SNIPPEN_PICTURE]: {
        type: PictureScalableType;
        screenSize: ScreenSize;
        data?: PictureScalableAttribute;
    };
    SET_REMAINING_APPLY_COUNT: BrandSnippetEditor['remainingApplyCount'];
}

const actionCreator = ActionCreatorHelper<PayloadTypes>();
export const setEditableBrandSnippet = actionCreator(SET_EDITABLE_BRAND_SNIPPET);
export const setEditableSnippetName = actionCreator(SET_EDITABLE_SNIPPET_NAME);
export const setEditableSnippetField = actionCreator(SET_EDITABLE_SNIPPET_FIELD);
export const setEditableSnippetPicture = actionCreator(SET_EDITABLE_SNIPPEN_PICTURE);
export const setRemainingApplyCount = actionCreator(SET_REMAINING_APPLY_COUNT);

export default createReducer<BrandSnippetEditor, PayloadTypes>(null, {
    [SET_EDITABLE_BRAND_SNIPPET]: (state, { payload }) => ({ ...state, editableSnippet: payload }),
    [SET_EDITABLE_SNIPPET_NAME]: (state, { payload }) => ({ ...state, editableSnippetName: payload }),
    [SET_EDITABLE_SNIPPET_FIELD]: (state, { payload }) => ({
        ...state,
        editableSnippet: { ...(state.editableSnippet || {}), ...payload },
    }),
    [SET_EDITABLE_SNIPPEN_PICTURE]: (state, { payload: { type, screenSize, data } }) => {
        const editableSnippet = { ...(state.editableSnippet || {}) };
        editableSnippet[type] = { ...(editableSnippet[type] || {}), [screenSize]: data };
        return { ...state, editableSnippet };
    },
    [SET_REMAINING_APPLY_COUNT]: (state, { payload }) => ({ ...state, remainingApplyCount: payload }),
});
