import { FC } from 'react';

import { Avatar } from '@hh.ru/magritte-ui';

import { type ResponseStatusResume } from 'src/models/applicantVacancyResponseStatuses';
import { ensureKnownGender } from 'src/utils/resume/gender';

interface ResumeAvatarProps {
    resume: ResponseStatusResume;
}

const ResumeAvatar: FC<ResumeAvatarProps> = ({ resume }) => {
    const previewImage = resume?.photoUrls?.[0]?.preview;

    return (
        <Avatar
            mode="image"
            aria-label={resume?.fio}
            size={48}
            image={previewImage}
            fallbackMode="placeholder"
            placeholder={ensureKnownGender(resume?.gender?.[0]?.string)}
        />
    );
};

export default ResumeAvatar;
