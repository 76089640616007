import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import FilterContent from 'src/components/Search/Common/Filters/FilterContent';
import FilterWrapper from 'src/components/Search/Common/Filters/FilterWrapper';
import MobileViewControl from 'src/components/Search/Common/Filters/MobileViewControl';
import AcceptTemporary from 'src/components/Search/Vacancy/Filters/AcceptTemporary';
import Internship from 'src/components/Search/Vacancy/Filters/Internship';
import MobileFilter from 'src/components/Search/Vacancy/Filters/MobileFilter';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { NovaFilterKey } from 'src/models/novaFilters';

import styles from './styles.less';

const TrlKeys = {
    title: 'searchvacancy.clusters.employment',
};

const Employment: TranslatedComponent = ({ trls }) => {
    const acceptTemporary = useSelector((state) => state.searchClusters[NovaFilterKey.AcceptTemporary]);
    const internship = useSelector((state) => state.searchClusters[NovaFilterKey.Label]?.groups?.internship);
    const isAdditionalFiltersVisible = Boolean(acceptTemporary) || Boolean(internship);

    return (
        <>
            <MobileViewControl
                mobileView={
                    <MobileFilter
                        name={NovaFilterKey.Employment}
                        title={trls[TrlKeys.title]}
                        withDivider={Boolean(acceptTemporary)}
                    />
                }
            >
                <FilterWrapper
                    filterKey={NovaFilterKey.Employment}
                    title={trls[TrlKeys.title]}
                    withDivider={isAdditionalFiltersVisible}
                >
                    <FilterContent name={NovaFilterKey.Employment} />
                </FilterWrapper>
            </MobileViewControl>
            {isAdditionalFiltersVisible && (
                <div className={styles.list}>
                    <AcceptTemporary />
                    <Internship />
                </div>
            )}
        </>
    );
};

export default translation(Employment);
