const hasTimeZoneOffset = (dateString: string): boolean =>
    /([+-]\d{2}:\d{2}|[+-]\d{4}|[+-]\d{2}|Z)$/.test(dateString.slice(8));

const isValidDate = (value: unknown): boolean => {
    if (typeof value !== 'string' && typeof value !== 'number') {
        return false;
    }
    const date = new Date(value);
    return !isNaN(date.getTime());
};

export const findNotIsoDates = (data: Record<string, unknown>, path = ['initialState']): string[] => {
    const result: string[] = [];

    const recursiveFind = (data: Record<string, unknown>, path: string[]) => {
        for (const key in data) {
            const value = data[key];
            const fullPath = [...path, key];

            if (
                typeof value === 'string' &&
                (value.match(/\d/g) || []).length >= 6 &&
                isValidDate(value) &&
                value.includes('-') &&
                !/[a-sA-Su-yU-Yа-яА-Я]/.test(value) &&
                !hasTimeZoneOffset(value)
            ) {
                result.push(`${fullPath.join('.')}: '${value}'`);
            }

            if (value && typeof value === 'object') {
                recursiveFind(value as Record<string, unknown>, fullPath);
            }
        }
    };

    if (!data || typeof data !== 'object') {
        return result;
    }

    recursiveFind(data, path);
    return result;
};
