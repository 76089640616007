import { FC, SyntheticEvent } from 'react';

import { VSpacing } from '@hh.ru/magritte-ui';

import SupportActionsLayout from 'src/components/Support/SupportActions/SupportActionsLayout';
import useSupportChatik from 'src/components/SupportChatPlate/useSupportChatik';
import { useSelector } from 'src/hooks/useSelector';
import { NavItem } from 'src/models/supernovaNaviMenu';
import { UserType } from 'src/models/userType';

import HelpModalButtonLink from 'src/components/SupernovaMainMenu/HelpModal/HelpModalButtonLinks/HelpModalButtonLink';

const HelpModalButtonLinks: FC<{
    item?: NavItem;
    handleCloseModal: (event?: SyntheticEvent) => void;
    onClose?: () => void;
}> = ({ item, handleCloseModal, onClose }) => {
    const userType = useSelector(({ userType }) => userType);
    const isEmployer = UserType.Employer === userType;

    const closeModalAndSupernovaDropdown = () => {
        onClose?.();
        handleCloseModal();
    };

    const chatik = useSupportChatik('menu_support', closeModalAndSupernovaDropdown);

    const helpModalButtonLinks = item?.subItems;

    if (!helpModalButtonLinks?.length) {
        return null;
    }

    const isSupportChatEnabled = helpModalButtonLinks.some((item) => item.name === 'supportChat');
    const numberOfCalculatedElements = helpModalButtonLinks.length - (isSupportChatEnabled && !isEmployer ? 1 : 0);

    return (
        <>
            {isEmployer && <VSpacing default={24} />}
            <SupportActionsLayout>
                {helpModalButtonLinks.map((supportAction, index) => {
                    const isEmployer = UserType.Employer === userType;
                    const isChatik = supportAction.name === 'supportChat';
                    const isWide = (isChatik && !isEmployer) || (!!(numberOfCalculatedElements % 2) && index === 0);
                    return <HelpModalButtonLink {...supportAction} chatik={chatik} isWide={isWide} key={index} />;
                })}
            </SupportActionsLayout>
        </>
    );
};

export default HelpModalButtonLinks;
