import { FC, PropsWithChildren, useMemo } from 'react';

import { ElementShownAnchor } from '@hh.ru/analytics-js';
import brandingTriggerNotificationCloseButtonClick from '@hh.ru/analytics-js-events/build/xhh/employer/price/branding/notifications/branding_trigger_notification_close_button_click';
import brandingTriggerNotificationElementShown from '@hh.ru/analytics-js-events/build/xhh/employer/price/branding/notifications/branding_trigger_notification_element_shown';
import brandingTriggerNotificationLinkButtonClick from '@hh.ru/analytics-js-events/build/xhh/employer/price/branding/notifications/branding_trigger_notification_link_button_click';

type UseTriggerNotificationAnalytics = (params: {
    notificationId?: number;
    notificationText: string;
    templateKey: string;
    place: 'supernova' | 'screen';
}) => {
    ElementShownWrapper: FC<PropsWithChildren>;
    sendOnRemoveEvent: () => void;
    sendLinkClickEvent: () => void;
};
const useTriggerNotificationsAnalytics: UseTriggerNotificationAnalytics = ({
    notificationId,
    notificationText,
    templateKey: notificationCode,
    place: notificationPlace,
}) => {
    return useMemo(() => {
        const analyticsParams = {
            notificationId,
            notificationText,
            notificationCode,
            notificationPlace,
        };
        return {
            ElementShownWrapper: ({ children }) => (
                <ElementShownAnchor Element="div" fn={brandingTriggerNotificationElementShown} {...analyticsParams}>
                    {children}
                </ElementShownAnchor>
            ),
            sendOnRemoveEvent: () => brandingTriggerNotificationCloseButtonClick(analyticsParams),
            sendLinkClickEvent: () => brandingTriggerNotificationLinkButtonClick(analyticsParams),
        };
    }, [notificationId, notificationText, notificationCode, notificationPlace]);
};

export default useTriggerNotificationsAnalytics;
