import { useRef, useEffect, useLayoutEffect } from 'react';
import { useDispatch } from 'react-redux';

import { Card, HSpacingContainer, Title, VSpacing as MagritteVSpacing, VSpacingContainer } from '@hh.ru/magritte-ui';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { format } from 'bloko/common/trl';

import LoopCounterParser, { LoopCounterStage } from 'Modules/LoopCounterParser';
import vacancyResponseCounter from 'Modules/VacancyResponseCounter/VacancyResponseCounter';
import verticalFadeIn from 'Modules/verticalFadeIn';
import Strings from 'Utils/Strings';
import LoopCounter from 'src/components/Applicant/LoopCounter';
import { useCardContainerClassNames } from 'src/components/VacancySearchItem/hooks/useCardContainerClassNames';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { responseStreakUpdate } from 'src/models/applicantResponseStreaks';

import styles from 'src/components/Applicant/Response/SearchResponsesStreak/search-responses-streak.less';

const TrlKeys = {
    pluralizeCount: 'vacancy.counter.responses.pluralizeCount',
    success: {
        title: 'vacancy.counter.responses.title.success',
        description: 'vacancy.counter.responses.description.success',
    },
    progress: {
        title: 'vacancy.counter.responses.title.progress',
        description: 'vacancy.counter.responses.description.progress',
    },
};

interface SearchResponsesStreakProps {
    vacancyId: number;
    defaultView?: string;
    visible?: boolean;
    isMagritteExp?: boolean;
}

const SearchResponsesStreak: TranslatedComponent<SearchResponsesStreakProps> = ({
    vacancyId,
    trls,
    visible,
    defaultView,
    isMagritteExp,
}) => {
    const responseStreak = useSelector((state) => state.applicantResponseStreaks[vacancyId]);
    const responseStatus = useSelector((state) => state.applicantVacancyResponseStatuses[vacancyId]);
    const vacancy = responseStatus?.shortVacancy;
    const nodeRef = useRef<HTMLDivElement>(null);
    const dispatch = useDispatch();
    const location = useSelector((state) => state.router.location);

    const streakRef = useRef(null);

    const { wideCardContainerClassName, narrowCardContainerClassName } = useCardContainerClassNames();

    const magritteOnSearch = defaultView === 'search' && isMagritteExp;

    useEffect(() => {
        if (location.query?.responsesCount) {
            dispatch(
                responseStreakUpdate({
                    vacancyId,
                    data: {
                        responsesCount: parseInt(location.query.responsesCount, 10),
                        responsesRequired: parseInt(location.query.responsesRequired, 10),
                        vacancyId: `${vacancyId}`,
                    },
                })
            );
            window.history.replaceState(window.history.state, document.title, location.pathname);
        }
    }, [dispatch, location, vacancyId]);

    let hasResponseLetter;
    if (responseStatus?.negotiations?.topicList) {
        const lastTopic = responseStatus.negotiations.topicList.sort(
            (a, b) => b.creationTimeMillis - a.creationTimeMillis
        )[0];
        hasResponseLetter = lastTopic?.resources?.map(({ type }) => type)?.includes('RESPONSE_LETTER');
    }

    const isVisible = visible || vacancy?.['@responseLetterRequired'] || hasResponseLetter;
    const show = parseInt(responseStreak?.vacancyId, 10) === vacancyId && isVisible;

    useLayoutEffect(() => {
        if (magritteOnSearch && show && streakRef.current) {
            void verticalFadeIn(streakRef.current, {
                hiddenClass: styles.responsesStreakContainerHidden,
                propertyList: {
                    opacity: 1,
                },
            });
        }
    }, [magritteOnSearch, show]);

    useEffect(() => {
        if (!magritteOnSearch && show && nodeRef.current) {
            nodeRef.current.innerHTML = '';
            nodeRef.current.classList.remove('g-hidden');
            nodeRef.current.dataset.qa = 'responses-streak';
            const params = {
                ...responseStreak,
                modifyCssClass: defaultView === 'search' ? 'vacancy-response-trigger-call' : '',
                trls: {
                    pluralizeCount: trls[TrlKeys.pluralizeCount],
                    success: {
                        title: trls[TrlKeys.success.title],
                        description: trls[TrlKeys.success.description],
                    },
                    progress: {
                        title: trls[TrlKeys.progress.title],
                        description: trls[TrlKeys.progress.description],
                    },
                },
            };

            vacancyResponseCounter(nodeRef.current, params);
        } else if (nodeRef.current) {
            nodeRef.current.innerHTML = '';
        }
    }, [defaultView, magritteOnSearch, responseStreak, show, trls, vacancyId]);

    if (!responseStreak) {
        return null;
    }

    const { responsesRequired, responsesCount } = responseStreak;
    const { roundCounter, completeStepCounter, stage } = LoopCounterParser(responsesRequired, responsesCount);

    const words = trls[TrlKeys.pluralizeCount].split(',');
    const titleNumber =
        stage === LoopCounterStage.Success && roundCounter > 1
            ? responsesCount
            : responsesRequired - completeStepCounter;

    if (magritteOnSearch) {
        return (
            <>
                <MagritteVSpacing default={12} />
                <div className={styles.responsesStreakContainerHidden} ref={streakRef}>
                    <Card style="extra-1" borderRadius={12} padding={24} stretched>
                        <div className={wideCardContainerClassName}>
                            <HSpacingContainer default={24}>
                                <LoopCounter
                                    completeStepCounter={completeStepCounter}
                                    requiredStepCounter={responsesRequired}
                                    roundCounter={roundCounter}
                                    stage={stage}
                                    isMagritteExp
                                />
                                <Title
                                    data-qa="respond-more_title"
                                    size="small"
                                    description={trls[TrlKeys[stage].description]}
                                    Element="h4"
                                >
                                    {format(trls[TrlKeys[stage].title], {
                                        '{0}': Strings.numConversion(titleNumber, words),
                                    })}
                                </Title>
                            </HSpacingContainer>
                        </div>
                        <div className={narrowCardContainerClassName}>
                            <VSpacingContainer default={16}>
                                <LoopCounter
                                    completeStepCounter={completeStepCounter}
                                    requiredStepCounter={responsesRequired}
                                    roundCounter={roundCounter}
                                    stage={stage}
                                    isMagritteExp
                                    isSmall
                                />
                                <Title size="small" description={trls[TrlKeys[stage].description]} Element="h4">
                                    {format(trls[TrlKeys[stage].title], {
                                        '{0}': Strings.numConversion(titleNumber, words),
                                    })}
                                </Title>
                            </VSpacingContainer>
                        </div>
                    </Card>
                </div>
            </>
        );
    }

    return (
        <>
            {show && <VSpacing base={5} />}
            <div ref={nodeRef} className="g-hidden" />
        </>
    );
};

export default translation(SearchResponsesStreak);
