import { FC } from 'react';
import { DomElement } from 'domhandler';
import parse, { domToReact, HTMLReactParserOptions } from 'html-react-parser';

export interface TagMapperProps {
    options: HTMLReactParserOptions;
    originalElement: DomElement;
}

export type TagMapperComponent<P extends TagMapperProps> = FC<P>;

type TagMapper = (options: HTMLReactParserOptions, originalElement: DomElement) => React.ReactElement<unknown> | null;

type HtmlParser = (html: string) => ReturnType<typeof domToReact>;

type HookType = (mappers: { [key: string]: TagMapper }) => HtmlParser;

export const createTagMapper = <P extends TagMapperProps>(
    component: TagMapperComponent<P>,
    props: Omit<P, 'options' | 'originalElement'>
): TagMapper => {
    return (options: HTMLReactParserOptions, originalElement: DomElement) => {
        // @ts-expect-error Ругается на то, что P может быть отличен от базового TagMapperProps
        return component({ options, originalElement, ...props });
    };
};

const useParseHtml: HookType = (mappers) => {
    const options: HTMLReactParserOptions = {
        replace: (currentElement: DomElement) => {
            if (currentElement.name && mappers[currentElement.name]) {
                const mapper = mappers[currentElement.name];
                return mapper(options, currentElement);
            }
            return null;
        },
    };
    return (html: string) => parse(html, options);
};

export default useParseHtml;
