import { Link as MagritteLink } from '@hh.ru/magritte-ui';
import { Link } from '@hh.ru/redux-spa-middleware';
import BlokoLink from 'bloko/blocks/link';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { formatToReactComponent } from 'bloko/common/trl';

import translation from 'src/components/translation';
import { useMagritte } from 'src/hooks/useMagritte';
import { useSelector } from 'src/hooks/useSelector';

const TrlKeys = {
    text: 'advertisementAgreement.checkbox.text',
    textBirthday: 'advertisementAgreement.checkbox.birthday.text',
    linkOne: 'advertisementAgreement.checkbox.text.part.1',
    linkTwo: 'advertisementAgreement.checkbox.text.part.2',

    shortVersion: {
        text: 'advertisementAgreement.checkbox.text.short',
        linkOne: 'advertisementAgreement.checkbox.text.part.1.short',
        linkTwo: 'advertisementAgreement.checkbox.text.part.2.short',
    },
};

interface AdvertisementAgreementTextProps {
    isBirthday?: boolean;
    articleId?: string;
}

const AdvertisementAgreementText: TranslatedComponent<AdvertisementAgreementTextProps> = ({
    trls,
    isBirthday,
    articleId,
}) => {
    if (!articleId) {
        articleId = isBirthday ? '30902' : '30901';
    }
    const isMagritte = useMagritte();
    const isAnonymousMagritteExp = useSelector(({ isAnonymousMagritteExp }) => isAnonymousMagritteExp);
    const typography = isAnonymousMagritteExp ? 'paragraph-3-regular' : 'label-3-regular';

    if (isMagritte) {
        return formatToReactComponent(trls[isBirthday ? TrlKeys.textBirthday : TrlKeys.shortVersion.text], {
            '{0}': (
                <MagritteLink
                    inline
                    typography={typography}
                    Element={Link}
                    to={`/article/${articleId}`}
                    target="_blank"
                >
                    {trls[TrlKeys.shortVersion.linkOne]}
                </MagritteLink>
            ),
            '{1}': (
                <MagritteLink
                    inline
                    typography={typography}
                    Element={Link}
                    to={`/article/${articleId}#clause1`}
                    target="_blank"
                >
                    {trls[TrlKeys.shortVersion.linkTwo]}
                </MagritteLink>
            ),
        });
    }

    return formatToReactComponent(trls[isBirthday ? TrlKeys.textBirthday : TrlKeys.text], {
        '{0}': (
            <BlokoLink disableVisited Element={Link} to={`/article/${articleId}`} target="_blank">
                {trls[TrlKeys.linkOne]}
            </BlokoLink>
        ),
        '{1}': (
            <BlokoLink disableVisited Element={Link} to={`/article/${articleId}#clause1`} target="_blank">
                {trls[TrlKeys.linkTwo]}
            </BlokoLink>
        ),
    });
};

export default translation(AdvertisementAgreementText);
