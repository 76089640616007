import { attributesToProps, domToReact } from 'html-react-parser';

import BlokoLink from 'bloko/blocks/link';

import { TagMapperComponent, TagMapperProps } from 'src/hooks/useParseHtml';

const Link: TagMapperComponent<TagMapperProps> = ({ options, originalElement: { attribs, children } }) => (
    <>
        {!!attribs && !!children && (
            <BlokoLink {...attributesToProps(attribs)} disableVisited>
                {domToReact(children, options)}
            </BlokoLink>
        )}
    </>
);

export default Link;
