import { ReactNode, FC, useRef } from 'react';

import { GridColumn, Card, Text as TextMagritte, TooltipHover } from '@hh.ru/magritte-ui';
import { ChevronRightOutlinedSize16 } from '@hh.ru/magritte-ui/icon';
import { Link } from '@hh.ru/redux-spa-middleware';
import Column from 'bloko/blocks/column';
import { H3Section } from 'bloko/blocks/header';
import BlokoLink from 'bloko/blocks/link';
import Text, { TextImportance } from 'bloko/blocks/text';

import Compensation from 'src/components/Compensation';
import SearchDebug from 'src/components/Debug/SearchDebug';
import RainbowTileDrop from 'src/components/RainbowCatalog/Drop';
import { getVacancyCompanyLink } from 'src/components/RainbowCatalog/utils';
import CompanyLink from 'src/components/VacancyCompanyLink';
import useIsTextClamped from 'src/hooks/useIsTextClamped';
import { useSelector } from 'src/hooks/useSelector';
import { VacancySearchItem } from 'src/models/vacancySearch/vacancySearchItem.types';

import styles from './styles.less';

interface RainbowTileVacancyProps {
    vacancy: VacancySearchItem;
    tileIndex: number;
    activeDropIndex: number;
    activeTileContent: ReactNode;
    previousActiveTileIndex: number;
}

const RainbowTileVacancy: FC<RainbowTileVacancyProps> = ({
    vacancy,
    tileIndex,
    activeDropIndex,
    activeTileContent,
    previousActiveTileIndex,
}) => {
    const isAnonymousMagritteExp = useSelector((state) => state.isAnonymousMagritteExp);
    const nameRef = useRef(null);
    const vacancyRef = useRef<HTMLDivElement>(null);

    const isNameClamped = useIsTextClamped(nameRef);

    if (isAnonymousMagritteExp) {
        return (
            <>
                <div className={styles.container} data-rainbow-catalog-tile="tile">
                    <GridColumn xs={4} s={8} m={4} l={3}>
                        <div className={styles.cardContainer}>
                            <Card showBorder verticalStretched stretched padding={24} borderRadius={24} actionCard>
                                <div className={styles.cardContent}>
                                    <Link to={vacancy.links.desktop}>
                                        <div ref={vacancyRef} className={styles.title}>
                                            <TextMagritte typography="subtitle-1-semibold" maxLines={1}>
                                                <div ref={nameRef}>{vacancy.name}</div>
                                            </TextMagritte>
                                            <div className={styles.chevronWrap}>
                                                {<ChevronRightOutlinedSize16 initial="secondary" />}
                                            </div>
                                        </div>
                                        {isNameClamped && (
                                            <TooltipHover placement="top-center" activatorRef={vacancyRef}>
                                                {vacancy.name}
                                            </TooltipHover>
                                        )}
                                        <TextMagritte typography="paragraph-2-regular">
                                            {vacancy.compensation && (
                                                <Compensation
                                                    {...vacancy.compensation}
                                                    analyticsContext="RainbowTileVacancy 2"
                                                />
                                            )}
                                        </TextMagritte>
                                    </Link>

                                    <Link to={getVacancyCompanyLink(vacancy)}>
                                        <TextMagritte typography="paragraph-3-regular" style="secondary">
                                            {`${vacancy.company.name}, ${vacancy.area.name}`}
                                        </TextMagritte>
                                    </Link>
                                </div>
                            </Card>
                        </div>
                    </GridColumn>
                </div>
                <RainbowTileDrop
                    tileIndex={tileIndex}
                    activeDropIndex={activeDropIndex}
                    activeTileContent={activeTileContent}
                    previousActiveTileIndex={previousActiveTileIndex}
                />
            </>
        );
    }

    return (
        <>
            <div className="dashboard-tiles-item dashboard-tiles-item_suitable-vacancies" data-rainbow-catalog-tile="">
                <Column xs="0" s="4" m="3" l="3">
                    <div
                        className="dashboard-tiles-item-suitable-vacancy-title"
                        data-qa="neva-vacancy-item-desktop-title"
                    >
                        <H3Section lite>
                            <BlokoLink Element={Link} to={vacancy.links.desktop}>
                                <span className="dashboard-tiles-item-suitable-vacancy-title-link">{vacancy.name}</span>
                            </BlokoLink>
                        </H3Section>
                    </div>
                    <div className="dashboard-tiles-item__salary">
                        <Compensation {...vacancy.compensation} analyticsContext="RainbowTileVacancy 1" />
                    </div>
                    <div className="dashboard-tiles-item-suitable-vacancy-company">
                        <Text importance={TextImportance.Tertiary}>
                            <CompanyLink vacancy={vacancy}>
                                {`${vacancy.company.name}, ${vacancy.area.name}`}
                            </CompanyLink>
                        </Text>
                    </div>
                    {vacancy.searchDebug && <SearchDebug {...vacancy.searchDebug} />}
                </Column>
            </div>
            <RainbowTileDrop
                tileIndex={tileIndex}
                activeDropIndex={activeDropIndex}
                activeTileContent={activeTileContent}
                previousActiveTileIndex={previousActiveTileIndex}
            />
        </>
    );
};

export default RainbowTileVacancy;
