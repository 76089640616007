import { FC, PropsWithChildren } from 'react';

import Column from 'bloko/blocks/column';
import Gap from 'bloko/blocks/gap';

import AdsExternalBanner from 'src/components/Banner/AdsExternalBanner';
import { useSelector } from 'src/hooks/useSelector';

const VacanciesOfTheDayBanners: FC<PropsWithChildren> = () => {
    const banners = useSelector((state) => state.banners['index-vod']);

    if (!banners?.length) {
        return null;
    }

    return (
        <Gap top>
            <Column xs="4" s="4" m="4" l="4" container>
                {banners?.map((banner) => (
                    <div
                        className="HHC-Banner-Wrapper banner-place-wrapper"
                        data-empty-class="banner-place-wrapper_hide"
                        key={banner.id}
                    >
                        <Column xs="4" s="4" m="4" l="4">
                            <div className="banner-place-fat-wrapper">
                                <div className="index-banner-wrapper">
                                    <div
                                        className="index-banner index-banner_big"
                                        data-page-analytics-event={`advertising_banner.right${banner.id}`}
                                    >
                                        <AdsExternalBanner {...banner} />
                                    </div>
                                </div>
                            </div>
                        </Column>
                    </div>
                ))}
            </Column>
        </Gap>
    );
};

export default VacanciesOfTheDayBanners;
