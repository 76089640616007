import Analytics from '@hh.ru/analytics-js';
import { ElementSpyInstance } from '@hh.ru/browser-api-utils';

export const faqItemClick = (item: string): void =>
    Analytics.sendHHEventButtonClick('faq_item', {
        item,
    });

export const faqItemsShown = (element: HTMLElement, items: string[]): ElementSpyInstance => {
    return Analytics.sendHHEventElementShown(element, {
        name: 'faq_items',
        item: items,
    });
};

export default {
    faqItemClick,
    faqItemsShown,
};
