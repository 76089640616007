import { ComponentType, useCallback, useEffect, useRef } from 'react';

import { Loader, Text, VSpacing } from '@hh.ru/magritte-ui';

import { useHHAuth } from 'src/components/ImportResumeModal/hooks';
import { Step } from 'src/components/ImportResumeModal/types';
import translation from 'src/components/translation';

import styles from './styles.less';

const TrlKeys = {
    loading: 'zp.importResume.auth.loading',
};

interface Props {
    onSetStep: (step: Step) => void;
}

const AuthStep: ComponentType<Props> = translation(({ trls, onSetStep }) => {
    const _authWindow = useRef<Window | null>(null);
    const timer = useRef(0);
    const authUri = useHHAuth();

    const check = useCallback(() => {
        try {
            if (
                !_authWindow.current?.top ||
                _authWindow.current.closed ||
                _authWindow.current?.location?.pathname === '/close'
            ) {
                _authWindow.current?.close();
                clearInterval(timer.current);
                onSetStep(Step.Import);
            }
        } catch (e) {
            console.error(e);
        }
    }, [onSetStep]);

    useEffect(() => {
        if (!authUri) {
            return;
        }
        if (timer.current) {
            clearInterval(timer.current);
        }
        timer.current = setInterval(check, 1000) as unknown as number;
        _authWindow.current = window.open(authUri, 'Авторизация', 'location=0,width=750,height=550,popup=true');
        setTimeout(() => _authWindow.current?.focus(), 100);
    }, [authUri, check]);

    useEffect(() => () => clearInterval(timer.current), []);

    return (
        <div className={styles.authContainer}>
            <Loader size={24} />
            <VSpacing default={16} />
            <Text>{trls[TrlKeys.loading]}</Text>
        </div>
    );
});

export default AuthStep;
