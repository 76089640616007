import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import useParseHtml, { createTagMapper } from 'src/hooks/useParseHtml';
import { useSelector } from 'src/hooks/useSelector';
import useToggleState from 'src/hooks/useToggleState';

import { HHA_TAGS } from 'src/components/IndexPageAnonymous/WorkInCity/consts';
import {
    AreaNamePre,
    Body,
    Header,
    Link,
    Paragraph,
    Toggle,
} from 'src/components/IndexPageAnonymous/WorkInCity/mappers';

const TrlKeys = {
    showMore: 'index.workInCity.showMore',
    open: 'index.collapsible.button.open',
    close: 'index.collapsible.button.close',
};

const WorkInCity: TranslatedComponent = ({ trls }) => {
    const articleXmlStr = useSelector((state) => state.workInCity.articleXmlStr);
    const areaName = useSelector((state) => state.workInCity.areaName);
    const [expanded, toggleExpanded] = useToggleState(false);

    const handleContainerClick = (): void => {
        toggleExpanded();
    };

    const parseHtml = useParseHtml({
        body: createTagMapper(Body, { expanded }),
        p: createTagMapper(Paragraph, {}),
        [HHA_TAGS.areaNamePre]: createTagMapper(AreaNamePre, { areaName }),
        [HHA_TAGS.header]: createTagMapper(Header, {}),
        [HHA_TAGS.toggle]: createTagMapper(Toggle, {
            handleContainerClick,
            expanded,
            showMore: trls[TrlKeys.showMore],
            open: trls[TrlKeys.open],
            close: trls[TrlKeys.close],
        }),
        [HHA_TAGS.link]: createTagMapper(Link, {}),
    });

    if (!articleXmlStr) {
        return null;
    }

    return <>{parseHtml(articleXmlStr)}</>;
};

export default translation(WorkInCity);
