import { MutableRefObject, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import Analytics from '@hh.ru/analytics-js';
import {
    Action,
    BottomSheet,
    BottomSheetFooter,
    Button as MagritteButton,
    Drop,
    NavigationBar,
    VSpacing,
} from '@hh.ru/magritte-ui';
import { CrossOutlinedSize24, ChevronLeftOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { ChatWritePossibility } from '@hh.ru/types-hh-microcore';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import axios from 'HHC/Axios';
import fetchResponseType from 'src/api/contacts/fetchResponseType';
import { useNotification } from 'src/components/Notifications/Provider';
import RegistrationAction from 'src/components/VacancyActions/RegistrationAction';
import VacancySearchItemContacts from 'src/components/VacancySearchItem/Contacts';
import { useApplicantSearchQuestionExp } from 'src/components/VacancySearchItem/hooks/useApplicantSearchQuestionExp';
import ContactChat from 'src/components/VacancyView/ContactChat';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import useRegisterInteraction from 'src/hooks/vacancies/useRegisterInteraction';
import { UserType } from 'src/models/userType';
import { vacancyChatInfoUpdate } from 'src/models/vacancyChatInfo';
import { ContactInfo, VacancyAddress } from 'src/models/vacancyView.types';

import vacancySearchItemContactsError from 'src/components/VacancySearchItem/ShowContactsButton/VacancySearchItemContactsError';

interface ShowContactsButtonProps {
    showContact: boolean;
    showQuestionInput?: boolean;
    vacancyId: number;
    companyName?: string;
    address?: VacancyAddress;
    employerId: number;
    chatWritePossibility: ChatWritePossibility;
    onClick?: () => void;
    handleContactsClickRef?: MutableRefObject<{ handleClick?: () => void }>;
    stretched?: boolean;
    magritteButtonSize?: 'medium' | 'small';
    employerManagerName?: string;
}

const TrlKeys = {
    contacts: 'vacancy.search.contacts',
    showContacts: 'vacancy.search.contacts.show',
    seeContacts: 'vacancy.search.contacts.see',
    contact: 'vacancy.of.the.day.show.contact',
    contactB: 'vacancy.search.contact.b',
};

const ShowContactsButton: TranslatedComponent<ShowContactsButtonProps> = ({
    showContact,
    showQuestionInput,
    vacancyId,
    companyName,
    address,
    employerId,
    chatWritePossibility,
    onClick,
    handleContactsClickRef,
    stretched,
    employerManagerName,
    magritteButtonSize = 'medium',
    trls,
}) => {
    const dispatch = useDispatch();
    const [isContactsVisible, setIsContactsVisible] = useState(false);
    const [asyncContacts, setAsyncContacts] = useState<ContactInfo>();
    const [loadContacts, setLoadContacts] = useState(false);
    const searchLoading = useSelector((state) => state.searchLoading);
    const userType = useSelector((state) => state.userType);
    const userLabel = useSelector((state) => state.userLabelsForVacancies[vacancyId]);
    const chatButtonInfo = useSelector((state) => state.vacancyChatInfo[vacancyId]);
    const { addNotification } = useNotification();
    const contactsButtonRef = useRef(null);
    const isAnonymous = userType === UserType.Anonymous;
    const isChatEnabled = chatWritePossibility !== ChatWritePossibility.Disabled;

    const { isQuestionExpB, isQuestionExpC, isQuestionAvailable } = useApplicantSearchQuestionExp(showQuestionInput);
    const isUserInQuestionExp = isQuestionExpB || isQuestionExpC;

    const registerFetchContactsAttempt = async () => {
        await axios.get(`/shards/vacancy/register_show_contacts_attempt`, {
            params: {
                employerId,
                vacancyId,
            },
        });
    };

    const registerShowQuestionResponse = async () => {
        await axios.get('/shards/vacancy/register_show_question_response', {
            params: {
                vacancyId,
            },
        });
    };

    const fetchContacts = async () => {
        if (asyncContacts || isContactsVisible) {
            setIsContactsVisible(!isContactsVisible);
            return;
        }

        setLoadContacts(true);

        if (showContact) {
            let contactsJson: null | {
                data: ContactInfo;
            };
            try {
                contactsJson = await axios.get(`/vacancy/${vacancyId}/contacts`, {
                    params: {
                        employerId,
                        withQuestion: isQuestionAvailable,
                    },
                });
            } catch (_) {
                addNotification(vacancySearchItemContactsError);
                setLoadContacts(false);
                return;
            }

            setAsyncContacts(contactsJson?.data);
        } else {
            setAsyncContacts({ fio: employerManagerName! });
        }

        const chatInfo = await fetchResponseType(vacancyId, isQuestionAvailable);
        dispatch(vacancyChatInfoUpdate({ data: chatInfo, vacancyId }));

        if (isQuestionAvailable && !showContact) {
            void registerShowQuestionResponse();
        }

        setIsContactsVisible(true);
        setLoadContacts(false);
    };

    const registerInteraction = useRegisterInteraction(vacancyId);

    const handleClick = () => {
        onClick?.();
        Analytics.sendEvent(userType, 'vacancy-view-contacts', 'open');
        void registerFetchContactsAttempt();
        if (isAnonymous) {
            setIsContactsVisible(true);
            void registerInteraction();
        } else {
            void fetchContacts();
        }
    };

    const hideContacts = useCallback(() => {
        setIsContactsVisible(false);
    }, []);

    if (handleContactsClickRef?.current) {
        handleContactsClickRef.current.handleClick = handleClick;
    }

    const initialRender = useRef(true);
    // for ajax load vacancies
    useEffect(() => {
        if (searchLoading) {
            initialRender.current = true;
        }
    }, [searchLoading]);

    useEffect(() => {
        if (initialRender.current) {
            initialRender.current = false;
        } else {
            void fetchResponseType(vacancyId, isQuestionAvailable).then((chatInfo) =>
                dispatch(vacancyChatInfoUpdate({ data: chatInfo, vacancyId }))
            );
        }
    }, [vacancyId, userLabel, dispatch, isQuestionAvailable]);

    const renderDownContent = () => {
        if (isAnonymous) {
            return <RegistrationAction vacancyId={vacancyId} isMagritte />;
        }

        return (
            <VacancySearchItemContacts
                employerId={employerId}
                chatWritePossibility={chatWritePossibility}
                vacancyId={vacancyId}
                callTrackingEnabled={asyncContacts?.callTrackingEnabled}
                contactInfo={asyncContacts}
                address={address}
                onClose={hideContacts}
                chatButtonInfo={chatButtonInfo?.data}
                isQuestionExp={isQuestionExpB || isQuestionExpC}
            />
        );
    };

    if (!showContact && !isQuestionAvailable) {
        return null;
    }

    const contactName = isUserInQuestionExp
        ? asyncContacts?.fio || employerManagerName || trls[TrlKeys.contact]
        : trls[TrlKeys.contact];

    return (
        <div>
            <MagritteButton
                ref={contactsButtonRef}
                mode="secondary"
                size={magritteButtonSize}
                style="accent"
                data-qa="vacancy-serp__vacancy_contacts"
                loading={loadContacts}
                onClick={handleClick}
                stretched={stretched}
            >
                {isQuestionExpB ? trls[TrlKeys.contactB] : trls[TrlKeys.contact]}
            </MagritteButton>
            <Drop
                activatorRef={contactsButtonRef}
                placement={['bottom-right', 'right-bottom']}
                visible={isContactsVisible}
                onClose={hideContacts}
                maxWidth={370}
            >
                {renderDownContent()}
            </Drop>
            <BottomSheet
                visible={isContactsVisible}
                showDivider={isUserInQuestionExp ? 'always' : undefined}
                withContentPaddings={showContact}
                interceptClickHandlers={false}
                header={
                    <NavigationBar
                        title={contactName}
                        subtitle={isUserInQuestionExp ? companyName : undefined}
                        right={<Action icon={CrossOutlinedSize24} onClick={hideContacts} />}
                        left={
                            isUserInQuestionExp ? (
                                <Action icon={ChevronLeftOutlinedSize24} onClick={hideContacts} />
                            ) : undefined
                        }
                    />
                }
                footer={
                    isQuestionAvailable && chatButtonInfo?.data && isChatEnabled && employerId ? (
                        <BottomSheetFooter>
                            <ContactChat
                                chatButtonInfo={chatButtonInfo.data}
                                vacancyId={vacancyId}
                                employerId={employerId}
                                isMagritteExp
                                isQuestionExp
                                onClick={hideContacts}
                            />
                        </BottomSheetFooter>
                    ) : null
                }
                onClose={hideContacts}
            >
                {renderDownContent()}
                {showContact && <VSpacing default={16} />}
            </BottomSheet>
        </div>
    );
};

export default translation(ShowContactsButton);
