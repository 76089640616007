import { FC, HTMLAttributes } from 'react';
import classnames from 'classnames';

import { useSelector } from 'src/hooks/useSelector';

interface LogoProps extends HTMLAttributes<HTMLSpanElement> {
    domain?: string;
    inverse?: boolean;
}

const Logo: FC<LogoProps> = ({ domain, inverse, ...otherProps }) => {
    const logoDomain = useSelector(({ logos }) => logos.logoDomain);

    return (
        <span
            className={classnames('supernova-logo', {
                'supernova-logo_inversed': inverse,
                [`supernova-logo_${logoDomain}`]: domain || logoDomain,
            })}
            {...otherProps}
        />
    );
};

export default Logo;
