import { Card, Title, Text, VSpacing, useBreakpoint } from '@hh.ru/magritte-ui';
import { Link } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import Compensation from 'src/components/Compensation';
import IndexCollapsibleBlock from 'src/components/IndexCollapsibleBlock';
import { getVacancyCompanyLink } from 'src/components/RainbowCatalog/utils';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { VacancySearchItem } from 'src/models/vacancySearch/vacancySearchItem.types';

import styles from './styles.less';

const TrlKeys = {
    title: 'index.anonymous.suitable.title',
};

const SuitableVacancies: TranslatedComponent = ({ trls }) => {
    const suitableVacancies = useSelector((state) => state.suitableVacancies);
    const { isMobile } = useBreakpoint();

    if (!suitableVacancies?.vacancies.length) {
        return null;
    }

    const renderCard = (vacancy: VacancySearchItem) => (
        <Card showBorder stretched padding={12} borderRadius={12} actionCard key={vacancy.name}>
            <div className={styles.cardContent}>
                <Link to={vacancy.links.desktop}>
                    <div className={styles.title}>
                        <Text typography="subtitle-1-semibold">{vacancy.name}</Text>
                    </div>
                    <Text typography="paragraph-2-regular">
                        {vacancy.compensation && (
                            <Compensation {...vacancy.compensation} analyticsContext="SuitableVacancies" />
                        )}
                    </Text>
                </Link>
                <VSpacing default={24} />
                <Link to={getVacancyCompanyLink(vacancy)}>
                    <Text typography="paragraph-3-regular" style="secondary">
                        {`${vacancy.company.name}, ${vacancy.area.name}`}
                    </Text>
                </Link>
            </div>
        </Card>
    );

    return (
        <div>
            <Title Element="h3" size={isMobile ? 'small' : 'medium'} style="primary">
                <div className={styles.title}>{trls[TrlKeys.title]}</div>
            </Title>
            <VSpacing default={16} gteS={20} gteM={24} />
            <IndexCollapsibleBlock
                renderPreview={() => (
                    <div className={styles.container}>
                        {suitableVacancies.vacancies.slice(0, 3).map((vacancy) => renderCard(vacancy))}
                    </div>
                )}
                renderRest={() => null}
                renderBottomSheetContent={() => (
                    <div className={styles.container}>
                        {suitableVacancies.vacancies.map((vacancy) => renderCard(vacancy))}
                    </div>
                )}
                bottomSheetTitle={trls[TrlKeys.title]}
                needToExpand={suitableVacancies.vacancies.length > 3}
            />
        </div>
    );
};

export default translation(SuitableVacancies);
