import { domToReact } from 'html-react-parser';

import { TagMapperComponent, TagMapperProps } from 'src/hooks/useParseHtml';

interface AreaNamePreProps extends TagMapperProps {
    areaName: string;
}

const AreaNamePre: TagMapperComponent<AreaNamePreProps> = ({ options, originalElement: { children }, areaName }) => (
    <>
        {areaName}
        {!!children && domToReact(children, options)}
    </>
);

export default AreaNamePre;
