import { FC, PropsWithChildren } from 'react';
import classNames from 'classnames';

import styles from './styles.less';

interface SupportActionCellProps {
    isWide: boolean;
}

const SupportActionCell: FC<SupportActionCellProps & PropsWithChildren> = ({ isWide, children }) => {
    return (
        <div
            className={classNames({
                [styles.wideActionCell]: isWide,
            })}
        >
            {children}
        </div>
    );
};

export default SupportActionCell;
