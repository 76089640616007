import { forwardRef, ForwardRefRenderFunction } from 'react';

import { useSelector } from 'src/hooks/useSelector';

const Form: ForwardRefRenderFunction<HTMLFormElement, React.ComponentPropsWithoutRef<'form'>> = (
    { children, method = 'POST', ...props },
    ref
) => {
    const xsrfToken = useSelector(({ xsrfToken }) => xsrfToken);
    return (
        <form {...props} ref={ref} method={method}>
            {method.toUpperCase() === 'POST' && <input type="hidden" name="_xsrf" value={xsrfToken} />}
            {children}
        </form>
    );
};
export default forwardRef(Form);
