import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import MobileViewControl from 'src/components/Search/Common/Filters/MobileViewControl';
import translation from 'src/components/translation';

import Order from 'src/components/Search/Vacancy/Filters/MobileSorts/Order';
import Period from 'src/components/Search/Vacancy/Filters/MobileSorts/Period';

const MobileSorts: TranslatedComponent = () => {
    return (
        <MobileViewControl
            mobileView={
                <>
                    <Order />
                    <Period />
                </>
            }
        />
    );
};

export default translation(MobileSorts);
