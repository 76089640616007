import { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import classnames from 'classnames';

import appInstallBannerButtonClick from '@hh.ru/analytics-js-events/build/xhh/applicant/app_install/app_install_banner_button_click';
import appInstallBannerElementShown from '@hh.ru/analytics-js-events/build/xhh/applicant/app_install/app_install_banner_element_shown';
import appBannerClickExternal, {
    Category as UserType,
} from '@hh.ru/analytics-js-events/build/xhh/common/app_banner/app_banner_click';
import { TranslatedComponent, useSelector } from '@hh.ru/front-static-app';
import { Action, Card, Cell, CellText, useBreakpoint, VSpacing, Link } from '@hh.ru/magritte-ui';
import { CrossOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import Storage from 'bloko/common/storage/LocalStorageWrapper';

import {
    DAY,
    DAYS_TO_HIDE_AFTER_CLOSE,
    STORAGE_VARIABLE_NAME,
    XS_ONLY_LINK_PARAM,
} from 'src/components/AppSmartBanner';
import translation from 'src/components/translation';

import styles from './styles.less';

const TrlKeys = {
    title: 'mobile.app.smartbanner.text.anchor.universal',
    storeLinks: {
        ios: 'mobile.app.smartbanner.text.anchor.ios',
        android: 'mobile.app.smartbanner.text.anchor.android',
        winphone: 'mobile.app.smartbanner.text.anchor.winphone',
        huawei: 'mobile.app.smartbanner.text.anchor.huawei',
    },
};

const sendLinkAnalytics = (userType: UserType) => {
    appBannerClickExternal({ category: userType });
    appInstallBannerButtonClick();
};

interface AppSmartBannerProps {
    spacingBottom?: ReactNode;
}

const AppSmartBanner: TranslatedComponent<AppSmartBannerProps> = ({ trls, spacingBottom }) => {
    const appLink = useSelector((state) => state.appLink);
    const device = useSelector((state) => state.device);
    const userType = useSelector((state) => state.userType);
    const isWebView = useSelector((state) => state.isWebView);
    const isAnonymousMagritteExpB = useSelector((state) => state.isAnonymousMagritteExpB);
    const [isVisible, setIsVisible] = useState(true);
    const wrapperRef = useRef<HTMLDivElement>(null);
    const { isXS } = useBreakpoint();

    const onClickClose = () => {
        const hideUntil = Date.now() + DAY * DAYS_TO_HIDE_AFTER_CLOSE;
        Storage.setItem(STORAGE_VARIABLE_NAME, String(hideUntil));
        setIsVisible(false);
    };

    const shouldShowBanner = useMemo(() => {
        const hiddenUntil = Number(Storage.getItem(STORAGE_VARIABLE_NAME));
        if (
            (hiddenUntil && hiddenUntil > Date.now()) ||
            !isVisible ||
            !appLink.detected ||
            isWebView ||
            !device.type ||
            !isXS
        ) {
            return false;
        }
        return true;
    }, [appLink.detected, device.type, isVisible, isWebView, isXS]);

    useEffect(() => {
        if (isVisible && wrapperRef.current) {
            appInstallBannerElementShown(wrapperRef.current);
        }
    }, [isVisible]);

    const link = `${appLink.href}?${XS_ONLY_LINK_PARAM}=${isAnonymousMagritteExpB ? 'hh_exp_b' : 'hh_exp_c'}`;

    if (!shouldShowBanner) {
        return null;
    }

    return (
        <>
            <Card ref={wrapperRef} showBorder stretched borderRadius={16} padding={16}>
                <Cell
                    right={<Action icon={CrossOutlinedSize24} onClick={onClickClose} mode="secondary" />}
                    left={
                        <Link href={link} data-qa="app-banner" onClick={() => sendLinkAnalytics(userType as UserType)}>
                            <span
                                className={classnames(styles.icon, {
                                    [`app-banner-icon_${appLink.type}`]: appLink.type,
                                })}
                                data-qa="app-banner-img"
                            />
                        </Link>
                    }
                >
                    <CellText type="title">{trls[TrlKeys.title]}</CellText>
                    <VSpacing default={4} />
                    {!!device.type && (
                        <Link href={link} onClick={() => sendLinkAnalytics(userType as UserType)}>
                            <CellText type="subtitle" style="accent" data-qa="app-banner-storelink">
                                {trls[TrlKeys.storeLinks[device.type]]}
                            </CellText>
                        </Link>
                    )}
                </Cell>
            </Card>
            {spacingBottom}
        </>
    );
};

export default translation(AppSmartBanner);
