import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';

const EMPLOYER_DOCUMENTS = 'EMPLOYER_DOCUMENTS';
const ADD_EMPLOYER_DOCUMENT = 'ADD_EMPLOYER_DOCUMENT';
const UPDATE_EMPLOYER_DOCUMENT = 'UPDATE_EMPLOYER_DOCUMENT';
const DELETE_EMPLOYER_DOCUMENT = 'DELETE_EMPLOYER_DOCUMENT';

export enum DocumentError {
    LimitReached = 'limitReached',
    BadFileType = 'badFileType',
    FileTooLarge = 'fileTooLarge',
    DeleteError = 'deleteError',
    BadChangeTime = 'badChangeTime',
    NoChangeType = 'noChangeType',
    OtherError = 'otherError',
}

export interface Document {
    id: string;
    url?: string;
    fileName: string;
    created: number;
    pending?: boolean;
    error?: DocumentError;
}

interface PayloadTypes {
    [EMPLOYER_DOCUMENTS]: Document[];
    [ADD_EMPLOYER_DOCUMENT]: Document;
    [DELETE_EMPLOYER_DOCUMENT]: string;
    [UPDATE_EMPLOYER_DOCUMENT]: { id: string; props: Partial<Document> };
}

export const INITIAL_STATE = [];

const actionCreator = ActionCreatorHelper<PayloadTypes>();
export const employerDocumentsAction = actionCreator(EMPLOYER_DOCUMENTS);
export const addEmployerDocumentAction = actionCreator(ADD_EMPLOYER_DOCUMENT);
export const updateEmployerDocumentAction = actionCreator(UPDATE_EMPLOYER_DOCUMENT);
export const deleteEmployerDocumentAction = actionCreator(DELETE_EMPLOYER_DOCUMENT);

export default createReducer<Document[], PayloadTypes>(INITIAL_STATE, {
    [EMPLOYER_DOCUMENTS]: (_state, { payload }) => payload,
    [ADD_EMPLOYER_DOCUMENT]: (state, { payload }) => [...state, payload],
    [DELETE_EMPLOYER_DOCUMENT]: (state, { payload }) => state.filter((document) => document.id !== payload),
    [UPDATE_EMPLOYER_DOCUMENT]: (state, { payload }) =>
        state.map((document) => {
            if (document.id === payload.id) {
                return { ...document, ...payload.props };
            }
            return document;
        }),
});
