import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

import { VacancyCompensation } from 'src/models/vacancy/compensation.type';

interface ResumeFormVacancy {
    id: number;
    name: string;
    currency: VacancyCompensation;
}

export default autoGeneratedReducer<ResumeFormVacancy>(null);
